import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card } from "../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { GroupButtons } from "../../../shared-module/components/group-buttons/group-buttons.component";
import { MaskedPhoneNumber } from "../../../shared-module/components/masked-phone-number/masked-phone-number.component";
import { LookupResource } from "../../../shared-module/api-resources/lookup.resource";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { AddressResource } from "../../../shared-module/api-resources/address.resource";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { ILegalRepresentative, LegalRepresentativeStore } from "./legal-representative.store";
import { ErrorContainer } from "../../../shared-module/components/error-container/error-container.component";
import { DebugContainer } from "../../../shared-module/components/debug-container/debug-container.component";
import { useFormValidation } from "../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { AddressCorrectionModal, AddressCorrectionModalOptions } from "../../extensions/address-correction-modal/address-correction-modal.component";
import { AddressValidationService } from "../../../shared-module/services/address-validation.service";
import { LegalRepresentativeValidationSchema } from "./legal-representative.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { GatewayNetworkError } from "shared-module/models/common.models";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";
import { SessionService } from "shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";

function LegalRepresentative() {
  const addressCorrectionModalId = "addressCorrectionModal";
  const navigate = useNavigate();
  const { id: applicationRefId } = useParams();

  const lookupService = new LookupResource(ApiConfig);
  const applicationService = new ApplicationResource(ApiConfig);
  const addressService = new AddressResource(ApiConfig);

  const [suffixTypes, setSuffixTypes] = useState<GatewayOapApi.SuffixTypeModel[]>([]);
  const [preferredLanguages, setPreferredLanguages] = useState<GatewayOapApi.PreferredLanguageModel[]>([]);
  const [counties, setCounties] = useState<GatewayOapApi.CountyModel[]>([]);
  const [states, setStates] = useState<GatewayOapApi.StateModel[]>([]);
  const [legalRepresentativeTypes, setLegalRepresentativeTypes] = useState<GatewayOapApi.LegalRepresentativeTypeModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savedState, setSavedState] = useState<ILegalRepresentative>({} as ILegalRepresentative);
  const [addressCorrenctionModalOptions, setAddressCorrenctionModalOptions] = useState<AddressCorrectionModalOptions>();
  const [fromSummary, setFromSummary] = useState<boolean>(false);
  const [secondaryLegalRepOtherLanguageRequired, setSecondaryLegalRepOtherLanguageRequired] = useState<boolean>(false);
  const [primaryLegalRepOtherLanguageRequired, setPrimaryLegalRepOtherLanguageRequired] = useState<boolean>(false);
  const store = LegalRepresentativeStore;
  const addressValidationService = new AddressValidationService(addressService, addressCorrenctionModalOptions);


  const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } =
    useFormValidation(LegalRepresentativeValidationSchema, store, null);

  useEffect(() => {
    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
      return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      lookupService.getSuffixTypes(),
      lookupService.getCounties(),
      lookupService.getPreferredLanguages(),
      lookupService.getStates(),
      applicationService.getLegalRepresentativeTypes(applicationRefId),
      applicationService.getApplication(applicationRefId),
    ])
      .then((response) => {
        setSuffixTypes(response[0].records);
        setCounties(response[1].records);
        setPreferredLanguages(response[2].records);
        setStates(response[3].records);
        setLegalRepresentativeTypes(response[4].records);

        const app = response[5].record;
        store.refresh(response[5].record);
        setPrimaryLegalRepOtherLanguageRequired(store.primary_PreferredLanguageId == '16');
        setSecondaryLegalRepOtherLanguageRequired(store.secondary_PreferredLanguageId == '16');
        setSavedState(store.buildState(response[5].record));
        setFromSummary(sessionStorage.getItem('fromSummary') == 'true' || false);
        return true;
      })
      .catch((ex) => { NavigationHelper.gotoHome(navigate); })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

  }, []);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
    if (currentValue === savedValue) {
      return;
    }

    setIsSaving(true);
    saveLegalRepresentative(eventSource)
      .finally(() => {
        setIsSaving(false);
      });
  }

  function saveLegalRepresentative(eventSource: string): Promise<any> {
    setIsSubmitting(true, eventSource);
    return applicationService.saveApplicationLegalRepresentatives(
      applicationRefId,
      Utility.stringToNumber(store.primary_RepresentativeTypeId, null),
      store.primary_NoFirstName,
      store.primary_FirstName,
      store.primary_LastName,
      store.primary_MiddleName,
      Utility.stringToNumber(store.primary_SuffixTypeId, null),
      store.primary_HasMailingInternationalAddress,
      store.primary_MailingStreetAddress,
      store.primary_MailingCity,
      Utility.stringToNumber(store.primary_MailingStateId, null),
      store.primary_MailingZipCode,
      Utility.stringToNumber(store.primary_MailingCountyId, null),
      store.primary_MailingInternationalAddress,
      store.primary_HasDifferentCurrentAddress,
      store.primary_HasCurrentInternationalAddress,
      store.primary_CurrentStreetAddress,
      store.primary_CurrentCity,
      Utility.stringToNumber(store.primary_CurrentStateId, null),
      store.primary_CurrentZipCode,
      Utility.stringToNumber(store.primary_CurrentCountyId, null),
      store.primary_CurrentInternationalAddress,
      store.primary_HomePhoneIsPrimary,
      store.unformat(store.primary_HomePhoneNumber),
      store.primary_MobilePhoneIsPrimary,
      store.unformat(store.primary_MobilePhoneNumber),
      store.primary_WorkPhoneIsPrimary,
      store.unformat(store.primary_WorkPhoneNumber),
      store.primary_EmailAddress,
      Utility.stringToNumber(store.primary_PreferredLanguageId, null),
      store.primary_PreferredLanguageOther,
      store.hasSecondaryLegalRepresentative == "has-second-legal-representative",

      Utility.stringToNumber(store.secondary_RepresentativeTypeId, null),
      store.secondary_NoFirstName,
      store.secondary_FirstName,
      store.secondary_LastName,
      store.secondary_MiddleName,
      Utility.stringToNumber(store.secondary_SuffixTypeId, null),
      store.secondary_HasMailingInternationalAddress,
      store.secondary_MailingStreetAddress,
      store.secondary_MailingCity,
      Utility.stringToNumber(store.secondary_MailingStateId, null),
      store.secondary_MailingZipCode,
      Utility.stringToNumber(store.secondary_MailingCountyId, null),
      store.secondary_MailingInternationalAddress,
      store.secondary_HasDifferentCurrentAddress,
      store.secondary_HasCurrentInternationalAddress,
      store.secondary_CurrentStreetAddress,
      store.secondary_CurrentCity,
      Utility.stringToNumber(store.secondary_CurrentStateId, null),
      store.secondary_CurrentZipCode,
      Utility.stringToNumber(store.secondary_CurrentCountyId, null),
      store.secondary_CurrentInternationalAddress,
      store.secondary_HomePhoneIsPrimary,
      store.unformat(store.secondary_HomePhoneNumber),
      store.secondary_MobilePhoneIsPrimary,
      store.unformat(store.secondary_MobilePhoneNumber),
      store.secondary_WorkPhoneIsPrimary,
      store.unformat(store.secondary_WorkPhoneNumber),
      store.secondary_EmailAddress,
      Utility.stringToNumber(store.secondary_PreferredLanguageId, null),
      store.secondary_PreferredLanguageOther)
      .then((response) => {
        return applicationService.getApplication(applicationRefId);
      })
      .then((response) => {
        setSavedState(store.buildState(response.record));
        var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
        if (guestSessionId != "0") {
          SessionService.slideGuestSession(Number(guestSessionId));
          EventBroadcasterService.RaiseGuestSessionExpirationEvent({
            isAuthenticated: false,
            remainingTimeInSeconds: 900,
            hasActiveMaintenance: false
          });
        }
        return true;
      })
      .catch(ex => {
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
      })
      .finally(() => {
        setIsSubmitting(false, null);
      });
  }

  function handleGoToNextStep() {
    touchedAll();
    //console.log(LegalRepresentativeValidationSchema.validate(store));
    if (LegalRepresentativeValidationSchema.isValidSync(store)) {

      setIsSaving(true);

      addressValidationService.verifyAddresses([
        {
          streetAddress: store.primary_MailingStreetAddress,
          city: store.primary_MailingCity,
          stateId: Utility.stringToNumber(store.primary_MailingStateId, null),
          zipCode: store.primary_MailingZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setPrimary_MailingStreetAddress(streetAddress);
            store.setPrimary_MailingCity(city);
            store.setPrimary_MailingStateId(stateId);
            store.setPrimary_MailingZipCode(zipCode);
            return saveLegalRepresentative(null);
          }
        },
        {
          streetAddress: store.primary_CurrentStreetAddress,
          city: store.primary_CurrentCity,
          stateId: Utility.stringToNumber(store.primary_CurrentStateId, null),
          zipCode: store.primary_CurrentZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setPrimary_CurrentStreetAddress(streetAddress);
            store.setPrimary_CurrentCity(city);
            store.setPrimary_CurrentStateId(stateId);
            store.setPrimary_CurrentZipCode(zipCode);
            return saveLegalRepresentative(null);
          }
        },
        {
          streetAddress: store.secondary_MailingStreetAddress,
          city: store.secondary_MailingCity,
          stateId: Utility.stringToNumber(store.secondary_MailingStateId, null),
          zipCode: store.primary_CurrentZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setSecondary_MailingStreetAddress(streetAddress);
            store.setSecondary_MailingCity(city);
            store.setSecondary_MailingStateId(stateId);
            store.setSecondary_MailingZipCode(zipCode);
            return saveLegalRepresentative(null);
          }
        },
        {
          streetAddress: store.secondary_CurrentStreetAddress,
          city: store.secondary_CurrentCity,
          stateId: Utility.stringToNumber(store.secondary_CurrentStateId, null),
          zipCode: store.secondary_CurrentZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setSecondary_CurrentStreetAddress(streetAddress);
            store.setSecondary_CurrentCity(city);
            store.setSecondary_CurrentStateId(stateId);
            store.setSecondary_CurrentZipCode(zipCode);
            return saveLegalRepresentative(null);
          }
        }],
        () => {
          NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
        })
        .finally(() => {
          setIsSaving(false);
        });
      return;
    }
    scrollTo(0, 0);
  }

  function handleReturnToSummary(){
    touchedAll();
    if (LegalRepresentativeValidationSchema.isValidSync(store)) {
      NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
      return;
    }

    scrollTo(0, 0);
  }
  
  return (
    <>
      <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
      <AddressCorrectionModal id={addressCorrectionModalId} accessor={
        (options: AddressCorrectionModalOptions) => {
          setAddressCorrenctionModalOptions(options);
        }} />
      <PageTitleSetter step={5} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar step={5} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={!isLoading}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <form>
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the legal representative's name?</div>
            <div className="bds-section-details mt-2">Please enter a first and last name for the legal representative. Middle name and suffix are optional.</div>
            <div className="row mt-3">
              <div className="col-12">
                <BdsCheckbox
                  id="primary-no-first-name"
                  name="primary_NoFirstName"
                  checked={store.primary_NoFirstName}
                  onChange={(event) => {
                    store.setPrimary_NoFirstName(event.target.checked);
                    store.setPrimary_FirstName("");
                    setFieldsTouched("primary_NoFirstName", "primary_FirstName");
                    handleValueChange(event,
                      "primary_NoFirstName",
                      store.primary_FirstName,
                      savedState.primary_FirstName);
                  }}
                  onBlur={(e) => {
                    setFieldsTouched("primary_NoFirstName", "primary_FirstName");
                  }}
                  label="No First Name"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">First Name</label>
                <input type="text" name="primary_FirstName" value={store.primary_FirstName}
                  placeholder={store.primary_NoFirstName ? "No First Name" : ""}
                  onChange={(event) => { store.setPrimary_FirstName(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_FirstName");
                    handleValueChange(event,
                      "primary_FirstName",
                      store.primary_FirstName,
                      savedState.primary_FirstName)
                  }}
                  disabled={store.primary_NoFirstName}
                  className="bds-form-text bds-grid-form form-control" maxLength={35} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">Last Name</label>
                <input type="text" name="primary_LastName" value={store.primary_LastName}
                  onChange={(event) => { store.setPrimary_LastName(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_LastName");
                    handleValueChange(event,
                      "primary_LastName",
                      store.primary_LastName,
                      savedState.primary_LastName);
                  }}
                  className="bds-form-text bds-grid-form form-control" maxLength={35} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label">Middle Name</label>
                <input type="text" name="primary_MiddleName" value={store.primary_MiddleName}
                  onChange={(event) => { store.setPrimary_MiddleName(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MiddleName");
                    handleValueChange(event,
                      "primary_MiddleName",
                      store.primary_MiddleName,
                      savedState.primary_MiddleName);
                  }}
                  className="bds-form-text bds-grid-form form-control" maxLength={35} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label">Suffix Type</label>
                <select name="primary_SuffixTypeId" value={store.primary_SuffixTypeId}
                  onChange={(event) => {
                    store.setPrimary_SuffixTypeId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_SuffixTypeId");
                    handleValueChange(event,
                      "primary_SuffixTypeId",
                      store.primary_SuffixTypeId,
                      savedState.primary_SuffixTypeId);
                  }}
                  className="bds-form-select bds-grid-form form-select">
                  <option value="">Please Select ...</option>
                  {
                    suffixTypes.map((item, index) => (
                      <option key={index} value={item.suffixTypeId}>{item.suffixTypeName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading mandatory">
              What is the legal representative's relationship to the applicant?
            </div>
            <div className="bds-section-details mt-2">Please be prepared to present the legal documentation at the time of the applicant's intake interview.</div>
            <div className="row mt-3">
              <div className="col-12">
                <select name="primary_RepresentativeTypeId" value={store.primary_RepresentativeTypeId}
                  onChange={(event) => {
                    store.setPrimary_RepresentativeTypeId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_RepresentativeTypeId");
                    handleValueChange(event,
                      "primary_RepresentativeTypeId",
                      store.primary_RepresentativeTypeId,
                      savedState.primary_RepresentativeTypeId);
                  }}
                  className="bds-form-select bds-section-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    legalRepresentativeTypes.map((item, index) => (
                      <option key={index} value={item.legalRepresentativeTypeId}>
                        {item.legalRepresentativeTypeName}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the legal representative's mailing address?</div>
            <div className="bds-section-details mt-2">
              Please enter the address where the legal representative currently recieves mail.
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <BdsCheckbox
                  id="primary-mailing-international-address"
                  name="primary_HasMailingInternationalAddress"
                  checked={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => {
                    store.setPrimary_HasMailingInternationalAddress(event.target.checked);
                    store.setPrimary_MailingStreetAddress("");
                    store.setPrimary_MailingCity("");
                    store.setPrimary_MailingStateId("");
                    store.setPrimary_MailingZipCode("");
                    store.setPrimary_MailingCountyId("");
                    store.setPrimary_MailingInternationalAddress("");
                    setFieldsTouched(
                      "primary_HasMailingInternationalAddress",
                      "primary_MailingStreetAddress",
                      "primary_MailingCity",
                      "primary_MailingStateId",
                      "primary_MailingZipCode",
                      "primary_MailingCountyId",
                      "primary_MailingInternationalAddress"
                    );
                    handleValueChange(event,
                      "primary_HasMailingInternationalAddress",
                      store.primary_HasMailingInternationalAddress,
                      savedState.primary_HasMailingInternationalAddress);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_HasMailingInternationalAddress");
                  }}
                  label="International Address"
                />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">Street Address</label>
                <input type="text" name="primary_MailingStreetAddress"
                  value={store.primary_MailingStreetAddress}
                  disabled={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => { store.setPrimary_MailingStreetAddress(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MailingStreetAddress");
                    handleValueChange(event,
                      "primary_MailingStreetAddress",
                      store.primary_MailingStreetAddress,
                      savedState.primary_MailingStreetAddress);
                  }}
                  className="bds-form-text form-control" maxLength={331} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">City</label>
                <input type="text" name="primary_MailingCity"
                  value={store.primary_MailingCity}
                  disabled={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => { store.setPrimary_MailingCity(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MailingCity");
                    handleValueChange(event,
                      "primary_MailingCity",
                      store.primary_MailingCity,
                      savedState.primary_MailingCity);
                  }}
                  className="bds-form-text form-control" maxLength={255} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">State</label>
                <select name="primary_MailingStateId"
                  value={store.primary_MailingStateId}
                  disabled={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => {
                    store.setPrimary_MailingStateId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MailingStateId");
                    handleValueChange(event,
                      "primary_MailingStateId",
                      store.primary_MailingStateId,
                      savedState.primary_MailingStateId);
                  }}
                  className="bds-form-select bds-grid-form form-select">
                  <option value="">Please Select ...</option>
                  {
                    states.map((item, index) => (
                      <option key={index} value={item.stateId}>{item.stateName}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">Zip Code</label>
                <input type="text" name="primary_MailingZipCode"
                  value={store.primary_MailingZipCode}
                  disabled={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => { store.setPrimary_MailingZipCode(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MailingZipCode");
                    handleValueChange(event,
                      "primary_MailingZipCode",
                      store.primary_MailingZipCode,
                      savedState.primary_MailingZipCode);
                  }}
                  className="bds-form-text form-control" maxLength={10} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">County</label>
                <select name="primary_MailingCountyId"
                  value={store.primary_MailingCountyId}
                  disabled={store.primary_HasMailingInternationalAddress}
                  onChange={(event) => {
                    store.setPrimary_MailingCountyId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_MailingCountyId");
                    handleValueChange(event,
                      "primary_MailingCountyId",
                      store.primary_MailingCountyId,
                      savedState.primary_MailingCountyId);
                  }}
                  className="bds-form-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    counties.map((item, index) => (
                      <option key={index} value={item.countyId}>{item.countyName}</option>
                    ))
                  }
                </select>
              </div>
              <ShowThen when={store.primary_HasMailingInternationalAddress}>
                <div className="col-12 mt-3">
                  <label className="bds-form-label form-label mandatory">International Address</label>
                  <textarea name="primary_MailingInternationalAddress" value={store.primary_MailingInternationalAddress}
                    onChange={(event) => {
                      store.setPrimary_MailingInternationalAddress(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_MailingInternationalAddress");
                      handleValueChange(event,
                        "primary_MailingInternationalAddress",
                        store.primary_MailingInternationalAddress,
                        savedState.primary_MailingInternationalAddress);
                    }}
                    className="bds-textarea form-control" rows={6} maxLength={1000}>
                  </textarea>
                </div>
              </ShowThen>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">Is the legal representative's current address different from the legal representative's mailing address?</div>
            <div className="bds-section-details mt-2">If yes, please check this box. If no, please go to next question.</div>
            <div className="row mt-3">
              <div className="col-12">
                <BdsCheckbox
                  id="primary-diff-mailing-address"
                  name="primary_HasDifferentCurrentAddress"
                  checked={store.primary_HasDifferentCurrentAddress}
                  onChange={(event) => {
                    store.setPrimary_HasDifferentCurrentAddress(event.target.checked);
                    store.setPrimary_HasCurrentInternationalAddress(false);
                    store.setPrimary_CurrentStreetAddress("");
                    store.setPrimary_CurrentCity("");
                    store.setPrimary_CurrentStateId("");
                    store.setPrimary_CurrentZipCode("");
                    store.setPrimary_CurrentCountyId("");
                    store.setPrimary_CurrentInternationalAddress("");
                    setFieldsTouched(
                      "primary_HasDifferentCurrentAddress",
                      "primary_HasCurrentInternationalAddress",
                      "primary_CurrentStreetAddress",
                      "primary_CurrentCity",
                      "primary_CurrentStateId",
                      "primary_CurrentZipCode",
                      "primary_CurrentCountyId",
                      "primary_CurrentInternationalAddress"
                    );
                    handleValueChange(event,
                      "primary_HasDifferentCurrentAddress",
                      store.primary_HasDifferentCurrentAddress,
                      savedState.primary_HasDifferentCurrentAddress);
                  }}
                  onBlur={(e) => {
                    setFieldsTouched("primary_HasDifferentCurrentAddress");
                  }}
                  label="Current address is different than mailing address"
                />
              </div>
            </div>

            <ShowThen when={store.primary_HasDifferentCurrentAddress}>
              <div className="bds-section-heading mt-3">What is the legal representative's current address?</div>
              <div className="bds-section-details mt-2">Please enter the address where the legal representative currently lives.</div>

              <div className="row mt-3">
                <div className="col-12">
                  <BdsCheckbox
                    id="primary-current-international-address"
                    name="primary_HasCurrentInternationalAddress" checked={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => {
                      store.setPrimary_HasCurrentInternationalAddress(event.target.checked);
                      store.setPrimary_CurrentStreetAddress("");
                      store.setPrimary_CurrentCity("");
                      store.setPrimary_CurrentStateId("");
                      store.setPrimary_CurrentZipCode("");
                      store.setPrimary_CurrentCountyId("");
                      store.setPrimary_CurrentInternationalAddress("");
                      setFieldsTouched(
                        "primary_HasCurrentInternationalAddress",
                        "primary_CurrentStreetAddress",
                        "primary_CurrentCity",
                        "primary_CurrentStateId",
                        "primary_CurrentZipCode",
                        "primary_CurrentCountyId",
                        "primary_CurrentInternationalAddress"
                      );
                      handleValueChange(event,
                        "primary_HasCurrentInternationalAddress",
                        store.primary_HasCurrentInternationalAddress,
                        savedState.primary_HasCurrentInternationalAddress);
                    }}
                    onBlur={(e) => {
                      setFieldsTouched("primary_HasCurrentInternationalAddress");
                    }}
                    label="International Address"
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Street Address</label>
                  <input type="text" name="primary_CurrentStreetAddress"
                    value={store.primary_CurrentStreetAddress}
                    disabled={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => { store.setPrimary_CurrentStreetAddress(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_CurrentStreetAddress");
                      handleValueChange(event,
                        "primary_CurrentStreetAddress",
                        store.primary_CurrentStreetAddress,
                        savedState.primary_CurrentStreetAddress);
                    }}
                    className="bds-form-text form-control" maxLength={331} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">City</label>
                  <input type="text" name="primary_CurrentCity"
                    value={store.primary_CurrentCity}
                    disabled={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => { store.setPrimary_CurrentCity(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_CurrentCity");
                      handleValueChange(event,
                        "primary_CurrentCity",
                        store.primary_CurrentCity,
                        savedState.primary_CurrentCity);
                    }}
                    className="bds-form-text form-control" maxLength={255} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">State</label>
                  <select name="primary_CurrentStateId"
                    value={store.primary_CurrentStateId}
                    disabled={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => {
                      store.setPrimary_CurrentStateId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_CurrentStateId");
                      handleValueChange(event,
                        "primary_CurrentStateId",
                        store.primary_CurrentStateId,
                        savedState.primary_CurrentStateId);
                    }}
                    className="bds-form-select bds-grid-form form-select">
                    <option value="">Please Select ...</option>
                    {
                      states.map((item, index) => (
                        <option key={index} value={item.stateId}>{item.stateName}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Zip Code</label>
                  <input type="text" name="primary_CurrentZipCode"
                    value={store.primary_CurrentZipCode}
                    disabled={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => { store.setPrimary_CurrentZipCode(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_CurrentZipCode");
                      handleValueChange(event,
                        "primary_CurrentZipCode",
                        store.primary_CurrentZipCode,
                        savedState.primary_CurrentZipCode)
                    }}
                    className="bds-form-text form-control" maxLength={10} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">County</label>
                  <select name="primary_CurrentCountyId"
                    value={store.primary_CurrentCountyId}
                    disabled={store.primary_HasCurrentInternationalAddress}
                    onChange={(event) => {
                      store.setPrimary_CurrentCountyId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_CurrentCountyId");
                      handleValueChange(event,
                        "primary_CurrentCountyId",
                        store.primary_CurrentCountyId,
                        savedState.primary_CurrentCountyId);
                    }}
                    className="bds-form-select form-select">
                    <option value="">Please Select ...</option>
                    {
                      counties.map((item, index) => (
                        <option key={index} value={item.countyId}>{item.countyName}</option>
                      ))
                    }
                  </select>
                </div>
                <ShowThen when={store.primary_HasCurrentInternationalAddress}>
                  <div className="col-12 mt-3">
                    <label className="bds-form-label form-label mandatory">International Address:</label>
                    <textarea name="primary_CurrentInternationalAddress"
                      value={store.primary_CurrentInternationalAddress}
                      onChange={(event) => {
                        store.setPrimary_CurrentInternationalAddress(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("primary_CurrentInternationalAddress");
                        handleValueChange(event,
                          "primary_CurrentInternationalAddress",
                          store.primary_CurrentInternationalAddress,
                          savedState.primary_CurrentInternationalAddress);
                      }}
                      className="bds-textarea form-control" rows={6} maxLength={1000}>
                    </textarea>
                  </div>
                </ShowThen>
              </div>
            </ShowThen>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the legal representative's phone number?</div>
            <div className="bds-section-details mt-2">Please enter up to three phone numbers without hyphens and designate one as primary. If the legal representative does not have a phone number, leave the phone numbers blank and go to the next question.</div>
            <div className="mt-4 text-center">
              <div className="row">
                <div className="col-2">Primary</div>
                <div className="col-3">Phone Type</div>
                <div className="col-7 col-lg-5">Phone Number</div>
              </div>
              <div className="row mt-2">
                <div className="col-2">
                  <BdsCheckbox
                    id="primary-home-phone-is-primary"
                    name="primary_HomePhoneIsPrimary"
                    checked={store.primary_HomePhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setPrimary_HomePhoneIsPrimary(event.target.checked);
                      setFieldsTouched("primary_HomePhoneIsPrimary");
                      event.target.checked && store.setPrimary_MobilePhoneIsPrimary(false);
                      event.target.checked && store.setPrimary_WorkPhoneIsPrimary(false);
                      handleValueChange(event,
                        "primary_HomePhoneIsPrimary",
                        store.primary_HomePhoneIsPrimary,
                        savedState.primary_HomePhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_HomePhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3">Home</div>
                <div className="col-7 col-lg-5">
                  <input type="text" name="primary_HomePhoneNumber"
                    value={store.primary_HomePhoneNumber}
                    onChange={(event) => {
                      store.setPrimary_HomePhoneNumber(event.currentTarget.value);
                      store.formatPhoneNumber()
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_HomePhoneNumber");
                      handleValueChange(event,
                        "primary_HomePhoneNumber",
                        store.primary_HomePhoneNumber,
                        savedState.primary_HomePhoneNumber);
                    }}
                    className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-2">
                  <BdsCheckbox
                    id="primary-mobile-phone-is-primary"
                    name="primary_MobilePhoneIsPrimary"
                    checked={store.primary_MobilePhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setPrimary_MobilePhoneIsPrimary(event.target.checked);
                      setFieldsTouched("primary_MobilePhoneIsPrimary");
                      event.target.checked && store.setPrimary_HomePhoneIsPrimary(false);
                      event.target.checked && store.setPrimary_WorkPhoneIsPrimary(false);
                      handleValueChange(event,
                        "primary_MobilePhoneIsPrimary",
                        store.primary_MobilePhoneIsPrimary,
                        savedState.primary_MobilePhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_MobilePhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3">Mobile</div>
                <div className="col-7 col-lg-5">
                  <input type="text" name="primary_MobilePhoneNumber"
                    value={store.primary_MobilePhoneNumber}
                    onChange={(event) => {
                      store.setPrimary_MobilePhoneNumber(event.currentTarget.value);
                      store.formatPhoneNumber()
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_MobilePhoneNumber");
                      handleValueChange(event,
                        "primary_MobilePhoneNumber",
                        store.primary_MobilePhoneNumber,
                        savedState.primary_MobilePhoneNumber);
                    }}
                    className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-2">
                  <BdsCheckbox
                    id="primary-work-phone-is-primary"
                    name="primary_WorkPhoneIsPrimary"
                    checked={store.primary_WorkPhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setPrimary_WorkPhoneIsPrimary(event.target.checked);
                      setFieldsTouched("primary_WorkPhoneIsPrimary");
                      event.target.checked && store.setPrimary_HomePhoneIsPrimary(false);
                      event.target.checked && store.setPrimary_MobilePhoneIsPrimary(false);
                      handleValueChange(event,
                        "primary_WorkPhoneIsPrimary",
                        store.primary_WorkPhoneIsPrimary,
                        savedState.primary_WorkPhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_WorkPhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3">Work</div>
                <div className="col-7 col-lg-5">
                  <input type="text" name="primary_WorkPhoneNumber"
                    value={store.primary_WorkPhoneNumber}
                    onChange={(event) => {
                      store.setPrimary_WorkPhoneNumber(event.currentTarget.value);
                      store.formatPhoneNumber()
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("primary_WorkPhoneNumber");
                      handleValueChange(event,
                        "primary_WorkPhoneNumber",
                        store.primary_WorkPhoneNumber,
                        savedState.primary_WorkPhoneNumber);
                    }}
                    className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the legal representative's email address?</div>
            <div className="bds-section-details mt-2">If the legal representative does not have an email address, leave the email address blank and go to the next question.</div>
            <div className="row mt-3">
              <div className="col-12">
                <input type="text" name="primary_EmailAddress" value={store.primary_EmailAddress}
                  onChange={(event) => { store.setPrimary_EmailAddress(event.target.value) }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_EmailAddress");
                    handleValueChange(event,
                      "primary_EmailAddress",
                      store.primary_EmailAddress,
                      savedState.primary_EmailAddress);
                  }}
                  className="bds-form-text bds-section-email form-control" maxLength={150} />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the legal representatives's preferred language?</div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <div className="bds-form-label form-label mt-2">Please select from the following list.</div>
                <select
                  name="primary_PreferredLanguageId"
                  value={store.primary_PreferredLanguageId}
                  onChange={(event) => {
                    store.setPrimary_PreferredLanguageId(event.target.value);
                    store.setPrimary_PreferredLanguageOther("");
                    setPrimaryLegalRepOtherLanguageRequired(store.primary_PreferredLanguageId == '16')
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_PreferredLanguageId", "primary_PreferredLanguageOther");
                    handleValueChange(event,
                      "primary_PreferredLanguageId",
                      store.primary_PreferredLanguageId,
                      savedState.primary_PreferredLanguageId);
                  }}
                  className="bds-form-select bds-grid-form form-select">
                  <option value="">Please Select ...</option>
                  {preferredLanguages.map((item, index) => (
                    <option key={index} value={item.preferredLanguageId}>
                      {item.preferredLanguageName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6">
                <ShowThen when={primaryLegalRepOtherLanguageRequired}>
                  <div className="bds-form-label form-label mt-2 mandatory">If other, please specify.</div>
                </ShowThen>
                <ShowThen when={!primaryLegalRepOtherLanguageRequired}>
                  <div className="bds-form-label form-label mt-2">If other, please specify.</div>
                </ShowThen>

                <input type="text" name="primary_PreferredLanguageOther"
                  disabled={store.primary_PreferredLanguageId != "16"}
                  value={store.primary_PreferredLanguageOther}
                  onChange={(event) => {
                    store.setPrimary_PreferredLanguageOther(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("primary_PreferredLanguageOther");
                    handleValueChange(event,
                      "primary_PreferredLanguageOther",
                      store.primary_PreferredLanguageOther,
                      savedState.primary_PreferredLanguageOther);
                  }}
                  className="bds-form-text form-control" />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading mandatory">Does the applicant have a second legal representative?</div>
            <div className="mt-4">
              <GroupButtons name="hasSecondaryLegalRepresentative"
                value={store.hasSecondaryLegalRepresentative}
                onChange={(event) => {
                  store.setHasSecondaryLegalRepresentative(event.value);
                  store.setSecondary_NoFirstName(false);
                  store.setSecondary_FirstName("");
                  store.setSecondary_LastName("");
                  store.setSecondary_MiddleName("");
                  store.setSecondary_SuffixTypeId("");
                  store.setSecondary_RepresentativeTypeId("");
                  store.setSecondary_HasMailingInternationalAddress(false);
                  store.setSecondary_MailingStreetAddress("");
                  store.setSecondary_MailingCity("");
                  store.setSecondary_MailingStateId("");
                  store.setSecondary_MailingZipCode("");
                  store.setSecondary_MailingCountyId("");
                  store.setSecondary_MailingInternationalAddress("");
                  store.setSecondary_HasDifferentCurrentAddress(false);
                  store.setSecondary_HasCurrentInternationalAddress(false);
                  store.setSecondary_CurrentStreetAddress("");
                  store.setSecondary_CurrentCity("");
                  store.setSecondary_CurrentStateId("");
                  store.setSecondary_CurrentZipCode("");
                  store.setSecondary_CurrentCountyId("");
                  store.setSecondary_CurrentInternationalAddress("");
                  store.setSecondary_HomePhoneIsPrimary(false);
                  store.setSecondary_HomePhoneNumber("");
                  store.setSecondary_MobilePhoneIsPrimary(false);
                  store.setSecondary_MobilePhoneNumber("");
                  store.setSecondary_WorkPhoneIsPrimary(false);
                  store.setSecondary_WorkPhoneNumber("");
                  store.setSecondary_EmailAddress("");
                  store.setSecondary_PreferredLanguageId("");
                  store.setSecondary_PreferredLanguageOther("");

                  setFieldsTouched(
                    "hasSecondaryLegalRepresentative",
                    "secondary_NoFirstName",
                    "secondary_FirstName",
                    "secondary_LastName",
                    "secondary_MiddleName",
                    "secondary_SuffixTypeId",
                    "secondary_RepresentativeTypeId",
                    "secondary_HasMailingInternationalAddress",
                    "secondary_MailingStreetAddress",
                    "secondary_MailingCity",
                    "secondary_MailingStateId",
                    "secondary_MailingZipCode",
                    "secondary_MailingCountyId",
                    "secondary_MailingInternationalAddress",
                    "secondary_HasDifferentCurrentAddress",
                    "secondary_HasCurrentInternationalAddress",
                    "secondary_CurrentStreetAddress",
                    "secondary_CurrentCity",
                    "secondary_CurrentStateId",
                    "secondary_CurrentZipCode",
                    "secondary_CurrentCountyId",
                    "secondary_CurrentInternationalAddress",
                    "secondary_HomePhoneIsPrimary",
                    "secondary_HomePhoneNumber",
                    "secondary_MobilePhoneIsPrimary",
                    "secondary_MobilePhoneNumber",
                    "secondary_WorkPhoneIsPrimary",
                    "secondary_WorkPhoneNumber",
                    "secondary_EmailAddress",
                    "secondary_PreferredLanguageId",
                    "secondary_PreferredLanguageOther"
                  );

                  handleValueChange(event,
                    "hasSecondaryLegalRepresentative",
                    store.hasSecondaryLegalRepresentative,
                    savedState.hasSecondaryLegalRepresentative);
                }}
                onBlur={(event) => {
                  setFieldsTouched("hasSecondaryLegalRepresentative");
                }}
                items={[
                  { itemValue: "has-second-legal-representative", content: <>Yes</> },
                  { itemValue: "has-not-second-legal-representative", content: <>No</> }
                ]}></GroupButtons>
            </div>
          </Card>
        </div>

        <ShowThen when={store.hasSecondaryLegalRepresentative == "has-second-legal-representative"}>
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">What is the secondary legal representative's name?</div>
              <div className="bds-section-details mt-2">Please enter a first and last name for the secondary legal representative. Middle name and suffix are optional.</div>
              <div className="row mt-3">
                <div className="col-12">
                  <BdsCheckbox
                    id="secondary-no-first-name"
                    name="secondary_NoFirstName" checked={store.secondary_NoFirstName}
                    onChange={(event) => {
                      store.setSecondary_NoFirstName(event.target.checked);
                      store.setSecondary_FirstName("");
                      setFieldsTouched(
                        "secondary_NoFirstName",
                        "secondary_FirstName"
                      );
                      handleValueChange(event,
                        "secondary_NoFirstName",
                        store.secondary_FirstName,
                        savedState.secondary_FirstName);
                    }}
                    onBlur={(e) => {
                      setFieldsTouched("secondary_NoFirstName", "secondary_FirstName");
                    }}
                    label="No First Name"
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">First Name</label>
                  <input type="text" name="secondary_FirstName" value={store.secondary_FirstName}
                    placeholder={store.secondary_NoFirstName ? "No First Name" : ""}
                    onChange={(event) => { store.setSecondary_FirstName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_FirstName");
                      handleValueChange(event,
                        "secondary_FirstName",
                        store.secondary_FirstName,
                        savedState.secondary_FirstName)
                    }}
                    disabled={store.secondary_NoFirstName}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Last Name</label>
                  <input type="text" name="secondary_LastName" value={store.secondary_LastName}
                    onChange={(event) => { store.setSecondary_LastName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_LastName");
                      handleValueChange(event,
                        "secondary_LastName",
                        store.secondary_LastName,
                        savedState.secondary_LastName);
                    }}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label">Middle Name</label>
                  <input type="text" name="secondary_MiddleName" value={store.secondary_MiddleName}
                    onChange={(event) => { store.setSecondary_MiddleName(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MiddleName");
                      handleValueChange(event,
                        "secondary_MiddleName",
                        store.secondary_MiddleName,
                        savedState.secondary_MiddleName);
                    }}
                    className="bds-form-text bds-grid-form form-control" maxLength={35} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label">Suffix Type</label>
                  <select name="secondary_SuffixTypeId" value={store.secondary_SuffixTypeId}
                    onChange={(event) => {
                      store.setSecondary_SuffixTypeId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_SuffixTypeId");
                      handleValueChange(event,
                        "secondary_SuffixTypeId",
                        store.secondary_SuffixTypeId,
                        savedState.secondary_SuffixTypeId);
                    }}
                    className="bds-form-select bds-grid-form form-select">
                    <option value="">Please Select ...</option>
                    {
                      suffixTypes.map((item, index) => (
                        <option key={index} value={item.suffixTypeId}>{item.suffixTypeName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading mandatory">
                What is the secondary Legal Representative's relationship to the applicant?
              </div>
              <div className="bds-section-details mt-2">Please be prepared to present the legal documentation at the time of the applicant's intake interview.</div>
              <div className="row mt-3">
                <div className="col-12">
                  <select name="secondary_RepresentativeTypeId" value={store.secondary_RepresentativeTypeId}
                    onChange={(event) => {
                      store.setSecondary_RepresentativeTypeId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_RepresentativeTypeId");
                      handleValueChange(event,
                        "secondary_RepresentativeTypeId",
                        store.secondary_RepresentativeTypeId,
                        savedState.secondary_RepresentativeTypeId);
                    }}
                    className="bds-form-select bds-section-select form-select">
                    <option value="">Please Select ...</option>
                    {
                      legalRepresentativeTypes.map((item, index) => (
                        <option key={index} value={item.legalRepresentativeTypeId}>
                          {item.legalRepresentativeTypeName}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">What is the secondary Legal Representative's mailing address?</div>
              <div className="bds-section-details mt-2">
                Please enter the address where the secondary legal representative currently recieves mail.
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <BdsCheckbox
                    id="secondary-mailing-international-address"
                    name="secondary_HasMailingInternationalAddress"
                    checked={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => {
                      store.setSecondary_HasMailingInternationalAddress(event.target.checked);
                      store.setSecondary_MailingStreetAddress("");
                      store.setSecondary_MailingCity("");
                      store.setSecondary_MailingStateId("");
                      store.setSecondary_MailingZipCode("");
                      store.setSecondary_MailingCountyId("");
                      store.setSecondary_MailingInternationalAddress("");
                      setFieldsTouched(
                        "secondary_HasMailingInternationalAddress",
                        "secondary_MailingStreetAddress",
                        "secondary_MailingCity",
                        "secondary_MailingStateId",
                        "secondary_MailingZipCode",
                        "secondary_MailingCountyId",
                        "secondary_MailingInternationalAddress"
                      );
                      handleValueChange(event,
                        "secondary_HasMailingInternationalAddress",
                        store.secondary_HasMailingInternationalAddress,
                        savedState.secondary_HasMailingInternationalAddress);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_HasMailingInternationalAddress");
                    }}
                    label="International Address"
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Street Address</label>
                  <input type="text" name="secondary_MailingStreetAddress"
                    value={store.secondary_MailingStreetAddress}
                    disabled={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => { store.setSecondary_MailingStreetAddress(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MailingStreetAddress");
                      handleValueChange(event,
                        "secondary_MailingStreetAddress",
                        store.secondary_MailingStreetAddress,
                        savedState.secondary_MailingStreetAddress);
                    }}
                    className="bds-form-text form-control" maxLength={331} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">City</label>
                  <input type="text" name="secondary_MailingCity"
                    value={store.secondary_MailingCity}
                    disabled={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => { store.setSecondary_MailingCity(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MailingCity");
                      handleValueChange(event,
                        "secondary_MailingCity",
                        store.secondary_MailingCity,
                        savedState.secondary_MailingCity);
                    }}
                    className="bds-form-text form-control" maxLength={255} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">State</label>
                  <select name="secondary_MailingStateId"
                    value={store.secondary_MailingStateId}
                    disabled={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => {
                      store.setSecondary_MailingStateId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MailingStateId");
                      handleValueChange(event,
                        "secondary_MailingStateId",
                        store.secondary_MailingStateId,
                        savedState.secondary_MailingStateId);
                    }}
                    className="bds-form-select bds-grid-form form-select">
                    <option value="">Please Select ...</option>
                    {
                      states.map((item, index) => (
                        <option key={index} value={item.stateId}>{item.stateName}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">Zip Code</label>
                  <input type="text" name="secondary_MailingZipCode"
                    value={store.secondary_MailingZipCode}
                    disabled={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => { store.setSecondary_MailingZipCode(event.target.value); }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MailingZipCode");
                      handleValueChange(event,
                        "secondary_MailingZipCode",
                        store.secondary_MailingZipCode,
                        savedState.secondary_MailingZipCode);
                    }}
                    className="bds-form-text form-control" maxLength={10} />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <label className="bds-form-label form-label mandatory">County</label>
                  <select name="secondary_MailingCountyId"
                    value={store.secondary_MailingCountyId}
                    disabled={store.secondary_HasMailingInternationalAddress}
                    onChange={(event) => {
                      store.setSecondary_MailingCountyId(event.target.value);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_MailingCountyId");
                      handleValueChange(event,
                        "secondary_MailingCountyId",
                        store.secondary_MailingCountyId,
                        savedState.secondary_MailingCountyId);
                    }}
                    className="bds-form-select form-select">
                    <option value="">Please Select ...</option>
                    {
                      counties.map((item, index) => (
                        <option key={index} value={item.countyId}>{item.countyName}</option>
                      ))
                    }
                  </select>
                </div>
                <ShowThen when={store.secondary_HasMailingInternationalAddress}>
                  <div className="col-12 mt-3">
                    <label className="bds-form-label form-label mandatory">International Address</label>
                    <textarea name="secondary_MailingInternationalAddress"
                      value={store.secondary_MailingInternationalAddress}
                      onChange={(event) => { store.setSecondary_MailingInternationalAddress(event.target.value); }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_MailingInternationalAddress");
                        handleValueChange(event,
                          "secondary_MailingInternationalAddress",
                          store.secondary_MailingInternationalAddress,
                          savedState.secondary_MailingInternationalAddress);
                      }}
                      className="bds-textarea form-control" rows={6} maxLength={1000}>
                    </textarea>
                  </div>
                </ShowThen>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">Is the secondary legal representative's current address different from the secondary legal representative's mailing address?</div>
              <div className="bds-section-details mt-2">If yes, please check this box. If no, please go to next question.</div>
              <div className="row mt-3">
                <div className="col-12">
                  <BdsCheckbox
                    id="secondary-diff-mailing-address"
                    name="secondary_HasDifferentCurrentAddress"
                    checked={store.secondary_HasDifferentCurrentAddress}
                    onChange={(event) => {
                      store.setSecondary_HasDifferentCurrentAddress(event.target.checked);
                      store.setSecondary_HasCurrentInternationalAddress(false);
                      store.setSecondary_CurrentStreetAddress("");
                      store.setSecondary_CurrentCity("");
                      store.setSecondary_CurrentStateId("");
                      store.setSecondary_CurrentZipCode("");
                      store.setSecondary_CurrentCountyId("");
                      store.setSecondary_CurrentInternationalAddress("");
                      setFieldsTouched(
                        "secondary_HasDifferentCurrentAddress",
                        "secondary_HasCurrentInternationalAddress",
                        "secondary_CurrentStreetAddress",
                        "secondary_CurrentCity",
                        "secondary_CurrentStateId",
                        "secondary_CurrentZipCode",
                        "secondary_CurrentCountyId",
                        "secondary_CurrentInternationalAddress"
                      );
                      handleValueChange(event,
                        "secondary_HasDifferentCurrentAddress",
                        store.secondary_HasDifferentCurrentAddress,
                        savedState.secondary_HasDifferentCurrentAddress);
                    }}
                    onBlur={(e) => {
                      setFieldsTouched("secondary_HasDifferentCurrentAddress");
                    }}
                    label="Current address is different than mailing address"
                  />
                </div>
              </div>

              <ShowThen when={store.secondary_HasDifferentCurrentAddress}>
                <div className="bds-section-heading mt-3">What is the legal representative's current address?</div>
                <div className="bds-section-details mt-2">Please enter the address where the legal representative currently lives.</div>

                <div className="row mt-3">
                  <div className="col-12">
                    <BdsCheckbox
                      id="secondary-current-international-address"
                      name="secondary_HasCurrentInternationalAddress" checked={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => {
                        store.setSecondary_HasCurrentInternationalAddress(event.target.checked);
                        store.setSecondary_CurrentStreetAddress("");
                        store.setSecondary_CurrentCity("");
                        store.setSecondary_CurrentStateId("");
                        store.setSecondary_CurrentZipCode("");
                        store.setSecondary_CurrentCountyId("");
                        store.setSecondary_CurrentInternationalAddress("");
                        setFieldsTouched(
                          "secondary_HasCurrentInternationalAddress",
                          "secondary_CurrentStreetAddress",
                          "secondary_CurrentCity",
                          "secondary_CurrentStateId",
                          "secondary_CurrentZipCode",
                          "secondary_CurrentCountyId",
                          "secondary_CurrentInternationalAddress"
                        );
                        handleValueChange(event,
                          "secondary_HasCurrentInternationalAddress",
                          store.secondary_HasCurrentInternationalAddress,
                          savedState.secondary_HasCurrentInternationalAddress);
                      }}
                      onBlur={(e) => {
                        setFieldsTouched("secondary_HasCurrentInternationalAddress");
                      }}
                      label="International Address"
                    />

                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">Street Address</label>
                    <input type="text" name="secondary_CurrentStreetAddress"
                      value={store.secondary_CurrentStreetAddress}
                      disabled={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => {
                        store.setSecondary_CurrentStreetAddress(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_CurrentStreetAddress");
                        handleValueChange(event,
                          "secondary_CurrentStreetAddress",
                          store.secondary_CurrentStreetAddress,
                          savedState.secondary_CurrentStreetAddress);
                      }}
                      className="bds-form-text form-control" maxLength={331} />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">City</label>
                    <input type="text" name="secondary_CurrentCity"
                      value={store.secondary_CurrentCity}
                      disabled={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => {
                        store.setSecondary_CurrentCity(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_CurrentCity");
                        handleValueChange(event,
                          "secondary_CurrentCity",
                          store.secondary_CurrentCity,
                          savedState.secondary_CurrentCity);
                      }}
                      className="bds-form-text form-control" maxLength={255} />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label bds-grid-form form-label mandatory">State</label>
                    <select name="secondary_CurrentStateId"
                      value={store.secondary_CurrentStateId}
                      disabled={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => {
                        store.setSecondary_CurrentStateId(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_CurrentStateId");
                        handleValueChange(event,
                          "secondary_CurrentStateId",
                          store.secondary_CurrentStateId,
                          savedState.secondary_CurrentStateId);
                      }}
                      className="bds-form-select bds-grid-form form-select">
                      <option value="">Please Select ...</option>
                      {
                        states.map((item, index) => (
                          <option key={index} value={item.stateId}>{item.stateName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">Zip Code</label>
                    <input type="text" name="secondary_CurrentZipCode"
                      value={store.secondary_CurrentZipCode}
                      disabled={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => { store.setSecondary_CurrentZipCode(event.target.value); }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_CurrentZipCode");
                        handleValueChange(event,
                          "secondary_CurrentZipCode",
                          store.secondary_CurrentZipCode,
                          savedState.secondary_CurrentZipCode)
                      }}
                      className="bds-form-text form-control" maxLength={10} />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">County</label>
                    <select name="secondary_CurrentCountyId"
                      value={store.secondary_CurrentCountyId}
                      disabled={store.secondary_HasCurrentInternationalAddress}
                      onChange={(event) => {
                        store.setSecondary_CurrentCountyId(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_CurrentCountyId");
                        handleValueChange(event,
                          "secondary_CurrentCountyId",
                          store.secondary_CurrentCountyId,
                          savedState.secondary_CurrentCountyId);
                      }}
                      className="bds-form-select form-select">
                      <option value="">Please Select ...</option>
                      {
                        counties.map((item, index) => (
                          <option key={index} value={item.countyId}>{item.countyName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <ShowThen when={store.secondary_HasCurrentInternationalAddress}>
                    <div className="col-12 mt-3">
                      <label className="bds-form-label form-label">International Address:</label>
                      <textarea name="secondary_CurrentInternationalAddress"
                        value={store.secondary_CurrentInternationalAddress}
                        onChange={(event) => {
                          store.setSecondary_CurrentInternationalAddress(event.target.value);
                        }}
                        onBlur={(event) => {
                          setFieldsTouched("secondary_CurrentInternationalAddress");
                          handleValueChange(event,
                            "secondary_CurrentInternationalAddress",
                            store.secondary_CurrentInternationalAddress,
                            savedState.secondary_CurrentInternationalAddress);
                        }}
                        className="bds-textarea form-control" rows={6} maxLength={1000}>
                      </textarea>
                    </div>
                  </ShowThen>
                </div>
              </ShowThen>
            </Card>
          </div>

          {/*Secondary Legal Rep Phone Number*/}
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">What is the phone number for the secondary Legal Representative?</div>
              <div className="bds-section-details mt-2">Please enter up to three phone numbers. Enter phone numbers with no hyphens. Please designate which phone is Secondary and the type of each phone number. If the Legal Representative does not have a phone number, leave the phone numbers blank and go to the next question.</div>
              <div className="mt-4 text-center">
                <div className="row">
                  <div className="col-2">Primary</div>
                  <div className="col-3">Phone Type</div>
                  <div className="col-7 col-lg-5">Phone Number</div>
                </div>
                <div className="row mt-2">
                  <div className="col-2">
                    <BdsCheckbox
                      id="secondary-home-phone-is-primary"
                      name="secondary_HomePhoneIsPrimary"
                      checked={store.secondary_HomePhoneIsPrimary}
                      cssClass="text-center-column"
                      onChange={(event) => {
                        store.setSecondary_HomePhoneIsPrimary(event.target.checked);
                        setFieldsTouched("secondary_HomePhoneIsPrimary");
                        event.target.checked && store.setSecondary_MobilePhoneIsPrimary(false);
                        event.target.checked && store.setSecondary_WorkPhoneIsPrimary(false);
                        handleValueChange(event,
                          "secondary_HomePhoneIsPrimary",
                          store.secondary_HomePhoneIsPrimary,
                          savedState.secondary_HomePhoneIsPrimary);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_HomePhoneIsPrimary");
                      }}
                      label=""
                    />
                  </div>
                  <div className="col-3">Home</div>
                  <div className="col-7 col-lg-5">
                    <input type="text" name="secondary_HomePhoneNumber"
                      value={store.secondary_HomePhoneNumber}
                      onChange={(event) => {
                        store.setSecondary_HomePhoneNumber(event.currentTarget.value);
                        store.formatPhoneNumber()
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_HomePhoneNumber");
                        handleValueChange(event,
                          "secondary_HomePhoneNumber",
                          store.secondary_HomePhoneNumber,
                          savedState.secondary_HomePhoneNumber);
                      }}
                      className="bds-form-text form-control" maxLength={14} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-2">
                    <BdsCheckbox
                      id="secondary-mobile-phone-is-primary"
                      name="secondary_MobilePhoneIsPrimary"
                      checked={store.secondary_MobilePhoneIsPrimary}
                      cssClass="text-center-column"
                      onChange={(event) => {
                        store.setSecondary_MobilePhoneIsPrimary(event.target.checked);
                        setFieldsTouched("secondary_MobilePhoneIsPrimary");
                        event.target.checked && store.setSecondary_HomePhoneIsPrimary(false);
                        event.target.checked && store.setSecondary_WorkPhoneIsPrimary(false);
                        handleValueChange(event,
                          "secondary_MobilePhoneIsPrimary",
                          store.secondary_MobilePhoneIsPrimary,
                          savedState.secondary_MobilePhoneIsPrimary);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_MobilePhoneIsPrimary");
                      }}
                      label=""
                    />
                  </div>
                  <div className="col-3">Mobile</div>
                  <div className="col-7 col-lg-5">
                    <input type="text" name="secondary_MobilePhoneNumber"
                      value={store.secondary_MobilePhoneNumber}
                      onChange={(event) => {
                        store.setSecondary_MobilePhoneNumber(event.currentTarget.value);
                        store.formatPhoneNumber()
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_MobilePhoneNumber");
                        handleValueChange(event,
                          "secondary_MobilePhoneNumber",
                          store.secondary_MobilePhoneNumber,
                          savedState.secondary_MobilePhoneNumber);
                      }}
                      className="bds-form-text form-control" maxLength={14} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-2">
                    <BdsCheckbox
                      id="secondary-work-phone-is-primary"
                      name="secondary_WorkPhoneIsPrimary"
                      checked={store.secondary_WorkPhoneIsPrimary}
                      cssClass="text-center-column"
                      onChange={(event) => {
                        store.setSecondary_WorkPhoneIsPrimary(event.target.checked);
                        setFieldsTouched("secondary_WorkPhoneIsPrimary");
                        event.target.checked && store.setSecondary_HomePhoneIsPrimary(false);
                        event.target.checked && store.setSecondary_MobilePhoneIsPrimary(false);
                        handleValueChange(event,
                          "secondary_WorkPhoneIsPrimary",
                          store.secondary_WorkPhoneIsPrimary,
                          savedState.secondary_WorkPhoneIsPrimary);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_WorkPhoneIsPrimary");
                      }}
                      label=""
                    />
                  </div>
                  <div className="col-3">Work</div>
                  <div className="col-7 col-lg-5">
                    <input type="text" name="secondary_WorkPhoneNumber"
                      value={store.secondary_WorkPhoneNumber}
                      onChange={(event) => {
                        store.setSecondary_WorkPhoneNumber(event.currentTarget.value);
                        store.formatPhoneNumber()
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("secondary_WorkPhoneNumber");
                        handleValueChange(event,
                          "secondary_WorkPhoneNumber",
                          store.secondary_WorkPhoneNumber,
                          savedState.secondary_WorkPhoneNumber);
                      }}
                      className="bds-form-text form-control" maxLength={14} />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">What is the email address for the secondary Legal Representative?</div>
              <div className="bds-section-details mt-2">If the applicant does not have an email address, leave the email address blank and go to the next question.</div>
              <div className="row mt-3">
                <div className="col-12">
                  <input type="text" name="secondary_EmailAddress" value={store.secondary_EmailAddress}
                    onChange={(event) => { store.setSecondary_EmailAddress(event.target.value) }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_EmailAddress");
                      handleValueChange(event,
                        "secondary_EmailAddress",
                        store.secondary_EmailAddress,
                        savedState.secondary_EmailAddress);
                    }}
                    className="bds-form-text bds-section-email form-control" maxLength={150} />
                </div>
              </div>
            </Card>
          </div>

          {/*Secondary Legal Rep Preferred Language */}
          <div className="mt-4">
            <Card>
              <div className="bds-section-heading">What is the secondary Legal Representative's preferred language?</div>
              <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <div className="bds-form-label form-label mt-2">Please select from the following list.</div>
                  <select
                    name="secondary_PreferredLanguageId"
                    value={store.secondary_PreferredLanguageId}
                    onChange={(event) => {
                      store.setSecondary_PreferredLanguageId(event.target.value);
                      store.setSecondary_PreferredLanguageOther("");
                      setSecondaryLegalRepOtherLanguageRequired(store.secondary_PreferredLanguageId == '16')
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_PreferredLanguageId", "secondary_PreferredLanguageOther");
                      handleValueChange(event,
                        "secondary_PreferredLanguageId",
                        store.secondary_PreferredLanguageId,
                        savedState.secondary_PreferredLanguageId);
                    }}
                    className="bds-form-select bds-grid-form form-select">
                    <option value="">Please Select ...</option>
                    {preferredLanguages.map((item, index) => (
                      <option key={index} value={item.preferredLanguageId}>
                        {item.preferredLanguageName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <ShowThen when={secondaryLegalRepOtherLanguageRequired}>
                    <div className="bds-form-label form-label mt-2 mandatory">If other, please specify.</div>
                  </ShowThen>
                  <ShowThen when={!secondaryLegalRepOtherLanguageRequired}>
                    <div className="bds-form-label form-label mt-2">If other, please specify.</div>
                  </ShowThen>
                  <input type="text" name="secondary_PreferredLanguageOther"
                    disabled={store.secondary_PreferredLanguageId != "16"}
                    value={store.secondary_PreferredLanguageOther}
                    onChange={(event) => {
                      store.setSecondary_PreferredLanguageOther(event.target.value);
                      setSecondaryLegalRepOtherLanguageRequired(store.secondary_PreferredLanguageId == "16")
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("secondary_PreferredLanguageOther");
                      handleValueChange(event,
                        "secondary_PreferredLanguageOther",
                        store.secondary_PreferredLanguageOther,
                        savedState.secondary_PreferredLanguageOther);
                    }}
                    className="bds-form-text form-control" />
                </div>
              </div>
            </Card>
          </div>
        </ShowThen>

        <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
            <button type="button" className="btn bds-btn-primary"
              onClick={() => NavigationHelper.gotoApplicantDemographics(navigate, applicationRefId)}>Back</button>
            <IsAuthenticated>
              <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
            </IsAuthenticated>
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
            <ShowThen when={fromSummary}>
              <button type="button" disabled={isLoading}
                onClick={() => handleReturnToSummary()} 
                className="btn bds-btn-primary me-3">Return To Summary
              </button>
            </ShowThen>
            <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep()}
              className="btn bds-btn-primary">Next</button>
          </div>
        </div>
      </form>
    </>
  );
}

export default observer(LegalRepresentative);
