import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { IGatewayTransientStore } from "../../../../shared-module/models/IGatewayTransientStore";
import { Utility } from "../../../../shared-module/helpers/utility";


export interface INbarApplicantSignature {
  firstName: string,
  middleName: string,
  lastName: string,
  suffixTypeId: string,
  dateOfBirth: string,
  streetAddress1: string,
  city: string,
  state: string,
  zipCode: string,
  homePhoneNumber: string,
  alternatePhoneNumber: string,
  reasonDescription: string,
  residents: GatewayOapApi.ResidentInformationModel[],
  addResidentName: string,
  addResidentAge: number,
  addResidentRelationship: string,
  triggerResidentValidation: boolean,
  applicantCertified: boolean,
  applicantSignatureSeed: string,
  additionalInformation: string,
  legalRepresentativeSignature: string,
  legalRepresentativeCertified: boolean,
  legalRepresentativeSignatureSeed: string,
  legalRepresentativeFullName: string,
};

class NbarApplicantSignatureStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.NbarApplicationModel, INbarApplicantSignature>,
  INbarApplicantSignature {

    public firstName: string;
    public middleName: string;
    public lastName: string;
    public suffixTypeId: string;
    public dateOfBirth: string;
    public streetAddress1: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public homePhoneNumber: string;
    public alternatePhoneNumber: string;
    public reasonDescription: string;
    public residents: GatewayOapApi.ResidentInformationModel[];
    public addResidentName: string;
    public addResidentAge: number;
    public addResidentRelationship: string;
    public triggerResidentValidation: boolean;
    public additionalInformation: string;
    public applicantCertified: boolean;
    public applicantSignatureSeed: string;
    public legalRepresentativeSignature: string;
    public legalRepresentativeCertified: boolean;
    public legalRepresentativeSignatureSeed: string;
    public legalRepresentativeFullName: string;

  constructor() {
    this.firstName = '';
    this.middleName = '';
    this.lastName = '';
    this.suffixTypeId = '';
    this.dateOfBirth = '';
    this.streetAddress1 = '';
    this.city = '';
    this.state = '';
    this.zipCode = '';
    this.homePhoneNumber = '';
    this.alternatePhoneNumber = '';
    this.reasonDescription = '';
    this.residents = [];
    this.addResidentName = '';
    this.addResidentAge = 0;
    this.addResidentRelationship = '';
    this.triggerResidentValidation = false;
    this.applicantCertified = false;
    this.additionalInformation = '';
    this.applicantSignatureSeed = '';
    this.legalRepresentativeSignature = '',
    this.legalRepresentativeCertified = false;
    this.legalRepresentativeSignatureSeed = '';
    this.legalRepresentativeFullName = '';

    makeObservable(this, {
      firstName: observable,
      middleName: observable,
      lastName: observable,
      suffixTypeId: observable,
      dateOfBirth: observable,
      streetAddress1: observable,
      city: observable,
      state: observable,
      zipCode: observable,
      homePhoneNumber: observable,
      alternatePhoneNumber: observable,
      reasonDescription: observable,
      residents: observable,
      addResidentName: observable,
      addResidentAge: observable,
      addResidentRelationship: observable,
      triggerResidentValidation: observable,
      applicantCertified: observable,
      additionalInformation: observable,
      legalRepresentativeSignature: observable,
      legalRepresentativeCertified: observable,
      legalRepresentativeSignatureSeed: observable,
      legalRepresentativeFullName: observable,

      setFirstName: action,
      setMiddleName: action,
      setLastName: action,
      setSuffixTypeId: action,
      setDateOfBirth: action,
      setStreetAddress1: action,
      setCity: action,
      setState: action,
      setZipCode: action,
      setHomePhoneNumber: action,
      setAlternatePhoneNumber: action,
      setReasonDescription: action,
      setResidents: action,
      setAddResidentName: action,
      setAddResidentAge: action,
      setAddResidentRelationship: action,
      setTriggerResidentValidation: action,
      setApplicantCertified: action,
      setAdditionalInformation: action,
      setLegalRepresentativeSignature: action,
      setLegalRepresentativeCertified: action,
      setLegalRepresentativeSignatureSeed: action,
      setLegalRepresentativeFullName: action,
    });
  }

  public setFirstName(value: string) {
    this.firstName = value;
  }
  
  public setMiddleName(value: string) {
    this.middleName = value;
  }

  public setLastName(value: string) {
    this.lastName = value;
  }

  public setSuffixTypeId(value: string) {
    this.suffixTypeId = value;
  }

  public setDateOfBirth(value: string) {
    this.dateOfBirth = value;
  }

  public setStreetAddress1(value: string) {
    this.streetAddress1 = value;
  }

  public setCity(value: string) {
    this.city = value;
  }

  public setState(value: string) {
    this.state = value;
  }

  public setZipCode(value: string) {
    this.zipCode = value;
  }

  public setHomePhoneNumber(value: string) {
    this.homePhoneNumber = value;
  }

  public setAlternatePhoneNumber(value: string) {
    this.alternatePhoneNumber = value;
  }

  public setReasonDescription(value: string) {
    this.reasonDescription = value;
  }

  public setResidents(value: GatewayOapApi.ResidentInformationModel[]) {
    this.residents = value;
  }

  public setAddResidentName(value: string) {
    this.addResidentName = value;
  }

  public setAddResidentAge(value: number) {
    this.addResidentAge = value;
  }

  public setAddResidentRelationship(value: string) {
    this.addResidentRelationship = value;
  }

  public setTriggerResidentValidation(value: boolean) {
    this.triggerResidentValidation = value;
  }

  public setApplicantCertified(value: boolean) {
    this.applicantCertified = value;
  }

  public setApplicantSignatureSeed(value: string) {
    this.applicantSignatureSeed = value;
  }

  public setAdditionalInformation(value: string) {
    this.additionalInformation = value;
  }

  public setLegalRepresentativeCertified(value: boolean) {
    this.legalRepresentativeCertified = value;
  }

  public setLegalRepresentativeSignature(value: string) {
    this.legalRepresentativeSignature = value;
  }

  public setLegalRepresentativeSignatureSeed(value: string) {
    this.legalRepresentativeSignature = value.replace('[^a-zA-Z]', '');
  }

  public setLegalRepresentativeFullName(value: string) {
    this.legalRepresentativeFullName = value;
  }



  public getNbarDocumentName() : string {
      const lastPart = Utility.defaultIfNull(this.lastName, '') != '' ? `${this.lastName}` : '';
      const firstPart = Utility.defaultIfNull(this.firstName, '') != '' ? `${this.firstName}` : '';
      const middlePart = Utility.defaultIfNull(this.middleName, '') != '' ? `${this.middleName.substring(0,1)}` : '';
      const datePart = new Date().getTime().toString();
      return `${lastPart}${firstPart}${middlePart}_NbarDocument_${datePart}`;
  }


  public refresh(application: GatewayOapApi.NbarApplicationModel): void {
    var state = this.buildState(application);
    this.setApplicantCertified(state.applicantCertified);
    this.setApplicantSignatureSeed(state.applicantSignatureSeed);
    this.setAdditionalInformation(state.additionalInformation);
    this.setFirstName(state.firstName);
    this.setLastName(state.lastName);
    this.setMiddleName(state.middleName);
    this.setSuffixTypeId(state.suffixTypeId);
    this.setDateOfBirth(state.dateOfBirth);
    this.setStreetAddress1(state.streetAddress1);
    this.setCity(state.city);
    this.setState(state.state);
    this.setZipCode(state.zipCode);
    this.setHomePhoneNumber(state.homePhoneNumber);
    this.setAlternatePhoneNumber(state.alternatePhoneNumber);
    this.setAddResidentName(state.addResidentName);
    this.setAddResidentAge(state.addResidentAge);
    this.setAddResidentRelationship(state.addResidentRelationship);
    this.setReasonDescription(state.reasonDescription);
    this.setTriggerResidentValidation(false);
    this.setLegalRepresentativeSignature(state.legalRepresentativeSignature);
    this.setLegalRepresentativeCertified(state.legalRepresentativeCertified);
    this.setLegalRepresentativeFullName(state.legalRepresentativeFullName);
  }

  public buildState(application: GatewayOapApi.NbarApplicationModel): INbarApplicantSignature {
    return {
      applicantCertified: false,
      applicantSignatureSeed: `${Utility.defaultIfNull(application.firstName, "")}${Utility.defaultIfNull(application.lastName, "")}`,
      additionalInformation: application.additionalInformation,
      firstName: Utility.defaultIfNull(application.firstName, ""),
      middleName: Utility.defaultIfNull(application.middleName, ""),
      lastName: Utility.defaultIfNull(application.lastName, ""),
      suffixTypeId: Utility.numberToString(application.suffixTypeId, ""),
      dateOfBirth: Utility.toHtmlDateString(Utility.toDate(application.dateOfBirth), ""),
      streetAddress1: Utility.defaultIfNull(application.address?.streetAddress1, ""),
      city: Utility.defaultIfNull(application.address?.city, ""),
      state: Utility.numberToString(application.address?.stateId, ""),
      zipCode: Utility.mergeZipCodeParts(Utility.defaultIfNull(application.address?.zipCode, ""), Utility.defaultIfNull(application.address?.zipPlus4, ""), ""),
      homePhoneNumber: Utility.defaultIfNull(application.homePhoneNumber?.phoneNumber, ""),
      alternatePhoneNumber: Utility.defaultIfNull(application.alternatePhoneNumber?.phoneNumber, ""),
      reasonDescription: application.applicationReasonDescription || '',
      residents: application.residentInformation || [],
      addResidentName: '',
      addResidentAge: 0,
      addResidentRelationship: '',
      triggerResidentValidation: false,
      legalRepresentativeSignature: '',
      legalRepresentativeCertified: false,
      legalRepresentativeSignatureSeed: '',
      legalRepresentativeFullName: '',
    }
  }

}

export const NbarApplicantSignatureStore = new NbarApplicantSignatureStoreComponent();