import { useEffect } from "react";
import { EventBroadcasterService } from "../../../shared-module/events/event-broadcaster.service";
import { AppSettings } from "../../../app-setting";
import { ApiConfig } from "shared-module/services/api-config";
import { SystemResource } from "shared-module/api-resources/system.resource";

function ActivityPollComponent() {
  const systemService = new SystemResource(ApiConfig);

    function pollActivity(): Promise<any> {
       var pulseResult =  systemService.checkActivityPulse()
        .then((response) => {
            var record = response.record;
            EventBroadcasterService.RaiseSessionStateChangedEvent({
                isAuthenticated: record?.isAuthenticated || false,
                remainingTimeInSeconds: record?.expirationSeconds || 0,
                hasActiveMaintenance: record?.hasActiveMaintenance
            });
        })

        return pulseResult;
    }

  useEffect(() => {
    const timerId = setInterval(() => pollActivity(), AppSettings.activityPulseInterval);
    return () => clearInterval(timerId);
  }, []);

  return (
    <></>
  )
}

export const ActivityPoll = ActivityPollComponent;