import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class UserProfileResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public isAuthenticated(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.getRequest(`api/v1/profile/is-authenticated`, null,
      abortController) as Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public getProfile(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfUserModel> {
    return super.getRequest(`api/v1/profile/me`, null,
      abortController) as Promise<GatewayOapApi.ApiSingleResponseOfUserModel>;
  }

  public getPermissions(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiMultipleResponseOfString> {
    return super.getRequest(`api/v1/profile/permissions`, null,
      abortController) as Promise<GatewayOapApi.ApiMultipleResponseOfString>;
  }
}