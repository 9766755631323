import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { IGatewayTransientStore } from "../../../../shared-module/models/IGatewayTransientStore";
import { BdsSelectOption } from "shared-module/components/multi-select/multi-select.component";

export interface INbarPreferenceInformation {
    thingsLoveMost: string,
    thingsFearMost: string,
    thingsDislikeMost: string,
    thingsUpsetMost: string
};

class NbarPreferenceInformationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.PreferenceInformationModel, INbarPreferenceInformation>, 
  INbarPreferenceInformation {
    public thingsLoveMost: string;
    public thingsFearMost: string;
    public thingsDislikeMost: string;
    public thingsUpsetMost: string;

    constructor() {
        this.thingsLoveMost = '';
        this.thingsFearMost = '';
        this.thingsDislikeMost = '';
        this.thingsUpsetMost = '';

        makeObservable(this, {
            thingsLoveMost: observable,
            thingsFearMost: observable,
            thingsDislikeMost: observable,
            thingsUpsetMost: observable,

            setThingsLoveMost: action,
            setThingsFearMost: action,
            setThingsDislikeMost: action,
            setThingsUpsetMost: action,
        });
    }

    public setThingsLoveMost(value: string) {
        this.thingsLoveMost = value;
    }

    public setThingsFearMost(value: string) {
        this.thingsFearMost = value;
    }

    public setThingsDislikeMost(value: string) {
        this.thingsDislikeMost = value;
    }

    public setThingsUpsetMost(value: string) {
        this.thingsUpsetMost = value;
    }

    public refresh(info: GatewayOapApi.PreferenceInformationModel): void {
        var state = this.buildState(info);
        this.setThingsLoveMost(state.thingsLoveMost);
        this.setThingsFearMost(state.thingsFearMost);
        this.setThingsDislikeMost(state.thingsDislikeMost);
        this.setThingsUpsetMost(state.thingsUpsetMost);
    }

    public buildState(info: GatewayOapApi.PreferenceInformationModel): INbarPreferenceInformation {
        return {
            thingsLoveMost: info.loveMostDescription || '',
            thingsFearMost: info.fearMostDescription || '',
            thingsDislikeMost: info.dislikeMostDescription || '',
            thingsUpsetMost: info.angerMostDescription || '',
        }
    }
  }

  export const NbarPreferenceInformation = new NbarPreferenceInformationStoreComponent();