import { useNavigate } from "react-router-dom";
import { Card } from "../../../shared-module/components/card/card.component";

function ApplicationSessionExpired() {
  const navigate = useNavigate();

  function gotoHome() {
    navigate("/home");
  }

  return (
    <>
      <div className="mt-4">
        <Card>
          <div className="bds-session-expired-message">
            Your application session has timed out. Please start a new application.</div>
        </Card>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
        </div>
        <div className="col-12 col-md-6 text-start text-md-end mt-3 mt-md-0">
          <button className="btn bds-btn-primary" onClick={gotoHome}>Start Application</button>
        </div>
      </div>

    </>
  );
}

export default ApplicationSessionExpired;