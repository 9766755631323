import { action, makeObservable, observable } from "mobx";
import { BdsSelectOption } from "../../../shared-module/components/multi-select/multi-select.component";
import { Utility } from "../../../shared-module/helpers/utility";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { IGatewayTransientStore } from "../../../shared-module/models/IGatewayTransientStore";

export interface IApplicantDemographics {
  applicantGenderId: string,
  applicantMaritalStatusId: string,
  isCurrentlyStudent: string,
  applicantEnrolledEducationTypeId: string,
  applicantNotEnrolledEducationTypeId: string,
  applicantIsHispanicOrLatino: string,
  applicantRaceOptions: BdsSelectOption[],
  applicantPreferredLanguageId: string,
  applicantPreferredLanguageOther: string,
  hasApplicantVrsAssessed: string,
  hasLegalRepresentative: string
}

class ApplicantDemographicsStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.ApplicationModel, IApplicantDemographics>,
  IApplicantDemographics {

  public applicantGenderId: string;
  public applicantMaritalStatusId: string;
  public isCurrentlyStudent: string;
  public applicantEnrolledEducationTypeId: string;
  public applicantNotEnrolledEducationTypeId: string;
  public applicantIsHispanicOrLatino: string;
  public applicantRaceOptions: BdsSelectOption[];
  public applicantPreferredLanguageId: string;
  public applicantPreferredLanguageOther: string;
  public hasApplicantVrsAssessed: string;
  public hasLegalRepresentative: string;

  constructor() {
    this.applicantGenderId = "";
    this.applicantMaritalStatusId = "";
    this.isCurrentlyStudent = "";
    this.applicantEnrolledEducationTypeId = "";
    this.applicantNotEnrolledEducationTypeId = "";
    this.applicantIsHispanicOrLatino = "";
    this.applicantRaceOptions = [] as BdsSelectOption[];
    this.applicantPreferredLanguageId = "";
    this.applicantPreferredLanguageOther = "";
    this.hasApplicantVrsAssessed = "";
    this.hasLegalRepresentative = "";

    makeObservable(this, {
      applicantGenderId: observable,
      applicantMaritalStatusId: observable,
      isCurrentlyStudent: observable,
      applicantEnrolledEducationTypeId: observable,
      applicantNotEnrolledEducationTypeId: observable,
      applicantIsHispanicOrLatino: observable,
      applicantRaceOptions: observable,
      applicantPreferredLanguageId: observable,
      applicantPreferredLanguageOther: observable,
      hasApplicantVrsAssessed: observable,
      hasLegalRepresentative: observable,

      setApplicantGenderId: action,
      setApplicantMaritalStatusId: action,
      setIsCurrentlyStudent: action,
      setApplicantEnrolledEducationTypeId: action,
      setApplicantNotEnrolledEducationTypeId: action,
      setApplicantIsHispanicOrLatino: action,
      setApplicantRaceOptions: action,
      setApplicantPreferredLanguageId: action,
      setApplicantPreferredLanguageOther: action,
      setHasApplicantVrsAssessed: action,
      setHasLegalRepresentative: action,
      refresh: action
    });
  }

  public setApplicantGenderId(value: string) {
    this.applicantGenderId = value;
  }

  public setApplicantMaritalStatusId(value: string) {
    this.applicantMaritalStatusId = value;
  }

  public setIsCurrentlyStudent(value: string) {
    this.isCurrentlyStudent = value;
  }

  public setApplicantEnrolledEducationTypeId(value: string) {
    this.applicantEnrolledEducationTypeId = value;
  }

  public setApplicantNotEnrolledEducationTypeId(value: string) {
    this.applicantNotEnrolledEducationTypeId = value;
  }

  public setApplicantIsHispanicOrLatino(value: string) {
    this.applicantIsHispanicOrLatino = value;
  }

  public setApplicantRaceOptions(values: BdsSelectOption[]) {
    this.applicantRaceOptions = values;
  }

  public setApplicantPreferredLanguageId(value: string) {
    this.applicantPreferredLanguageId = value;
  }

  public setApplicantPreferredLanguageOther(value: string) {
    this.applicantPreferredLanguageOther = value;
  }

  public setHasApplicantVrsAssessed(value: string) {
    this.hasApplicantVrsAssessed = value;
  }

  public setHasLegalRepresentative(value: string) {
    this.hasLegalRepresentative = value;
  }

  public refresh(app: GatewayOapApi.ApplicationModel): boolean {
    var state = this.buildState(app);
    this.setApplicantGenderId(state.applicantGenderId),
      this.setApplicantMaritalStatusId(state.applicantMaritalStatusId),
      this.setIsCurrentlyStudent(state.isCurrentlyStudent),
      this.setApplicantEnrolledEducationTypeId(state.applicantEnrolledEducationTypeId),
      this.setApplicantNotEnrolledEducationTypeId(state.applicantNotEnrolledEducationTypeId),
      this.setApplicantIsHispanicOrLatino(state.applicantIsHispanicOrLatino),
      this.setApplicantRaceOptions(state.applicantRaceOptions),
      this.setApplicantPreferredLanguageId(state.applicantPreferredLanguageId),
      this.setApplicantPreferredLanguageOther(state.applicantPreferredLanguageOther),
      this.setHasApplicantVrsAssessed(state.hasApplicantVrsAssessed),
      this.setHasLegalRepresentative(state.hasLegalRepresentative)
    return true;
  }

  public buildState(app: GatewayOapApi.ApplicationModel): IApplicantDemographics {
    var isCurrentlyStudent = Utility.defaultIfNull(app.isStudent, null);
    var isHispanicOrLatino = Utility.defaultIfNull(app.isHispanicOrLatino, null);

    return {
      applicantGenderId: Utility.numberToString(app.genderId, ""),
      applicantMaritalStatusId: Utility.numberToString(app.maritalStatusId, ""),
      isCurrentlyStudent: isCurrentlyStudent == null ? "" : 
        (isCurrentlyStudent ? "is-currently-student" : "is-currently-not-student"),
      applicantEnrolledEducationTypeId: Utility.numberToString(app.educationalTypeId, ""),
      applicantNotEnrolledEducationTypeId: Utility.numberToString(app.educationalTypeId, ""),
      applicantIsHispanicOrLatino: isHispanicOrLatino == null ? "" :
        (isHispanicOrLatino ? "is-hispanic-latino" : "is-not-hispanic-latino"),
      applicantRaceOptions: this.buildRaceOptions(app),
      applicantPreferredLanguageId: Utility.numberToString(app.preferredLanguageId, ""),
      applicantPreferredLanguageOther: Utility.defaultIfNull(app.preferredLanguageOther, ""),
      hasApplicantVrsAssessed: Utility.defaultIfNull(app.hasAssessedByVocationRehab, false) ? "has-vrs-assessment" : "has-not-vrs-assessment",
      hasLegalRepresentative: (app.isMinor || Utility.defaultIfNull(app.hasLegalRepresentative, false)) ? "has-legal-representative" : "has-not-legal-representative"
    }
  }

  public readonly raceOptions: BdsSelectOption[] = [
    { label: "MultiRacial", value: "1" },
    { label: "American Indian Or Alaskan Native", value: "2" },
    { label: "Black African American", value: "3" },
    { label: "Native Hawaiian Pacific Islander", value: "4" },
    { label: "Asian", value: "5" },
    { label: "White", value: "6" }
  ];

  public findRaceOption(raceOptions: BdsSelectOption[], value: string): BdsSelectOption {
    return raceOptions && raceOptions.find((item) => { return item.value == value });
  }

  private buildRaceOptions(app: GatewayOapApi.ApplicationModel): BdsSelectOption[] {
    var selection: BdsSelectOption[] = [];
    !!app.isMultiRacial && this.addRaceOption(selection, this.raceOptions, "1");
    !!app.isAmericanIndianAlaskanNative && this.addRaceOption(selection, this.raceOptions, "2");
    !!app.isBlackAfricanAmerican && this.addRaceOption(selection, this.raceOptions, "3");
    !!app.isNativeHawaiianPacificIslander && this.addRaceOption(selection, this.raceOptions, "4");
    !!app.isAsian && this.addRaceOption(selection, this.raceOptions, "5");
    !!app.isWhite && this.addRaceOption(selection, this.raceOptions, "6");
    return selection;
  }

  private addRaceOption(selection: BdsSelectOption[], raceOptions: BdsSelectOption[], value: string) {
    var found = this.findRaceOption(raceOptions, value);
    !!found && selection.push(found);
    return !!found;
  }
}

export const ApplicantDemographicsStore = new ApplicantDemographicsStoreComponent(); 