import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import LoadingOverlayEventBus from "./loading-overlay-eventbus";
import { LoadingOverlayEvents } from "./loading-overlay";
import * as focusTrap from 'focus-trap';
import { useAsyncState } from "../../hooks/use-async-state";

function LoadingOverlayContainerComponent(props) {
  const [trap, setTrap] = useAsyncState<focusTrap.FocusTrap>(null);
  const ref = useRef<HTMLDivElement>();

  function getFocusTrap(): focusTrap.FocusTrap {
    if (!trap) {
      var instance = focusTrap.createFocusTrap(ref.current);
      setTrap(instance);
      instance.activate();
      return instance;
    }
    return trap;
  }

  useEffect(() => {
    LoadingOverlayEventBus.on(LoadingOverlayEvents.OVERLAY_SHOW, function () {
      if (ref.current && !ref.current.classList.contains("bds-loading-overlay-hidden")) {
        return;
      }

      document.body.classList.add("bds-loading-overlay-disable-scroll");
      ref.current.classList.remove("bds-loading-overlay-hidden");

      // uppause only after dom elements are added
      var t = getFocusTrap();
      t.unpause();
    });

    LoadingOverlayEventBus.on(LoadingOverlayEvents.OVERLAY_HIDE, function () {
      if (ref.current && ref.current.classList.contains("bds-loading-overlay-hidden")) {
        return;
      }

      // pause before dom elements are removed.
      var t = getFocusTrap();
      t.pause();

      document.body.classList.remove("bds-loading-overlay-disable-scroll");
      ref.current && ref.current.classList.add("bds-loading-overlay-hidden");
    });

    return () => {
      LoadingOverlayEventBus.remove(LoadingOverlayEvents.OVERLAY_SHOW);
      LoadingOverlayEventBus.remove(LoadingOverlayEvents.OVERLAY_HIDE);
    }
  }, [])

  return (
    <>
      {
        createPortal(
          <div ref={ref} className="bds-loading-overlay bds-loading-overlay-hidden">
            <button type="button" tabIndex={0} className="bds-loading-overlay-trap">Focus Trap</button>
            <div className="text-center h-100">
              <div className="spinner-positioning">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )
      }
    </>
  )
}


export const LoadingOverlayContainer = LoadingOverlayContainerComponent;