import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card } from "../../../../shared-module/components/card/card.component";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { ICreateApplication, CreateApplicationStore } from "./create-application.store";
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { CreateApplicationValidationSchema } from "./create-application.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { SessionService } from "shared-module/services/session.service";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { IsNotAuthenticated } from "shared-module/components/is-not-authenticated/is-not-authenticated";

import { ApplicationTypeNames, GatewayNetworkError } from "shared-module/models/common.models";
import { Modal } from "bootstrap";
import { DuplicateDraftApplicationModal } from "app-module/extensions/duplicate-draft-application-modal/duplicate-draft-application-modal.component";
import { DuplicateOldApplicationModal } from "app-module/extensions/duplicate-old-application-modal/duplicate-old-application-modal.component";
import { DuplicateRecentApplicationModal } from "app-module/extensions/duplicate-recent-application-modal/duplicate-recent-application-modal.component";

function NbarCreateApplication() {
  const navigate = useNavigate();
  const { id: nbarApplicationRefId } = useParams();

  const applicationService = new NbarApplicationResource(ApiConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savedState, setSavedState] = useState<ICreateApplication>({} as ICreateApplication);
  const store = CreateApplicationStore;

  const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched: setFieldsTouched, touchedAll } =
    useFormValidation(CreateApplicationValidationSchema, store);

  useEffect(() => {
    console.log('create application component. useEffect()');

    if (hasNbarApplicationRefId()) {
      if (Utility.stringToNumber(nbarApplicationRefId, 0) == 0) {
        NavigationHelper.gotoNbarStartApplication(navigate, nbarApplicationRefId);
        return;
      }

      setIsLoading(true);
      Utility.showLoadingOverlay();

      applicationService.getApplication(nbarApplicationRefId)
        .then((response) => {
          store.refresh(response.record);
          setSavedState(store.buildState(response.record));
          return true;
        })
        .catch(ex => { 
          NavigationHelper.gotoNbarCreateApplication(navigate); 
        })
        .finally(() => {
          setIsLoading(false);
          Utility.hideLoadingOverlay();
        });

      return;
    }

    store.setSsnType("");
    store.setSsn("");
  }, []);

  function hasNbarApplicationRefId() {
    return !!nbarApplicationRefId;
  }

  function handleStop() {
    SessionService.isAuthenticated()
    .then((response) => {
      if(response){
        NavigationHelper.gotoDashboard(navigate);
      }
      else{
        NavigationHelper.gotoNbarHome(navigate);
      }
    });
  }

  function handleContinue() {
    createOrUpdateApplication();
  }

  function handleValueChange(event) {
    Promise.all([
      applicationService.getDuplicateApplicationInfo(store.ssnType === 'ITIN', store.ssn),
    ])
      .then((response) => {
        var showRecentModal = response[0].record?.hasApplicationSubmittedInLast30Days;
        var showOldModal = response[0].record?.hasApplicationSubmittedMoreThan30DaysInPast;
        var showDraftModal = response[0].record?.hasApplicationInDraft;

        if(showDraftModal){
          console.log("draftmodal", showDraftModal);
          var modal = Modal.getOrCreateInstance("#duplicateDraftApplicationModal");
          modal.show();
          return false;
        }
        else if(showRecentModal){
          console.log("recentmodal", showRecentModal);
          var modal = Modal.getOrCreateInstance("#duplicateRecentApplicationModal");
          modal.show();
          return false;
        }
        else if(showOldModal){
          console.log("oldmodal", showOldModal);
          var modal = Modal.getOrCreateInstance("#duplicateOldApplicationModal");
          modal.show();
          return false;
        }
        
        return true;
      })
      .then((response) => {
        if(response){
          createOrUpdateApplication();
        }
      });
  }

  function createOrUpdateApplication() {
    setIsLoading(true);
    Utility.showLoadingOverlay();
    if (!hasNbarApplicationRefId()) {
      applicationService.createApplication(store.ssnType === 'ITIN', store.ssn)
        .then((response: GatewayOapApi.ApiGuidResponse) => {
          if(Boolean(sessionStorage.getItem("isGuestUser"))) {
              SessionService.createGuestSession(response.record, ApplicationTypeNames.NBAR)
              .then((response) => {
                sessionStorage.setItem("fromNbar", "true");
                sessionStorage.setItem("guestSessionId", response.record.toString());
                return response;
              });
            }
            NavigationHelper.gotoNbarStartApplication(navigate, response.record.toString())
            return true;
          })
        .catch(ex => {
          if (!(ex instanceof GatewayNetworkError)) {
            Utility.alert("Error", ex);
          }
        })
        .finally(() => {
          setIsLoading(false);
          Utility.hideLoadingOverlay();
        });

      return;
    }
  }

  return (
    <>
      <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
      <DuplicateDraftApplicationModal id="duplicateDraftApplicationModal" onStop={handleStop} onContinue={handleContinue}></DuplicateDraftApplicationModal>
      <DuplicateOldApplicationModal id="duplicateOldApplicationModal" onStop={handleStop} onContinue={handleContinue}></DuplicateOldApplicationModal>
      <DuplicateRecentApplicationModal id="duplicateRecentApplicationModal" onContinue={handleStop}></DuplicateRecentApplicationModal>
      <ShowThen when={!isLoading}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <form>
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading mandatory">What is the applicant's social security number?</div>
            <div className="bds-section-details mt-2">
              If you do not have a social security number (SSN), select ITIN and enter your ITIN.
              Enter either your SSN or ITIN as numbers with no hyphens. BDS is requiring disclosure
              of your social security number per IC 4-1-8-1. The information obtained on this form
              is confidential under state and federal regulations. This information will not be
              released except as permitted or required by law or with the consent of the applicant.
            </div>
            <div className="row mt-3">
              <div className="col-12 d-md-flex">
                <select name="ssnType" value={store.ssnType}
                  onChange={(event) => {
                    setFieldsTouched("ssnType", "ssn");
                    store.setSsnType(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("ssnType", "ssn");
                  }}
                  className="bds-form-select bds-ssn form-select">
                  <option value="">Please Select ...</option>
                  <option value="SSN">SSN</option>
                  <option value="ITIN">ITIN</option>
                </select>
                <input type="text" name="ssn" value={store.ssn}
                  onChange={(event) => {
                    setFieldsTouched("ssnType", "ssn");
                    store.setSsn(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("ssnType", "ssn");
                  }}
                  className="bds-form-text bds-grid-form form-control ms-md-3 mt-2 mt-md-0"
                  id="ssn" maxLength={9} />
              </div>
            </div>
          </Card>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-8 order-2 order-md-0">
              <button type="button" className="btn bds-btn-primary"
                onClick={() => NavigationHelper.gotoNbarHome(navigate)}>Back to Home</button>
          </div>
          <div className="col-12 col-md-2 text-start text-md-center order-1 order-md-1 mt-2 mt-md-0">
          </div>
          <div className="col-12 col-md-2 text-start text-md-end order-0 order-md-2">
            <button type="button" disabled={isLoading || !CreateApplicationValidationSchema.isValidSync(store)}
              onClick={(event) => { handleValueChange(event); }}
              className="btn bds-btn-primary">Next</button>
          </div>
        </div>
      </form>
    </>
  )
  
}

export default observer(NbarCreateApplication);