import { AppSettings } from "app-setting";
import { useEffect, useState } from "react";

interface Props {
  id: string;
  name: string;
  cssClass?: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  honorAgeRestriction? : boolean
}


const BdsDatePicker = ({ id, name, cssClass, value, onChange, onBlur, honorAgeRestriction = true } : Props) => {
  const [minDate, setMinDate] = useState<string>("")
  useEffect(() => {
    if(honorAgeRestriction) {
      var minDate = new Date();
      setMinDate(minDate.setFullYear(minDate.getFullYear() - AppSettings.ageDateRangeRestriction).toString())
    }
    else {
      setMinDate("1800-01-01")
    }
  }, []);

  const handleChange = (event) => {
    const year = new Date(event.target.value).getFullYear();
    if(year <= 9999) {
        onChange(event);
    }      
  }

  const handleBlur = (event) => {
    if(onBlur) {
      onBlur(event);
    }
  }

  return (
    <div>       
        <input type="date"
            id={id}
            name={name}
            value={value}
            onChange={(event) => handleChange(event)} 
            onBlur={(event) => handleBlur(event)}
            min={minDate}
            max={"9999-12-31"}
            pattern="\d{2}-\d{2}-\d{4}"
            className={`bds-datepicker ${cssClass || ""}`}
        />
    </div>
  );
};

export default BdsDatePicker;


