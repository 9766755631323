import { GatewayOapApi } from "../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class AddressResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public VerifyAddress(streetAddress1: string, city: string,
    stateId: number, zipCode: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfVerifiedAddressModel> {
    return super.postRequest(`api/v1/addresses/verify-address`, null, {
      streetAddress1: streetAddress1,
      city: city,
      stateId: stateId,
      zipCode: zipCode
    } as GatewayOapApi.VerifyAddressRequest,
    abortController) as Promise<GatewayOapApi.ApiSingleResponseOfVerifiedAddressModel>;
  }
}