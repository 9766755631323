import * as Yup from "yup";
import { INbarTreatmentNeeds } from "./treatment-information.store";
import { Utility } from "../../../../shared-module/helpers/utility";
import * as enums from '../../../../shared-module/helpers/enums';
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";

// validation schema
export const NbarTreatmentInformationValidationSchema: Yup.ObjectSchema<INbarTreatmentNeeds> = Yup.object({
    selectedTreatmentOptions: Yup.array().nullable()
        .test("validate-availableTreatmentNeeds", "-", function(item) {
            if(item.length < 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("selectedTreatmentOptions", "Treatment Needs being explored is a required selection")
                )
            }
            return true;
        }),
    treatmentNeedsDescription: Yup.string()
        .test("validate-treatmentNeedsDescription", "-", function(item) {
            if(!item) {
                return this.createError(
                    Utility.buildYupErrorMessage("treatmentNeedsDescription", "Please describe the treatment needs")
                );
            }
            return true;
        }),
    hasPrimaryCarePhysician: Yup.number()
        .test("validate-hasPrimaryCarePhysician", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasPrimaryCarePhysician", "Has Primary Care Physician is a required selection")
                )
            }
            return true;
        }),
    primaryCarePhysicianName: Yup.string()
        .test("validate-primaryCarePhysicianName", "-", function(item) {
            if(this.parent.hasPrimaryCarePhysician == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("primaryCarePhysicianName", "Please provide a Primary Physician Name")
                );
            }
            return true;
        }),
    medicalDiagnoses: Yup.string()
        .test("validate-medicalDiagnoses", "-", function(item) {
            if(this.parent.hasPrimaryCarePhysician == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("medicalDiagnoses", "Please provide a description for Medical Diagnoses")
                );
            }
            return true;
        }),
    isUnderPsychiatricCare: Yup.number()
        .test("validate-isUnderPsychiatricCare", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("isUnderPsychiatricCare", "Is Under Psychiatric Care is a required selection")
                )
            }
            return true;
        }),
    psychiatristName: Yup.string()
        .test("validate-psychiatristName", "-", function(item) {
            if(this.parent.isUnderPsychiatricCare  == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("psychiatristName", "Please provide a Psychiatrist Name")
                );
            }
            return true;
        }),
    psychiatristDiagnoses: Yup.string()
        .test("validate-psychiatristDiagnoses", "-", function(item) {
            if(this.parent.isUnderPsychiatricCare == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("psychiatristDiagnoses", "Please provide a description for Psychiatrist Diagnoses")
                );
            }
            return true;
        }),
    triggerMedicationValidation: Yup.boolean(),
    triggerMedicationChangeValidation: Yup.boolean(),
    medicationValidationMessages: Yup.array().nullable()
        .test('validate-medicationValidationMessages', "-", function() {
            if(this.parent.triggerMedicationValidation) {
                if (!this.parent.addMedicationName && !this.parent.addMedicationDescription) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationValidationMessage", `Medication Name and Reason for Prescribing are required`)
                    );
                } else if(!this.parent.addMedicationName) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationValidationMessage", `Medication Name is required`)
                    );
                } else if(!this.parent.addMedicationDescription) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationValidationMessage", `Reason for Prescribing is required`)
                    );
                }
            }
            return true;
        }),
    medicationChangeValidationMessages: Yup.array().nullable()
        .test('validate-medicationChangeValidationMessages', "-", function() {
            if(this.parent.triggerMedicationChangeValidation) {
                // console.log(this.parent.hasChangesInMedication);
                if (!this.parent.addMedicationLastChanged && !this.parent.selectedMedicationForChange) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationChangeValidationMessages", `Please select the medication name and appropriate change frequency.`)
                    );
                } else if(!this.parent.selectedMedicationForChange) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationChangeValidationMessages", `Please select the appropriate medication`)
                    );
                } else if(!this.parent.addMedicationLastChanged) {
                    return this.createError( 
                        Utility.buildYupErrorMessage("medicationChangeValidationMessages", `Please indicate the change frequency`)
                    );
                }
            }
            return true;
        }),
    hasMedications: Yup.number()
        .test('validate-hasMedications', "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage('hasMedications', "Please provide an answer to Has Medications question.")
                )
            }
            return true;
        }),
    medicationList: Yup.array()
        .test('validate-medicationList', "-", function(item : GatewayOapApi.MedicationInformationModel[]) {
            // console.log(`Medication List item: ${item.length}`);
            if(this.parent.hasMedications == enums.YesNoNARadioButtonGroupOptions.Yes && item.length < 1) {
                return this.createError(
                    Utility.buildYupErrorMessage('hasMedications', "Please provide relevant prescription information")
                )
            }
            return true;
        }),
    addMedicationName: Yup.string().nullable(),
    addMedicationDescription: Yup.string().nullable(),
    addMedicationLastChanged: Yup.string().nullable(),
    hasChangesInMedication: Yup.number()
        .test("validate-hasChangesInMedication", "-", function(item) {
            if(item < 0 && this.parent.medicationList.length > 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasMedications", "Has Changes In Medication is a required selection")
                )
            }
            return true;
        })
        .test("validate-hasChangesInMedication", "-", function(item) {
            // console.log('any: ', this.parent.medicationList.some(x => x.prescriptionChangeTypeId > 0));
            if(item == enums.YesNoNARadioButtonGroupOptions.Yes && !this.parent.medicationList.some(x => x.prescriptionChangeTypeId > 0)) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasMedications", "Please provide the change frequency for any relevant medications")
                )
            }
            return true;
        }),
    selectedMedicationForChange: Yup.string().nullable(),
    hasRefusedMedication: Yup.number()
        .test("validate-hasRefusedMedication", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasRefusedMedication", "Has Refused Medication is a required selection")
                )
            }
            return true;
        }),
    hasRefusedMedicationDescription: Yup.string()
        .test('validate-hasRefusedMedicationDescription', "-", function(item) {
            if(this.parent.hasRefusedMedication == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage('hasRefusedMedicationDescription', "Has Refused Medication Description is required")
                )
            }
            return true;
        }),
    hasBehaviorManagmentService: Yup.number()
        .test("validate-hasBehaviorManagmentService", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasBehaviorManagmentService", "Has Behavior Managment Services is a required selection")
                )
            }
            return true;
        }),
    hasBehaviorManagmentConsultation: Yup.number()
        .test("validate-hasBehaviorManagmentConsultation", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasBehaviorManagmentConsultation", "Has Behavior Managment Consultation is a required selection")
                )
            }
            return true;
        }),
    hasTriggerEvents: Yup.number()
        .test("validate-hasTriggerEvents", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasTriggerEvents", "Has Triggered Events is a required selection")
                )
            }
            return true;
        }),
    triggerEventsDescription: Yup.string()
        .test('validate-triggerEventsDescription', "-", function(item) {
            if(this.parent.hasTriggerEvents == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage('triggerEventsDescription', "Has Trigger Events Description is required")
                )
            }
            return true;
        }),
    hasAdditionalServices: Yup.number()
        .test("validate-hasAdditionalServices", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasAdditionalServices", "Has Additional Services is a required selection")
                )
            }
            return true;
        }),
    selectedAdditionalServiceOptions: Yup.array()
        .test("validate-additionalServicesList", "-", function(item) {
            if(this.parent.hasAdditionalServices == enums.YesNoNARadioButtonGroupOptions.Yes && item.length < 1) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasAdditionalServices", "Additional Services is a required selection")
                )
            }
            return true;
        }),
    hasRecentHospitalizations: Yup.number()
        .test("validate-hasRecentHospitalizations", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasRecentHospitalizations", "Has Recent Hospitalizations is a required selection")
                )
            }
            return true;
        }),
    recentHospitalizationDescription: Yup.string()
        .test("validate-recentHospitalizationDescription", "-", function(item) {
            if(this.parent.hasRecentHospitalizations == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("recentHospitalizationDescription", "Has Recent Hospitalizations Description is required")
                )
            }
            return true;
        }),
    hasPastLawEnforcement: Yup.number()
        .test("validate-hasPastLawEnforcement", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasPastLawEnforcement", "Has Past Law Enforcement is a required selection")
                )
            }
            return true;
        }),
    pastLawEnforcementDescription: Yup.string()
        .test("validate-pastLawEnforcementDescription", "-", function(item) {
            if(this.parent.hasRecentHospitalizations == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("pastLawEnforcementDescription", "Past Law Enforcement Description is required")
                )
            }
            return true;
        }),
    hasStructuredRoutine: Yup.number()
        .test("validate-hasStructuredRoutine", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasStructuredRoutine", "Has Structured Routine is a required selection")
                )
            }
            return true;
        }),
    routineDescription: Yup.string()
        .test("validate-routineDescription", "-", function(item) {
            if(this.parent.hasStructuredRoutine == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("routineDescription", "Routine Description is required")
                )
            }
            return true;
        }),
    participatesInCommunityActivities: Yup.number()
        .test("validate-participatesInCommunityActivities", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("participatesInCommunityActivities", "Participates in Community Activities is a required selection")
                )
            }
            return true;
        }),
    communityActivityDescription: Yup.string()
        .test("validate-communityActivityDescription", "-", function(item) {
            if(this.parent.participatesInCommunityActivities == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("communityActivityDescription", "Community Activity Description is required")
                )
            }
            return true;
        }),
    meaningfulDayDescription: Yup.string()
        .test("validate-meaningfulDayDescription", "-", function(item) {
            if(!item) {
                return this.createError(
                    Utility.buildYupErrorMessage("meaningfulDayDescription", "Meaningful Day Description is required")
                )
            }
            return true;
        }),
    capacityOfWaiverUsage: Yup.string()
        .test("validate-capacityOfWaiverUsage", "-", function(item) {
            if(!item) {
                return this.createError(
                    Utility.buildYupErrorMessage("capacityOfWaiverUsage", "Capacity of Waiver Usage Description is required")
                )
            }
            return true;
        }),
    isStudent: Yup.number()
        .test("validate-isStudent", "-", function(item) {
            if(item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("isStudent", "Is Student is a required selection")
                )
            }
            return true;
        }),
    studentStatus: Yup.number()
        .test("validate-studentStatus", "-", function(item) {
            if(this.parent.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes && item < 0) {
                return this.createError(
                    Utility.buildYupErrorMessage("studentStatus", "Student Status is a required selection")
                )
            }
            return true;
        }),
    studentWeeklySchedule: Yup.string()
        .test("validate-studentWeeklySchedule", "-", function(item) {
            if(this.parent.studentStatus == enums.StudentStatusRadioButtonGroupOptions.PartTime && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("studentWeeklySchedule", "Student Weekly Schedule Description is required")
                )
            }
            return true;
        }),
    hasIEP: Yup.number()
        .test("validate-hasIEP", "-", function(item) {
            if(this.parent.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes && item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasIEP", "Has IEP is a required selection")
                )
            }
            return true;
        }),
    isInSpecialEd: Yup.number()
        .test("validate-isInSpecialEd", "-", function(item) {
            if(this.parent.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes && item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("isInSpecialEd", "Is In Special Ed is a required selection")
                )
            }
            return true;
        }),
    hasSchoolBehaviors: Yup.number()
        .test("validate-hasSchoolBehaviors", "-", function(item) {
            if(this.parent.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes && item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("hasSchoolBehaviors", "Has School Behaviors is a required selection")
                )
            }
            return true;
        }),
    schoolBehaviorFrequency: Yup.number().nullable()
        .test("validate-hasSchoolBehaviors", "-", function(item) {
            if(this.parent.hasSchoolBehaviors == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("schoolBehaviorFrequency", "School Behavior Frequency is a required selection")
                )
            }
            return true;
        }),
    schoolBehaviorDescription: Yup.string().nullable()
        .test("validate-schoolBehaviorDescription", "-", function(item) {
            if(this.parent.hasSchoolBehaviors == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("schoolBehaviorDescription", "Please describe the School Behavior")
                )
            }
            return true;
        }),
});