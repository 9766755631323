import FilePreviewModalEventBus from "./file-preview-modal-event-bus";
import { FilePreviewModalOptions } from "./file-preview-modal-container.component";

export enum FilePreviewModalEvents {
  MODAL_SHOW = "PREVIEW.MODAL.SHOW",
  MODAL_HIDE = "PREVIEW.MODAL.HIDE"
}

class FilePreviewModal {
  private static instance: FilePreviewModal;

  private constructor() {
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new FilePreviewModal();
    }
    return this.instance;
  }

  public showModal(options: FilePreviewModalOptions) {
    FilePreviewModalEventBus.dispatch(FilePreviewModalEvents.MODAL_SHOW, options);
  }

  public hideModal() {
    FilePreviewModalEventBus.dispatch(FilePreviewModalEvents.MODAL_HIDE, null);
  }
}

export default FilePreviewModal.getInstance();
