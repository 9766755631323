import { useEffect, useState } from "react";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";
import { NavigationHelper } from "shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "app-setting";


function InternalServerErrorComponent() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    function showServerErrorBanner() {
        setShow(true);
        if (!(sessionStorage.getItem("network-outage"))) {
            sessionStorage.setItem("network-outage", new Date().toString())
            return;
        }
        const elapsed = (new Date().getTime() - Date.parse(sessionStorage.getItem("network-outage")));
        const threshold = AppSettings.fatalErrorBannerThreshold;
        if (elapsed > threshold) {
            NavigationHelper.gotoHome(navigate);
        }
    }

    function clearServerErrorBanner() {
        sessionStorage.removeItem("network-outage")
        setShow(false)
    }

    useEffect(() => {
        EventBroadcasterService.RegisterForServerResponseErrorEvent(showServerErrorBanner)
        EventBroadcasterService.RegisterForClearServerResponseErrorEvent(clearServerErrorBanner)
        return () => {
            EventBroadcasterService.UnregisterForServerResponseErrorEvent(showServerErrorBanner)
            EventBroadcasterService.UnregisterForClearServerResponseErrorEvent(clearServerErrorBanner)
    }}, []);

    return (
    <>
    <ShowThen when={show}>
        <div className="bds-loading-overlay">
            <div className="bds-internal-server-error-banner">
                <div className="container bds-container p-2">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex">
                    <div className="align-self-center">
                        <span className="heading">Connection to server has been interrupted</span>
                        {" - "}
                        <span className="message">The system administrator has been notified. Please do not refresh your screen.</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </ShowThen>
    </>
  )
}

export const InternalErrorBanner = InternalServerErrorComponent;