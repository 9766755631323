import Form from 'react-bootstrap/Form';

interface RadioGroupOptions {
    text: string,
    value: number,
}

interface Props {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    labelText: string,
    radioGroupOptions: RadioGroupOptions[],
    name: string,
    selectedValue: number,
    toolTipText?: string
}
export const RadioButtonGroup = ({onChange, name, labelText, radioGroupOptions, selectedValue, toolTipText = ''}: Props) => {
    return (
        <>
            <div className="col-md-6">
                <Form.Label><strong>{labelText}</strong></Form.Label>
                {toolTipText && 
                    <div className="bds-section-details mt-2">
                        {toolTipText}
                    </div>
                }
            </div>
            <div className="col-md-6">
                { radioGroupOptions.map((option, index) => {
                    return (                      
                        <Form.Check
                            inline
                            label={option.text}
                            value={option.value}
                            name={name}
                            type="radio"
                            id={`${name}_${index}`}
                            onChange={onChange}
                            checked={selectedValue == option.value}
                        ></Form.Check>
                        )
                    })
                }
            </div>
        </>
    )
}