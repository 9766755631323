// GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

// APP_MODULE
// local_component_imports
import { NbarApplicantSignatureValidationSchema } from "./nbar-signature.validation";
import { INbarApplicantSignature, NbarApplicantSignatureStore } from "./nbar-signature.store";

// ../extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";
import { PrintAndSubmitModal, PrintAndSubmitModalOptions, PrintAndSubmitModalSetup } from "../../../extensions/print-and-submit-modal/print-and-submit-modal.component";

// SHARED_MODULE
// ../resources
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarDemographicsResource } from "../../../../shared-module/api-resources/Nbar/nbar-demographics.resource";

// ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";

// ../helpers
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Utility } from "../../../../shared-module/helpers/utility";

// ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// ../models
import { GatewayNetworkError } from "../../../../shared-module/models/common.models";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";

// ../services
import { ApiConfig } from "../../../../shared-module/services/api-config";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";
import { ShowThen } from "shared-module/components/show-then/show-then.component";



function NbarSignature() {
    // INSTANTIATIONS
    // view model
    const store = NbarApplicantSignatureStore;

    // ./hooks
    const { id: nbarApplicationRefId } = useParams();
    const navigate = useNavigate();
    const { errors, touched, setFieldsTouched } = useFormValidation(NbarApplicantSignatureValidationSchema, store);
    
    // const legalRepresentativeStamp = Utility.getLegalRepresentativeStamp(store.legalRepresentativeSignatureSeed, nbarApplicationRefId)

    // ./resources
    const applicationService = new NbarApplicationResource(ApiConfig);
    const demographicService = new NbarDemographicsResource(ApiConfig);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarApplicantSignature>({} as INbarApplicantSignature);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // ../component state
    const submissionModalId = "printAndSubmitModal";
    const [printAndSubmitModalOptions, setPrintAndSubmitModalOptions] = useState<PrintAndSubmitModalOptions>();


    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) {
            return;
        }
        setIsSaving(true);
        applicationService.saveNbarApplicationInformation(nbarApplicationRefId, store.firstName, store.middleName, store.lastName,
            Number(store.suffixTypeId), store.dateOfBirth, store.streetAddress1, store.city, Number(store.state), store.zipCode,
            store.homePhoneNumber, store.alternatePhoneNumber, store.reasonDescription, store.additionalInformation, store.legalRepresentativeFullName
        )
        .then(() => {
            console.log('after save');
            //applicationService.printSubmittedNbarApplication(nbarApplicationRefId);
        })
        .finally(() => {
            setIsSaving(false);
        });
    }


    // LOCAL FORM PROCESSING
    function openApplicationforPrint(): Promise<any> {
        var date = new Date();
        return applicationService.printSubmittedNbarApplication(nbarApplicationRefId)
        .then((response: string) => {
            var file = new Blob([response], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            var fileLink = document.createElement("a");
            fileLink.target = "_blank";
            fileLink.href = fileURL;
            fileLink.download = store.getNbarDocumentName();
            fileLink.click();
        });
    }

    function beginSubmission() {
        printAndSubmitModalOptions.show({
            onCancel: () => {
                return Promise.resolve(true)
                    .then(() => {
                        return printAndSubmitModalOptions.hide();
                    })
            },
            onPrintAndSubmit: () => {
                return submitNbarApplication(true)
                    .then(() => {
                        return printAndSubmitModalOptions.hide();
                    });
            },
            onSubmitOnly: () => {
                return submitNbarApplication(false)
                    .then(() => {
                        return printAndSubmitModalOptions.hide();
                    })
            },
        } as PrintAndSubmitModalSetup);
    }

    function submitNbarApplication(printWithSubmit: boolean): Promise<any> {

        let applicantStamp = null;
        let legalRepStamp = null;
        if(store.applicantCertified) {
            applicantStamp = Utility.getApplicantStamp(store.applicantSignatureSeed, nbarApplicationRefId);
        }
        if(store.legalRepresentativeCertified) {
            legalRepStamp = Utility.getLegalRepresentativeStamp(store.legalRepresentativeFullName.replace(/[^a-zA-Z]/, ''), nbarApplicationRefId);
        }

        console.log('Applicant Stamp: ', applicantStamp);
        console.log('Legal Rep Stamp: ', legalRepStamp);

        return applicationService.submitNbarApplication(
            nbarApplicationRefId,
            store.applicantCertified,
            applicantStamp?.signature,
            applicantStamp?.signatureOn,
            printWithSubmit,
            store.legalRepresentativeCertified,
            legalRepStamp?.signature,
            legalRepStamp?.signatureOn
        )
        .then((response: GatewayOapApi.ApiBooleanResponse) => {
            if (printWithSubmit) {
                return openApplicationforPrint();
            } else {
                return Promise.resolve();
            }
        })
        .then((response) => {
            if (IsAuthenticated) {
                NavigationHelper.gotoDashboard(navigate);
            } else {
                NavigationHelper.gotoNbarHome(navigate);
            }
            return response;
        })
        .catch(ex => {
            if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex.errorMessage);
            }
        })
        .finally(() => {
            setIsSaving(false);
        });
    }


    // PAGE LOAD
    useEffect(() => {
        setIsLoading(true);
        Utility.showLoadingOverlay();
        Promise.all([
            applicationService.getApplication(nbarApplicationRefId)
        ])
            .then((response) => {
                store.refresh(response[0].record);
                setSavedState(store.buildState(response[0].record));
            })
            .catch((ex) => {
                if (!(ex instanceof GatewayNetworkError)) {
                    Utility.alert("Error Saving", ex);
                }
                NavigationHelper.gotoHome(navigate);
            })
            .finally(() => {
                setIsLoading(false);
                Utility.hideLoadingOverlay();
            });

    }, [])

    // COMPONENT MARKUP
    return (
        <>
            <PageTitleSetter step={6} totalSteps={6}></PageTitleSetter>
            <div className="mt-4">
                <ApplicationProgressBar step={6} totalSteps={6}></ApplicationProgressBar>
            </div>
            <PrintAndSubmitModal id={submissionModalId} accessor={(options: PrintAndSubmitModalOptions) => {
                setPrintAndSubmitModalOptions(options);
            }} />

            <form>
                <div className="row mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Additional Information</div>
                        <div className="row mt-3">
                            <NbarTextArea
                                id="additional-information"
                                name="additionalInformation"
                                value={store.additionalInformation}
                                onChange={(event) => {
                                    store.setAdditionalInformation(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("additionalInformation");
                                    handleValueChange(event,
                                        "additionalInformation",
                                        store.additionalInformation,
                                        savedState.additionalInformation
                                    )
                                }}
                                labelText="Please identify any other issues, concerns or needs that have not been asked in any of the previous questions that you believe the reviewer needs to know:"
                            ></NbarTextArea>
                        </div>
                    </Card>
                </div>
                <div className="row mt-4">
                    <Card>
                        <div className="bds-section-heading">
                            Signature of Legal Representative/Guardian (if applicable)
                        </div>
                        <div className="signature-description mt-2">
                            By checking the signature box, you are attesting to the fact
                            that you are a legal representative of the applicant and wish to
                            pursue an application for BDS services for the applicant. At any
                            time, you can choose to decline services for the applicant or
                            stop the application process.
                        </div>
                        <div className="mt-3">
                            The applicant may check the signature box but is not required to
                            do so.
                        </div>
                        <div>
                            <BdsCheckbox
                                id="legal-representative-certified"
                                name="legalRepresentativeCertified"
                                checked={store.legalRepresentativeCertified}
                                cssClass="mt-3"
                                onChange={(event) => {
                                    store.setLegalRepresentativeFullName('')
                                    store.setLegalRepresentativeCertified(
                                        event.target.checked
                                    );
                                    setFieldsTouched("legalRepresentativeCertified");
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("legalRepresentativeCertified");
                                    handleValueChange(event,
                                        "legalRepresentativeCertified",
                                        store.legalRepresentativeCertified,
                                        savedState.legalRepresentativeCertified
                                    )
                                }}
                                label="I certify under penalty of perjury, all information I have given on this application is complete and correct to the best of my knowledge and belief."
                            />
                        </div>
                        <div className="mt-3">
                            Please check the box and provide a Full Name below to sign this application.
                        </div>

                            <div className="mt-3">
                            <ShowThen when={store.legalRepresentativeCertified}>
                                <label className="bds-form-label form-label mandatory">Full Name:</label>
                            </ShowThen>
                            <ShowThen when={!store.legalRepresentativeCertified}>
                                <label className="bds-form-label form-label">Full Name:</label>
                            </ShowThen>
                            <input type="text" name="legalRepresentativeFullName" 
                                value={store.legalRepresentativeFullName}
                                onChange={(event) => {
                                    setFieldsTouched("legalRepresentativeFullName");
                                    store.setLegalRepresentativeFullName(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("legalRepresentativeFullName");
                                    handleValueChange(event,
                                        "legalRepresentativeFullName",
                                        store.legalRepresentativeFullName,
                                        savedState.legalRepresentativeFullName
                                    )
                                }}
                                className="bds-form-text bds-grid-form form-control mt-2 mt-md-0"
                                id="legal-representative-full-name" 
                                disabled = {!store.legalRepresentativeCertified}/>
                            </div>
                    </Card>
                </div>

                <div className="row mt-4">
                    <Card>
                        <ShowThen when={store.legalRepresentativeCertified}>
                            <div className="bds-section-heading">Signature of Applicant</div>
                        </ShowThen>
                        <ShowThen when={!store.legalRepresentativeCertified}>
                            <div className="bds-section-heading mandatory">Signature of Applicant</div>
                        </ShowThen>
                        <div className="signature-description mt-2">
                            By clicking the signature box, you are attesting to the fact that
                            you are the applicant and wish to pursue an application for BDS
                            services. At any time, you can choose to decline services or stop
                            the application process.
                        </div>
                        <BdsCheckbox id="applicant-certified"
                            name="applicantCertified"
                            checked={store.applicantCertified}
                            onChange={(event) => {
                                store.setApplicantCertified(event.target.checked);
                                setFieldsTouched("applicantCertified");
                            }}
                            onBlur={() => {
                                setFieldsTouched("applicantCertified");
                            }}
                            cssClass="mt-3"
                            label="I certify under penalty of perjury, all information I have
                                given on this application is complete and correct to the best
                                of my knowledge and belief."
                        />
                        <div className="">
                            Check the box to sign this application.
                        </div>
                    </Card>
                </div>

                {/*BUTTONS*/}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <button type="button" className="btn bds-btn-primary" disabled={isLoading}
                            onClick={() => NavigationHelper.gotoNbarBehavioralInformation(navigate, nbarApplicationRefId)}>Back</button>
                        <IsAuthenticated>
                            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading || isSaving || !NbarApplicantSignatureValidationSchema.isValidSync(store)}
                            onClick={beginSubmission}
                            className="btn bds-btn-primary">Submit</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default observer(NbarSignature)
