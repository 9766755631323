// --GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

// --APP_MODULE
// -- --  ./local_component_imports
import { INbarBehavioralInformation, NbarBehavioralInformation } from "./behavioral-information.store";
import { NbarBehavioralInformationValidationSchema } from "./behavioral-information.validation";

// -- --  ../extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";

// --SHARED_MODULE
// -- --  ../resources
import { LookupResource } from "../../../../shared-module/api-resources/lookup.resource";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarBehavioralInformationResource } from "../../../../shared-module/api-resources/Nbar/nbar-behavioral.resource";

// -- --  ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { RadioButtonGroup } from "../../../../shared-module/components/radio-button-group/radio-button-group.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";

// -- --  ../helpers
import * as enums from '../../../../shared-module/helpers/enums'
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Utility } from "../../../../shared-module/helpers/utility";

// -- --  ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// -- --  ../models
import { ApplicationExpirationError, GatewayNetworkError } from "../../../../shared-module/models/common.models";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";

// -- --  ../services
import { ApiConfig } from "../../../../shared-module/services/api-config";
import { SessionService } from "../../../../shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";


function AdditionalInformation() {
    // INSTANTIATIONS
    // view model
    const store = NbarBehavioralInformation;

    // ./hooks
    const { id: nbarApplicationRefId } = useParams();
    const navigate = useNavigate();
    const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } = 
        useFormValidation(NbarBehavioralInformationValidationSchema, store);

    // ./resources
    const lookupService = new LookupResource(ApiConfig);
    const behavioralService = new NbarBehavioralInformationResource(ApiConfig);
    const applicationService = new NbarApplicationResource(ApiConfig);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarBehavioralInformation>({} as INbarBehavioralInformation);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // ../component state
    const [YesNoRadioOptions, setYesNoRadioOptions] = useState([]);
    const [YesNoNARadioOptions, setYesNoNARadioOptions] = useState([]);
    const [behavioralInformationId, setBehavioralInformationId] = useState<number>(null);

    // GLOBAL FORM PROCESSING AND NAVIGATION
    // ../"Public Methods"
    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) { return; }
        setIsSubmitting(true, eventSource);
        submit()
        .finally(() => {
            setIsSubmitting(false, eventSource);
        })
    }

    const handleGoToNextStep = (event) => {
        validate().then(() => {
            setIsSaving(true);
            submit().then(() => {
                NavigationHelper.gotoNbarSignature(navigate, nbarApplicationRefId);
            })     
        })
        .catch((error) => {
            console.log('error: ', error);
            scrollTo(0, 0);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    // ../"Private Methods"
    function submit() : Promise<any> {
        // the call to applicationService.getApplication() is needed to ensure the application has not expired.
        // TODO: refactor this out for nBar. We do not want to pull the application data on each call.
        // --- will need an alternate way of determining current application status
        setIsSaving(true); 
        return applicationService.getApplication(nbarApplicationRefId)
        .then(() => {
            saveBehavioralInformation()
            .then(() => {
                var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
                if(guestSessionId != "0") {
                    SessionService.slideGuestSession(Number(guestSessionId));
                    EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                        isAuthenticated: false,
                        remainingTimeInSeconds: 900,
                        hasActiveMaintenance: false
                    });
                }
            })   
        })
        .catch((ex) => {
            if (ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    function validate() : Promise<any> {
        touchedAll();
        //    console.log('entered: validate');
        return NbarBehavioralInformationValidationSchema.validate(store)
        .then((response) => {
            console.log('validation response: ', response);
            NbarBehavioralInformationValidationSchema.isValidSync(store);
        })
        .catch((error) => {
            throw error;
        });
    }

    function saveBehavioralInformation(): Promise<any> {
        return behavioralService.saveNbarBehavioralInformation(
            behavioralInformationId,
            store.useOfInappropriateLanguage, 
            store.useOfInappropriateLanguageDescription, 
            store.useOfInappropriateLanguageFrequency, 
            store.useOfInappropriateLanguageTechniques,
            store.refusalToAcceptDirection, 
            store.refusalToAcceptDirectionDescription, 
            store.refusalToAcceptDirectionFrequency, 
            store.refusalToAcceptDirectionTechniques,
            store.verbalOrPhysicalIntimidation, 
            store.verbalOrPhysicalIntimidationDescription, 
            store.verbalOrPhysicalIntimidationFrequency, 
            store.verbalOrPhysicalIntimidationTechniques,
            store.destructionOfPersonalProperty, 
            store.destructionOfPersonalPropertyDescription, 
            store.destructionOfPersonalPropertyFrequency, 
            store.destructionOfPersonalPropertyTechniques,
            store.destructionOfPublicProperty, 
            store.destructionOfPublicPropertyDescription, 
            store.destructionOfPublicPropertyFrequency, 
            store.destructionOfPublicPropertyTechniques,
            store.physicalAgressionTowardsSelf, 
            store.physicalAgressionTowardsSelfDescription, 
            store.physicalAgressionTowardsSelfFrequency, 
            store.physicalAgressionTowardsSelfTechniques,
            store.physicalAgressionTowardsOthers, 
            store.physicalAgressionTowardsOthersDescription, 
            store.physicalAgressionTowardsOthersFrequency, 
            store.physicalAgressionTowardsOthersTechniques,
            store.elopementFromHome, 
            store.elopementFromHomeDescription, 
            store.elopementFromHomeFrequency, 
            store.elopementFromHomeTechniques,
            store.elopementFromCommunity, 
            store.elopementFromCommunityDescription, 
            store.elopementFromCommunityFrequency, 
            store.elopementFromCommunityTechniques,
            store.rageAndOutbursts, 
            store.rageAndOutburstsDescription, 
            store.rageAndOutburstsFrequency, 
            store.rageAndOutburstsTechniques,
            store.inappropriateSexualBehavior, 
            store.inappropriateSexualBehaviorDescription, 
            store.inappropriateSexualBehaviorFrequency, 
            store.inappropriateSexualBehaviorTechniques, 
            null
        )
    }

    // PAGE LOAD
    useEffect(() => {      
        setIsLoading(true);
        Utility.showLoadingOverlay();
        setYesNoRadioOptions(Utility.getRadioGroupYesNoOptions());
        setYesNoNARadioOptions(Utility.getRadioGroupYesNoOptions(true));
        Promise.all([
            applicationService.getApplication(nbarApplicationRefId),
            lookupService.getFrequencyTypes()
          ])
        .then((response) => {
            const id = response[0].record?.behavioralInformationId;
            store.setAvailableFrequencyOptions(response[1].records);
            setBehavioralInformationId(id);
            return behavioralService.getNbarBehavioralInformation(id);
        })
        .then((response) => {
            store.refresh(response.record);
            setSavedState(store.buildState(response.record));

        })
        .catch((ex) => {
            if (ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsLoading(false);
            Utility.hideLoadingOverlay();
        });
    }, [])

    // COMPONENT MARKUP
    return (
        <>
            <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
            <PageTitleSetter step={5} totalSteps={6}></PageTitleSetter>
            <div className="mt-4">
                <ApplicationProgressBar step={5} totalSteps={6}></ApplicationProgressBar>
            </div>
            <ShowThen when={!isLoading}>
                <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
            </ShowThen>

            <form>
                {/* FORM INSTRUCTIONS*/}
                <div className="row mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Please provide a Yes or No answer to each of the questions below:</div>
                        <div className="row mt-3">
                            <p>If the answer is YES, please provide answers to the additional questions as follows:</p>
                            
                            <div className="col-12 ms-3">
                                <ul>
                                    <li><strong>Frequency:</strong> How often does it occur? Please provide the answer that is representative of a normal week. Not a reflection of one or two unusual weeks</li>
                                    <li><strong>Description:</strong> When did this begin? Was there an event surrounding the behavior that may have triggered it? What age did it first happen?</li>
                                    <li><strong>Techniques and Strategies:</strong> Elaborate on the actions taken to address this behavior or otherwise diffuse the situation. </li>
                                </ul>
                            </div>

                            <p><em>Answers to all visible questions are required. If you cannot provide an answer, please mark the text field with &quot;NA&quot;</em>.</p>

                        </div>
                        <div className="row mt-3">
                        </div>
                    </Card>
                </div>

                {/* USE OF INAPPROPRIATE LANGUAGE*/}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there use of inappropriate language or swearing?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="useOfInappropriateLanguage"
                                selectedValue={store.useOfInappropriateLanguage}
                                onChange={(event) => {
                                    store.setUseOfInappropriateLanguage(Number(event.target.value))
                                    setFieldsTouched("useOfInappropriateLanguage")
                                    handleValueChange(event, 
                                        "useOfInappropriateLanguage", 
                                        store.useOfInappropriateLanguage, 
                                        savedState.useOfInappropriateLanguage
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.useOfInappropriateLanguage == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="useOfInappropriateLanguageFrequency" value={store.useOfInappropriateLanguageFrequency}
                                            onChange={(event) => {
                                                store.setUseOfInappropriateLanguageFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("useOfInappropriateLanguageFrequency");
                                                handleValueChange(event,
                                                "useOfInappropriateLanguageFrequency",
                                                store.useOfInappropriateLanguageFrequency,
                                                savedState.useOfInappropriateLanguageFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="inappropriate-language-description" 
                                        name="useOfInappropriateLanguageDescription" 
                                        value={store.useOfInappropriateLanguageDescription}
                                        onChange={(event) => { 
                                            store.setUseOfInappropriateLanguageDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("useOfInappropriateLanguageDescription");
                                            handleValueChange(event,
                                                "useOfInappropriateLanguageDescription",
                                                store.useOfInappropriateLanguageDescription,
                                                savedState.useOfInappropriateLanguageDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>

                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="inappropriate-language-techniques" 
                                        name="useOfInappropriateLanguageTechniques" 
                                        value={store.useOfInappropriateLanguageTechniques}
                                        onChange={(event) => { 
                                            store.setUseOfInappropriateLanguageTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("useOfInappropriateLanguageTechniques");
                                            handleValueChange(event,
                                                "useOfInappropriateLanguageTechniques",
                                                store.useOfInappropriateLanguageTechniques,
                                                savedState.useOfInappropriateLanguageTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* REFUSAL TO ACCEPT DIRECTION*/}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Does the individual refuse to accept direction from parent/guardian or authority figure?"
                                radioGroupOptions={YesNoRadioOptions}
                                name="refusalToAcceptDirection"
                                selectedValue={store.refusalToAcceptDirection}
                                onChange={(event) => {
                                    store.setRefusalToAcceptDirection(Number(event.target.value))
                                    setFieldsTouched("refusalToAcceptDirection")
                                    handleValueChange(event, 
                                        "refusalToAcceptDirection", 
                                        store.refusalToAcceptDirection, 
                                        savedState.refusalToAcceptDirection
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.refusalToAcceptDirection == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="refusalToAcceptDirectionFrequency" value={store.refusalToAcceptDirectionFrequency}
                                            onChange={(event) => {
                                                store.setRefusalToAcceptDirectionFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("refusalToAcceptDirectionFrequency");
                                                handleValueChange(event,
                                                "refusalToAcceptDirectionFrequency",
                                                store.refusalToAcceptDirectionFrequency,
                                                savedState.refusalToAcceptDirectionFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="refusal-to-accept-direction-description" 
                                        name="refusalToAcceptDirectionDescription" 
                                        value={store.refusalToAcceptDirectionDescription}
                                        onChange={(event) => { 
                                            store.setRefusalToAcceptDirectionDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("refusalToAcceptDirectionDescription");
                                            handleValueChange(event,
                                                "refusalToAcceptDirectionDescription",
                                                store.refusalToAcceptDirectionDescription,
                                                savedState.refusalToAcceptDirectionDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="refusal-to-accept-direction-techniques" 
                                        name="refusalToAcceptDirectionTechniques" 
                                        value={store.refusalToAcceptDirectionTechniques}
                                        onChange={(event) => { 
                                            store.setRefusalToAcceptDirectionTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("refusalToAcceptDirectionTechniques");
                                            handleValueChange(event,
                                                "refusalToAcceptDirectionTechniques",
                                                store.refusalToAcceptDirectionTechniques,
                                                savedState.refusalToAcceptDirectionTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* VERBAL OR PHYSICAL INTIMIDATION*/}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there verbal or physical intimidation?"
                                toolTipText="For example: making threats towards family, friends or peers."
                                radioGroupOptions={YesNoRadioOptions}
                                name="verbalOrPhysicalIntimidation"
                                selectedValue={store.verbalOrPhysicalIntimidation}
                                onChange={(event) => {
                                    store.setVerbalOrPhysicalIntimidation(Number(event.target.value))
                                    setFieldsTouched("verbalOrPhysicalIntimidation")
                                    handleValueChange(event, 
                                        "verbalOrPhysicalIntimidation", 
                                        store.verbalOrPhysicalIntimidation, 
                                        savedState.verbalOrPhysicalIntimidation
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.verbalOrPhysicalIntimidation == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="verbalOrPhysicalIntimidationFrequency" value={store.verbalOrPhysicalIntimidationFrequency}
                                            onChange={(event) => {
                                                store.setVerbalOrPhysicalIntimidationFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("verbalOrPhysicalIntimidationFrequency");
                                                handleValueChange(event,
                                                "verbalOrPhysicalIntimidationFrequency",
                                                store.verbalOrPhysicalIntimidationFrequency,
                                                savedState.verbalOrPhysicalIntimidationFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="intimidation-description" 
                                        name="verbalOrPhysicalIntimidationDescription" 
                                        value={store.verbalOrPhysicalIntimidationDescription}
                                        onChange={(event) => { 
                                            store.setVerbalOrPhysicalIntimidationDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("verbalOrPhysicalIntimidationDescription");
                                            handleValueChange(event,
                                                "verbalOrPhysicalIntimidationDescription",
                                                store.verbalOrPhysicalIntimidationDescription,
                                                savedState.verbalOrPhysicalIntimidationDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="intimidation-techniques" 
                                        name="setVerbalOrPhysicalIntimidationTechniques" 
                                        value={store.verbalOrPhysicalIntimidationTechniques}
                                        onChange={(event) => { 
                                            store.setVerbalOrPhysicalIntimidationTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("verbalOrPhysicalIntimidationTechniques");
                                            handleValueChange(event,
                                                "verbalOrPhysicalIntimidationTechniques",
                                                store.verbalOrPhysicalIntimidationTechniques,
                                                savedState.verbalOrPhysicalIntimidationTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* DESTRUCTION OF PERSONAL PROPERTY */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there destruction of personal property?"
                                toolTipText="For example: Deliberately ripping clothing, throwing chairs, tables, desks."
                                radioGroupOptions={YesNoRadioOptions}
                                name="destructionOfPersonalProperty"
                                selectedValue={store.destructionOfPersonalProperty}
                                onChange={(event) => {
                                    store.setDestructionOfPersonalProperty(Number(event.target.value))
                                    setFieldsTouched("destructionOfPersonalProperty")
                                    handleValueChange(event, 
                                        "destructionOfPersonalProperty", 
                                        store.destructionOfPersonalProperty, 
                                        savedState.destructionOfPersonalProperty
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.destructionOfPersonalProperty == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="destructionOfPersonalPropertyFrequency" value={store.destructionOfPersonalPropertyFrequency}
                                            onChange={(event) => {
                                                store.setDestructionOfPersonalPropertyFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("destructionOfPersonalPropertyFrequency");
                                                handleValueChange(event,
                                                "destructionOfPersonalPropertyFrequency",
                                                store.destructionOfPersonalPropertyFrequency,
                                                savedState.destructionOfPersonalPropertyFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="destruction-of-personal-property-description" 
                                        name="useOfInappropriateLanguageDescription" 
                                        value={store.destructionOfPersonalPropertyDescription}
                                        onChange={(event) => { 
                                            store.setDestructionOfPersonalPropertyDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("destructionOfPersonalPropertyDescription");
                                            handleValueChange(event,
                                                "destructionOfPersonalPropertyDescription",
                                                store.destructionOfPersonalPropertyDescription,
                                                savedState.destructionOfPersonalPropertyDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                

                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="destruction-of-personal-property-techniques" 
                                        name="destructionOfPersonalPropertyTechniques" 
                                        value={store.destructionOfPersonalPropertyTechniques}
                                        onChange={(event) => { 
                                            store.setDestructionOfPersonalPropertyTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("destructionOfPersonalPropertyTechniques");
                                            handleValueChange(event,
                                                "destructionOfPersonalPropertyTechniques",
                                                store.destructionOfPersonalPropertyTechniques,
                                                savedState.destructionOfPersonalPropertyTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* DESTRUCTION OF PUBLIC PROPERTY*/}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there destruction of public property?"
                                toolTipText="For example: deliberately breaking things, punching holes in walls, breaking windows."
                                radioGroupOptions={YesNoRadioOptions}
                                name="destructionOfPublicProperty"
                                selectedValue={store.destructionOfPublicProperty}
                                onChange={(event) => {
                                    store.setDestructionOfPublicProperty(Number(event.target.value))
                                    setFieldsTouched("destructionOfPublicProperty")
                                    handleValueChange(event, 
                                        "destructionOfPublicProperty", 
                                        store.destructionOfPublicProperty, 
                                        savedState.destructionOfPublicProperty
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.destructionOfPublicProperty == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="destructionOfPublicPropertyFrequency" value={store.destructionOfPublicPropertyFrequency}
                                            onChange={(event) => {
                                                store.setDestructionOfPublicPropertyFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("destructionOfPublicPropertyFrequency");
                                                handleValueChange(event,
                                                "destructionOfPublicPropertyFrequency",
                                                store.destructionOfPublicPropertyFrequency,
                                                savedState.destructionOfPublicPropertyFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="destruction-of-public-property-description" 
                                        name="destructionOfPublicPropertyDescription" 
                                        value={store.destructionOfPublicPropertyDescription}
                                        onChange={(event) => { 
                                            store.setDestructionOfPublicPropertyDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("destructionOfPublicPropertyDescription");
                                            handleValueChange(event,
                                                "destructionOfPublicPropertyDescription",
                                                store.destructionOfPublicPropertyDescription,
                                                savedState.destructionOfPublicPropertyDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                

                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="destruction-of-public-property-techniques" 
                                        name="destructionOfPublicPropertyTechniques" 
                                        value={store.destructionOfPublicPropertyTechniques}
                                        onChange={(event) => { 
                                            store.setDestructionOfPublicPropertyTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("destructionOfPublicPropertyTechniques");
                                            handleValueChange(event,
                                                "destructionOfPublicPropertyTechniques",
                                                store.destructionOfPublicPropertyTechniques,
                                                savedState.destructionOfPublicPropertyTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* PHYSICAL AGRESSION TOWARDS SELF */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there physical aggression towards self?"
                                toolTipText="For example: self-injurious behaviors, cutting and stabbing, punching or harming self."
                                radioGroupOptions={YesNoRadioOptions}
                                name="physicalAgressionTowardsSelf"
                                selectedValue={store.physicalAgressionTowardsSelf}
                                onChange={(event) => {
                                    store.setPhysicalAgressionTowardsSelf(Number(event.target.value))
                                    setFieldsTouched("physicalAgressionTowardsSelf")
                                    handleValueChange(event, 
                                        "physicalAgressionTowardsSelf", 
                                        store.physicalAgressionTowardsSelf, 
                                        savedState.physicalAgressionTowardsSelf
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.physicalAgressionTowardsSelf == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="useOfInappropriateLanguageFrequency" value={store.physicalAgressionTowardsSelfFrequency}
                                            onChange={(event) => {
                                                store.setPhysicalAgressionTowardsSelfFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("physicalAgressionTowardsSelfFrequency");
                                                handleValueChange(event,
                                                "physicalAgressionTowardsSelfFrequency",
                                                store.physicalAgressionTowardsSelfFrequency,
                                                savedState.physicalAgressionTowardsSelfFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="rwo mt-3">
                                    <NbarTextArea
                                        id="physical-aggression-towards-self-description" 
                                        name="physicalAgressionTowardsSelfDescription" 
                                        value={store.physicalAgressionTowardsSelfDescription}
                                        onChange={(event) => { 
                                            store.setPhysicalAgressionTowardsSelfDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("physicalAgressionTowardsSelfDescription");
                                            handleValueChange(event,
                                                "physicalAgressionTowardsSelfDescription",
                                                store.physicalAgressionTowardsSelfDescription,
                                                savedState.physicalAgressionTowardsSelfDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                

                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="physical-aggression-towards-self-techniques" 
                                        name="physicalAgressionTowardsSelfTechniques" 
                                        value={store.physicalAgressionTowardsSelfTechniques}
                                        onChange={(event) => { 
                                            store.setPhysicalAgressionTowardsSelfTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("physicalAgressionTowardsSelfTechniques");
                                            handleValueChange(event,
                                                "physicalAgressionTowardsSelfTechniques",
                                                store.physicalAgressionTowardsSelfTechniques,
                                                savedState.physicalAgressionTowardsSelfTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* PHYSICAL AGRESSION TOWARDS OTHERS*/}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Physical aggression toward family/guardian, friends, or peers:"
                                toolTipText="For example: family/guardian, friends or peers, punching or hitting others, pinching, pushing, spitting, smearing blood."
                                radioGroupOptions={YesNoRadioOptions}
                                name="physicalAgressionTowardsOthers"
                                selectedValue={store.physicalAgressionTowardsOthers}
                                onChange={(event) => {
                                    store.setPhysicalAgressionTowardsOthers(Number(event.target.value))
                                    setFieldsTouched("physicalAgressionTowardsOthers")
                                    handleValueChange(event, 
                                        "physicalAgressionTowardsOthers", 
                                        store.physicalAgressionTowardsOthers, 
                                        savedState.physicalAgressionTowardsOthers
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.physicalAgressionTowardsOthers == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="physicalAgressionTowardsOthersFrequency" value={store.physicalAgressionTowardsOthersFrequency}
                                            onChange={(event) => {
                                                store.setPhysicalAgressionTowardsOthersFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("physicalAgressionTowardsOthersFrequency");
                                                handleValueChange(event,
                                                "physicalAgressionTowardsOthersFrequency",
                                                store.physicalAgressionTowardsOthersFrequency,
                                                savedState.physicalAgressionTowardsOthersFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="physical-aggression-towards-others-description" 
                                        name="physicalAgressionTowardsOthersDescription" 
                                        value={store.physicalAgressionTowardsOthersDescription}
                                        onChange={(event) => { 
                                            store.setPhysicalAgressionTowardsOthersDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("physicalAgressionTowardsOthersDescription");
                                            handleValueChange(event,
                                                "physicalAgressionTowardsOthersDescription",
                                                store.physicalAgressionTowardsOthersDescription,
                                                savedState.physicalAgressionTowardsOthersDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="physical-aggression-towards-others-techniques" 
                                        name="physicalAgressionTowardsOthersTechniques" 
                                        value={store.physicalAgressionTowardsOthersTechniques}
                                        onChange={(event) => { 
                                            store.setPhysicalAgressionTowardsOthersTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("physicalAgressionTowardsOthersTechniques");
                                            handleValueChange(event,
                                                "physicalAgressionTowardsOthersTechniques",
                                                store.physicalAgressionTowardsOthersTechniques,
                                                savedState.physicalAgressionTowardsOthersTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* ELOPEMENT FROM HOME */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there elopement from home?"
                                toolTipText="For example: running away or sneaking out of home."
                                radioGroupOptions={YesNoRadioOptions}
                                name="elopementFromHome"
                                selectedValue={store.elopementFromHome}
                                onChange={(event) => {
                                    store.setElopementFromHome(Number(event.target.value))
                                    setFieldsTouched("elopementFromHome")
                                    handleValueChange(event, 
                                        "elopementFromHome", 
                                        store.elopementFromHome, 
                                        savedState.elopementFromHome
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.elopementFromHome == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="elopementFromHomeFrequency" value={store.elopementFromHomeFrequency}
                                            onChange={(event) => {
                                                store.setElopementFromHomeFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("elopementFromHomeFrequency");
                                                handleValueChange(event,
                                                "elopementFromHomeFrequency",
                                                store.elopementFromHomeFrequency,
                                                savedState.elopementFromHomeFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="elopement-from-home-description" 
                                        name="elopementFromHomeDescription" 
                                        value={store.elopementFromHomeDescription}
                                        onChange={(event) => { 
                                            store.setElopementFromHomeDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("elopementFromHomeDescription");
                                            handleValueChange(event,
                                                "elopementFromHomeDescription",
                                                store.elopementFromHomeDescription,
                                                savedState.elopementFromHomeDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="elopement-from-home-techniques" 
                                        name="elopementFromHomeTechniques" 
                                        value={store.elopementFromHomeTechniques}
                                        onChange={(event) => { 
                                            store.setElopementFromHomeTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("elopementFromHomeTechniques");
                                            handleValueChange(event,
                                                "elopementFromHomeTechniques",
                                                store.elopementFromHomeTechniques,
                                                savedState.elopementFromHomeTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* ELOPEMENT FROM COMMUNITY */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there elopement in community setting?"
                                toolTipText="For example: wandering off in grocery store, running away in department store or park."
                                radioGroupOptions={YesNoRadioOptions}
                                name="elopementFromCommunity"
                                selectedValue={store.elopementFromCommunity}
                                onChange={(event) => {
                                    store.setElopementFromCommunity(Number(event.target.value))
                                    setFieldsTouched("elopementFromCommunity")
                                    handleValueChange(event, 
                                        "elopementFromCommunity", 
                                        store.elopementFromCommunity, 
                                        savedState.elopementFromCommunity
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.elopementFromCommunity == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="elopementFromCommunityFrequency" value={store.elopementFromCommunityFrequency}
                                            onChange={(event) => {
                                                store.setElopementFromCommunityFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("elopementFromCommunityFrequency");
                                                handleValueChange(event,
                                                "elopementFromCommunityFrequency",
                                                store.elopementFromCommunityFrequency,
                                                savedState.elopementFromCommunityFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="elopement-from-community-description" 
                                        name="elopementFromCommunityDescription" 
                                        value={store.elopementFromCommunityDescription}
                                        onChange={(event) => { 
                                            store.setElopementFromCommunityDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("elopementFromCommunityDescription");
                                            handleValueChange(event,
                                                "elopementFromCommunityDescription",
                                                store.elopementFromCommunityDescription,
                                                savedState.elopementFromCommunityDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="elopement-from-community-techniques" 
                                        name="elopementFromCommunityTechniques" 
                                        value={store.elopementFromCommunityTechniques}
                                        onChange={(event) => { 
                                            store.setElopementFromCommunityTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("elopementFromCommunityTechniques");
                                            handleValueChange(event,
                                                "elopementFromCommunityTechniques",
                                                store.elopementFromCommunityTechniques,
                                                savedState.elopementFromCommunityTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* RAGE AND OUTBURSTS */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Are there rage and outbursts?"
                                toolTipText="For example: verbal intimidation accompanied by violent personal or public property damage. Can include psychotic hallucinations."
                                radioGroupOptions={YesNoRadioOptions}
                                name="rageAndOutbursts"
                                selectedValue={store.rageAndOutbursts}
                                onChange={(event) => {
                                    store.setRageAndOutbursts(Number(event.target.value))
                                    setFieldsTouched("rageAndOutbursts")
                                    handleValueChange(event, 
                                        "rageAndOutbursts", 
                                        store.rageAndOutbursts, 
                                        savedState.rageAndOutbursts
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.rageAndOutbursts == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="rageAndOutburstsFrequency" value={store.rageAndOutburstsFrequency}
                                            onChange={(event) => {
                                                store.setRageAndOutburstsFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("rageAndOutburstsFrequency");
                                                handleValueChange(event,
                                                "rageAndOutburstsFrequency",
                                                store.rageAndOutburstsFrequency,
                                                savedState.rageAndOutburstsFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="rage-and-outbursts-description" 
                                        name="rageAndOutburstsDescription" 
                                        value={store.rageAndOutburstsDescription}
                                        onChange={(event) => { 
                                            store.setRageAndOutburstsDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("rageAndOutburstsDescription");
                                            handleValueChange(event,
                                                "rageAndOutburstsDescription",
                                                store.rageAndOutburstsDescription,
                                                savedState.rageAndOutburstsDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="rage-and-outbursts-techniques" 
                                        name="rageAndOutburstsTechniques" 
                                        value={store.rageAndOutburstsTechniques}
                                        onChange={(event) => { 
                                            store.setRageAndOutburstsTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("rageAndOutburstsTechniques");
                                            handleValueChange(event,
                                                "rageAndOutburstsTechniques",
                                                store.rageAndOutburstsTechniques,
                                                savedState.rageAndOutburstsTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/* INAPPROPRITATE SEXUAL BEHAVIOR */}
                <div className="row mt-4">
                    <Card>
                        <div className="row mt-3">
                            <RadioButtonGroup
                                labelText="Is there sexually inappropriate behavior?"
                                toolTipText="For example: making sexual remarks, masturbating in common areas of the home, propositioning strangers for sex, inappropriate touching of others or animals."
                                radioGroupOptions={YesNoRadioOptions}
                                name="inappropriateSexualBehavior"
                                selectedValue={store.inappropriateSexualBehavior}
                                onChange={(event) => {
                                    store.setInappropriateSexualBehavior(Number(event.target.value))
                                    setFieldsTouched("inappropriateSexualBehavior")
                                    handleValueChange(event, 
                                        "inappropriateSexualBehavior", 
                                        store.inappropriateSexualBehavior, 
                                        savedState.inappropriateSexualBehavior
                                    )
                                }}
                            ></RadioButtonGroup>

                            <ShowThen when={store.inappropriateSexualBehavior == enums.YesNoNARadioButtonGroupOptions.Yes}>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="bds-form-label form-label">Frequency:</label>
                                        <select name="inappropriateSexualBehaviorFrequency" value={store.inappropriateSexualBehaviorFrequency}
                                            onChange={(event) => {
                                                store.setInappropriateSexualBehaviorFrequency(Number(event.target.value));
                                            }}
                                            onBlur={(event) => {
                                                setFieldsTouched("inappropriateSexualBehaviorFrequency");
                                                handleValueChange(event,
                                                "inappropriateSexualBehaviorFrequency",
                                                store.inappropriateSexualBehaviorFrequency,
                                                savedState.inappropriateSexualBehaviorFrequency);
                                            }}
                                            className="bds-form-select bds-grid-form form-select">
                                            <option value="">Please Select ...</option>
                                            {
                                                store.availableFrequencyOptions.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="inappropriate-sexual-behavior-description" 
                                        name="inappropriateSexualBehaviorDescription" 
                                        value={store.inappropriateSexualBehaviorDescription}
                                        onChange={(event) => { 
                                            store.setInappropriateSexualBehaviorDescription(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("inappropriateSexualBehaviorDescription");
                                            handleValueChange(event,
                                                "inappropriateSexualBehaviorDescription",
                                                store.inappropriateSexualBehaviorDescription,
                                                savedState.inappropriateSexualBehaviorDescription
                                            )
                                        }}
                                        labelText="Description:"
                                    ></NbarTextArea>
                                </div>
                                <div className="row mt-3">
                                    <NbarTextArea
                                        id="inappropriate-sexual-behavior-techniques" 
                                        name="inappropriateSexualBehaviorTechniques" 
                                        value={store.inappropriateSexualBehaviorTechniques}
                                        onChange={(event) => { 
                                            store.setInappropriateSexualBehaviorTechniques(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("inappropriateSexualBehaviorTechniques");
                                            handleValueChange(event,
                                                "inappropriateSexualBehaviorTechniques",
                                                store.inappropriateSexualBehaviorTechniques,
                                                savedState.inappropriateSexualBehaviorTechniques
                                            )
                                        }}
                                        labelText="Techniques and Strategies:"
                                    ></NbarTextArea>
                                </div>
                            </ShowThen>
                        </div>
                    </Card>
                </div>

                {/*BUTTONS*/}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <button type="button" className="btn bds-btn-primary" disabled={isLoading}
                        onClick={() => NavigationHelper.gotoNbarApplicantPreferences(navigate, nbarApplicationRefId)}>Back</button>
                        <IsAuthenticated>
                            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep(event)}
                        className="btn bds-btn-primary">Next</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default observer(AdditionalInformation)