import { useEffect, useState } from "react"
import { Utility } from "shared-module/helpers/utility"

interface Props {
}



export const NbarDefinitions = () => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(Utility.getNbarDefinitions());
    }, []);

    return (
        <>
            <ul className="no-bullets no-indent mt-3">
                {options.map(option => {
                    return <li><strong>"{option.key}"</strong> means {option.value}</li>
                })}
            </ul>
        </>
    )
}