import AlertModalEventBus from "./alert-modal-event-bus";
import { AlertModalOptions } from "./alert-modal-container.component";

export enum AlertModalEvents {
  MODAL_SHOW = "ALERT.MODAL.SHOW",
  MODAL_HIDE = "ALERT.MODAL.HIDE"
}

class AlertModal {
  private static instance: AlertModal;

  private constructor() {
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AlertModal();
    }
    return this.instance;
  }

  public showModal(options: AlertModalOptions) {
    AlertModalEventBus.dispatch(AlertModalEvents.MODAL_SHOW, options);
  }

  public hideModal() {
    AlertModalEventBus.dispatch(AlertModalEvents.MODAL_HIDE, null);
  }
}

export default AlertModal.getInstance();
