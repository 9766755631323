import { UserProfileResource } from "shared-module/api-resources/user-profile.resource";
import { ApiConfig } from "./api-config";
import { find } from "lodash";
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { GuestUserResource } from "shared-module/api-resources/guest-user-resource";
import { ApplicationTypeNames } from "shared-module/models/common.models";

class SessionServiceImpl {
  private static instance: SessionServiceImpl;
  private userProfileService: UserProfileResource;
  private guestUserService: GuestUserResource;

  private constructor() {
    this.userProfileService = new UserProfileResource(ApiConfig);
    this.guestUserService = new GuestUserResource(ApiConfig);
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new SessionServiceImpl();
    }
    return this.instance;
  }

  public async isAuthenticated(): Promise<boolean> {
    const response = await this.userProfileService.isAuthenticated();
    return response.record;
  }

  public async getProfile(): Promise<GatewayOapApi.UserModel> {
    const response = await this.userProfileService.getProfile();
    return response.record;
  }

  public async getPermissions(): Promise<string[]> {
    const response = await this.userProfileService.getPermissions();
    return response.records;
  }

  public async hasPermission(permissions: string[]): Promise<boolean> {
    const response = await this.getPermissions();
    var userPermissions = response;
    console.log("user permissions:", userPermissions);
    return userPermissions && !!find(userPermissions, (item) => {
      return !!find(permissions, (subItem) => {
        console.log("permission matched:", item);
        return subItem == item;
      });
    });
  }

  public async expireGuestSession(guestSessionId : Number) : Promise<boolean> {
    const response = await this.guestUserService.expireGuestSession(guestSessionId, null);
    return response;
  }

  public async slideGuestSession(guestSessionId : Number) : Promise<boolean> {
    const response = await this.guestUserService.slideGuestSession(guestSessionId, null);
    return response;
  }

  public async createGuestSession(applicationRefId : string, applicationTypeName: ApplicationTypeNames) : Promise<GatewayOapApi.ApiIntegerResponse> {
    return this.guestUserService.createGuestSession(applicationRefId, applicationTypeName, null)
    .then((response) => {
      return response;
    });
  }

  public async getGuestSessionExpiration(guestSessionId : Number) : Promise<Date> {
    const response = await this.guestUserService.getGuestSessionExpiration(guestSessionId, null);
    return response;
  }

}


export const SessionService = SessionServiceImpl.getInstance();