// --GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

// --APP_MODULE
// -- --  ./local_component_imports
import { INbarPreferenceInformation, NbarPreferenceInformation } from "./applicant-preferences.store";
import { NbarPreferenceInformationValidationSchema } from "./applicant-preferences.validation";

// -- --  ../extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";

// --SHARED_MODULE
// -- --  ../resources
import { LookupResource } from "../../../../shared-module/api-resources/lookup.resource";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarPreferencesResource } from "../../../../shared-module/api-resources/Nbar/nbar-preference.resource";

// -- --  ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";

// -- --  ../helpers
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Utility } from "../../../../shared-module/helpers/utility";

// -- --  ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// -- --  ../models
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { ApplicationExpirationError, GatewayNetworkError } from "../../../../shared-module/models/common.models";

// -- --  ../services
import { ApiConfig } from "shared-module/services/api-config";
import { SessionService } from "../../../../shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";


function ApplicantPreferences() {
    // INSTANTIATIONS
    // ./view model
    const store = NbarPreferenceInformation;

    // ./hooks
    const { id: nbarApplicationRefId } = useParams();
    const navigate = useNavigate();
    const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } = 
        useFormValidation(NbarPreferenceInformationValidationSchema, store);

    // ./resources
    const preferenceService = new NbarPreferencesResource(ApiConfig);
    const applicationService = new NbarApplicationResource(ApiConfig);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarPreferenceInformation>({} as INbarPreferenceInformation);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // ../component state
    const [preferenceInformationId, setPreferenceInformationId] = useState<number>(null);
    

    // GLOBAL FORM PROCESSING AND NAVIGATION
    // ../"Public Methods"
    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) { return; }
        setIsSubmitting(true, eventSource);
        submit()
        .finally(() => {
            setIsSubmitting(false, eventSource);
        })
    }

    const handleGoToNextStep = (event) => {
        validate().then(() => {
            setIsSaving(true);
            submit().then(() => {
                NavigationHelper.gotoNbarBehavioralInformation(navigate, nbarApplicationRefId);
            })     
        })
        .catch((error) => {
            scrollTo(0, 0);
        })
        .finally(() => {
            setIsSaving(false);
        })  
    }

    // ../"Private Methods"
    function submit() : Promise<any> {
        // the call to applicationService.getApplication() is needed to ensure the application has not expired.
        // TODO: refactor this out for nBar. We do not want to pull the application data on each call.
        // --- will need an alternate way of determining current application status
        setIsSaving(true); 
        return applicationService.getApplication(nbarApplicationRefId)
        .then(() => {
            savePreferenceInformation()
            .then(() => {
                var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
                if(guestSessionId != "0") {
                    SessionService.slideGuestSession(Number(guestSessionId));
                    EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                        isAuthenticated: false,
                        remainingTimeInSeconds: 900,
                        hasActiveMaintenance: false
                    });
                }
            })      
        })     
        .catch((ex) => { 
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    function validate() : Promise<any> {
        touchedAll();
        return NbarPreferenceInformationValidationSchema.validate(store)
        .then((response) => {
            NbarPreferenceInformationValidationSchema.isValidSync(store);
        })
        .catch((error) => {
            throw error;
        });
    }

    function savePreferenceInformation(): Promise<any> {
        return preferenceService.saveNbarPreferences(
            preferenceInformationId,
            store.thingsLoveMost,
            store.thingsFearMost,
            store.thingsDislikeMost,
            store.thingsUpsetMost,
            null
        )
        .catch(ex => {
            throw ex;
        })
    }

    // PAGE LOAD
    useEffect(() => {      
        setIsLoading(true);
        Utility.showLoadingOverlay();
        Promise.all([
            applicationService.getApplication(nbarApplicationRefId)
        ])
        .then((response) => {
            setPreferenceInformationId(response[0].record?.preferenceInformationId)
            return preferenceService.getNbarPreferences(response[0].record?.preferenceInformationId)
        })
        .then((response) => {
            store.refresh(response.record);
            setSavedState(store.buildState(response.record));
        })
        .catch((ex) => {
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate); 
        })
        .finally(() => {
            setIsLoading(false);
            Utility.hideLoadingOverlay();
        });
    }, [])

    // COMPONENT MARKUP
    return (
        <>
            <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
            <PageTitleSetter step={4} totalSteps={6}></PageTitleSetter>
            <div className="mt-4">
                <ApplicationProgressBar step={4} totalSteps={6}></ApplicationProgressBar>
            </div>
            <ShowThen when={!isLoading}>
                <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
            </ShowThen>

            <form>
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">Individual's Likes &amp; Dislikes</div>
                        
                        <div className="row mt-3">
                            <NbarTextArea
                                id="things-loved-most" 
                                name="thingsLoveMost" 
                                value={store.thingsLoveMost}
                                onChange={(event) => { 
                                    store.setThingsLoveMost(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("thingsLoveMost");
                                    handleValueChange(event,
                                        "thingsLoveMost",
                                        store.thingsLoveMost,
                                        savedState.thingsLoveMost
                                    )
                                }}
                                labelText="What things does the individual love most?"
                            ></NbarTextArea>
                        </div>

                        <div className="row mt-3">
                            <NbarTextArea
                                id="things-feared-most" 
                                name="thingsFearMost" 
                                value={store.thingsFearMost}
                                onChange={(event) => { 
                                    store.setThingsFearMost(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("thingsFearMost");
                                    handleValueChange(event,
                                        "thingsFearMost",
                                        store.thingsFearMost,
                                        savedState.thingsFearMost
                                    )
                                }}
                                labelText="What things does the individual fear most?"
                            ></NbarTextArea>
                        </div>

                        <div className="row mt-3">
                            <NbarTextArea
                                id="things-disliked-most" 
                                name="thingsDislikeMost" 
                                value={store.thingsDislikeMost}
                                onChange={(event) => { 
                                    store.setThingsDislikeMost(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("thingsDislikeMost");
                                    handleValueChange(event,
                                        "thingsDislikeMost",
                                        store.thingsDislikeMost,
                                        savedState.thingsDislikeMost
                                    )
                                }}
                                labelText="What things does the individual dislike most?"
                            ></NbarTextArea>

                        </div>

                        <div className="row mt-3">
                            <NbarTextArea
                                id="things-upset-most" 
                                name="thingsUpsetMost" 
                                value={store.thingsUpsetMost}
                                onChange={(event) => { 
                                    store.setThingsUpsetMost(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("thingsUpsetMost");
                                    handleValueChange(event,
                                        "thingsUpsetMost",
                                        store.thingsUpsetMost,
                                        savedState.thingsUpsetMost
                                    )
                                }}
                                labelText="What things upset or anger the individual the most?"
                            ></NbarTextArea>
                        </div>
                    </Card>
                </div>

                {/* BUTTONS */}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <button type="button" className="btn bds-btn-primary" disabled={isLoading}
                        onClick={() => NavigationHelper.gotoNbarTreatmentInformation(navigate, nbarApplicationRefId)}>Back</button>
                        <IsAuthenticated>
                        <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                            onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep(event)}
                        className="btn bds-btn-primary">Next</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default observer(ApplicantPreferences)