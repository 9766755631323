import { GatewayOapApi } from "../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class ApplicationResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getApplication(applicationRefId: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfApplicationModel> {
    return super.getRequest(`api/v1/applications/${applicationRefId}`, null, abortController) as
      Promise<GatewayOapApi.ApiSingleResponseOfApplicationModel>;
  }

  public getLegalRepresentativeTypes(applicationRefId: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiMultipleResponseOfLegalRepresentativeTypeModel> {
    return super.getRequest(`api/v1/applications/${applicationRefId}/legal-representative-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfLegalRepresentativeTypeModel>;
  }

  public getApplicationDocuments(applicationRefId: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel> {
    return super.getRequest(`api/v1/applications/${applicationRefId}/documents`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel>;
  }

  public getDuplicateApplicationInfo(isItin: boolean, ssnOrItin: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfDuplicateApplicationModel> {
    return super.getRequest(`api/v1/applications/get-duplicate-application-info`, {
      isItin: isItin,
      ssnOrItin: ssnOrItin
    }, abortController) as
      Promise<GatewayOapApi.ApiSingleResponseOfDuplicateApplicationModel>;
  }

  public getApplicationLegalRepresentatives(
    applicationRefId: string,
    abortController?: AbortController
  ): Promise<GatewayOapApi.ApiMultipleResponseOfLegalRepresentativeModel> {
    return super.getRequest(
      `api/v1/applications/${applicationRefId}/legal-representatives`,
      null,
      abortController
    ) as Promise<GatewayOapApi.ApiMultipleResponseOfLegalRepresentativeModel>;
  }

  public createApplication(isItin: boolean, ssnOrItin: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiGuidResponse> {
    return super.postRequest(`api/v1/applications/create`, null, {
      isItin: isItin,
      ssnOrItin: ssnOrItin
    } as GatewayOapApi.CreateApplicationRequest, abortController) as
      Promise<GatewayOapApi.ApiGuidResponse>;
  }

  public UpdateApplicantSsn(applicationRefId: string, isItin: boolean, ssnOrItin: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/update-applicant-ssn`, null, {
      isItin: isItin,
      ssnOrItin: ssnOrItin
    } as GatewayOapApi.UpdateApplicantSsnRequest, abortController) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public saveApplicationInformation(applicationRefId: string, applyingSelf: boolean,
    applicantRelationshipTypeId: number, entryRelationIsBdds: boolean,
    noFirstName: boolean, firstName: string, lastName: string, middleName: string,
    suffixTypeId: number, dateOfBirth: string, hasMedicaid: boolean,
    medicaid: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/application-information`, null, {
      applyingSelf: applyingSelf,
      applicantRelationshipTypeId: applicantRelationshipTypeId,
      entryRelationIsBdds: entryRelationIsBdds,
      noFirstName: noFirstName,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      suffixTypeId: suffixTypeId,
      dateOfBirth: dateOfBirth,
      hasMedicaid: hasMedicaid,
      medicaid: medicaid
    } as GatewayOapApi.SaveApplicationInformationRequest, abortController) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public saveApplicantInformation(applicationRefId: string, livingArrangementTypeId: number,
    mailingAddressLine: string, mailingCity: string, mailingStateId: number, mailingZipCode: string,
    mailingCountyId: number, hasDifferentCurrentAddress: boolean, currentAddressLine: string,
    currentCity: string, currentStateId: number, currentZipCode: string, currentCountyId: number,
    homePhoneIsPrimary: boolean, homePhone: string, mobilePhoneIsPrimary: boolean, mobilePhone: string,
    workPhoneIsPrimary: boolean, workPhone: string, emailAddress: string, diagnosisAge: number,
    disabilityDescription: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/applicant-information`, null, {
      livingArrangementTypeId: livingArrangementTypeId,
      mailingAddressLine: mailingAddressLine,
      mailingCity: mailingCity,
      mailingStateId: mailingStateId,
      mailingZipCode: mailingZipCode,
      mailingCountyId: mailingCountyId,
      hasDifferentCurrentAddress: hasDifferentCurrentAddress,
      currentAddressLine: currentAddressLine,
      currentCity: currentCity,
      currentStateId: currentStateId,
      currentZipCode: currentZipCode,
      currentCountyId: currentCountyId,
      homePhoneIsPrimary: homePhoneIsPrimary,
      homePhone: homePhone,
      mobilePhoneIsPrimary: mobilePhoneIsPrimary,
      mobilePhone: mobilePhone,
      workPhoneIsPrimary: workPhoneIsPrimary,
      workPhone: workPhone,
      emailAddress: emailAddress,
      diagnosisAge: diagnosisAge,
      disabilityDescription: disabilityDescription,
    } as GatewayOapApi.SaveApplicantInformationRequest, abortController) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public saveApplicantDemographics(applicationRefId: string, applicantGenderId: number,
    applicantMaritalStatusId: number, isCurrentlyStudent: boolean,
    applicantEducationTypeId: number, applicantIsHispanicOrLatino: boolean,
    isMultiRacial: boolean, isAmericanIndianAlaskanNative: boolean, isBlackAfricanAmerican: boolean,
    isNativeHawaiianPacificIslander: boolean, isAsian: boolean, isWhite: boolean,
    applicantPrefferedLanguageId: number, applicantOtherLanguage: string,
    isApplicantVrsAssessed: boolean, hasLegalRepresentative: boolean,
    abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/applicant-demographics`, null, {
      applicantGenderId: applicantGenderId,
      applicantMaritalStatusId: applicantMaritalStatusId,
      isCurrentlyStudent: isCurrentlyStudent,
      applicantEducationTypeId: applicantEducationTypeId,
      applicantIsHispanicOrLatino: applicantIsHispanicOrLatino,
      isMultiRacial: isMultiRacial,
      isAmericanIndianAlaskanNative: isAmericanIndianAlaskanNative,
      isBlackAfricanAmerican: isBlackAfricanAmerican,
      isNativeHawaiianPacificIslander: isNativeHawaiianPacificIslander,
      isAsian: isAsian,
      isWhite: isWhite,
      applicantPrefferedLanguageId: applicantPrefferedLanguageId,
      applicantOtherLanguage: applicantOtherLanguage,
      isApplicantVrsAssessed: isApplicantVrsAssessed,
      hasLegalRepresentative: hasLegalRepresentative,
    } as GatewayOapApi.SaveApplicantDemographicsRequest, abortController) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public saveApplicationLegalRepresentatives(applicationRefId, primary_RelationshipTypeId:
    number, primary_NoFirstName: boolean, primary_FirstName: string, primary_LastName: string,
    primary_MiddleName: string, primary_SuffixTypeId: number, primary_HasMailingInternationalAddress: boolean,
    primary_MailingStreetAddress: string, primary_MailingCity: string, primary_MailingStateId: number,
    primary_MailingZipCode: string, primary_MailingCountyId: number, primary_MailingInternationalAddress: string,
    primary_HasDifferentCurrentAddress: boolean, primary_HasCurrentInternationalAddress: boolean,
    primary_CurrentStreetAddress: string, primary_CurrentCity: string, primary_CurrentStateId: number,
    primary_CurrentZipCode: string, primary_CurrentCountyId: number, primary_CurrentInternationalAddress: string,
    primary_HomePhoneIsPrimary: boolean, primary_HomePhoneNumber: string, primary_MobilePhoneIsPrimary: boolean,
    primary_MobilePhoneNumber: string, primary_WorkPhoneIsPrimary: boolean, primary_WorkPhoneNumber: string,
    primary_EmailAddress: string, primary_PreferredLanguageId: number, primary_PreferredLanguageOther: string,
    hasSecondaryLegalRepresentative: boolean,
    secondary_RelationshipTypeId: number, secondary_NoFirstName: boolean, secondary_FirstName: string,
    secondary_LastName: string, secondary_MiddleName: string, secondary_SuffixTypeId: number,
    secondary_HasMailingInternationalAddress: boolean, secondary_MailingStreetAddress: string,
    secondary_MailingCity: string, secondary_MailingStateId: number, secondary_MailingZipCode: string,
    secondary_MailingCountyId: number, secondary_MailingInternationalAddress: string,
    secondary_HasDifferentCurrentAddress: boolean, secondary_HasCurrentInternationalAddress: boolean,
    secondary_CurrentStreetAddress: string, secondary_CurrentCity: string, secondary_CurrentStateId: number,
    secondary_CurrentZipCode: string, secondary_CurrentCountyId: number, secondary_CurrentInternationalAddress: string,
    secondary_HomePhoneIsPrimary: boolean, secondary_HomePhoneNumber: string, secondary_MobilePhoneIsPrimary: boolean,
    secondary_MobilePhoneNumber: string, secondary_WorkPhoneIsPrimary: boolean, secondary_WorkPhoneNumber: string,
    secondary_EmailAddress: string, secondary_PreferredLanguageId: number, secondary_PreferredLanguageOther,
    abortController?: AbortController,
  ): Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/legal-representatives`, null,
      {
        applicationRefId: applicationRefId,
        primaryLegalRepresentativeTypeId: primary_RelationshipTypeId,
        primaryNoFirstName: primary_NoFirstName,
        primaryFirstName: primary_FirstName,
        PrimaryLastName: primary_LastName,
        primaryMiddleName: primary_MiddleName,
        primarySuffixTypeId: primary_SuffixTypeId,
        primaryMailingIsInternational: primary_HasMailingInternationalAddress,
        primaryMailingStreetAddress: primary_MailingStreetAddress,
        primaryMailingCity: primary_MailingCity,
        primaryMailingStateId: primary_MailingStateId,
        primaryMailingZipCode: primary_MailingZipCode,
        primaryMailingCountyId: primary_MailingCountyId,
        primaryMailingInternationalAddress: primary_MailingInternationalAddress,
        primaryHasDifferentCurrentAddress: primary_HasDifferentCurrentAddress,
        primaryCurrentIsInternational: primary_HasCurrentInternationalAddress,
        primaryCurrentStreetAddress: primary_CurrentStreetAddress,
        primaryCurrentCity: primary_CurrentCity,
        primaryCurrentStateId: primary_CurrentStateId,
        primaryCurrentZipCode: primary_CurrentZipCode,
        primaryCurrentCountyId: primary_CurrentCountyId,
        primaryCurrentInternationalAddress: primary_CurrentInternationalAddress,
        primaryHomePhoneIsprimary: primary_HomePhoneIsPrimary,
        primaryHomePhoneNumber: primary_HomePhoneNumber,
        primaryMobilePhoneIsPrimary: primary_MobilePhoneIsPrimary,
        primaryMobilePhoneNumber: primary_MobilePhoneNumber,
        primaryWorkPhoneIsPrimary: primary_WorkPhoneIsPrimary,
        primaryWorkPhoneNumber: primary_WorkPhoneNumber,
        primaryEmailAddress: primary_EmailAddress,
        primaryPreferredLanguageId: primary_PreferredLanguageId,
        primaryPreferredLanguageOther: primary_PreferredLanguageOther,
        hasSecondaryLegalRepresentative: hasSecondaryLegalRepresentative,
        secondaryLegalRepresentativeTypeId: secondary_RelationshipTypeId,
        secondaryNoFirstName: secondary_NoFirstName,
        secondaryFirstName: secondary_FirstName,
        secondaryLastName: secondary_LastName,
        secondaryMiddleName: secondary_MiddleName,
        secondarySuffixTypeId: secondary_SuffixTypeId,
        secondaryMailingIsInternational: secondary_HasMailingInternationalAddress,
        secondaryMailingStreetAddress: secondary_MailingStreetAddress,
        secondaryMailingCity: secondary_MailingCity,
        secondaryMailingCountyId: secondary_MailingCountyId,
        secondaryMailingStateId: secondary_MailingStateId,
        secondaryMailingZipCode: secondary_MailingZipCode,
        secondaryMailingInternationalAddress: secondary_MailingInternationalAddress,
        secondaryHasDifferentCurrentAddress: secondary_HasDifferentCurrentAddress,
        secondaryCurrentIsInternational: secondary_HasCurrentInternationalAddress,
        secondaryCurrentStreetAddress: secondary_CurrentStreetAddress,
        secondaryCurrentCity: secondary_CurrentCity,
        secondaryCurrentStateId: secondary_CurrentStateId,
        secondaryCurrentZipCode: secondary_CurrentZipCode,
        secondaryCurrentCountyId: secondary_CurrentCountyId,
        secondaryCurrentInternationalAddress: secondary_CurrentInternationalAddress,
        secondaryHomePhoneIsPrimary: secondary_HomePhoneIsPrimary,
        secondaryHomePhoneNumber: secondary_HomePhoneNumber,
        secondaryMobilePhoneIsPrimary: secondary_MobilePhoneIsPrimary,
        secondaryMobilePhoneNumber: secondary_MobilePhoneNumber,
        secondaryWorkPhoneIsPrimary: secondary_WorkPhoneIsPrimary,
        secondaryWorkPhoneNumber: secondary_WorkPhoneNumber,
        secondaryEmailAddress: secondary_EmailAddress,
        secondaryPreferredLanguageId: secondary_PreferredLanguageId,
        secondaryPreferredLanguageOther: secondary_PreferredLanguageOther,
      } as GatewayOapApi.SaveApplicationLegalRepresentativesRequest,
      abortController
    ) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public submitApplication(applicationRefId: string, applicantSignatureCheck: boolean,
    legalRepresentativeSignatureCheck: boolean, applicantSignatureCapture: string,
    legalRepresentativeSignatureCapture: string, applicantSignatureCapturedOn: string,
    legalRepresentativeSignatureCapturedOn: string, printDocument: boolean,
    abortController?: AbortController)
    : Promise<GatewayOapApi.ApiBooleanResponse> {
    return super.postRequest(`api/v1/applications/${applicationRefId}/submit`, null, {
      applicantSignatureCheck: applicantSignatureCheck,
      legalRepresentativeSignatureCheck: legalRepresentativeSignatureCheck,
      applicantSignatureCapture: applicantSignatureCapture,
      legalRepresentativeSignatureCapture: legalRepresentativeSignatureCapture,
      applicantSignatureCapturedOn: applicantSignatureCapturedOn,
      legalRepresentativeSignatureCapturedOn: legalRepresentativeSignatureCapturedOn,
      printDocument: printDocument
    } as GatewayOapApi.SubmitApplicationRequest,
      abortController) as
      Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public removeApplication(applicationRefId: string, abortController?: AbortController) {
    return super.deleteRequest(`api/v1/applications/${applicationRefId}`,
      null,
      abortController) as
      Promise<string>;
  }

  public printSubmittedApplication(applicationRefId: string, abortController?: AbortController): Promise<string> {
    return super.documentRequest(`api/v1/applications/${applicationRefId}/print-application`,
      null,
      null,
      abortController) as
      Promise<string>;
  }

  public getApplicationDraftUrl(applicationRefId: string): string {
    return super.getApiUrl(`api/v1/applications/${applicationRefId}/print-draft`);
  }

  public getApplicationCodDocumentUrl(applicationRefId: string): string {
    return super.getApiUrl(`api/v1/applications/${applicationRefId}/documents/confirmation-of-diagnosis`);
  }

  public getApplicationAfdDocumentUrl(applicationRefId: string): string {
    return super.getApiUrl(`api/v1/applications/${applicationRefId}/documents/authorization-for-disclosure`);
  }

  public uploadConfirmationofDiagnosisDocument(applicationRefId: string, file: File,
    abortController?: AbortController): Promise<any> {
    const formData = new FormData();
    formData.append('confirmationOfDiagnosisFile', file);
    return super.postFileRequest(`api/v1/applications/${applicationRefId}/documents/confirmation-of-diagnosis`,
      null, formData, abortController);
  }

  public uploadAuthorizationForDisclosureDocument(applicationRefId: string, file: File,
    abortController?: AbortController): Promise<boolean> {
    const formData = new FormData();
    formData.append('authorizationFoDisclosureFile', file);
    return super.postFileRequest(`api/v1/applications/${applicationRefId}/documents/authorization-for-disclosure`,
      null, formData, abortController);
  }

  public saveStagedApplicationDocuments(applicationRefId: string, file: File, documentType: number, abortController?: AbortController): Promise<boolean> {
    const formData = new FormData();
    formData.append('Document', file);
    formData.append('DocumentType', documentType.toString());
    return super.postFileRequest(`api/v1/applications/${applicationRefId}/stage-application-document`, null, formData, abortController) as Promise<boolean>;
  }

  public getStagedApplicationDocuments(applicationRefId: string, abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfStateModel> {
    console.log('getStagedApplicationDocuments api call');
    return super.getRequest(`api/v1/applications/${applicationRefId}/get-staged-application-documents`, null, abortController) as Promise<GatewayOapApi.ApiMultipleResponseOfStateModel>;
  }

  public removeStagedApplicationDocuments(applicationRefId: string, documentType: number, abortController?: AbortController): Promise<boolean> {
    console.log('removeStagedApplicationDocuments: ', documentType);
    return super.deleteRequest(`api/v1/applications/${applicationRefId}/unstage-application-document/${documentType}`, null, null);
  }

}
