import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";
import { ApplicationTypeNames } from "shared-module/models/common.models";

export class GuestUserResource extends ApiBaseResource {
    constructor(apiConfig: IApiConfig) {
        super(apiConfig);
    }

    public expireGuestSession(guestSessionId: Number, abortController?: AbortController)
        : Promise<boolean> {
            //console.log(`GuestUserResource.expireGuestSession ${applicationId} _ ${applicationTypeName}`);
            super.postRequest(`api/v1/system/expire-guest-session`, null, 
                {
                    guestSessionId: guestSessionId, 
                },
            abortController) as Promise<boolean>;
        return;
    }

    public slideGuestSession(guestSessionId: Number, abortController?: AbortController)
    : Promise<boolean> {
        //console.log(`GuestUserResource.slideGuestSession ${guestSessionId}`);
        super.postRequest(`api/v1/system/slide-guest-session`, null, 
            {
                GuestSessionId: guestSessionId, 
            },
        abortController) as Promise<boolean>;
        return;
    }

    public createGuestSession(applicationRefId: string, applicationTypeName: ApplicationTypeNames, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiIntegerResponse> {
        return super.postRequest(
            `api/v1/system/${applicationRefId}/create-guest-session`, 
            null, 
            { 
                applicationTypeName: applicationTypeName
            },
        abortController) as Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public getGuestSessionExpiration(guestSessionId : Number, abortController?: AbortController)
    : Promise<Date> {
        //console.log(`GuestUserResource.expireGuestSession ${applicationId} _ ${applicationTypeName}`);
        super.postRequest(`api/v1/system/get-guest-session-expiration`, null, 
            {
                GuestSessionId: guestSessionId, 
            },
        abortController) as Promise<Date>;
        return;
    }

}