import * as Yup from "yup";
import { IApplicationInformation } from "./application-information.store";
import { Utility } from "../../../shared-module/helpers/utility";

// validation schema
export const ApplicationInformationValidationSchema: Yup.ObjectSchema<IApplicationInformation> = Yup.object({
  selfApplying: Yup.string(),
  entryRelationTypeId: Yup.string()
    .test("validate-selfApplying", "-",
      function (item) {
        if (this.parent.selfApplying == "apply-for-others" && !item) {
          return this.createError(
            Utility.buildYupErrorMessage("entryRelationTypeId",
              "Please specify your relationship with the applicant."));
        }
        return true;
      }),
  entryRelationIsBdds: Yup.boolean(),
  noFirstName: Yup.boolean(),
  firstName: Yup.string()
    .test("validate-firstName", "-",
      function (item) {
        const message = "The First Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
        if (!this.parent.noFirstName && !item) {
          return this.createError(
            Utility.buildYupErrorMessage("firstName", "First name is required."));
        } else if (item) {
            if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
            return this.createError(
              Utility.buildYupErrorMessage("firstName",
                "At least 2 letters are necessary for First Name."));
            } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("firstName", message));
          }
        }
        return true;
      }),
  lastName: Yup.string()
    .test("validate-lastName", "-",
      function (item) {
        const message = "The Last Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName", "Last name is required."));
        } else if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName",
              "At least 2 letters are necessary for Last Name."));
        } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName", message));
        }
        return true;
      }),
  middleName: Yup.string()
    .test("validate-middleName", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z]{0,1}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("middleName", "The Middle Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),
  suffixTypeId: Yup.string(),
  dateOfBirth: Yup.string()
    .test("validate-dateOfBirth", "-",
      function (item) {
        var date = Utility.fromHtmlDateString(item, null);
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is required."));
        } else if (date == null) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is invalid."));
        } else if (date > Utility.getToday()) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "A date of birth cannot be in the future."));
        } else if (date <= Utility.getMinimumAge()) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Age cannot exceed 130 years. Please modify date of birth."));
        }
        return true;
      }),
  hasMedicaid: Yup.string(),
  medicaid: Yup.string()
    .test("validate-medicaid", "-",
      function (item) {
        if (item) {
          if (item.length < 12) {
            return this.createError(
              Utility.buildYupErrorMessage("medicaid", "A Medicaid | RID number must 12-digits long."));
          } else if (!new RegExp(/^[1][0-9]{9}[9][9]$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("medicaid",
                "A Medicaid | RID number must be all numerical that start with a 1 and end with 99."));
          }
        }
        return true;
      }),
});