// GLOBAL_LIBRARY
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

// APP_MODULE
// local component imports
import { NbarStartApplicationValidationSchema } from "./nbar-start-application.validation";
import { NbarStartApplicationStore, INbarStartApplication } from "./nbar-start-application.store";

// extensions
import { PageTitleSetter } from "../../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../../extensions/application-progress-bar/application-progress-bar.component";
import { AddressCorrectionModal, AddressCorrectionModalOptions } from "../../../extensions/address-correction-modal/address-correction-modal.component";


// SHARED_MODULE
// ../resources
import { LookupResource } from "../../../../shared-module/api-resources/lookup.resource";
import { AddressResource } from "../../../../shared-module/api-resources/address.resource";
import { NbarApplicationResource } from "../../../../shared-module/api-resources/Nbar/nbar-application.resource";
import { NbarOtherResource } from "../../../../shared-module/api-resources/Nbar/nbar-other.resource";

// ../components
import { Card } from "../../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import { ErrorContainer } from "../../../../shared-module/components/error-container/error-container.component";
import { DebugContainer } from "../../../../shared-module/components/debug-container/debug-container.component";
import { IsAuthenticated } from "../../../../shared-module/components/is-authenticated/is-authenticated";
import { NbarTextArea } from "../../../../shared-module/components/nbar-text-area/nbar-text-area.component";
import { IsNotAuthenticated } from "../../../../shared-module/components/is-not-authenticated/is-not-authenticated";
 //TODO: would like to get back to this component with logic applied in latest update
import { MaskedPhoneNumber } from "../../../../shared-module/components/masked-phone-number/masked-phone-number.component";

// ../helpers
import { Utility } from "../../../../shared-module/helpers/utility";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";

// ../hooks
import { useFormValidation } from "../../../../shared-module/hooks/use-form-validation/use-form-validation.hook";

// ../models
import { ApplicationExpirationError, GatewayNetworkError } from "../../../../shared-module/models/common.models";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";

// ../services
import { AddressValidationService } from "../../../../shared-module/services/address-validation.service";
import { ApiConfig } from "../../../../shared-module/services/api-config";
import { SessionService } from "../../../../shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";
import { SystemResource } from "shared-module/api-resources/system.resource";
import BdsDatePicker from "shared-module/components/date-picker/bds-date-picker.component";




function StartNbarApplication() {
    // INSTANTIATIONS
    // ./view model
    const store = NbarStartApplicationStore;

    // ./hooks
    const { id: nbarApplicationRefId } = useParams();  
    const navigate = useNavigate();
    const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } = 
        useFormValidation(NbarStartApplicationValidationSchema, store);

    // ./resources
    const applicationService = new NbarApplicationResource(ApiConfig);
    const otherService = new NbarOtherResource(ApiConfig);
    const lookupService = new LookupResource(ApiConfig);
    const systemService = new SystemResource(ApiConfig);

    const addressService = new AddressResource(ApiConfig);
    const addressCorrectionModalId = "addressCorrectionModal";
    const [addressCorrenctionModalOptions, setAddressCorrenctionModalOptions] = useState<AddressCorrectionModalOptions>();
    const addressValidationService = new AddressValidationService(addressService, addressCorrenctionModalOptions);

    // ./form processing
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [savedState, setSavedState] = useState<INbarStartApplication>({} as INbarStartApplication);

    // ../component state
    const [suffixTypes, setSuffixTypes] = useState<GatewayOapApi.SuffixTypeModel[]>([]);
    const [states, setStates] = useState<GatewayOapApi.StateModel[]>([]);
    const [relationshipTypes, setRelationshipTypes] = useState<GatewayOapApi.RelationshipTypesModel[]>([]);
    const [residentErrors, setResidentErrors] = useState<string>('');
    const [residentList, setResidentList] = useState<GatewayOapApi.ResidentInformationModel[]>([]);

    
    // COMPONENT PROCESSING
    const handleAddResident = (event) => {
        event.preventDefault();
        store.setTriggerResidentValidation(true);
        NbarStartApplicationValidationSchema.validateAt('residentValidationMessages', store)
            .then(() => {
                errors['residentValidationMessages'] = '';
                otherService.addOrUpdateResidentInformation(null, nbarApplicationRefId, store.addResidentName, store.addResidentAge, Number(store.addResidentRelationship))
                .then((response) => {
                    return applicationService.getApplication(nbarApplicationRefId);
                })
                .then((response) => {
                    store.refresh(response.record);
                    setResidentList(response.record.residentInformation);
                })          
                setResidentErrors('');
            })
            .catch(err => {
                errors['residentValidationMessages'] = err.message;
                setResidentErrors(errors["residentValidationMessages"].message);
            })
            .finally(() => {
                store.setTriggerResidentValidation(false);
            }
        )
    }

    const handleRemoveResident = (event) => {
        event.preventDefault();
        otherService.deleteResidentInformation(Number(event.target.name))
        .then(() => {
            return applicationService.getApplication(nbarApplicationRefId)
            .then((response) => {
                setResidentList(response.record.residentInformation)
            })     
        })
    }

    // GLOBAL FORM PROCESSING AND NAVIGATION
    // ../"Public Methods"
    function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
        if (currentValue === savedValue) { return; }
        console.log('handleValueChange');
        setIsSubmitting(true, eventSource);
        submit()
        setIsSubmitting(false, eventSource);
    }

    const handleGoToNextStep = () => {
        validate().then(() => {
          setIsSaving(true);
          addressValidationService.verifyAddresses([
            {
              streetAddress: store.streetAddress1,
              city: store.city,
              stateId: Utility.stringToNumber(store.state, null),
              zipCode: store.zipCode,
              addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
                store.setStreetAddress1(streetAddress);
                store.setCity(city);
                store.setState(stateId);
                store.setZipCode(zipCode);
                return submit();
              }
            }],
            () => {
                NavigationHelper.gotoNbarDemographics(navigate, nbarApplicationRefId);          
            })
            .finally(() => {
              setIsSaving(false);
            });
            return;
        })
        .catch((error) => {
            // console.log('error::', error);
            scrollTo(0, 0);
        })
    }

    // ../"Private Methods"
    function submit() : Promise<any> {
        // the call to applicationService.getApplication() is needed to ensure the application has not expired.
        // TODO: refactor this out for nBar. We do not want to pull the application data on each call.
        // --- will need an alternate way of determining current application status
        setIsSaving(true); 
        return applicationService.getApplication(nbarApplicationRefId)
        .then(() => {
            saveApplicationInformation()
            .then(() => {
                var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
                if(guestSessionId != "0") {
                    SessionService.slideGuestSession(Number(guestSessionId));
                    EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                        isAuthenticated: false,
                        remainingTimeInSeconds: 900,
                        hasActiveMaintenance: false
                    });
                }
            })
        })     
        .catch((ex) => {
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    function validate() : Promise<any> {
        touchedAll();
    //    console.log('entered: validate');

        return NbarStartApplicationValidationSchema.validate(store)
        .then((response) => {
            // console.log('validation response: ', response);
            NbarStartApplicationValidationSchema.isValidSync(store);
        })
        .catch((error) => {
            throw error;
        });
    }

    function saveApplicationInformation(): Promise<any> {
        console.log('save application information');
        //TODO: map store model to api model and pass to service    
        return applicationService.saveNbarApplicationInformation(
            nbarApplicationRefId, 
            store.firstName,
            store.middleName,
            store.lastName,
            Utility.stringToNumber(store.suffixTypeId, 0),
            store.dateOfBirth,
            store.streetAddress1,
            store.city,
            Utility.stringToNumber(store.state, 0),
            store.zipCode,
            store.unformat(store.homePhoneNumber),
            store.unformat(store.alternatePhoneNumber),
            store.reasonDescription,
            store.additionalInformation,
            null
        )
        .catch(ex => {
            throw ex;
        });
    }

    // PAGE LOAD
    useEffect(() => {      
        setIsLoading(true);
        Utility.showLoadingOverlay();
        Promise.all([
            applicationService.getApplication(nbarApplicationRefId),
            lookupService.getSuffixTypes(),
            lookupService.getStates(),
            lookupService.getNbarRelationshipTypes(),
          ])
        .then((response) => {
            setResidentList(response[0].record.residentInformation);
            store.refresh(response[0].record);
            setSavedState(store.buildState(response[0].record));
            setSuffixTypes(response[1].records);
            setStates(response[2].records);
            setRelationshipTypes(response[3].records);
            return true;
        })
        .catch((ex) => { 
            if(ex instanceof ApplicationExpirationError) {
                Utility.alert("Application Timeout", ex.errorMessage);
            }
            else if (!(ex instanceof GatewayNetworkError)) {
                Utility.alert("Error Saving", ex);
            }
            NavigationHelper.gotoNbarHome(navigate);
        })
        .finally(() => {
            setIsLoading(false);
            Utility.hideLoadingOverlay();
        });
    }, [])


    // COMPONENT MARKUP
    return (
        <>
            <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
            <AddressCorrectionModal id={addressCorrectionModalId} accessor={
                (options: AddressCorrectionModalOptions) => {
                setAddressCorrenctionModalOptions(options);
            }} />
          
            <PageTitleSetter step={1} totalSteps={6}></PageTitleSetter>
            <div className="mt-4">
                <ApplicationProgressBar step={1} totalSteps={6}></ApplicationProgressBar>
            </div>

            <ShowThen when={!isLoading}>
                <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
            </ShowThen>

            <IsAuthenticated>
                <div className="mt-4">
                    <div className="callout">
                    <div>
                        A new application draft has been created in your dashboard. Your application progress will be saved and can be returned to at a later time.
                    </div>
                    </div>
                </div>
            </IsAuthenticated>

            <form>
                {/* NAME INFORMATION */}
                <div className="mt-4">
                    <Card>
                    <div className="bds-section-heading mandatory">
                        What is the name of the individual applying for BDS Services?
                    </div>
                    <div className="bds-section-details mt-2">
                        Please enter a first and last name for the applicant. Middle name and suffix are optional.
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-6 mt-3">
                            <label className="bds-form-label form-label mandatory">First Name</label>
                            <input type="text" name="firstName" value={store.firstName}
                                onChange={(event) => { store.setFirstName(event.target.value); }}
                                onBlur={(event) => {
                                setFieldsTouched("firstName");
                                handleValueChange(event,
                                    "firstName",
                                    store.firstName,
                                    savedState.firstName)
                                }}
                                className="bds-form-text bds-grid-form form-control" maxLength={35} />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <label className="bds-form-label form-label mandatory">Last Name</label>
                            <input type="text" name="lastName" value={store.lastName}
                                onChange={(event) => { store.setLastName(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("lastName");
                                    handleValueChange(event,
                                    "lastName",
                                    store.lastName,
                                    savedState.lastName);
                                }}
                                className="bds-form-text bds-grid-form form-control" maxLength={35} />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <label className="bds-form-label form-label">Middle Name</label>
                                <input type="text" name="middleName" value={store.middleName}
                                onChange={(event) => { store.setMiddleName(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("middleName");
                                    handleValueChange(event,
                                    "middleName",
                                    store.middleName,
                                    savedState.middleName);
                                }}
                                className="bds-form-text bds-grid-form form-control" maxLength={35} />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <label className="bds-form-label form-label">Suffix Type</label>
                            <select name="suffixTypeId" value={store.suffixTypeId}
                                onChange={(event) => {
                                    store.setSuffixTypeId(event.target.value);
                                }}
                                    onBlur={(event) => {
                                    setFieldsTouched("suffixTypeId");
                                    handleValueChange(event,
                                    "suffixTypeId",
                                    store.suffixTypeId,
                                    savedState.suffixTypeId);
                                }}
                                className="bds-form-select bds-grid-form form-select">
                                <option value="">Please Select ...</option>
                                {
                                    suffixTypes.map((item, index) => (
                                        <option key={index} value={item.suffixTypeId}>{item.suffixTypeName}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    </Card>
                </div>

                {/* DATE OF BIRTH */}
                <div className="mt-4">
                    <Card>
                    <div className="bds-section-heading mandatory">What is the applicant's date of birth?</div>
                    <div className="bds-section-details mt-2">
                        Please enter in the following format: XX/XX/XXXX. You do not need to add a zero at
                        the beginning of single digit months or days.
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <BdsDatePicker
                                id='date-of-birth'
                                name="dateOfBirth" 
                                value={store.dateOfBirth}
                                cssClass="bds-form-text bds-section-dob form-control"
                                onChange={(event) => {
                                    store.setDateOfBirth(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("dateOfBirth");
                                    if (Utility.isHtmlDateStringValid(event.target.value)) {
                                        handleValueChange(event,
                                        "dateOfBirth",
                                        store.dateOfBirth,
                                        savedState.dateOfBirth)
                                    }
                                }}
                            ></BdsDatePicker>
                        </div> 
                    </div>
                    </Card>
                </div>

                {/* ADDRESS INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">What is the applicant's mailing address?</div>
                        <div className="bds-section-details mt-2">
                            Please enter the address where the applicant currently recieves mail.
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-md-6">
                                <label className="bds-form-label form-label mandatory">Street Address</label>
                                <input type="text" name="streetAddress1"
                                value={store.streetAddress1}
                                onChange={(event) => { store.setStreetAddress1(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("streetAddress1");
                                    handleValueChange(event,
                                    "streetAddress1",
                                    store.streetAddress1,
                                    savedState.streetAddress1);
                                }}
                                className="bds-form-text form-control" maxLength={331} />
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                                <label className="bds-form-label form-label mandatory">City</label>
                                <input type="text" name="city" value={store.city}
                                onChange={(event) => { store.setCity(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("city");
                                    handleValueChange(event,
                                    "city",
                                    store.city,
                                    savedState.city);
                                }}
                                className="bds-form-text form-control" maxLength={255} />
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <label className="bds-form-label form-label mandatory">State</label>
                                <select name="nbarStateId" value={store.state}
                                onChange={(event) => {
                                    store.setState(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("nbarStateId");
                                    handleValueChange(event,
                                    "nbarStateId",
                                    store.state,
                                    savedState.state);
                                }}
                                className="bds-form-select bds-grid-form form-select">
                                <option value="">Please Select ...</option>
                                {
                                    states.map((item, index) => (
                                    <option key={index} value={item.stateId}>{item.stateName}</option>
                                    ))
                                }
                                </select>
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <label className="bds-form-label form-label mandatory">Zip Code</label>
                                <input type="text" name="zipCode" value={store.zipCode}
                                onChange={(event) => { store.setZipCode(event.target.value); }}
                                onBlur={(event) => {
                                    setFieldsTouched("zipCode");
                                    handleValueChange(event,
                                    "zipCode",
                                    store.zipCode,
                                    savedState.zipCode);
                                }}
                                className="bds-form-text form-control" maxLength={10} />
                            </div>
                        </div>
                    </Card>
                </div>

                {/* PHONE NUMBER */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">What is the individual's phone number?</div>
                        <div className="bds-section-details mt-2">
                            Please enter a home phone number below, and an alternate number, if applicable.</div>
                            <div className="mt-4">
                            <div className="row">
                                <div className="col-3">Phone Type</div>
                                <div className="col-9 col-lg-7">Phone Number</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3">Home</div>
                                <div className="col-9 col-lg-7">
                                    <input type="text" name="homePhoneNumber"
                                        value={store.homePhoneNumber}
                                        onChange={(event) => { 
                                            store.setHomePhoneNumber(event.currentTarget.value); 
                                            store.formatPhoneNumber() 
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("homePhoneNumber");
                                            handleValueChange(event,
                                            "homePhoneNumber",
                                            store.homePhoneNumber,
                                            savedState.homePhoneNumber);
                                        }}
                                        className="bds-form-text form-control" maxLength={14} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-3"><span className="align-middle">Mobile</span></div>
                                <div className="col-9 col-lg-7">
                                <input type="text" name="alternatePhoneNumber"
                                        value={store.alternatePhoneNumber}
                                        onChange={(event) => { 
                                            store.setAlternatePhoneNumber(event.currentTarget.value); 
                                            store.formatPhoneNumber() 
                                        }}
                                        onBlur={(event) => {
                                            setFieldsTouched("alternatePhoneNumber");
                                            handleValueChange(event,
                                            "alternatePhoneNumber",
                                            store.alternatePhoneNumber,
                                            savedState.alternatePhoneNumber);
                                        }}
                                        className="bds-form-text form-control" maxLength={14} />
                                    {/* <MaskedPhoneNumber name="alternatePhoneNumber"
                                    value={store.alternatePhoneNumber}
                                    onChange={(event) => { store.setAlternatePhoneNumber(event.value); }}
                                    onBlur={(event) => {
                                        setFieldsTouched("alternatePhoneNumber");
                                        handleValueChange(event,
                                        "alternatePhoneNumber",
                                        store.alternatePhoneNumber,
                                        savedState.alternatePhoneNumber);
                                    }}
                                    className="bds-form-text form-control" maxLength={14} /> */}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>

                {/* RESIDENT INFORMATION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading">With whom is the individual residing?</div>
                        <div className="bds-section-details mt-2">
                            Please identify all individuals residing in the home full or part time.
                        </div>

                        <div className="row mt-3">
                            <ShowThen when={residentErrors != ''}>
                                <label className="bds-error">{residentErrors}</label>
                            </ShowThen>
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-md-6"><strong>Full Name</strong></div>
                                        <div className="col-md-1"><strong>Age</strong></div>
                                        <div className="col-md-3"><strong>Relationship</strong></div>
                                        <div className="col-md-2"><strong>Action</strong></div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="text" name="addResidentName" className="form-control" id="resident-name"
                                                value={store.addResidentName}
                                                onChange={(event) => { store.setAddResidentName(event.target.value); }}
                                                onBlur={(event) => {
                                                    setFieldsTouched("addResidentName", "addResidentAge", "addResidentRelationship");
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <input type="number" name="addResidentAge" className="form-control" id="resident-age" min="0" max="130" step="1"
                                                value={store.addResidentAge}
                                                onChange={(event) => { store.setAddResidentAge(Number(event.target.value)); }}
                                                onBlur={(event) => {
                                                    setFieldsTouched("addResidentName", "addResidentAge", "addResidentRelationship");
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <select name="suffixTypeId" 
                                                value={store.addResidentRelationship}
                                                onChange={(event) => { store.setAddResidentRelationship(event.target.value); }}
                                                onBlur={(event) => {
                                                    setFieldsTouched("addResidentName", "addResidentAge", "addResidentRelationship");
                                                }}
                                                className="bds-form-select form-select">
                                                <option value="">Please Select ...</option>
                                                {
                                                    relationshipTypes.map((item, index) => (
                                                        <option key={index} value={item.relationshipTypeId}>{`${item.name} ${item.description}`}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <button className="btn bds-btn-primary" onClick={(event) => handleAddResident(event)}>Add</button>
                                        </div>
                                    </div>
                                </li>                               
                                {
                                    residentList.map(resident => {
                                        return (
                                            <li className="list-group-item" key={resident.residentInformationId}>
                                                <div className="row">
                                                    <div className="col-md-6">{resident.name}</div>
                                                    <div className="col-md-1">{resident.age}</div>
                                                    <div className="col-md-3">{`${relationshipTypes.find(x => x.relationshipTypeId == Number(resident.relationshipTypeId)).name} ${relationshipTypes.find(x => x.relationshipTypeId == Number(resident.relationshipTypeId)).description}`}</div>
                                                    <div className="col-md-2"><button className="btn bds-btn-primary" name={resident.residentInformationId.toString()} onClick={(event) => handleRemoveResident(event)}>Remove</button></div>

                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Card>
                </div>

                {/* WAIVER DESCRIPTION */}
                <div className="mt-4">
                    <Card>
                        <div className="bds-section-heading mandatory">
                            Briefly describe the reason why you are seeking a CIH Waiver.
                        </div>
                        <div className="row mt-3">
                            <NbarTextArea
                                id="waiver-reason-description" 
                                name="reasonDescription" 
                                value={store.reasonDescription}
                                onChange={(event) => { 
                                    store.setReasonDescription(event.target.value);
                                }}
                                onBlur={(event) => {
                                    setFieldsTouched("reasonDescription");
                                    handleValueChange(event,
                                        "reasonDescription",
                                        store.reasonDescription,
                                        savedState.reasonDescription
                                    )
                                }}
                                labelText="Please briefly describe the event or long-term conditions that have prompted you to seek the CIH waiver.
                                    Or explain why the individual can no longer be safely maintained in your home without outside intervention."
                            ></NbarTextArea> 
                        </div>
                    </Card>
                </div>

                {/* PAGE NAVIGATION */}
                <div className="row mt-4">
                    <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
                        <IsNotAuthenticated>
                            <button type="button" className="btn bds-btn-primary"
                                onClick={() => NavigationHelper.gotoNbarHome(navigate)}>Back to Home</button>
                        </IsNotAuthenticated>
                        <IsAuthenticated>
                            <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
                        </IsAuthenticated>
                    </div>
                    <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
                        <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep()}
                        className="btn bds-btn-primary">Next</button>
                    </div>
                </div>
            </form>
        </>
    )

}

export default observer(StartNbarApplication)