import { AppSettings } from "app-setting";
import { useRef, useState } from "react";
import { ApplicationResource } from "shared-module/api-resources/application.resource";
import { NbarDocumentResource } from "../../../../shared-module/api-resources/Nbar/nbar-document.resource";
import { ShowThen } from "shared-module/components/show-then/show-then.component";
import { Utility } from "shared-module/helpers/utility";
import { DocumentTypeCodes, GatewayNetworkError } from "shared-module/models/common.models";
import { ApiConfig } from "shared-module/services/api-config";

interface Props {
  applicationRefId: string,
  isSubmitted: boolean,
  documentName: string,
  documentTypeName: string,
  lastUploadedOn: string,
  hasDocument: boolean,
  documentTypeCode: string,
  onChange: () => void
}

function ApplicationDashboardDocumentComponent(props: Props) {
  const [isUpLoading, setIsUploading] = useState<boolean>(false);
  const uploadInput = useRef<HTMLInputElement>();
  const applicationService = new ApplicationResource(ApiConfig);
  const nbarDocumentService = new NbarDocumentResource(ApiConfig);

  const {
    applicationRefId,
    documentName,
    documentTypeName,
    lastUploadedOn,
    hasDocument,
    documentTypeCode,
    onChange
  } = props;

  function selectDocumentFile() {
    uploadInput.current.value = null;
    uploadInput.current.click();
  }

  function uploadPromiseChain(file: File): Promise<any> {
    if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.COD)) {
      return applicationService.uploadConfirmationofDiagnosisDocument(applicationRefId, file);
    } else if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.AFD)) {
      return applicationService.uploadAuthorizationForDisclosureDocument(applicationRefId, file);
    } else if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.NBG)) {
      return nbarDocumentService.uploadNbarGuardianshipDocument(applicationRefId, file);
    }
    Promise.reject();
  }

  function uploadDocumentFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target && event.target.files && event.target.files.length) {
      var file = event.target.files[0];

      if (file.size > AppSettings.maxUploadFileSize) {
        Utility.alert('Error Uploading', `Selected file size exceeds the allowed limit of ${AppSettings.maxUploadFileSize / 1000000}MB.`);
        return;
      }

      setIsUploading(true);

      uploadPromiseChain(file)
        .then((response) => {
          if (onChange) {
            onChange();
          }
          return true;
        })
        .catch((ex) => {
          if (!(ex instanceof GatewayNetworkError)) {
            Utility.alert("Error Saving", ex.errorMessage);
          }
        })
        .finally(() => {
          setIsUploading(false);
        })
    }
  }

  function getAllApplicationDocumentUrl(): string {
    if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.COD)) {
      return applicationService.getApplicationCodDocumentUrl(applicationRefId);
    } else if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.AFD)) {
      return applicationService.getApplicationAfdDocumentUrl(applicationRefId);
    } else if (Utility.caseInsensitiveCompare(documentTypeCode, DocumentTypeCodes.NBG)) {
      return nbarDocumentService.getNbarGuardianshipDocumentUrl(applicationRefId);
    } else {
      throw "Invalid document type requested.";
    }
  }

  return (<>
    <div className="row mt-2">
      <div className="col-12 col-md-3">
        <label>Document Name:</label>
      </div>
      <div className="col-12 col-md-9 mt-2 mt-md-0 text-wrap">
        <span className="me-3 end-with-breaking-space">
          {!!documentName ? documentName : "N/A"}
        </span>
      </div>
      <div className="col-6 col-md-3 mt-2">
        <label>Type:</label>
      </div>
      <div className="col-6 col-md-3 mt-2">
        {documentTypeName}
      </div>
      <div className="col-6 col-md-3 mt-2">
        <label>Last Uploaded:</label>
      </div>
      <div className="col-6 col-md-3 mt-2">
        {Utility.toDateString_US_en(lastUploadedOn, 'N/A')}
      </div>
      <div className="col-12 my-2">
        <input ref={uploadInput} type="file" className="d-none"
          accept={AppSettings.validUploadFileFormats.toString()}
          onChange={(event) => uploadDocumentFile(event)} />
        <ShowThen when={hasDocument}>
          <a className="btn btn-sm btn-primary mt-2 me-0 me-md-2"
            href={getAllApplicationDocumentUrl()}
            download={documentName} target="_blank" >Download</a>
        </ShowThen>
        <ShowThen when={!hasDocument}>
          <button type="button" className="btn btn-sm btn-primary mt-2 me-0 me-md-2"
            onClick={() => { selectDocumentFile() }}>
            <ShowThen when={isUpLoading}>
              <div className="spinner-border me-1" role="status">
                <span className="visually-hidden">Uploading...</span>
              </div>
            </ShowThen>
            Upload
          </button>
        </ShowThen>
        <ShowThen when={hasDocument}>
          <button type="button" className="btn btn-sm btn-primary mt-2"
            onClick={() => { selectDocumentFile() }}>
            <ShowThen when={isUpLoading}>
              <div className="spinner-border me-1" role="status">
                <span className="visually-hidden">Uploading...</span>
              </div>
            </ShowThen>
            Reupload
          </button>
        </ShowThen>
      </div>
    </div>
  </>)
}

export const ApplicationDashboardDocument = ApplicationDashboardDocumentComponent;