import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card } from "../../../shared-module/components/card/card.component";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { MaskedPhoneNumber } from "../../../shared-module/components/masked-phone-number/masked-phone-number.component";
import { LookupResource } from "../../../shared-module/api-resources/lookup.resource";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { AddressResource } from "../../../shared-module/api-resources/address.resource";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { ApplicantInformationStore, IApplicantInformation } from "./applicant-information.store";
import { ErrorContainer } from "../../../shared-module/components/error-container/error-container.component";
import { AddressCorrectionModal, AddressCorrectionModalOptions } from "../../extensions/address-correction-modal/address-correction-modal.component";
import { DebugContainer } from "../../../shared-module/components/debug-container/debug-container.component";
import { useFormValidation } from "../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { AddressValidationService } from "../../../shared-module/services/address-validation.service";
import { ApplicantInformationValidationSchema } from "./applicant-information.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { ApplicationExpirationError, GatewayNetworkError } from "shared-module/models/common.models";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";
import { SessionService } from "shared-module/services/session.service";
import { EventBroadcasterService } from "shared-module/events/event-broadcaster.service";

function ApplicantInformation() {
  const addressCorrectionModalId = "addressCorrectionModal";
  const navigate = useNavigate();
  const { id: applicationRefId } = useParams();

  const lookupService = new LookupResource(ApiConfig);
  const applicationService = new ApplicationResource(ApiConfig);
  const addressService = new AddressResource(ApiConfig);

  const [livingArrangementTypes, setLivingArrangementTypes] = useState<GatewayOapApi.LivingArrangementModel[]>([]);
  const [counties, setCounties] = useState<GatewayOapApi.CountyModel[]>([]);
  const [states, setStates] = useState<GatewayOapApi.StateModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savedState, setSavedState] = useState<IApplicantInformation>({} as IApplicantInformation);
  const [addressCorrenctionModalOptions, setAddressCorrenctionModalOptions] = useState<AddressCorrectionModalOptions>();
  const store = ApplicantInformationStore;
  const addressValidationService = new AddressValidationService(addressService, addressCorrenctionModalOptions);
  const [fromSummary, setFromSummary] = useState<boolean>(false);

  const { errors, touched, setIsSubmitting, getFieldState, setFieldsTouched, touchedAll } =
    useFormValidation(ApplicantInformationValidationSchema, store, null);

    useEffect(() => {
      if (!hasApplicationRefId()) {
        NavigationHelper.gotoHome(navigate);
        return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      lookupService.getLivingArrangements(),
      lookupService.getCounties(),
      lookupService.getStates(),
      applicationService.getApplication(applicationRefId)
    ])
      .then((response) => {
        setLivingArrangementTypes(response[0].records);
        setCounties(response[1].records);
        setStates(response[2].records);
        store.refresh(response[3].record);
        setSavedState(store.buildState(response[3].record));
        setFromSummary(sessionStorage.getItem('fromSummary') == 'true' || false);
        return true;
      })
      .catch((ex) => { 
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate); 
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

  }, []);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function handleCurrentAddressChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
    if(!currentValue) {
      store.clearCurrentAddress();
      handleValueChange(event, eventSource, currentValue, savedValue)
    }
  }

  function handleValueChange<T>(event, eventSource: string, currentValue: T, savedValue: T) {
      setIsSaving(true);
      saveApplicantInformation(eventSource)
      .finally(() => {
        setIsSaving(false);
      });
  }

  function saveApplicantInformation(eventSource: string): Promise<any> {
    setIsSubmitting(true, eventSource);
    return applicationService.saveApplicantInformation(
      applicationRefId,
      Utility.stringToNumber(store.livingArrangementTypeId, null),
      store.applicantMailingAddressLine,
      store.applicantMailingCity,
      Utility.stringToNumber(store.applicantMailingStateId, null),
      store.applicantMailingZipCode,
      Utility.stringToNumber(store.applicantMailingCountyId, null),
      store.applicantHasDifferentCurrentAddress,
      store.applicantCurrentAddressLine,
      store.applicantCurrentCity,
      Utility.stringToNumber(store.applicantCurrentStateId, null),
      store.applicantCurrentZipCode,
      Utility.stringToNumber(store.applicantCurrentCountyId, null),
      store.applicantHomePhoneIsPrimary,
      store.unformat(store.applicantHomePhone),
      store.applicantMobilePhoneIsPrimary,
      store.unformat(store.applicantMobilePhone),
      store.applicantWorkPhoneIsPrimary,
      store.unformat(store.applicantWorkPhone),
      store.applicantEmailAddress,
      Utility.stringToNumber(store.diagnosisAge, null),
      store.disabilityDescription)
      .then((response) => {
        return applicationService.getApplication(applicationRefId);
      })
      .then((response) => {
        setSavedState(store.buildState(response.record));
        var guestSessionId = sessionStorage.getItem("guestSessionId") || "0";
        if(guestSessionId != "0") {
            SessionService.slideGuestSession(Number(guestSessionId));
            EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                isAuthenticated: false,
                remainingTimeInSeconds: 900,
                hasActiveMaintenance: false
            });
        }
        return true;
      })
      .catch(ex => {
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsSubmitting(false, null);
      });
  }

  function handleGoToNextStep() {
    touchedAll();
    if (ApplicantInformationValidationSchema.isValidSync(store)) {

      if (!store.applicantMailingAddressLine &&
        !store.applicantCurrentAddressLine) {
        NavigationHelper.gotoApplicantDemographics(navigate, applicationRefId);
      }

      setIsSaving(true);

      addressValidationService.verifyAddresses([
        {
          streetAddress: store.applicantMailingAddressLine,
          city: store.applicantMailingCity,
          stateId: Utility.stringToNumber(store.applicantMailingStateId, null),
          zipCode: store.applicantMailingZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setApplicantMailingAddressLine(streetAddress);
            store.setApplicantMailingCity(city);
            store.setApplicantMailingStateId(stateId);
            store.setApplicantMailingZipCode(zipCode);
            return saveApplicantInformation(null);
          }
        },
        {
          streetAddress: store.applicantCurrentAddressLine,
          city: store.applicantCurrentCity,
          stateId: Utility.stringToNumber(store.applicantCurrentStateId, null),
          zipCode: store.applicantCurrentZipCode,
          addressUpdateHandler: (streetAddress, city, stateId, zipCode): Promise<any> => {
            store.setApplicantCurrentAddressLine(streetAddress);
            store.setApplicantCurrentCity(city);
            store.setApplicantCurrentStateId(stateId);
            store.setApplicantCurrentZipCode(zipCode);
            return saveApplicantInformation(null);
          }
        }],
        () => {
          NavigationHelper.gotoApplicantDemographics(navigate, applicationRefId);
        })
        .finally(() => {
          setIsSaving(false);
        });
      return;
    }

    scrollTo(0, 0);
  }

  function handleReturnToSummary(){
    touchedAll();
    if (ApplicantInformationValidationSchema.isValidSync(store)) {
      NavigationHelper.gotoApplicationSummary(navigate, applicationRefId);
      return;
    }

    scrollTo(0, 0);
  }

  return (
    <>
      <DebugContainer data={store} saved={savedState} touched={touched} errors={errors}></DebugContainer>
      <AddressCorrectionModal id={addressCorrectionModalId} accessor={
        (options: AddressCorrectionModalOptions) => {
          setAddressCorrenctionModalOptions(options);
        }} />
      <PageTitleSetter step={3} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar step={3} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={!isLoading}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <form>
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">
              Please select the current living arrangement that best describes where the applicant lives.
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <select name="livingArrangementTypeId" value={store.livingArrangementTypeId}
                  onChange={(event) => {
                    store.setLivingArrangementTypeId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("livingArrangementTypeId");
                    handleValueChange(event,
                      "livingArrangementTypeId",
                      store.livingArrangementTypeId,
                      savedState.livingArrangementTypeId);
                  }}
                  className="bds-form-select bds-section-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    livingArrangementTypes.map((item, index) => (
                      <option key={index} value={item.livingArrangementTypeId}>{item.livingArrangementTypeName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>

        {/* Current Address */}
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the applicant's mailing address?</div>
            <div className="bds-section-details mt-2">
              Please enter the address where the applicant currently recieves mail.
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <label className="bds-form-label form-label mandatory">Street Address</label>
                <input type="text" name="applicantMailingAddressLine"
                  value={store.applicantMailingAddressLine}
                  onChange={(event) => { store.setApplicantMailingAddressLine(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMailingAddressLine");
                    handleValueChange(event,
                      "applicantMailingAddressLine",
                      store.applicantMailingAddressLine,
                      savedState.applicantMailingAddressLine);
                  }}
                  className="bds-form-text form-control" maxLength={331} />
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                <label className="bds-form-label form-label mandatory">City</label>
                <input type="text" name="applicantMailingCity" value={store.applicantMailingCity}
                  onChange={(event) => { store.setApplicantMailingCity(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMailingCity");
                    handleValueChange(event,
                      "applicantMailingCity",
                      store.applicantMailingCity,
                      savedState.applicantMailingCity);
                  }}
                  className="bds-form-text form-control" maxLength={255} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">State</label>
                <select name="applicantMailingStateId" value={store.applicantMailingStateId}
                  onChange={(event) => {
                    store.setApplicantMailingStateId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMailingStateId");
                    handleValueChange(event,
                      "applicantMailingStateId",
                      store.applicantMailingStateId,
                      savedState.applicantMailingStateId);
                  }}
                  className="bds-form-select bds-grid-form form-select">
                  <option value="">Please Select ...</option>
                  {
                    states.map((item, index) => (
                      <option key={index} value={item.stateId}>{item.stateName}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">Zip Code</label>
                <input type="text" name="applicantMailingZipCode" value={store.applicantMailingZipCode}
                  onChange={(event) => { store.setApplicantMailingZipCode(event.target.value); }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMailingZipCode");
                    handleValueChange(event,
                      "applicantMailingZipCode",
                      store.applicantMailingZipCode,
                      savedState.applicantMailingZipCode);
                  }}
                  className="bds-form-text form-control" maxLength={10} />
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="bds-form-label form-label mandatory">County</label>
                <select name="applicantMailingCountyId" value={store.applicantMailingCountyId}
                  onChange={(event) => {
                    setFieldsTouched("applicantMailingCountyId");
                    store.setApplicantMailingCountyId(event.target.value);
                  }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantMailingCountyId");
                    handleValueChange(event,
                      "applicantMailingCountyId",
                      store.applicantMailingCountyId,
                      savedState.applicantMailingCountyId);
                  }}
                  className="bds-form-select form-select">
                  <option value="">Please Select ...</option>
                  {
                    counties.map((item, index) => (
                      <option key={index} value={item.countyId}>{item.countyName}</option>
                    ))
                  }
                </select>
              </div>
            </div>
          </Card>
        </div>
        {/* Alternate Address */}
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">
              Is the applicant's current address different from the applicant's mailing address?</div>
            <div className="bds-section-details mt-2">
              If yes, please check this box. If no, please go to next question.</div>
            <div className="row mt-3">
              <div className="col-12">
                <BdsCheckbox 
                  id="diff-current-address"
                  name="applicantHasDifferentCurrentAddress"
                  cssClass="form-check"
                  checked={store.applicantHasDifferentCurrentAddress}
                    onChange={(event) => {
                      store.setApplicantHasDifferentCurrentAddress(event.target.checked);
                      setFieldsTouched("applicantHasDifferentCurrentAddress");
                      handleCurrentAddressChange(event,
                        "applicantHasDifferentCurrentAddress",
                        store.applicantHasDifferentCurrentAddress,
                        savedState.applicantHasDifferentCurrentAddress );
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantHasDifferentCurrentAddress");
                    }}
                    label="Current address is different than mailing address"
                />
              </div>
              <ShowThen when={store.applicantHasDifferentCurrentAddress}>
                <div className="row mt-3">
                  <div className="col-12 col-md-6">
                    <label className="bds-form-label form-label mandatory">Street Address</label>
                    <input type="text" name="applicantCurrentAddressLine" value={store.applicantCurrentAddressLine}
                      onChange={(event) => { store.setApplicantCurrentAddressLine(event.target.value); }}
                      onBlur={(event) => {
                        setFieldsTouched("applicantCurrentAddressLine");
                        handleValueChange(event,
                          "applicantCurrentAddressLine",
                          store.applicantCurrentAddressLine,
                          savedState.applicantCurrentAddressLine);
                      }}
                      className="bds-form-text form-control" maxLength={331} />
                  </div>
                  <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <label className="bds-form-label form-label mandatory">City</label>
                    <input type="text" name="applicantCurrentCity" value={store.applicantCurrentCity}
                      onChange={(event) => { store.setApplicantCurrentCity(event.target.value) }}
                      onBlur={(event) => {
                        setFieldsTouched("applicantCurrentCity");
                        handleValueChange(event,
                          "applicantCurrentCity",
                          store.applicantCurrentCity,
                          savedState.applicantCurrentCity);
                      }}
                      className="bds-form-text form-control" maxLength={255} />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">State</label>
                    <select name="applicantCurrentStateId" value={store.applicantCurrentStateId}
                      onChange={(event) => {
                        store.setApplicantCurrentStateId(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("applicantCurrentStateId");
                        handleValueChange(event,
                          "applicantCurrentStateId",
                          store.applicantCurrentStateId,
                          savedState.applicantCurrentStateId);
                      }}
                      className="bds-form-select bds-grid-form form-select">
                      <option value="">Please Select ...</option>
                      {
                        states.map((item, index) => (
                          <option key={index} value={item.stateId}>{item.stateName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">Zip Code</label>
                    <input type="text" name="applicantCurrentZipCode" value={store.applicantCurrentZipCode}
                      onChange={(event) => { store.setApplicantCurrentZipCode(event.target.value) }}
                      onBlur={(event) => {
                        setFieldsTouched("applicantCurrentZipCode");
                        handleValueChange(event,
                          "applicantCurrentZipCode",
                          store.applicantCurrentZipCode,
                          savedState.applicantCurrentZipCode);
                      }}
                      className="bds-form-text form-control mandatory" maxLength={10} />
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="bds-form-label form-label mandatory">County</label>
                    <select name="applicantCurrentCountyId" value={store.applicantCurrentCountyId}
                      onChange={(event) => {
                        store.setApplicantCurrentCountyId(event.target.value);
                      }}
                      onBlur={(event) => {
                        setFieldsTouched("applicantCurrentCountyId");
                        handleValueChange(event,
                          "applicantCurrentCountyId",
                          store.applicantCurrentCountyId,
                          savedState.applicantCurrentCountyId);
                      }}
                      className="bds-form-select form-select">
                      <option value="">Please Select ...</option>
                      {
                        counties.map((item, index) => (
                          <option key={index} value={item.countyId}>{item.countyName}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </ShowThen>
            </div>
          </Card>
        </div>

        {/* Phone Number */}
        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the applicant's phone number?</div>
            <div className="bds-section-details mt-2">
              Please enter up to three phone numbers without hyphens and designate
              one as primary. If the applicant does not have a phone number, leave
              the phone numbers blank and go to the next question.</div>
            <div className="mt-4 text-center">
              <div className="row">
                <div className="col-2">Primary</div>
                <div className="col-3">Phone Type</div>
                <div className="col-7 col-lg-5">Phone Number</div>
              </div>
              <div className="row mt-2">
                <div className="col-2 text-center">
                  <BdsCheckbox
                    id="applicant-home-phone-is-primary"
                    name="applicantHomePhoneIsPrimary"
                    checked={store.applicantHomePhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setApplicantHomePhoneIsPrimary(event.target.checked);
                      setFieldsTouched("applicantHomePhoneIsPrimary");
                      event.target.checked && store.setApplicantMobilePhoneIsPrimary(false);
                      event.target.checked && store.setApplicantWorkPhoneIsPrimary(false);
                      handleValueChange(event,
                        "applicantHomePhoneIsPrimary",
                        store.applicantHomePhoneIsPrimary,
                        savedState.applicantHomePhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantHomePhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3">Home</div>
                    <div className="col-7 col-lg-5">
                    <input type="text" name="applicantHomePhone"
                        value={store.applicantHomePhone}
                        onChange={(event) => {
                            store.setApplicantHomePhone(event.currentTarget.value);
                            store.formatPhoneNumber()
                        }}
                        onBlur={(event) => {
                        setFieldsTouched("homePhoneNumber");
                        handleValueChange(event,
                            "homePhoneNumber",
                            store.applicantHomePhone,
                            savedState.applicantHomePhone);
                        }}
                        className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-2">
                  <BdsCheckbox 
                    id="applicant-mobile-phone-is-primary"
                    name="applicantMobilePhoneIsPrimary"
                    checked={store.applicantMobilePhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setApplicantMobilePhoneIsPrimary(event.target.checked);
                      setFieldsTouched("applicantMobilePhoneIsPrimary");
                      event.target.checked && store.setApplicantHomePhoneIsPrimary(false);
                      event.target.checked && store.setApplicantWorkPhoneIsPrimary(false);
                      handleValueChange(event,
                        "applicantMobilePhoneIsPrimary",
                        store.applicantMobilePhoneIsPrimary,
                        savedState.applicantMobilePhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantMobilePhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3"><span className="align-middle">Mobile</span></div>
                <div className="col-7 col-lg-5">
                    <input type="text" name="applicantMobilePhone"
                        value={store.applicantMobilePhone}
                        onChange={(event) => {
                            store.setApplicantMobilePhone(event.currentTarget.value);
                            store.formatPhoneNumber()
                        }}
                        onBlur={(event) => {
                            setFieldsTouched("applicantMobilePhone");
                            handleValueChange(event,
                                "applicantMobilePhone",
                                store.applicantMobilePhone,
                                savedState.applicantMobilePhone);
                        }}
                        className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-2">
                  <BdsCheckbox
                    id="applicant-work-phone-is-primary" 
                    name="applicantWorkPhoneIsPrimary"
                    checked={store.applicantWorkPhoneIsPrimary}
                    cssClass="text-center-column"
                    onChange={(event) => {
                      store.setApplicantWorkPhoneIsPrimary(event.target.checked);
                      setFieldsTouched("applicantWorkPhoneIsPrimary");
                      event.target.checked && store.setApplicantHomePhoneIsPrimary(false);
                      event.target.checked && store.setApplicantMobilePhoneIsPrimary(false);
                      handleValueChange(event,
                        "applicantWorkPhoneIsPrimary",
                        store.applicantWorkPhoneIsPrimary,
                        savedState.applicantWorkPhoneIsPrimary);
                    }}
                    onBlur={(event) => {
                      setFieldsTouched("applicantWorkPhoneIsPrimary");
                    }}
                    label=""
                  />
                </div>
                <div className="col-3">Work</div>
                <div className="col-7 col-lg-5">
                    <input type="text" name="applicantWorkPhone"
                        value={store.applicantWorkPhone}
                        onChange={(event) => {
                            store.setApplicantWorkPhone(event.currentTarget.value);
                            store.formatPhoneNumber()
                        }}
                        onBlur={(event) => {
                            setFieldsTouched("applicantWorkPhone");
                            handleValueChange(event,
                                "applicantWorkPhone",
                                store.applicantWorkPhone,
                                savedState.applicantWorkPhone);
                        }}
                        className="bds-form-text form-control" maxLength={14} />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading">What is the applicant's email address?</div>
            <div className="bds-section-details mt-2">
              If the applicant does not have an email address, leave the email address
              blank and go to the next question.</div>
            <div className="row mt-3">
              <div className="col-12">
                <input type="text" name="applicantEmailAddress" value={store.applicantEmailAddress}
                  onChange={(event) => { store.setApplicantEmailAddress(event.target.value) }}
                  onBlur={(event) => {
                    setFieldsTouched("applicantEmailAddress");
                    handleValueChange(event,
                      "applicantEmailAddress",
                      store.applicantEmailAddress,
                      savedState.applicantEmailAddress);
                  }}
                  className="bds-form-text bds-section-email form-control" maxLength={150} />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading mandatory">
              At what age was the applicant diagnosed with an intellectual or developmental disability?</div>
            <div className="bds-section-details mt-2">If the applicant's disability was from birth, enter 0.</div>
            <div className="row mt-3">
              <div className="col-12">
                <input type="text" name="diagnosisAge" value={store.diagnosisAge}
                  onChange={(event) => { store.setDiagnosisAge(event.target.value) }}
                  onBlur={(event) => {
                    setFieldsTouched("diagnosisAge");
                    handleValueChange(event,
                      "diagnosisAge",
                      store.diagnosisAge,
                      savedState.diagnosisAge);
                  }}
                  className="bds-form-text bds-section-age form-control" maxLength={3} />
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <Card>
            <div className="bds-section-heading mandatory">
              Briefly describe how the applicant's disability affects daily life.</div>
            <div className="bds-section-details mt-2">
              This should be a brief and general description of how the disability impacts
              the applicant's daily life and access to the home or to the community.
              This may include any support needs related to communication, mobility,
              learning, self-care, self-direction and/or independent living. More detailed
              information will be gathered during the applicant's intake interview.</div>
            <div className="row mt-3">
              <div className="col-12">
                <span>{store.disabilityDescription?.length || 0}/1000</span>
              </div>
              <div className="col-12 mt-2">
                <textarea name="disabilityDescription" value={store.disabilityDescription}
                  onChange={(event) => { store.setDisabilityDescription(event.target.value) }}
                  onBlur={(event) => {
                    setFieldsTouched("disabilityDescription");
                    handleValueChange(event,
                      "disabilityDescription",
                      store.disabilityDescription,
                      savedState.disabilityDescription);
                  }}
                  className="bds-textarea form-control" rows={6} maxLength={1000}>
                </textarea>
              </div>
            </div>
          </Card>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
            <button type="button" className="btn bds-btn-primary"
              onClick={() => NavigationHelper.gotoApplicationInformation(navigate, applicationRefId)}>Back</button>
            <IsAuthenticated>
              <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
            </IsAuthenticated>
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
            <ShowThen when={fromSummary}>
              <button type="button" disabled={isLoading} 
                onClick={() => handleReturnToSummary()} 
                className="btn bds-btn-primary me-3">Return To Summary
              </button>
            </ShowThen>
            <button type="button" disabled={isLoading} onClick={(event) => handleGoToNextStep()}
              className="btn bds-btn-primary">Next</button>
          </div>
        </div>
      </form>
    </>
  );
}

export default observer(ApplicantInformation);