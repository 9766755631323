import LoadingOverlayEventBus from "./loading-overlay-eventbus";

export enum LoadingOverlayEvents {
  OVERLAY_SHOW = "LOADING.OVERLAY.SHOW",
  OVERLAY_HIDE = "LOADING.OVERLAY.HIDE"
}

class LoadingOverlay {
  private static instance: LoadingOverlay;

  private constructor() {
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new LoadingOverlay();
    }
    return this.instance;
  }

  public show() {
    LoadingOverlayEventBus.dispatch(LoadingOverlayEvents.OVERLAY_SHOW, {});
  }

  public hide() {
    LoadingOverlayEventBus.dispatch(LoadingOverlayEvents.OVERLAY_HIDE, null);
  }
}

export default LoadingOverlay.getInstance();
