import * as Yup from "yup";
import { ILegalRepresentative } from "./legal-representative.store";
import { Utility } from "../../../shared-module/helpers/utility";

export const LegalRepresentativeValidationSchema: Yup.ObjectSchema<ILegalRepresentative> = Yup.object({
  _primary_primaryPhone: Yup.boolean()
    .test("validate-_primary_primaryPhone", "-", function (item) {
      if (
        (this.parent.primary_HomePhoneNumber ||
          this.parent.primary_MobilePhoneNumber ||
          this.parent.primary_WorkPhoneNumber) &&
        !this.parent.primary_HomePhoneIsPrimary &&
        !this.parent.primary_MobilePhoneIsPrimary &&
        !this.parent.primary_WorkPhoneIsPrimary) {
        return this.createError(
          Utility.buildYupErrorMessage("_primary_primaryPhone",
            "A primary phone must be selected for the Primary Legal Representative if a phone number is entered."))
      } else if ((this.parent.primary_HomePhoneIsPrimary && !this.parent.primary_HomePhoneNumber) ||
        (this.parent.primary_MobilePhoneIsPrimary && !this.parent.primary_MobilePhoneNumber) ||
        (this.parent.primary_WorkPhoneIsPrimary && !this.parent.primary_WorkPhoneNumber)) {
        return this.createError(
          Utility.buildYupErrorMessage("_primary_primaryPhone",
            "Primary Legal Representative phone number marked as primary can not be blank."));
      }
      return true;
    }),
  _secondary_primaryPhone: Yup.boolean()
    .test("validate-_secondary_primaryPhone", "-", function (item) {
      if (this.parent.hasSecondaryLegalRepresentative == "has-second-legal-representative" &&
        (this.parent.secondary_HomePhoneNumber ||
          this.parent.secondary_MobilePhoneNumber ||
          this.parent.secondary_WorkPhoneNumber) &&
        !this.parent.secondary_HomePhoneIsPrimary &&
        !this.parent.secondary_MobilePhoneIsPrimary &&
        !this.parent.secondary_WorkPhoneIsPrimary) {
        return this.createError(
          Utility.buildYupErrorMessage("_secondary_primaryPhone",
            "A primary phone must be selected for the Secondary Legal Representative if a phone number is entered."))
      } else if ((this.parent.secondary_HomePhoneIsPrimary && !this.parent.secondary_HomePhoneNumber) ||
        (this.parent.secondary_MobilePhoneIsPrimary && !this.parent.secondary_MobilePhoneNumber) ||
        (this.parent.secondary_WorkPhoneIsPrimary && !this.parent.secondary_WorkPhoneNumber)) {
        return this.createError(
          Utility.buildYupErrorMessage("_secondary_primaryPhone",
            "Secondary Legal Representative phone number marked as primary can not be blank."));
      }
      return true;
    }),
  _primary_atleastOneContact: Yup.boolean()
    .test("validate-_primary_atleastOneContact", "-", function (item) {
      if (!this.parent.primary_MailingStreetAddress &&
        // !this.parent.primary_MailingInternationalAddress &&
        !this.parent.primary_HomePhoneNumber &&
        !this.parent.primary_MobilePhoneNumber &&
        !this.parent.primary_WorkPhoneNumber &&
        !this.parent.primary_EmailAddress) {
        return this.createError(
          Utility.buildYupErrorMessage("_primary_atleastOneContact",
            "A mailing address, phone number or email address is required for the Primary Legal Representative."));
      }
      return true;
    }),
  _secondary_atleastOneContact: Yup.boolean()
    .test("validate-_secondary_atleastOneContact", "-", function (item) {
      if (this.parent.hasSecondaryLegalRepresentative == "has-second-legal-representative" &&
        !this.parent.secondary_MailingStreetAddress &&
        // !this.parent.secondary_MailingInternationalAddress &&
        !this.parent.secondary_HomePhoneNumber &&
        !this.parent.secondary_MobilePhoneNumber &&
        !this.parent.secondary_WorkPhoneNumber &&
        !this.parent.secondary_EmailAddress) {
        return this.createError(
          Utility.buildYupErrorMessage("_secondary_atleastOneContact",
            "A mailing address, phone number or email address is required for the Secondary Legal Representative."));
      }
      return true;
    }),
  primary_NoFirstName: Yup.boolean(),
  primary_FirstName: Yup.string()
    .test("validate-primary_FirstName", "-",
      function (item) {
        const message = "The First Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
        if (!this.parent.primary_NoFirstName && !item) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_FirstName", "First name is required."));
        } else if (item) {
          if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
            return this.createError(
              Utility.buildYupErrorMessage("primary_FirstName",
                "At least 2 letters are necessary for First Name."));
          } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("primary_FirstName", message));
          }
        }
        return true;
      }),
  primary_LastName: Yup.string()
    .test("validate-primary_LastName", "-",
      function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_LastName", "Last name for Primary Legal Representative is required."));
        } else if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_LastName",
              "At least 2 letters are necessary for Last Name."));
        } else if (item && !new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_LastName", "The Last Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),
  primary_MiddleName: Yup.string()
    .test("validate-primary_MiddleName", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z]{0,1}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MiddleName",
              "The Middle Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),
  primary_SuffixTypeId: Yup.string(),
  primary_RepresentativeTypeId: Yup.string()
    .test("validate-primary_RepresentativeTypeId", "-",
      function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_RepresentativeTypeId",
              "Please specify the relationship with the applicant for the Primary Legal Representative."));
        }
        return true;
      }),
  primary_HasMailingInternationalAddress: Yup.boolean(),
  primary_MailingStreetAddress: Yup.string()
    .test("validate-primary_MailingStreetAddress", "-",
      function (item) {
        if (!item && (this.parent.primary_MailingCity || this.parent.primary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingStreetAddress",
              "A street address is required for the Primary Legal Representative mailing address."));
        }
        return true;
      }),
  primary_MailingCity: Yup.string()
    .test("validate-primary_MailingCity", "-",
      function (item) {
        if (!item && (this.parent.primary_MailingStreetAddress || this.parent.primary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingCity",
              "A city is required for the Primary Legal Representative mailing address."));
        }
        return true;
      }),
  primary_MailingStateId: Yup.string()
    .test("validate-primary_MailingStateId", "-",
      function (item) {
        if (!item && (this.parent.primary_MailingStreetAddress || this.parent.primary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingStateId",
              "A State is required for the Primary Legal Representative mailing address."));
        }
        return true;
      }),
  primary_MailingZipCode: Yup.string()
    .test("validate-primary_MailingZipCode", "-",
      function (item) {
        if (!item && (this.parent.primary_MailingStreetAddress || this.parent.primary_MailingCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingZipCode",
              "A 5-digit or 9-digit numeric zip code is required for the Primary Legal Representative mailing address."));
        } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingZipCode",
              "Invalid Primary Legal Representative Mailing Address Zip Code (XXXXX-XXXX)."));
        }
        return true;
      }),
  primary_MailingCountyId: Yup.string()
    .test("validate-primary_MailingCountyId", "-",
      function (item) {
        if (!item && (this.parent.primary_MailingStreetAddress || this.parent.primary_MailingCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingCountyId",
              "A county is required for the Primary Legal Representative mailing address. If you do not know your county, select unknown."));
        }
        return true;
      }),
  primary_MailingInternationalAddress: Yup.string()
    .test("validate-primary_MailingInternationalAddress", "-",
      function (item) {
        if (!item && this.parent.primary_HasMailingInternationalAddress) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MailingInternationalAddress",
              "The international mailing address for the primary legal representative is required when selected."));
        }
        return true;
      }),
  primary_HasDifferentCurrentAddress: Yup.boolean(),
  primary_HasCurrentInternationalAddress: Yup.boolean(),
  primary_CurrentStreetAddress: Yup.string()
    .test("validate-primary_CurrentStreetAddress", "-",
      function (item) {
        if (!item && this.parent.primary_HasDifferentCurrentAddress &&
          !this.parent.primary_HasCurrentInternationalAddress) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentStreetAddress",
              "A street address is required for the Primary Legal Representative physical address."));
        } else if (!item && (this.parent.primary_CurrentCity || this.parent.primary_CurrentZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentStreetAddress",
              "A street address is required for the Primary Legal Representative physical address."));
        }
        return true;
      }),
  primary_CurrentCity: Yup.string()
    .test("validate-primary_CurrentCity", "-",
      function (item) {
        if (!item && (this.parent.primary_CurrentStreetAddress || this.parent.primary_CurrentZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentCity",
              "A city is required for the Primary Legal Representative physical address."));
        }
        return true;
      }),
  primary_CurrentStateId: Yup.string()
    .test("validate-primary_CurrentStateId", "-",
      function (item) {
        if (!item && (this.parent.primary_CurrentStreetAddress || this.parent.primary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentStateId",
              "A State is required for the Primary Legal Representative physical address."));
        }
        return true;
      }),
  primary_CurrentZipCode: Yup.string()
    .test("validate-primary_CurrentZipCode", "-",
      function (item) {
        if (!item && (this.parent.primary_CurrentStreetAddress || this.parent.primary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentZipCode",
              "A 5-digit or 9-digit numeric zip code is required for the Primary Legal Representative physical address."));
        } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentZipCode",
              "Invalid Primary Legal Representative Current Address Zip Code (XXXXX-XXXX)."));
        }
        return true;
      }),
  primary_CurrentCountyId: Yup.string()
    .test("validate-primary_CurrentCountyId", "-",
      function (item) {
        if (!item && (this.parent.primary_CurrentStreetAddress || this.parent.primary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_CurrentCountyId",
              "A county is required. If you do not know your county, select unknown. Please check this field in the Current Address."));
        }
        return true;
      }),
  primary_CurrentInternationalAddress: Yup.string()
    .test("validate-primary_CurrentInternationalAddress", "-",
      function (item) {
        if (this.parent.primary_HasCurrentInternationalAddress) {
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("primary_CurrentInternationalAddress",
                "The international current address for the primary legal representative is required when selected."));
          }
        }
        return true;
      }),
  primary_HomePhoneIsPrimary: Yup.boolean(),
  primary_HomePhoneNumber: Yup.string()
    .test("validate-primary_HomePhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("primary_HomePhoneNumber",
            "A telephone number must not being with 0 or 1. Please check the Home phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_HomePhoneNumber",
              "A telephone number must have 10-digits. Please check the Home phone section."));
        }
        return true;
      }),
  primary_MobilePhoneIsPrimary: Yup.boolean(),
  primary_MobilePhoneNumber: Yup.string()
    .test("validate-primary_MobilePhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("primary_MobilePhoneNumber",
            "A telephone number must not being with 0 or 1. Please check the Work phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_MobilePhoneNumber",
              "A telephone number must have 10-digits. Please check the Work phone section."));
        }
        return true;
      }),
  primary_WorkPhoneIsPrimary: Yup.boolean(),
  primary_WorkPhoneNumber: Yup.string()
    .test("validate-primary_WorkPhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("primary_WorkPhoneNumber",
            "A telephone number must not being with 0 or 1. Please check the Work phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_WorkPhoneNumber",
              "A telephone number must have 10-digits. Please check the Work phone section."));
        }
        return true;
      }),
  primary_EmailAddress: Yup.string()
    .test("validate-primary_EmailAddress", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("primary_EmailAddress",
              "The email provided is invalid. Enter your email address in the following format: xxx@xxx.xxx."));
        }
        return true;
      }),
  primary_PreferredLanguageId: Yup.string(),
  primary_PreferredLanguageOther: Yup.string()
    .test("validate-primary_PreferredLanguageOther", "-",
      function (item) {
        if (!item && this.parent.primary_PreferredLanguageId == "16") {
          return this.createError(
            Utility.buildYupErrorMessage("primary_PreferredLanguageOther",
              "A language must be entered for the other preferred languange not in the list."))
        }
        return true;
      }),
  hasSecondaryLegalRepresentative: Yup.string(),
  secondary_NoFirstName: Yup.boolean(),
  secondary_FirstName: Yup.string()
    .test("validate-secondary_FirstName", "-",
      function (item) {
        if (this.parent.hasSecondaryLegalRepresentative == "has-second-legal-representative") {
          const message = "The First Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
          if (!this.parent.secondary_NoFirstName && !item) {
            return this.createError(
              Utility.buildYupErrorMessage("secondary_FirstName", "First name for Secondary Legal Representative is required."));
          } else if (item) {
            if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
              return this.createError(
                Utility.buildYupErrorMessage("secondary_FirstName",
                  "At least 2 letters are necessary for First Name."));
            } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
              return this.createError(
                Utility.buildYupErrorMessage("secondary_FirstName", message));
            }
          }
        }
        return true;
      }),
  secondary_LastName: Yup.string()
    .test("validate-secondary_LastName", "-",
      function (item) {
        if (!item &&
          this.parent.hasSecondaryLegalRepresentative == "has-second-legal-representative") {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_LastName", "Last name for Secondary Legal Representative is required."));
        } else if (item && item.length < 2) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_LastName",
              "At least 2 letters are necessary for Last Name."));
        } else if (item && !new RegExp(/^[a-zA-Z][a-zA-Z-']{1,34}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_LastName", "The Last Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),

  secondary_MiddleName: Yup.string()
    .test("validate-secondary_MiddleName", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z]{0,1}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MiddleName", "The Middle Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),
  secondary_SuffixTypeId: Yup.string(),
  secondary_RepresentativeTypeId: Yup.string()
    .test("validate-secondary_RepresentativeTypeId", "-",
      function (item) {
        if (!item &&
          this.parent.hasSecondaryLegalRepresentative == "has-second-legal-representative") {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_RepresentativeTypeId",
              "Please specify the relationship with the applicant for the Secondary Legal Representative."));
        }
        return true;
      }),

  secondary_HasMailingInternationalAddress: Yup.boolean(),
  secondary_MailingStreetAddress: Yup.string()
    .test("validate-secondary_MailingStreetAddress", "-",
      function (item) {
        if (!item && (this.parent.secondary_MailingCity || this.parent.secondary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingStreetAddress",
              "A street address is required for the Secondary Legal Representative mailing address."));
        }
        return true;
      }),
  secondary_MailingCity: Yup.string()
    .test("validate-secondary_MailingCity", "-",
      function (item) {
        if (!item && (this.parent.secondary_MailingStreetAddress || this.parent.secondary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingCity",
              "A city is required for the Secondary Legal Representative mailing address."));
        }
        return true;
      }),
  secondary_MailingStateId: Yup.string()
    .test("validate-secondary_MailingStateId", "-",
      function (item) {
        if (!item && (this.parent.secondary_MailingStreetAddress || this.parent.secondary_MailingZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingStateId",
              "A State is required for the Secondary Legal Representative mailing address."));
        }
        return true;
      }),
  secondary_MailingZipCode: Yup.string()
    .test("validate-secondary_MailingZipCode", "-",
      function (item) {
        if (!item && (this.parent.secondary_MailingStreetAddress || this.parent.secondary_MailingCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingZipCode",
              "A 5-digit or 9-digit numeric zip code is required for the Secondary Legal Representative mailing address."));
        } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingZipCode",
              "Invalid Secondary Legal Representative Mailing Address Zip Code (XXXXX-XXXX)."));
        }
        return true;
      }),
  secondary_MailingCountyId: Yup.string()
    .test("validate-secondary_MailingCountyId", "-",
      function (item) {
        if (!item && (this.parent.secondary_MailingStreetAddress || this.parent.secondary_MailingCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingCountyId",
              "A county is required for the Secondary Legal Representative mailing address. If you do not know your county, select unknown."));
        }
        return true;
      }),
  secondary_MailingInternationalAddress: Yup.string()
    .test("validate-secondary_MailingInternationalAddress", "-",
      function (item) {
        if (!item && this.parent.secondary_HasMailingInternationalAddress) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MailingInternationalAddress",
              "The international mailing address for the Secondary legal representative is required when selected."));
        }
        return true;
      }),
  secondary_HasDifferentCurrentAddress: Yup.boolean(),
  secondary_HasCurrentInternationalAddress: Yup.boolean(),
  secondary_CurrentStreetAddress: Yup.string()
    .test("validate-secondary_CurrentStreetAddress", "-",
      function (item) {
        if (!item && this.parent.secondary_HasDifferentCurrentAddress &&
          !this.parent.secondary_HasCurrentInternationalAddress) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentStreetAddress",
              "A street address is required for the Secondary Legal Representative physical address."));
        } else if (!item && (this.parent.secondary_CurrentCity || this.parent.secondary_CurrentZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentStreetAddress",
              "A street address is required for the Secondary Legal Representative physical address."));
        }
        return true;
      }),
  secondary_CurrentCity: Yup.string()
    .test("validate-secondary_CurrentCity", "-",
      function (item) {
        if (!item && (this.parent.secondary_CurrentStreetAddress || this.parent.secondary_CurrentZipCode)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentCity",
              "A city is required for the Secondary Legal Representative physical address."));
        }
        return true;
      }),
  secondary_CurrentStateId: Yup.string()
    .test("validate-secondary_CurrentStateId", "-",
      function (item) {
        if (!item && (this.parent.secondary_CurrentStreetAddress || this.parent.secondary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentStateId",
              "A State is required for the Secondary Legal Representative physical address."));
        }
        return true;
      }),
  secondary_CurrentZipCode: Yup.string()
    .test("validate-secondary_CurrentZipCode", "-",
      function (item) {
        if (!item && (this.parent.secondary_CurrentStreetAddress || this.parent.secondary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentZipCode",
              "A 5-digit or 9-digit numeric zip code is required for the Secondary Legal Representative physical address."));
        } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentZipCode",
              "Invalid Secondary Legal Representative Current Address Zip Code (XXXXX-XXXX)."));
        }
        return true;
      }),
  secondary_CurrentCountyId: Yup.string()
    .test("validate-secondary_CurrentCountyId", "-",
      function (item) {
        if (!item && (this.parent.secondary_CurrentStreetAddress || this.parent.secondary_CurrentCity)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentCountyId",
              "A county is required. If you do not know your county, select unknown. Please check this field in the Current Address."));
        }
        return true;
      }),
  secondary_CurrentInternationalAddress: Yup.string()
    .test("validate-secondary_CurrentInternationalAddress", "-",
      function (item) {
        if (!item && this.parent.secondary_HasCurrentInternationalAddress) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_CurrentInternationalAddress",
              "The international current address for the Secondary Legal Representative is required when selected."));
        }
        return true;
      }),
  secondary_HomePhoneIsPrimary: Yup.boolean(),
  secondary_HomePhoneNumber: Yup.string()
    .test("validate-secondary_HomePhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("secondary_HomePhoneNumber",
            "A telephone number must not being with 0 or 1. Please check the Home phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_HomePhoneNumber",
              "A telephone number must have 10-digits. Please check the Home phone section."));
        }
        return true;
      }),
  secondary_MobilePhoneIsPrimary: Yup.boolean(),
  secondary_MobilePhoneNumber: Yup.string()
    .test("validate-secondary_MobilePhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MobilePhoneNumber",
              "A telephone number must not being with 0 or 1. Please check the Mobile phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_MobilePhoneNumber",
              "A telephone number must have 10-digits.Please check the Mobile phone section."));
        }
        return true;
      }),
  secondary_WorkPhoneIsPrimary: Yup.boolean(),
  secondary_WorkPhoneNumber: Yup.string()
    .test("validate-secondary_WorkPhoneNumber", "-",
      function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("secondary_WorkPhoneNumber",
            "A telephone number must not being with 0 or 1. Please check the Work phone section."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_WorkPhoneNumber",
              "A telephone number must have 10-digits. Please check the Work phone section."));
        }
        return true;
      }),
  secondary_EmailAddress: Yup.string()
    .test("validate-secondary_EmailAddress", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_EmailAddress",
              "The email provided is invalid. Enter your email address in the following format: xxx@xxx.xxx."));
        }
        return true;
      }),
  secondary_PreferredLanguageId: Yup.string(),
  secondary_PreferredLanguageOther: Yup.string()
    .test("validate-secondary_PreferredLanguageOther", "-",
      function (item) {
        if (!item && this.parent.secondary_PreferredLanguageId == "16") {
          return this.createError(
            Utility.buildYupErrorMessage("secondary_PreferredLanguageOther",
              "A language must be entered for the other preferred languange not in the list."))
        }
        return true;
      })
});
