import * as Yup from "yup";
import { IApplicantInformation } from "./applicant-information.store";
import { Utility } from "../../../shared-module/helpers/utility";

// validation schema
export const ApplicantInformationValidationSchema: Yup.ObjectSchema<IApplicantInformation> = Yup.object({
  _primaryPhone: Yup.boolean()
    .test("validate-_primaryPhone", "-", function (item) {
      if (
        (this.parent.applicantHomePhone ||
          this.parent.applicantMobilePhone ||
          this.parent.applicantWorkPhone) &&
        !this.parent.applicantHomePhoneIsPrimary &&
        !this.parent.applicantMobilePhoneIsPrimary &&
        !this.parent.applicantWorkPhoneIsPrimary) {
        return this.createError(
          Utility.buildYupErrorMessage("_primaryPhone",
            "A primary phone must be selected if a phone number is entered."))
      } else if ((this.parent.applicantHomePhoneIsPrimary && !this.parent.applicantHomePhone) ||
        (this.parent.applicantMobilePhoneIsPrimary && !this.parent.applicantMobilePhone) ||
        (this.parent.applicantWorkPhoneIsPrimary && !this.parent.applicantWorkPhone)) {
        return this.createError(
          Utility.buildYupErrorMessage("_primaryPhone",
            "Applicant phone number marked as primary can not be blank."));
      }
      return true;
    }),
  _atleastOneContact: Yup.boolean()
    .test("validate-_atleastOneContact", "-", function (item) {
      if (!this.parent.applicantMailingAddressLine &&
        !this.parent.applicantHomePhone &&
        !this.parent.applicantWorkPhone &&
        !this.parent.applicantMobilePhone &&
        !this.parent.applicantEmailAddress) {
        return this.createError(
          Utility.buildYupErrorMessage("_atleastOneContact",
            "A mailing address, phone number or email address is required."));
      }
      return true;
    }),
  livingArrangementTypeId: Yup.string(),
  applicantMailingAddressLine: Yup.string()
    .test("validate-applicantMailingAddressLine", "-", function (item) {
      if (!item && (this.parent.applicantMailingCity || this.parent.applicantMailingZipCode)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingAddressLine",
            "A street address is required. Please check this field in the Mailing Address."));
      }
      return true;
    }),
  applicantMailingCity: Yup.string()
    .test("validate-applicantMailingCity", "-", function (item) {
      if (!item && (this.parent.applicantMailingAddressLine || this.parent.applicantMailingZipCode)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingCity",
            "A city is required. Please check this field in the Mailing Address."));
      }
      return true;
    }),
  applicantMailingStateId: Yup.string()
    .test("validate-applicantMailingStateId", "-", function (item) {
      if (!item && (this.parent.applicantMailingAddressLine || this.parent.applicantMailingCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingStateId",
            "A State is required. Please check this field in the Mailing Address."));
      }
      return true;
    }),
  applicantMailingZipCode: Yup.string()
    .test("validate-applicantMailingZipCode", "-", function (item) {
      if (!item && (this.parent.applicantMailingAddressLine || this.parent.applicantMailingCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingZipCode",
            "A 5-digit or 9-digit numeric zip code is required.  Please check this field in the Mailing Address."));
      } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingZipCode",
            "Invalid Mailing Address Zip Code (XXXXX-XXXX)."));
      }
      return true;
    }),
  applicantMailingCountyId: Yup.string()
    .test("validate-applicantMailingCountyId", "-", function (item) {
      if (!item && (this.parent.applicantMailingAddressLine || this.parent.applicantMailingCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMailingCountyId",
            "A county is required. If you do not know your county, select unknown. Please check this field in the Mailing Address."));
      }
      return true;
    }),
  applicantHasDifferentCurrentAddress: Yup.boolean(),
  applicantCurrentAddressLine: Yup.string()
    .test("validate-applicantCurrentAddressLine", "-", function (item) {
      if (!item && this.parent.applicantHasDifferentCurrentAddress) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentAddressLine",
            "A street address is required. Please check this field in the Current Address."));
      } else if (!item && (this.parent.applicantCurrentCity || this.parent.applicantCurrentZipCode)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentAddressLine",
            "A street address is required. Please check this field in the Current Address."));
      }
      return true;
    }),
  applicantCurrentCity: Yup.string()
    .test("validate-applicantCurrentCity", "-", function (item) {
      if (!item && (this.parent.applicantCurrentAddressLine || this.parent.applicantCurrentZipCode)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentCity",
            "A city is required. Please check this field in the Current Address."));
      }
      return true;
    }),
  applicantCurrentStateId: Yup.string()
    .test("validate-applicantCurrentStateId", "-", function (item) {
      if (!item && (this.parent.applicantCurrentAddressLine || this.parent.applicantCurrentCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentStateId",
            "A State is required. Please check this field in the Current Address."));
      }
      return true;
    }),
  applicantCurrentZipCode: Yup.string()
    .test("validate-applicantCurrentZipCode", "-", function (item) {
      if (!item && (this.parent.applicantCurrentAddressLine || this.parent.applicantCurrentCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentZipCode",
            "A 5-digit or 9-digit numeric zip code is required.  Please check this field in the Current Address."));
      } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentZipCode",
            "Invalid Current Address Zip Code (XXXXX-XXXX)."));
      }
      return true;
    }),
  applicantCurrentCountyId: Yup.string()
    .test("validate-applicantCurrentCountyId", "-", function (item) {
      if (!item && (this.parent.applicantCurrentAddressLine || this.parent.applicantCurrentCity)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCurrentCountyId",
            "A county is required. If you do not know your county, select unknown. Please check this field in the Current Address."));
      }
      return true;
    }),
  applicantHomePhoneIsPrimary: Yup.boolean(),
  applicantHomePhone: Yup.string()
    .test("validate-applicantHomePhone", "-", 
      function (item) {
      if (item && new RegExp(/^[(][0-1]/).test(item)) {
        return this.createError(Utility.buildYupErrorMessage("applicantHomePhone",
          "A telephone number must not being with 0 or 1. Please check the Home phone section."));
      } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantHomePhone",
            "A telephone number must have 10-digits. Please check the Home phone section."));
      }
      return true;
    }),
  applicantMobilePhoneIsPrimary: Yup.boolean(),
  applicantMobilePhone: Yup.string()
    .test("validate-applicantMobilePhone", "-", 
      function (item) {
      if (item && new RegExp(/^[(][0-1]/).test(item)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMobilePhone",
            "A telephone number must not being with 0 or 1. Please check the Mobile phone section."));
      } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantMobilePhone",
            "A telephone number must have 10-digits.Please check the Mobile phone section."));
      }
      return true;
    }),
  applicantWorkPhoneIsPrimary: Yup.boolean(),
  applicantWorkPhone: Yup.string()
    .test("validate-applicantWorkPhone", "-", 
      function (item) {
      if (item && new RegExp(/^[(][0-1]/).test(item)) {
        return this.createError(Utility.buildYupErrorMessage("applicantWorkPhone",
          "A telephone number must not being with 0 or 1. Please check the Work phone section."));
      } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantWorkPhone",
            "A telephone number must have 10-digits. Please check the Work phone section."));
      }
      return true;
    }),
  applicantEmailAddress: Yup.string()
    .test("validate-applicantEmailAddress", "-", function (item) {
      if (item && !new RegExp(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/).test(item)) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantEmailAddress",
            "The email provided is invalid. Enter your email address in the following format: xxx@xxx.xxx."));
      }
      return true;
    }),
  diagnosisAge: Yup.string()
    .test("validate-diagnosisAge", "-", function (item) {
      if (!item) {
        return this.createError(
          Utility.buildYupErrorMessage("diagnosisAge",
            "The age of when applicant was first diagnosed is required."));
      } else if (!new RegExp(/^([0-9]{1,3})$/).test(item)) {
        return this.createError(
          Utility.buildYupErrorMessage("diagnosisAge",
            "The age of when applicant was first diagnosed must be entered as a zero or positive number."));
      } else if (Utility.stringToNumber(item, 0) >= 200) {
        return this.createError(
          Utility.buildYupErrorMessage("diagnosisAge",
            "The age of when applicant was first diagnosed must not be more than 199 years."));
      }
      return true;
    }),
  disabilityDescription: Yup.string()
    .test("validate-disabilityDescription", "-", function (item) {
      if (!item) {
        return this.createError(
          Utility.buildYupErrorMessage("disabilityDescription",
            "A brief description on how the applicant's disability affects daily life is required."));
      }
      return true;
    }),
});

