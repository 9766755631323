import { GatewayOapApi } from "../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class ApplicantResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getApplicantApplications(abortController?: AbortController)
  : Promise<GatewayOapApi.ApiMultipleResponseOfApplicationSummaryModel> {
  return super.getRequest(`api/v1/applicant/applications`, null, abortController) as
    Promise<GatewayOapApi.ApiMultipleResponseOfApplicationSummaryModel>;
  }  

  public getApplicantDocuments(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel> {
    return super.getRequest(`api/v1/applicant/documents`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel>;
  }  

  public getNbarApplicantApplications(abortController?: AbortController)
  : Promise<GatewayOapApi.ApiMultipleResponseOfNbarApplicationSummaryModel> {
  return super.getRequest(`api/v1/applicant/nbar/applications`, null, abortController) as
    Promise<GatewayOapApi.ApiMultipleResponseOfNbarApplicationSummaryModel>;
  }
  
  public getNbarApplicationDocuments(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel> {
    return super.getRequest(`api/v1/applicant/nbar/applicationdocuments`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfApplicationDocumentModel>;
  }  

}