import { Axios, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export interface HttpRequestInterceptor {
  InterceptRequest: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  InterceptRequestError: (error: any) => any;
  InterceptResponse: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  InterceptResponseError: (error: any) => any;
}

export interface IApiConfig {
  axios: Axios,
  baseUrl: string;
}

export interface ParamList {
  [name: string]: string | number | boolean
}

export class ApiBaseResource {
  
  constructor(private apiConfig: IApiConfig) {
  }

  public getRequest(url: string, getParams: (ParamList | null), abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'get', getParams, null, abortController, null, null);
  }

  public postRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'post', getParams, postData, abortController, null, null);
  }

  public postFileRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'post', getParams, postData, abortController, 'multipart/form-data', null);
  }

  public putRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'put', getParams, postData, abortController, null, null);
  }

  public deleteRequest(url: string, getParams: (ParamList | null), abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'delete', getParams, null, abortController, null, null);
  }

  public documentRequest(url: string, getParams: (ParamList | null), postData: any, abortController?: AbortController): Promise<any> {
    return this.httpRequest(url, 'get', getParams, null, abortController, null, 'blob');
  }

  public getApiUrl(url: string): string {
    return this.apiConfig.baseUrl + '/' + url;
  }

  private httpRequest(url: string, method: string, getParams: (ParamList | null), postData: any, abortController?: AbortController, contentType?: string, responseType?: any ): Promise<any> {
    // Do not comment this line below
    // console.log('httpRequest:', method + ':', url, 'params:', getParams, 'post:', postData, 'baseurl:', this.apiConfig.baseUrl, 'contentType:', contentType, 'responseType:', responseType);

    if((!contentType || contentType=='application/json')) {
      postData = JSON.stringify(postData);
    }
    
    return this.apiConfig.axios.request({
      url: url,
      method: method,
      headers: {
        'Accept': contentType || 'application/json',
        'Content-Type': contentType || 'application/json'
      },
      baseURL: this.apiConfig.baseUrl,
      params: getParams || {},
      data: postData || {},
      responseType: responseType || 'json',
      signal: abortController && abortController.signal
    })
    .then((response) => {
      if(responseType == "blob") {
        return response.data;
      } else {
        var jsonResponse = JSON.parse(response.data);
        return jsonResponse;
      }
    })
    .catch((err) => {
      console.log('catch error', err);
      throw err;
    });
    
  }
}
