import { action, makeObservable, observable } from "mobx";
import { IGatewayTransientStore } from "shared-module/models/IGatewayTransientStore";
import { DocumentTypeCodes } from "shared-module/models/common.models";
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";

export interface IDocumentUpload {
  applicationRefId: string;
  confirmationOfDiagnosisFile: File;
  authorizationForDisclosureFile: File;
  confirmationOfDiagnosisFileName: string;
  authorizationForDisclosureFileName: string;
  documentTypes: GatewayOapApi.DocumentTypeModel[];
  confirmationOfDiagnosisTypeId: number;
  authorizationForDisclosureTypeId: number;
};

class DocumentUploadStoreComponent implements IGatewayTransientStore<any, IDocumentUpload>,
  IDocumentUpload {
  public applicationRefId: string;
  public confirmationOfDiagnosisFile: File;
  public authorizationForDisclosureFile: File;
  public confirmationOfDiagnosisFileName: string;
  public authorizationForDisclosureFileName: string;
  public documentTypes: GatewayOapApi.DocumentTypeModel[];
  public confirmationOfDiagnosisTypeId: number;
  public authorizationForDisclosureTypeId: number;

  constructor() {
    this.applicationRefId = "";
    this.confirmationOfDiagnosisFile = null;
    this.authorizationForDisclosureFile = null;
    this.confirmationOfDiagnosisFileName = "";
    this.authorizationForDisclosureFileName = "";
    this.documentTypes = null;
    this.confirmationOfDiagnosisTypeId = null;
    this.authorizationForDisclosureTypeId = null;

    makeObservable(this, {
      applicationRefId: observable,
      setApplicationRefId: action,
      getApplicationRefId: action,

      confirmationOfDiagnosisFile: observable,
      setConfirmationOfDiagnosisFile: action,
      getConfirmationOfDiagnosisFile: action,

      authorizationForDisclosureFile: observable,
      setAuthorizationForDisclosureFile: action,
      getAuthorizationForDisclosureFile: action,

      confirmationOfDiagnosisFileName: observable,
      getConfirmationOfDiagnosisFileName: action,
      setConfirmationOfDiagnosisFileName: action,

      authorizationForDisclosureFileName: observable,
      getAuthorizationForDisclosureFileName: action,
      setAuthorizationForDisclosureFileName: action,

      documentTypes: observable,
      setDocumentTypes: action,

      confirmationOfDiagnosisTypeId: observable,
      getConfirmationOfDiagnosisTypeId: action,

      authorizationForDisclosureTypeId: observable,
      getAuthorizationForDisclosureTypeId: action,

    })
  }

  public setApplicationRefId(value: string) {
    this.applicationRefId = value;
  }
  public getApplicationRefId() {
    return this.applicationRefId;
  }

  public setDocumentTypes(value: GatewayOapApi.DocumentTypeModel[]) {
    this.documentTypes = value;
  }

  public getConfirmationOfDiagnosisTypeId() {
    return this.documentTypes?.filter((type) => {
      return type.documentTypeCode == DocumentTypeCodes.COD.toUpperCase()
    })[0].documentTypeId;
  }

  public getAuthorizationForDisclosureTypeId() {
    return this.documentTypes?.filter((type) => {
      return type.documentTypeCode.toUpperCase() == DocumentTypeCodes.AFD.toUpperCase()
    })[0].documentTypeId;
  }

  public setConfirmationOfDiagnosisFile(value: File) {
    this.confirmationOfDiagnosisFile = value;
  }
  public getConfirmationOfDiagnosisFile() {
    return this.confirmationOfDiagnosisFile;
  }

  public setConfirmationOfDiagnosisFileName(value: string) {
    this.confirmationOfDiagnosisFileName = value;
  }

  public getConfirmationOfDiagnosisFileName() {
    return this.confirmationOfDiagnosisFileName;
  }

  public setAuthorizationForDisclosureFile(value: File) {
    this.authorizationForDisclosureFile = value;
  }

  public getAuthorizationForDisclosureFile() {
    return this.authorizationForDisclosureFile;
  }

  public setAuthorizationForDisclosureFileName(value: string) {
    this.authorizationForDisclosureFileName = value;
  }
  public getAuthorizationForDisclosureFileName() {
    return this.authorizationForDisclosureFile;
  }

  public reset(): void {
    this.setConfirmationOfDiagnosisFile(null);
    this.setAuthorizationForDisclosureFile(null);
    this.setConfirmationOfDiagnosisFileName(null);
    this.setAuthorizationForDisclosureFileName(null);
  }

  public refresh(document: any): void {
    var state = this.buildState(document);
    this.setConfirmationOfDiagnosisFileName(state.confirmationOfDiagnosisFileName);
    this.setAuthorizationForDisclosureFileName(state.authorizationForDisclosureFileName);
  }

  public buildState(document: any): IDocumentUpload {
    const codFile = document.filter(obj => {
      return obj.documentTypeId == this.getConfirmationOfDiagnosisTypeId()
    });
    const afdFile = document.filter(obj => {
      return obj.documentTypeId == this.getAuthorizationForDisclosureTypeId()
    });

    return {
      applicationRefId: this.getApplicationRefId(),
      authorizationForDisclosureFile: document.authorizationForDisclosureFile,
      confirmationOfDiagnosisFile: document.confirmationOfDiagnosisFile,
      confirmationOfDiagnosisFileName: codFile[0]?.documentName || null,
      authorizationForDisclosureFileName: afdFile[0]?.documentName || null,
      documentTypes: document.documentTypes || null,
      confirmationOfDiagnosisTypeId: document.confirmationOfDiagnosisTypeId || null,
      authorizationForDisclosureTypeId: document.authorizationForDisclosureTypeId || null,
    }
  }
}

export const DocumentUploadStore = new DocumentUploadStoreComponent();