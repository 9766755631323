import { useEffect, useRef, useState } from "react";
import { AppSettings } from "../../../../app-setting";
import { Card } from "../../../../shared-module/components/card/card.component";
import { AccessIndianaNbarCard } from "../../access-indiana/access-indiana-nbar-card/access-indiana-nbar-card.component";
import * as bootstrap from "bootstrap";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { Link, useNavigate } from "react-router-dom";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import BdsCheckbox from "../../../../shared-module/components/checkbox/bds-checkbox.component";
import $ from "jquery";
import "../../../../assets/scripts/print-this";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { AppStateService } from "../../../services/app-state-service";
import { SessionService } from "shared-module/services/session.service";
import blankNbarDoc from '../../../../assets/documents/NBAR_2024.doc';


function NBarLanding() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<GatewayOapApi.UserModel>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const tooltipRef = useRef();
  const [confirmed, setConfirmed] = useState(false);

  function clearSessionValues(): Promise<void> {
    console.log('clear session values: ', sessionStorage);
    sessionStorage.removeItem("applicationTimeout");
    sessionStorage.removeItem("isGuestUser");
    sessionStorage.removeItem("guestSessionId");
    sessionStorage.removeItem("fromNbar");
    return Promise.resolve();
  };

  function handleConfirmation(event : React.ChangeEvent<HTMLInputElement>) {
    setConfirmed(!confirmed);
    return;
  };

  useEffect(() => {
    if(sessionStorage.getItem("applicationTimeout")) {
      Utility.alert("Application Timeout", 'Your browser was redirected due to inactivity during your last session. Registered users may continue editing the application from the dashboard; guest applicants will need to begin a new application.', clearSessionValues)
    }

    bindPrintButton();

    const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipRef.current, {
      boundary: document.body,
      container: '[data-bs-toggle="tooltip"]',
      trigger: 'hover'
    });

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([
      SessionService.isAuthenticated(),
      SessionService.getProfile()
    ])
      .then((response) => {
        setIsAuthenticated(response[0]);
        setUser(response[1]);
        return Promise.resolve(true);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

    return () => {
      tooltip.dispose();
    }

  }, []);

  function handleLogout() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  function handleStartApplication() {
    if (confirmed) {
      AppStateService.setShowGuestApplicationDisclaimer(true);
      NavigationHelper.gotoNbarInstructions(navigate)
    }
  }

  function bindPrintButton() {
    $("#print-instructions").on('click', function () {
      ($("#instructions") as any).printThis({
        debug: false,
        importCSS: false,
        header: "<h3>" + AppSettings.bdsAcronym + " Gateway Instructions</h3>"
      });
      return false;

    });
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-8 order-2 order-md-0">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button" onClick={() => handleLogout()}
              className="btn bds-btn-primary">Logout</button>
          </ShowThen>
        </div>
        <div className="col-12 col-md-2 text-start text-md-center order-1 order-md-1 mt-2 mt-md-0">
        </div>
        <div className="col-12 col-md-2 text-start text-md-end order-0 order-md-2">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button"
              onClick={() => NavigationHelper.gotoDashboard(navigate)}
              className="btn bds-btn-primary">My Dashboard</button>
          </ShowThen>
        </div>
      </div>

      <div id="instructions" className="mt-4">
        <style>
          {"@media print { .no-print, .no-print * { display: none !important; } } body { font-size: 14px; }"}
        </style>
        <Card>
          <ShowThen when={!isLoading && isAuthenticated}>
            <div className="row mb-4">
              <span className="bds-welcome-heading">Welcome, { user && (user.firstName + " " + user.lastName) }</span>
            </div>
          </ShowThen>
          <div>
            <p>
            Welcome to the online Needs Based Access Request (NBAR) application for services available through The {" "}{AppSettings.bdsName} ({AppSettings.bdsAcronym}).
            </p>
            <p>
              If you would rather download a copy of the form to fill out and submit it via mail or to your local BDS district office, 
              please click here: <Link to={blankNbarDoc} className="bds-anchor" download="NBAR_template.doc" target="_blank">NBAR Application Form</Link>.
            </p>
            <p>
              Please note, you will not be able to save your progress and finish later unless you login or create an account with Access Indiana using the buttons below. 
              You may continue with the application as a guest, however if you stop working on your application for more than 15 minutes or do not submit the completed application then the system will timeout and you will need to start a new application. 
              Please ensure that you have all the required information prior to beginning the application.
            </p>
            <div className="no-print">
              <ShowThen when={!isLoading && !isAuthenticated}>
                <AccessIndianaNbarCard></AccessIndianaNbarCard>
              </ShowThen>
            </div>
            <p>
              To complete the online application the following information is
              required:
            </p>
            <ul className="bds-item-list">
              <li>Social Security Number or ITIN</li>
              <li>Applicant's full name</li>
              <li>RID number (if applicable)</li>
              <li>Date of birth</li>
              <li>Applicant's current physical address</li>
              <li>Applicant's current telephone number</li>
              <li>Guardian name(s) (if applicable)</li>
            </ul>

            <p>Upon completion, please take a moment to review all answers for accuracy prior to submission. 
              Upon submission, you will have the opportunity at that time to download a copy of the submitted application for your records. 
              Once submitted, you will receive a message that the application was successfully submitted.
            </p>

            <p>
              If at any time you need assistance you may contact your
              {" "}{AppSettings.bdsAcronym}{" "} district office. To find your district
              office,{" "}
              <a className="bds-anchor"
                href={AppSettings.Link_DistrictOffices}
                target="_blank"
              >
                go here
              </a>
              .
            </p>
          </div>
        </Card>
      </div>

      <div className="mt-4">
        <Card>
          <div className="row">
            <div className="col-12">
              <BdsCheckbox
                id="read-confirm"
                name="readConfirm"
                label="By checking this box, you are confirming that you have read the instructions."
                onChange={(event) => handleConfirmation(event)}
                checked={false}
              ></BdsCheckbox>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
              <button type="button" id="print-instructions" className="btn bds-btn-primary">
                Print the Instructions
              </button>
            </div>
            <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
              <span data-bs-toggle="tooltip" ref={tooltipRef} data-bs-offset="0,15"
                data-bs-title="Please signify that you have read the instructions before continuing.">
                <button type="button" disabled={!confirmed}
                  onClick={() => handleStartApplication()}
                  id="start-application" className="btn bds-btn-primary">
                    <ShowThen when={isAuthenticated}>
                      Start a New Application
                    </ShowThen>
                    <ShowThen when={!isAuthenticated}>
                      Start Application as Guest
                    </ShowThen>
                </button>
              </span>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default NBarLanding
