import { useRef, useState } from "react";
import { ShowThen } from "../show-then/show-then.component";
import { auto } from "@popperjs/core";
import { DisplayNone } from "../display-none/display-none.component";

interface Props {
  data: any;
  saved?: any;
  touched?: any;
  errors?: { key: string, message: string }[]
}

function DebugContainerComponent(props: Props) {
  var debug: boolean = false;
  var ref = useRef(null);
  var [flipped, setFlipped] = useState(true);

  function toggle() {
    setFlipped(!flipped);
  }

  function getDebug(): boolean {
    var show = document.querySelector("[name=symbols][content]").getAttribute("content");
    return show && show == "yes";
  }

  return (
    <>
      <ShowThen when={getDebug() || debug}>
        <div className="fixed-top text-wrap container" style={{ backgroundColor: "#ffffff" }}>
          <button type="button" onClick={(e) => toggle()}>Toggle</button>
          <DisplayNone when={flipped}>
            <div className="mt-2" style={{ fontSize: ".8rem", overflow: "auto", maxHeight: "500px" }} ref={ref}>
              <div className="fw-bold">Current State</div>
              <div className="text-break">{JSON.stringify(props.data)}</div>
              <div className="mt-3 fw-bold">Saved State</div>
              <div className="text-break">{JSON.stringify(props.saved)}</div>
              <div className="mt-3 fw-bold">Touched State</div>
              <div className="text-break">{JSON.stringify(props.touched)}</div>
              <div className="mt-3 fw-bold">Errors</div>
              <ul>
                {
                  props.errors && props.errors.map((item, index) => (
                    <li key={index} style={{ color: '#ff0000' }}>{item.message}</li>
                  ))
                }
              </ul>
            </div>
          </DisplayNone>
        </div>
      </ShowThen>
    </>
  )
}

export const DebugContainer = DebugContainerComponent;