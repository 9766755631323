import React, { FocusEvent, FocusEventHandler, createRef, useId } from "react";
import { BDS } from "../../events/bds-events.module";

interface GroupButtonItem {
  content: React.ReactNode;
  itemValue: string;
}

interface Props {
  onChange?: (event: BDS.OnChangeEvent) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  name: string;
  value: string;
  items: GroupButtonItem[];
  children?: React.ReactNode;
}

function GroupButtonsComponent({ items, name, value, onChange, onBlur, children }: Props) {
  var ref01 = createRef<HTMLInputElement>();
  var ref02 = createRef<HTMLInputElement>();
  var id1 = useId();
  var id2 = useId();

  if (!items || items.length != 2) {
    return <>Invalid number of items (Allowed items: 2). </>
  }

  function handleOnChange(event: any) {
    if (ref01.current != null && ref02.current != null) {
      var checkedItem = ref01.current.checked ? ref01.current : ref02.current.checked ? ref02.current : null;
      if (checkedItem) {
        value = checkedItem.value;
        if (onChange) {
          onChange(new BDS.OnChangeEvent(name, value));
        }
      }
    }
  }

  function handleOnBlur(event: FocusEvent<HTMLInputElement>): void {
    if (onBlur) {
      onBlur(event);
    }
  }

  return (
    <>
      <div id={name} onBlur={handleOnBlur} tabIndex={0} className="bds-button-group row bds-outline-none">
        <div className="col-12 col-md-6">
          <input type="radio" id={id1} className="bds-btn-radio" name={name}
            ref={ref01} checked={value == items[0].itemValue}
            onChange={handleOnChange} value={items[0].itemValue} />
          <label tabIndex={0} htmlFor={id1}>
            <div className="btn bds-btn-primary">{items[0].content}</div>
          </label>
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <input type="radio" id={id2} className="bds-btn-radio" name={name}
            ref={ref02} checked={value == items[1].itemValue}
            onChange={handleOnChange} value={items[1].itemValue} />
          <label tabIndex={0} htmlFor={id2}>
            <div className="btn bds-btn-primary">{items[1].content}</div>
          </label>
        </div>
      </div>
    </>
  )
}

export const GroupButtons = GroupButtonsComponent;