import { ReactNode, useEffect, useState } from "react";
import { SessionService } from "shared-module/services/session.service";

interface Props {
  children?: ReactNode;
}

function IsNotAuthenticatedComponent({ children }: Props) {
  const [isNotAuthenticated, setNotAuthenticated] = useState<boolean>(false);

  async function evaluateAuthenticationAsync() {
    return SessionService.isAuthenticated()
      .then((authenticated) => {
        var notAuthenticated = !authenticated;
        setNotAuthenticated(notAuthenticated);
        return notAuthenticated;
      });
  }

  useEffect(() => {
    var func = async () => await evaluateAuthenticationAsync();
    func();
  }, [])

  return (
    <>
      {isNotAuthenticated && children}
    </>
  )
}


export const IsNotAuthenticated = IsNotAuthenticatedComponent;