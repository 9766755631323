import * as Yup from "yup";
import { IApplicantSignature } from "./applicant-signature.store";
import { Utility } from "../../../shared-module/helpers/utility";

// validation schema
export const ApplicantSignatureValidationSchema: Yup.ObjectSchema<IApplicantSignature> = Yup.object({
  applicantSignatureSeed: Yup.string(),
  legalRepresentativeSignatureSeed: Yup.string(),
  hasLegalRepresentative: Yup.boolean(),
  legalRepresentativeCertified: Yup.boolean()
    .test("validate-legalRepresentativeCertified", "-", function (item) {
      if (!item && this.parent.hasLegalRepresentative) {
        return this.createError(
          Utility.buildYupErrorMessage("legalRepresentativeCertified",
            "legalRepresentativeCertified field must be true"));
      }
      return true;
    }),
  applicantCertified: Yup.boolean()
    .test("validate-applicantCertified", "-", function (item) {
      if (!item && !this.parent.hasLegalRepresentative) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCertified",
            "applicantCertified field must be true"));
      }
      return true;
    })
});