interface EventListener {
    event: string,
    callback: any
  }
  
  class FilePreviewModalEventBus {
    private static instance: FilePreviewModalEventBus;
    private listeners: EventListener[] = [];
  
    private constructor() {
    }
  
    public static getInstance() {
      if (!this.instance) {
        this.instance = new FilePreviewModalEventBus();
      }
      return this.instance;
    }
  
    public on(event, callback) {
      var listener = {
        event: event,
        callback: callback
      } as EventListener;
  
      this.listeners.push(listener);
      document.addEventListener(event, (e) => callback(e.detail));
      console.log(event, ': FilePreviewModal listener added');
    }
  
    public dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
    }
  
    public remove(event) {
      var listener = this.listeners.find((item) => {
        return item.event == event;
      });
  
      if (listener) {
        document.removeEventListener(listener.event, listener.callback);
        console.debug(event, ': FilePreviewModal listener removed');
      }
  
      console.debug(event, ': FilePreviewModal listener not found - not removed');
    }
  
  };
  
  export default FilePreviewModalEventBus.getInstance();