export interface ModalOptions {
  show: <T>(params: T | void) => void;
}

export enum DocumentTypeCodes {
  COD = "COD",
  AFD = "AFD",
  NBG = "NBG"
}

export enum ApplicationStatusCodes {
  SUBMITTED = "SUBMITTED",
  EXPIRED = "EXPIRED",
  DRAFT = "DRAFT"
}

export enum ApplicationTypeNames{
  BDS = "BDS",
  NBAR = "NBAR"
}


export enum GatewayErrorCodes {
  NETWORK_ERROR = "ERR_NETWORK"
}

export class GatewayError {
  supressError: boolean;
  errorMessage: any;
}

export class GatewayNetworkError 
  extends GatewayError {
}

export class ApplicationExpirationError 
  extends GatewayError {
    constructor(errorMessage) {
      super();
      this.supressError = false;
      this.errorMessage = errorMessage;
    }
}

