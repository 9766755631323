import { Modal } from "bootstrap";
import { useEffect, useState } from "react";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";

interface Props {
  id: string,
  accessor: (AddressCorrectionModalOptions) => void
}

export interface AddressCorrectionModalOptions {
  show: (setup: AddressCorrectionModalSetup) => void,
  hide: (onHidden?: () => void) => void,
}

export interface AddressCorrectionModalSetup {
  isVerified: boolean,
  onCancel: () => Promise<void>,
  onAcceptUspsAddress: () => Promise<void>,
  onUseCurrentAddress: () => Promise<void>,
  verifiedAddress: GatewayOapApi.VerifiedAddressModel
}

function AddressCorrectionModalComponent(props: Props) {
  const [modalSetup, setModalSetup] = useState<AddressCorrectionModalSetup>({} as AddressCorrectionModalSetup);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    props.accessor({
      show: function (setup: AddressCorrectionModalSetup) {
        setModalSetup(setup);
        var element = document.getElementById(props.id);
        if (element) {
          var modal = Modal.getOrCreateInstance(element, {
            keyboard: false
          });
          modal.show();
        }
      },
      hide: function (onHidden?: () => void) {
        var element = document.getElementById(props.id);
        if (element) {
          var modal = Modal.getOrCreateInstance(element);
          modal.hide();
          element.addEventListener('hidden.bs.modal', event => {
            if (onHidden) {
              onHidden();
            }
          }, { once: true });
        }
      },
    });
  }, [])

  function handleCancel() {
    setIsSaving(true);
    if (modalSetup.onCancel) {
      modalSetup.onCancel()
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  function handleAcceptUspsAddress() {
    setIsSaving(true);
    if (modalSetup.onAcceptUspsAddress) {
      modalSetup.onAcceptUspsAddress()
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  function handleUseCurrent() {
    setIsSaving(true);
    if (modalSetup.onUseCurrentAddress) {
      modalSetup.onUseCurrentAddress()
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  function renderVerifiedAddress(record: GatewayOapApi.VerifiedAddressModel): any {
    var originalAddress = record?.originalAddress;
    var verifiedAddress = record?.verifiedAddress;

    return (<>
      <div>
        <ShowThen when={record?.isMatched}>
          <div>{record?.validationMessage}</div>
          <div className="mt-2 fw-bold">{verifiedAddress?.streetAddress1}</div>
          <div className="fw-bold">{verifiedAddress?.city}, {verifiedAddress?.state}</div>
          <div className="fw-bold">{verifiedAddress?.zipCode}</div>
        </ShowThen>
        <ShowThen when={!record?.isMatched}>
          <div>{record?.validationMessage}</div>
          <div className="mt-2 fw-bold">{originalAddress?.streetAddress1}</div>
          <div className="fw-bold">{originalAddress?.city}, {originalAddress?.state}</div>
          <div className="fw-bold">{originalAddress?.zipCode}</div>
        </ShowThen>
      </div>
    </>);
  }

  function getButtonText(found: boolean) {
    return found ? "ACCEPT" : "CANCEL";
  }

  return (
    <>
      <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">USPS Address Validation</h5>
              <button type="button" className="btn-close" onClick={handleCancel} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>{renderVerifiedAddress(modalSetup.verifiedAddress)}</div>
              <p>
                <ShowThen when={modalSetup.isVerified}>
                  Do you want to use this address as your address? Click ‘" {getButtonText(modalSetup.isVerified)} "’
                  to use this address. Click ‘USE MY ADDRESS’ to use the one that you entered.
                </ShowThen>
                <ShowThen when={!modalSetup.isVerified}>
                  Do you want to use this address as your address? Click ‘" {getButtonText(modalSetup.isVerified)} "’
                  to edit this address. Click ‘USE MY ADDRESS’ to use the one that you entered.
                </ShowThen>
              </p>
            </div>
            <div className="modal-footer">
              <ShowThen when={isSaving}>
                <div className="spinner-border text-secondary me-3" role="status">
                  <span className="visually-hidden">Saving...</span>
                </div>
              </ShowThen>
              <ShowThen when={modalSetup.isVerified}>
                <button disabled={isSaving} type="button" className="btn btn-primary" onClick={handleAcceptUspsAddress}>{getButtonText(modalSetup.isVerified)}</button>
              </ShowThen>
              <ShowThen when={!modalSetup.isVerified}>
                <button disabled={isSaving} type="button" className="btn btn-primary" onClick={handleCancel}>{getButtonText(modalSetup.isVerified)}</button>
              </ShowThen>
              <button disabled={isSaving} type="button" className="btn btn-secondary" onClick={handleUseCurrent}>USE MY ADDRESS</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const AddressCorrectionModal = AddressCorrectionModalComponent