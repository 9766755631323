import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarDocumentResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getNbarGuardianshipDocumentUrl(nbarApplicationRefId: string): string {
    return super.getApiUrl(`api/v1/nbar/document/${nbarApplicationRefId}/guardianship`);
  }

  public uploadNbarGuardianshipDocument(nbarApplicationRefId: string, file: File,
    abortController?: AbortController): Promise<boolean> {
    const formData = new FormData();
    formData.append('nbarGuardianshipFile', file);
    return super.postFileRequest(`api/v1/nbar/document/${nbarApplicationRefId}/guardianship`,
      null, formData, abortController);
  }

  public saveStagedApplicationDocuments(nbarApplicationRefId: string, file: File, documentType: number, abortController?: AbortController): Promise<boolean> {
    const formData = new FormData();
    formData.append('Document', file);
    formData.append('DocumentType', documentType.toString());
    return super.postFileRequest(`api/v1/nbar/document/${nbarApplicationRefId}/stage-application-document`, null, formData, abortController) as Promise<boolean>;
  }

  public getStagedApplicationDocuments(nbarApplicationRefId: string, abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfStagedDocumentModel> {
    console.log(nbarApplicationRefId);
    return super.getRequest(`api/v1/nbar/document/${nbarApplicationRefId}/get-staged-application-documents`, null, abortController) as Promise<GatewayOapApi.ApiMultipleResponseOfStagedDocumentModel>;
  }

  public removeStagedApplicationDocuments(nbarApplicationRefId: string, documentType: number, abortController?: AbortController): Promise<boolean> {
    console.log('removeStagedApplicationDocuments: ', documentType);
    return super.deleteRequest(`api/v1/nbar/document/${nbarApplicationRefId}/unstage-application-document/${documentType}`, null, null);
  }
}

