import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarApplicationResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getApplication(nbarApplicationRefId: string, abortController?: AbortController)
  : Promise<GatewayOapApi.ApiSingleResponseOfNbarApplicationModel> {
    return super.getRequest(`api/v1/nbar/applications/${nbarApplicationRefId}`, null, abortController) as
      Promise<GatewayOapApi.ApiSingleResponseOfNbarApplicationModel>;
  }

  public getDuplicateApplicationInfo(isItin: boolean, ssnOrItin: string, abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfDuplicateApplicationModel> {
    return super.getRequest(`api/v1/nbar/applications/get-duplicate-application-info`, {
      isItin: isItin,
      ssnOrItin: ssnOrItin
    }, abortController) as
      Promise<GatewayOapApi.ApiSingleResponseOfDuplicateApplicationModel>;
  }
  
  public createApplication(isItin: boolean, ssnOrItin: string, abortController?: AbortController) 
  : Promise<GatewayOapApi.ApiGuidResponse> {
    return super.postRequest(`api/v1/nbar/applications/create`,
      null, 
      {
        isItin: isItin,
        ssnOrItin: ssnOrItin
      }, 
      abortController
    ) as 
      Promise<GatewayOapApi.ApiGuidResponse>;
  }

  public saveNbarApplicationInformation(nbarApplicationRefId: string, firstName: string, middleName: string, lastName: string, suffixTypeId: number,
    dateOfBirth: string, streetAddress1: string, city: string, state: number, zipCode: string, homePhoneNumber: string, alternatePhoneNumber: string, 
    applicationReasonDescription: string, additionalInformation: string, legalRepresentativeFullName: string, abortController?: AbortController)
      : Promise<GatewayOapApi.ApiIntegerResponse>
  {
      console.log('application save hit with ref id');
      return super.postRequest(`api/v1/nbar/applications/${nbarApplicationRefId}/save`, 
      null, 
      {
        firstName,
        middleName,
        lastName,
        suffixTypeId,
        dateOfBirth,
        streetAddress1,
        city,
        state,
        zipCode,
        homePhoneNumber,
        alternatePhoneNumber,
        applicationReasonDescription,
        additionalInformation,
        legalRepresentativeFullName,
      },
      abortController)
  }

  public submitNbarApplication(nbarApplicationRefId: string, applicantCertified: boolean, signature: string, signatureOn: string, printWithSubmit: boolean, legalRepCertified: boolean, legalRepSignature: string, legalRepSignatureOn: string, abortController?: AbortController) {
    console.log('nbar-application.resource.submitNbarApplication: ', nbarApplicationRefId);
    
    return super.postRequest(`api/v1/nbar/applications/${nbarApplicationRefId}/submit`,
      null,
      {
        nbarApplicationRefId, applicantCertified, signature, signatureOn, printWithSubmit, legalRepCertified, legalRepSignature, legalRepSignatureOn
      },
      abortController
    ) as 
    Promise<GatewayOapApi.ApiBooleanResponse>;
  }

  public printSubmittedNbarApplication(nbarApplicationRefId: string, abortController?: AbortController): Promise<string> {
    return super.documentRequest(`api/v1/nbar/document/${nbarApplicationRefId}/print`,
      null,
      null,
      abortController) as
      Promise<string>;
  }

  public getBlankNbarDocument(abortController?: AbortController): Promise<GatewayOapApi.DocumentTypeModel> {
    return super.documentRequest('api/v1/nbar/document/blank-application', null, null, abortController) as Promise<GatewayOapApi.DocumentTypeModel>
  }

  public removeNbarApplication(nbarApplicationRefId: string, abortController?: AbortController) {
    return super.deleteRequest(`api/v1/nbar/applications/${nbarApplicationRefId}`,
      null,
      abortController) as
      Promise<string>;
  }

  public getNbarApplicationDraftUrl(nbarApplicationRefId: string): string {
    return super.getApiUrl(`api/v1/nbar/document/${nbarApplicationRefId}/print`);
  }
}