interface Props {
  data: any;
  saved?: any;
  touched?: any;
  errors?: { key: string, message: string }[]
}

function ErrorContainerComponent(props: Props) {

  function isTouched(key: string) {
    return props.touched[key] === true;
  }

  return (
    <>
      <ul className="mt-4">
        {
          props.errors && props.errors.map((item, index) => (
            isTouched(item.key) && <li key={index} style={{ color: '#ff0000' }}>{item.message}</li>
          ))
        }
      </ul>
    </>
  )
}

export const ErrorContainer = ErrorContainerComponent;