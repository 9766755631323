export const AppSettings = {
  environment: process.env.REACT_APP_SETTINGS_ENVIRONMENT,
  apiUrl: process.env.REACT_APP_SETTINGS_API_URL,
  bdsName: "Bureau of Disabilities Services",
  bdsAcronym: "BDS",
  Link_DistrictOffices: "https://www.in.gov/fssa/ddrs/files/BDS-District-Offices.pdf",
  Link_DDRSLanding: "https://www.in.gov/fssa/ddrs/developmental-disability-services/",
  Link_DDRSForms: "https://www.in.gov/fssa/ddrs/information-for-consumers/forms/",
  activityPulseInterval: 10000,
  fatalErrorBannerThreshold: 30000,
  autoLogoutNotificationWindowInSeconds: 120,
  maxUploadFileSize: 40000000,
  validUploadFileFormats: [".bmp",".jpg",".jpeg",".gif",".png",".tif",".tiff",".pdf",".doc",".docx"],
  ageDateRangeRestriction: 130
}