import { ReactNode, useEffect, useState } from "react";
import { SessionService } from "shared-module/services/session.service";

interface Props {
  children?: ReactNode;
}

function IsAuthenticatedComponent({ children }: Props) {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  async function evaluateAuthenticationAsync() {
    return SessionService.isAuthenticated()
      .then((authenticated) => {
        setAuthenticated(authenticated);
        return authenticated;
      });
  }

  useEffect(() => {
    var func = async () => await evaluateAuthenticationAsync();
    func();
  }, [])

  return (
    <>
      {isAuthenticated && children}
    </>
  )
}


export const IsAuthenticated = IsAuthenticatedComponent;