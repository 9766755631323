import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { IGatewayTransientStore } from "../../../../shared-module/models/IGatewayTransientStore";

export interface ICreateApplication {
  ssnType: string,
  ssn: string
};

class CreateApplicationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.ApplicationModel, ICreateApplication>,
  ICreateApplication {
    
  public ssnType: string;
  public ssn: string;

  constructor() {
    this.ssnType = "";
    this.ssn = "";

    makeObservable(this, {
      ssnType: observable,
      ssn: observable,

      setSsnType: action,
      setSsn: action,
      refresh: action
    });
  }

  public setSsnType(value: string) {
    this.ssnType = value;
  }

  public setSsn(value: string) {
    this.ssn = value;
  }

  public refresh(app: GatewayOapApi.ApplicationModel): boolean {
    var state = this.buildState(app);
    this.setSsnType(state.ssnType);
    this.setSsn(state.ssn);
    return true;
  }

  public buildState(app: GatewayOapApi.ApplicationModel): ICreateApplication {
    return {
      ssnType: app.isItin ? "ITIN" : "SSN",
      ssn: app.ssn
    }
  }

}

export const CreateApplicationStore = new CreateApplicationStoreComponent();