import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";
import applicantDashboardComponent from "app-module/components/applicant-dashboard/applicant-dashboard.component";

export class SystemResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public checkActivityPulse(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiSingleResponseOfUserActivityModel> {
    return super.getRequest(`api/v1/system/get-activity`, null,
      abortController) as Promise<GatewayOapApi.ApiSingleResponseOfUserActivityModel>;
  }

  public checkGuestActivityPulse(guestSessionId: Number, abortController?: AbortController)
  : Promise<GatewayOapApi.ApiSingleResponseOfGuestActivityModel> {
  return super.postRequest(`api/v1/system/get-guest-activity`, null,
    {
      GuestSessionId: guestSessionId,
    },
    abortController) as Promise<GatewayOapApi.ApiSingleResponseOfUserActivityModel>;
  }

  public getCookie(abortController?: AbortController)
    : Promise<GatewayOapApi.ApiStringResponse> {
    return super.getRequest(`api/v1/system/cookie`, null,
      abortController) as Promise<GatewayOapApi.ApiStringResponse>;
  }
}