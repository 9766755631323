import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { IGatewayTransientStore } from "../../../shared-module/models/IGatewayTransientStore";
import { Utility } from "../../../shared-module/helpers/utility";


export interface IApplicantSignature {
  hasLegalRepresentative: boolean,
  legalRepresentativeCertified: boolean,
  applicantCertified: boolean,
  applicantSignatureSeed: string,
  legalRepresentativeSignatureSeed: string,
};

class ApplicantSignatureStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.ApplicationModel, IApplicantSignature>,
  IApplicantSignature {

  hasLegalRepresentative: boolean;
  legalRepresentativeCertified: boolean;
  applicantCertified: boolean;
  applicantSignatureSeed: string;
  legalRepresentativeSignatureSeed: string;

  constructor() {
    this.hasLegalRepresentative = false;
    this.legalRepresentativeCertified = false;
    this.applicantCertified = false;

    makeObservable(this, {
      hasLegalRepresentative: observable,
      legalRepresentativeCertified: observable,
      applicantCertified: observable,

      setHasLegalRepresentative: action,
      setLegalRepresentativeCertified: action,
      setApplicantCertified: action,
    });
  }

  public setHasLegalRepresentative(value: boolean) {
    this.hasLegalRepresentative = value;
  }

  public setLegalRepresentativeCertified(value: boolean) {
    this.legalRepresentativeCertified = value;
  }

  public setApplicantCertified(value: boolean) {
    this.applicantCertified = value;
  }

  public setApplicantSignatureSeed(value: string) {
    this.applicantSignatureSeed = value;
  }

  public setLegalRepresentativeSignatureSeed(value: string) {
    this.legalRepresentativeSignatureSeed = value;
  }

  public refresh(application: GatewayOapApi.ApplicationModel): void {
    var state = this.buildState(application);
    this.setHasLegalRepresentative(state.hasLegalRepresentative);
    this.setLegalRepresentativeCertified(state.legalRepresentativeCertified);
    this.setApplicantCertified(state.applicantCertified);
    this.setApplicantSignatureSeed(state.applicantSignatureSeed);
    this.setLegalRepresentativeSignatureSeed(state.legalRepresentativeSignatureSeed);
  }

  public buildState(application: GatewayOapApi.ApplicationModel): IApplicantSignature {
    return {
      hasLegalRepresentative: Utility.defaultIfNull(application.hasLegalRepresentative, false),
      legalRepresentativeCertified: false,
      applicantCertified: false,
      applicantSignatureSeed: Utility.defaultIfNull(application.applicantSignatureSeed, ""),
      legalRepresentativeSignatureSeed: Utility.defaultIfNull(application.legalRepresentativeSignatureSeed, "")
    }
  }

}

export const ApplicantSignatureStore = new ApplicantSignatureStoreComponent();