import { IGatewayTransientStore } from "shared-module/models/IGatewayTransientStore";
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { action, makeObservable, observable } from "mobx";
import { BdsSelectOption } from "shared-module/components/multi-select/multi-select.component";
import * as enums from '../../../../shared-module/helpers/enums';

export interface INbarTreatmentNeeds {
    selectedTreatmentOptions: BdsSelectOption[],
    treatmentNeedsDescription: string,
    hasPrimaryCarePhysician: enums.YesNoNARadioButtonGroupOptions,
    primaryCarePhysicianName: string,
    medicalDiagnoses: string,
    isUnderPsychiatricCare: enums.YesNoNARadioButtonGroupOptions,
    psychiatristName: string,
    psychiatristDiagnoses: string,
    hasMedications: number,
    triggerMedicationValidation: boolean,
    triggerMedicationChangeValidation: boolean,
    medicationList: GatewayOapApi.MedicationInformationModel[],
    addMedicationName: string,
    addMedicationDescription: string,
    addMedicationLastChanged: string,
    selectedMedicationForChange: string,
    hasChangesInMedication: enums.YesNoNARadioButtonGroupOptions,
    hasRefusedMedication: enums.YesNoNARadioButtonGroupOptions,
    hasRefusedMedicationDescription: string,
    hasTriggerEvents: enums.YesNoNARadioButtonGroupOptions,
    triggerEventsDescription: string,
    hasRecentHospitalizations: enums.YesNoNARadioButtonGroupOptions,
    recentHospitalizationDescription: string,
    hasBehaviorManagmentService: enums.YesNoNARadioButtonGroupOptions,
    hasBehaviorManagmentConsultation: enums.YesNoNARadioButtonGroupOptions,
    hasAdditionalServices: enums.YesNoNARadioButtonGroupOptions,
    selectedAdditionalServiceOptions: BdsSelectOption[],
    hasPastLawEnforcement: enums.YesNoNARadioButtonGroupOptions,
    pastLawEnforcementDescription: string,
    hasStructuredRoutine: enums.YesNoNARadioButtonGroupOptions,
    routineDescription: string,
    meaningfulDayDescription: string,
    isStudent: enums.YesNoNARadioButtonGroupOptions,
    studentStatus: enums.StudentStatusRadioButtonGroupOptions,
    studentWeeklySchedule: string,
    hasIEP: enums.YesNoNARadioButtonGroupOptions,
    isInSpecialEd: enums.YesNoNARadioButtonGroupOptions,
    hasSchoolBehaviors: enums.YesNoNARadioButtonGroupOptions,
    schoolBehaviorFrequency: number,
    schoolBehaviorDescription: string,
    participatesInCommunityActivities: enums.YesNoNARadioButtonGroupOptions,
    communityActivityDescription: string,
    capacityOfWaiverUsage: string,
}

class NbarTreatmentNeedsComponent
  implements IGatewayTransientStore<GatewayOapApi.TreatmentInformationModel, INbarTreatmentNeeds>, 
  INbarTreatmentNeeds {
    public selectedTreatmentOptions: BdsSelectOption[];
    public treatmentNeedsDescription: string;
    public hasPrimaryCarePhysician: enums.YesNoNARadioButtonGroupOptions;
    public primaryCarePhysicianName: string;
    public medicalDiagnoses: string;
    public isUnderPsychiatricCare: enums.YesNoNARadioButtonGroupOptions;
    public psychiatristName: string;
    public psychiatristDiagnoses: string;
    public hasMedications: enums.YesNoNARadioButtonGroupOptions;
    public triggerMedicationValidation: boolean;
    public triggerMedicationChangeValidation: boolean;
    public medicationList: GatewayOapApi.MedicationInformationModel[];
    public addMedicationName: string;
    public addMedicationDescription: string;
    public addMedicationLastChanged: string;
    public hasChangesInMedication: enums.YesNoNARadioButtonGroupOptions;
    public selectedMedicationForChange: string;
    public hasRefusedMedication: enums.YesNoNARadioButtonGroupOptions;
    public hasRefusedMedicationDescription: string;
    public hasTriggerEvents: enums.YesNoNARadioButtonGroupOptions;
    public triggerEventsDescription: string;
    public hasRecentHospitalizations: enums.YesNoNARadioButtonGroupOptions;
    public recentHospitalizationDescription: string;
    public hasBehaviorManagmentService: enums.YesNoNARadioButtonGroupOptions;
    public hasBehaviorManagmentConsultation: enums.YesNoNARadioButtonGroupOptions;
    public hasAdditionalServices: enums.YesNoNARadioButtonGroupOptions;
    public selectedAdditionalServiceOptions: BdsSelectOption[];
    public hasPastLawEnforcement: enums.YesNoNARadioButtonGroupOptions;
    public pastLawEnforcementDescription: string;
    public hasStructuredRoutine: enums.YesNoNARadioButtonGroupOptions;
    public routineDescription: string;
    public meaningfulDayDescription: string;
    public isStudent: enums.YesNoNARadioButtonGroupOptions;
    public studentStatus: enums.StudentStatusRadioButtonGroupOptions
    public studentWeeklySchedule: string;
    public hasIEP: enums.YesNoNARadioButtonGroupOptions;
    public isInSpecialEd: enums.YesNoNARadioButtonGroupOptions;
    public hasSchoolBehaviors: enums.YesNoNARadioButtonGroupOptions;
    public schoolBehaviorFrequency: number;
    public schoolBehaviorDescription: string;
    public participatesInCommunityActivities: enums.YesNoNARadioButtonGroupOptions;
    public communityActivityDescription: string;
    public capacityOfWaiverUsage: string;
    public treatmentNeedsOptions: BdsSelectOption[];
    public medicationChangeIntervalOptions: BdsSelectOption[];
    public behavioralServiceOptions: BdsSelectOption[];
    public schoolBehaviorFrequencyOptions: BdsSelectOption[];

    constructor() {
        this.selectedTreatmentOptions = [];
        this.treatmentNeedsDescription = '';
        this.hasPrimaryCarePhysician = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.primaryCarePhysicianName = '';
        this.medicalDiagnoses = '';
        this.isUnderPsychiatricCare = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.psychiatristName = '';
        this.psychiatristDiagnoses = '';
        this.hasMedications = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.triggerMedicationValidation = false;
        this.triggerMedicationChangeValidation = false;
        this.medicationList = [];
        this.addMedicationName = '';
        this.addMedicationDescription = '';
        this.addMedicationLastChanged = '';
        this.hasChangesInMedication = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.selectedMedicationForChange = '';
        this.hasRefusedMedication = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.hasRefusedMedicationDescription = '';
        this.hasTriggerEvents = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.triggerEventsDescription = '';
        this.hasRecentHospitalizations = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.recentHospitalizationDescription = '';
        this.hasBehaviorManagmentService = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.hasBehaviorManagmentConsultation = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.hasAdditionalServices = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.selectedAdditionalServiceOptions = [];
        this.hasPastLawEnforcement = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.pastLawEnforcementDescription = '';
        this.hasStructuredRoutine = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.routineDescription = '';
        this.meaningfulDayDescription = '';
        this.isStudent = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.studentStatus = enums.StudentStatusRadioButtonGroupOptions.NotAnswered;
        this.studentWeeklySchedule = '',
        this.hasIEP = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.isInSpecialEd = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.hasSchoolBehaviors = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.schoolBehaviorFrequency = null;
        this.schoolBehaviorDescription = '';
        this.participatesInCommunityActivities = enums.YesNoNARadioButtonGroupOptions.NotAnswered;
        this.communityActivityDescription = '';
        this.capacityOfWaiverUsage = '';
        this.treatmentNeedsOptions = [];
        this.medicationChangeIntervalOptions = [];
        this.behavioralServiceOptions = [];
        this.schoolBehaviorFrequencyOptions = [];

        makeObservable(this, {
            selectedTreatmentOptions: observable,
            treatmentNeedsDescription: observable,
            hasPrimaryCarePhysician: observable,
            primaryCarePhysicianName: observable,
            medicalDiagnoses: observable,
            isUnderPsychiatricCare: observable,
            psychiatristName: observable,
            psychiatristDiagnoses: observable,
            triggerMedicationValidation: observable,
            triggerMedicationChangeValidation: observable,
            hasMedications: observable,
            medicationList: observable,
            addMedicationName: observable,
            addMedicationDescription: observable,
            addMedicationLastChanged: observable,
            hasChangesInMedication: observable,
            selectedMedicationForChange: observable,
            hasRefusedMedication: observable,
            hasRefusedMedicationDescription: observable,
            hasTriggerEvents: observable,
            triggerEventsDescription: observable,
            hasRecentHospitalizations: observable,
            recentHospitalizationDescription: observable,
            hasBehaviorManagmentService: observable,
            hasBehaviorManagmentConsultation: observable,
            hasAdditionalServices: observable,
            selectedAdditionalServiceOptions: observable,
            hasPastLawEnforcement: observable,
            pastLawEnforcementDescription: observable,
            hasStructuredRoutine: observable,
            routineDescription: observable,
            meaningfulDayDescription: observable,
            isStudent: observable,
            studentStatus: observable,
            studentWeeklySchedule: observable,
            hasIEP: observable,
            isInSpecialEd: observable,
            hasSchoolBehaviors: observable,
            schoolBehaviorFrequency: observable,
            schoolBehaviorDescription: observable,
            participatesInCommunityActivities: observable,
            communityActivityDescription: observable,
            capacityOfWaiverUsage: observable,

            setSelectedTreatmentOptions: action,
            setTreatmentNeedsDescription: action,
            setHasPrimaryCarePhysician: action,
            setPrimaryCarePhysicianName: action,
            setMedicalDiagnoses: action,
            setIsUnderPsychiatricCare: action,
            setPsychiatristName: action,
            setPsychiatristDiagnoses: action,
            setTriggerMedicationValidation: action,
            setTriggerMedicationChangeValidation: action,
            setMedicationList: action,
            setHasMedications: action,
            setAddMedicationName: action,
            setAddMedicationDescription: action,
            setAddMedicationLastChanged: action,
            setHasChangesInMedication: action,
            setSelectedMedicationForChange: action,
            setHasRefusedMedication: action,
            setHasRefusedMedicationDescription: action,
            setHasTriggerEvents: action,
            setTriggerEventsDescription: action,
            setHasRecentHospitalizations: action,
            setRecentHospitalizationDescription: action,
            setHasBehaviorManagmentService: action,
            setHasBehaviorManagmentConsultation: action,
            setHasAdditionalServices: action,
            setSelectedAdditionalServiceOptions: action,
            setHasPastLawEnforcement: action,
            setPastLawEnforcementDescription: action,
            setHasStructuredRoutine: action,
            setRoutineDescription: action,
            setMeaningfulDayDescription: action,
            setIsStudent: action,
            setStudentStatus: action,
            setStudentWeeklySchedule: action,
            setHasIEP: action,
            setIsInSpecialEd: action,
            setHasSchoolBehaviors: action,
            setSchoolBehaviorFrequency: action,
            setSchoolBehaviorDescription: action,
            setParticipatesInCommunityActivities: action,
            setCommunityActivityDescription: action,
            setCapacityOfWaiverUsage: action,
            setTreatmentNeedsOptions: action,
            setMedicationChangeIntervalOptions: action,
            setBehavioralServiceOptions: action,
            setSchoolBehaviorFrequencyOptions: action,
        })
    }

    public setSelectedTreatmentOptions(value: BdsSelectOption[]) {
        this.selectedTreatmentOptions = value;
    }

    public setTreatmentNeedsDescription(value: string) {
        this.treatmentNeedsDescription = value;
    }

    public setHasPrimaryCarePhysician(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasPrimaryCarePhysician = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPrimaryCarePhysicianName('');
            this.setMedicalDiagnoses('');
        }
    }

    public setPrimaryCarePhysicianName(value: string) {
        this.primaryCarePhysicianName = value;
    }

    public setMedicalDiagnoses(value: string) {
        this.medicalDiagnoses = value;
    }

    public setIsUnderPsychiatricCare(value: enums.YesNoNARadioButtonGroupOptions) {
        this.isUnderPsychiatricCare = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPsychiatristName('');
            this.setPsychiatristDiagnoses('');
        }
    }

    public setPsychiatristName(value: string) {
        this.psychiatristName = value;
    }

    public setPsychiatristDiagnoses(value: string) {
        this.psychiatristDiagnoses = value;
    }

    public setTriggerMedicationValidation(value: boolean) {
        this.triggerMedicationValidation = value;
    }

    public setTriggerMedicationChangeValidation(value: boolean) {
        this.triggerMedicationChangeValidation = value;
    }

    public setHasMedications(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasMedications = value;
    }

    public setMedicationList(value: GatewayOapApi.MedicationInformationModel[]) {
        this.medicationList = value;
    }

    public setAddMedicationName(value: string) {
        this.addMedicationName = value;
    }

    public setAddMedicationDescription(value: string) {
        this.addMedicationDescription = value;
    }

    public setAddMedicationLastChanged(value: string) {
        this.addMedicationLastChanged = value;
    }

    public setHasChangesInMedication(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasChangesInMedication = value;
    }

    public setSelectedMedicationForChange(value: string) {
        this.selectedMedicationForChange = value;
    }

    public setHasRefusedMedication(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasRefusedMedication = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setHasRefusedMedicationDescription('');
        }
    }

    public setHasRefusedMedicationDescription(value: string) {
        this.hasRefusedMedicationDescription = value;
    }

    public setHasBehaviorManagmentService(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasBehaviorManagmentService = value;
    }

    public setHasBehaviorManagmentConsultation(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasBehaviorManagmentConsultation = value;
    }

    public setHasTriggerEvents(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasTriggerEvents = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setTriggerEventsDescription('');
        }
    }

    public setTriggerEventsDescription(value: string) {
        this.triggerEventsDescription = value;
    }

    
    public setHasAdditionalServices(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasAdditionalServices = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setSelectedAdditionalServiceOptions([]);
        }
    }

    public setSelectedAdditionalServiceOptions(value: BdsSelectOption[]) {
        this.selectedAdditionalServiceOptions = value;
    }

    public setHasRecentHospitalizations(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasRecentHospitalizations = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setRecentHospitalizationDescription('');
        }
    }

    public setRecentHospitalizationDescription(value: string) {
        this.recentHospitalizationDescription = value;
    }

    public setHasPastLawEnforcement(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasPastLawEnforcement = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPastLawEnforcementDescription('');
        }
    }

    public setPastLawEnforcementDescription(value: string) {
        this.pastLawEnforcementDescription = value;

    }

    public setHasStructuredRoutine(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasStructuredRoutine = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setRoutineDescription('');
        }
    }

    public setRoutineDescription(value: string) {
        this.routineDescription = value;
    }

    public setParticipatesInCommunityActivities(value: enums.YesNoNARadioButtonGroupOptions) {
        this.participatesInCommunityActivities = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setCommunityActivityDescription('');
        }
    }

    public setCommunityActivityDescription(value: string) {
        this.communityActivityDescription = value;
    }

    public setMeaningfulDayDescription(value: string) {
        this.meaningfulDayDescription = value;
    }

    public setCapacityOfWaiverUsage(value: string) {
        this.capacityOfWaiverUsage = value;
    }

    public setIsStudent(value: enums.YesNoNARadioButtonGroupOptions) {
        this.isStudent = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setStudentStatus(enums.StudentStatusRadioButtonGroupOptions.NotAnswered);
            this.setHasIEP(enums.YesNoNARadioButtonGroupOptions.NotAnswered);
            this.setIsInSpecialEd(enums.YesNoNARadioButtonGroupOptions.NotAnswered);
            this.setHasSchoolBehaviors(enums.YesNoNARadioButtonGroupOptions.NotAnswered);
            this.setStudentWeeklySchedule('');
            this.setSchoolBehaviorFrequency(-1);
            this.setSchoolBehaviorDescription('');           
        }
    }

    public setStudentStatus(value: enums.StudentStatusRadioButtonGroupOptions) {
        this.studentStatus = value;
        if(value != enums.StudentStatusRadioButtonGroupOptions.PartTime) {
            this.setStudentWeeklySchedule('');       
        }
    }

    public setStudentWeeklySchedule(value: string) {
        this.studentWeeklySchedule= value;
    }
    
    public setHasIEP(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasIEP = value;
    }

    public setIsInSpecialEd(value: enums.YesNoNARadioButtonGroupOptions) {
        this.isInSpecialEd = value;
    }

    public setHasSchoolBehaviors(value: enums.YesNoNARadioButtonGroupOptions) {
        this.hasSchoolBehaviors = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setSchoolBehaviorFrequency(-1);
            this.setSchoolBehaviorDescription('');           
        }
    }

    public setSchoolBehaviorFrequency(value: number) {
        this.schoolBehaviorFrequency = value == -1 ? null: value;
    }

    public setSchoolBehaviorDescription(value: string) {
        this.schoolBehaviorDescription = value;
    }

    public setTreatmentNeedsOptions(value : GatewayOapApi.TreatmentTypesModel[]) {
        this.treatmentNeedsOptions = value.map((option) => {
            return { label: option.treatmentName, value: option.treatmentTypeId.toString() }
        });
    }

    public setMedicationChangeIntervalOptions(value: GatewayOapApi.PrescriptionChangeTypesModel[]) {
        this.medicationChangeIntervalOptions = value.map((option) => {
            return { label: option.prescriptionChangeName, value: option.prescriptionChangeTypeId.toString() }
        })
    }

    public setBehavioralServiceOptions(value: GatewayOapApi.BehaviorManagementServiceTypesModel[]) {
        this.behavioralServiceOptions = value.map((option) => {
            return { label: option.serviceName, value: option.behaviorManagementServiceTypeId.toString() }
        })
    }

    public setSchoolBehaviorFrequencyOptions(value: GatewayOapApi.BehaviorFrequencyTypesModel[]) {
        this.schoolBehaviorFrequencyOptions = value.map((option) => {
            return{ label: option.name, value: option.behaviorFrequencyTypeId.toString() }
        });
    }

    public mapSelectedTreatmentOptions(selected: GatewayOapApi.TreatmentTypesModel[]) : BdsSelectOption[] {
        const selectedIds = selected.map(x => x.treatmentTypeId);
        return this.treatmentNeedsOptions.filter(x => selectedIds.includes(Number(x.value)))
    }

    public mapSelectedAdditionalServiceOptions(selected: GatewayOapApi.BehaviorManagementServiceTypesModel[]) : BdsSelectOption[] {
        const selectedIds = selected.map(x => x.behaviorManagementServiceTypeId);
        return this.behavioralServiceOptions.filter(x => selectedIds.includes(Number(x.value)))
    }

    public refresh(application: GatewayOapApi.TreatmentInformationModel): void {
        var state = this.buildState(application);
        this.setSelectedTreatmentOptions(state.selectedTreatmentOptions);
        this.setTreatmentNeedsDescription(state.treatmentNeedsDescription);
        this.setHasPrimaryCarePhysician(state.hasPrimaryCarePhysician);
        this.setPrimaryCarePhysicianName(state.primaryCarePhysicianName);
        this.setMedicalDiagnoses(state.medicalDiagnoses);
        this.setIsUnderPsychiatricCare(state.isUnderPsychiatricCare);
        this.setPsychiatristName(state.psychiatristName);
        this.setPsychiatristDiagnoses(state.psychiatristDiagnoses);
        this.setTriggerMedicationValidation(state.triggerMedicationValidation);
        this.setTriggerMedicationChangeValidation(state.triggerMedicationChangeValidation);
        this.setHasMedications(state.hasMedications);
        this.setMedicationList(state.medicationList);
        this.setAddMedicationName(state.addMedicationName);
        this.setAddMedicationDescription(state.addMedicationDescription);
        this.setAddMedicationLastChanged(state.addMedicationLastChanged);
        this.setHasChangesInMedication(state.hasChangesInMedication);
        this.setSelectedMedicationForChange(state.selectedMedicationForChange);
        this.setHasRefusedMedication(state.hasRefusedMedication);
        this.setHasRefusedMedicationDescription(state.hasRefusedMedicationDescription);
        this.setHasTriggerEvents(state.hasTriggerEvents);
        this.setTriggerEventsDescription(state.triggerEventsDescription);
        this.setHasRecentHospitalizations(state.hasRecentHospitalizations);
        this.setRecentHospitalizationDescription(state.recentHospitalizationDescription);
        this.setHasBehaviorManagmentService(state.hasBehaviorManagmentService);
        this.setHasBehaviorManagmentConsultation(state.hasBehaviorManagmentConsultation);
        this.setHasAdditionalServices(state.hasAdditionalServices);
        this.setSelectedAdditionalServiceOptions(state.selectedAdditionalServiceOptions);
        this.setHasPastLawEnforcement(state.hasPastLawEnforcement);
        this.setPastLawEnforcementDescription(state.pastLawEnforcementDescription);
        this.setHasStructuredRoutine(state.hasStructuredRoutine);
        this.setRoutineDescription(state.routineDescription);
        this.setMeaningfulDayDescription(state.meaningfulDayDescription);
        this.setIsStudent(state.isStudent);
        this.setStudentStatus(state.studentStatus);
        this.setStudentWeeklySchedule(state.studentWeeklySchedule);
        this.setHasIEP(state.hasIEP);
        this.setIsInSpecialEd(state.isInSpecialEd);
        this.setHasSchoolBehaviors(state.hasSchoolBehaviors);
        this.setSchoolBehaviorFrequency(state.schoolBehaviorFrequency);
        this.setSchoolBehaviorDescription(state.schoolBehaviorDescription);
        this.setParticipatesInCommunityActivities(state.participatesInCommunityActivities);
        this.setCommunityActivityDescription(state.communityActivityDescription);
        this.setCapacityOfWaiverUsage(state.capacityOfWaiverUsage);
    }
    
    public buildState(info: GatewayOapApi.TreatmentInformationModel): INbarTreatmentNeeds {
        console.log('BuildState: isInSpecialEd: ', info.hasSpecialEducation);
        return {
            selectedTreatmentOptions: this.mapSelectedTreatmentOptions(info.medicalInformation),
            treatmentNeedsDescription: info.treatmentDescription || '',
            hasPrimaryCarePhysician: info.hasPrimaryCarePhysician,
            primaryCarePhysicianName: info.physicianName,
            medicalDiagnoses: info.medicalDiagnosisByPhysician,
            isUnderPsychiatricCare: info.hasPsychiatrist,
            psychiatristName: info.psychiatristName,
            psychiatristDiagnoses: info.medicalDiagnosisByPsychiatrist,
            hasMedications: info.hasPrescribedMedications,
            triggerMedicationValidation: false,
            triggerMedicationChangeValidation: false,
            medicationList: info.medicationInformation,
            addMedicationName: '',
            addMedicationDescription: '',
            addMedicationLastChanged: '',
            hasChangesInMedication: info.medicationInformation.find(m => m.prescriptionChangeTypeId > 0) !== undefined ? enums.YesNoNARadioButtonGroupOptions.Yes : enums.YesNoNARadioButtonGroupOptions.No,
            selectedMedicationForChange: '',
            hasRefusedMedication: info.hasRefusalToTakeMedications,
            hasRefusedMedicationDescription: info.refusalDescription,
            hasTriggerEvents: info.hasRecentTriggerEvents,
            triggerEventsDescription: info.triggerEventDescription,
            hasRecentHospitalizations: info.hasHospitalization,
            recentHospitalizationDescription: info.hospitalizationDescription,
            hasBehaviorManagmentService: info.isReceivingBehaviorManagementServices,
            hasBehaviorManagmentConsultation: info.hasRecievedBehaviorManagementConsultation,
            hasAdditionalServices: info.isReceivingAdditionalServices,
            selectedAdditionalServiceOptions: this.mapSelectedAdditionalServiceOptions(info.additionalBehaviorServices),
            hasPastLawEnforcement: info.hasLawEnforcement,
            pastLawEnforcementDescription: info.lawEnforcementDescription,
            hasStructuredRoutine: info.hasStructuredSchedule,
            routineDescription: info.scheduleDescription || '',
            meaningfulDayDescription: info.meaningfulDayDescription || '',
            isStudent: info.isStudent,
            studentStatus: this.getStudentStatus(info),
            studentWeeklySchedule: info.partTimeDescription,
            hasIEP: info.hasIEP, 
            isInSpecialEd: info.hasSpecialEducation,
            hasSchoolBehaviors: info.hasSchoolBehaviors,
            schoolBehaviorFrequency: info.behaviorFrequencyTypeId,
            schoolBehaviorDescription: info.behaviorDescription,
            participatesInCommunityActivities: info.hasCommunityActivities,
            communityActivityDescription: info.communityActivityDescription,
            capacityOfWaiverUsage: info.waiverCapacityDescription || '',
        }
    }

    
    
    public AddNewMedication() {
        // const newMedication: IMedication = {
        //     id: new Date().getMilliseconds().toString(),
        //     name: this.addMedicationName,
        //     description: this.addMedicationDescription,
        //     lastChanged: '',
        // }
        // this.medicationList.push(newMedication);
        // this.setAddMedicationName('');
        // this.setAddMedicationDescription('');
    }

    public AddChangeFrequencyToMedicationList() {
        // const currentMed: number =  this.medicationList.findIndex(o => o.medicationInformationId == Number(this.selectedMedicationForChange));
        // this.medicationList[currentMed]. = this.addMedicationLastChanged;
        // this.setAddMedicationLastChanged('');
    }

    public RemoveInterval(id: string) {
        // const currentMed: number =  this.medicationList.findIndex(o => o.medicationInformationId == Number(this.selectedMedicationForChange));
        // this.medicationList[currentMed].lastChanged = '';
        // this.setAddMedicationLastChanged('');
    }

    public RemoveMedication(id: string) {
        // const currentMed: number =  this.medicationList.findIndex(o => o.medicationInformationId == Number(this.selectedMedicationForChange));
        // this.medicationList.splice(currentMed, 1);
        // this.setAddMedicationLastChanged('');
        // if(this.medicationList.length == 0) {
        //     this.setHasChangesInMedication(enums.YesNoNARadioButtonGroupOptions.NotAnswered);
        // }
    }

    mapMedicationList(items: GatewayOapApi.MedicationInformationModel) {
        return [];
    }

    public getStudentStatus(info: GatewayOapApi.TreatmentInformationModel) {
        if(info.isStudent == enums.YesNoNARadioButtonGroupOptions.Yes) {
            return info.isFullTimeStudent ? enums.StudentStatusRadioButtonGroupOptions.FullTime : enums.StudentStatusRadioButtonGroupOptions.PartTime;
        }
        return enums.StudentStatusRadioButtonGroupOptions.NotAnswered;
    }
}

export const NbarTreatmentNeeds = new NbarTreatmentNeedsComponent();