import { AppSettings } from "../../../app-setting";

interface Props {
  id: string,
  onStop: () => void,
  onContinue: () => void
}

function DuplicateDraftApplicationModalComponent(props: Props) {

  function handleOnStop(event) {
    if (props.onStop) {
      props.onStop();
    }
  }

  function handleOnContinue(event) {
    if (props.onContinue) {
      props.onContinue();
    }
  }

  return (
    <>
      <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Potential Duplicate Application Detected</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
              An application of this type matching this SSN or ITIN is in progress for this person.
                Would you like to proceed?
              </p>
              <p>
                To continue, select <strong>CONTINUE</strong>.
                To stop the application, select <strong>STOP</strong>.
                <br />If STOP is selected, any information you have added to the current draft will be lost.
              </p>
              <p>
                If you need help, please contact your local
                {" "} {AppSettings.bdsAcronym} office.
                Your {AppSettings.bdsAcronym} local office contact information can be found {" "}
                <a href={AppSettings.Link_DistrictOffices} target='_blank'>here</a>
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                onClick={handleOnContinue}>CONTINUE</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                onClick={handleOnStop}>STOP</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const DuplicateDraftApplicationModal = DuplicateDraftApplicationModalComponent
