
import * as Yup from "yup";
import { ICreateApplication } from "./create-application.store";
import { Utility } from "../../../../shared-module/helpers/utility";

// Validation schema
export const CreateApplicationValidationSchema: Yup.ObjectSchema<ICreateApplication> = Yup.object({
  ssnType: Yup.string()
    .test("validate-ssnType", "-", function (item) {
      if (!item) {
        return this.createError(Utility.buildYupErrorMessage("ssnType", "SSN/ITIN Type must be provided"))
      }
      return true;
    }),
  ssn: Yup.string()
    .test("validate-ssn", "-", function (item) {
      if (!item && !this.parent.ssnType) {
        return this.createError(
          Utility.buildYupErrorMessage("ssn", "SSN or ITIN is Required."))
      } else if (this.parent.ssnType == "SSN") {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "SSN is Required."));
        } else if (item.length < 9) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "SSN must be nine digits."));
        } else if (!new RegExp(/^[0-9]{9}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "SSN must only contain numbers."));
        } else if (new RegExp(/^(000|666)[0-9]{6}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn",
              "A social security number cannot begin with 000 or 666."));
        } else if (new RegExp(/^[9][0-9]{8}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn",
              "A social security number must not start with 9."));
        } else if (new RegExp(/^[0-9]{3}(00)[0-9]{4}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn",
              "The 4th and 5th numbers of a social security cannot contain 00."));
        } else if (new RegExp(/^[0-9]{5}(0000)$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn",
              "The last four digits of a social security number cannot contain 0000."));
        }
      } else if (this.parent.ssnType == "ITIN") {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "ITIN is Required."));
        } else if (item.length < 9) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "ITIN must be nine digits."));
        } else if (!new RegExp(/^[0-9]{9}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "ITIN must only contain numbers."));
        } else if (!new RegExp(/^[9][0-9]{8}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn", "ITIN must begin with a 9."));
        } else if (!new RegExp(/^[9][0-9]{2}(5[0-9]|6[0-5]|7[0-9]|8[0-8]|9[0-2,4-9])[0-9]{4}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("ssn",
              "ITIN 4th and 5th digits must range from 50 to 65, 70 to 88, 90 to 92, or 94 to 99."));
        }
      }
      return true;
    })
});
