import * as Yup from "yup";
import { INbarPreferenceInformation } from "./applicant-preferences.store";
import { Utility } from "../../../../shared-module/helpers/utility";

// validation schema
export const NbarPreferenceInformationValidationSchema: Yup.ObjectSchema<INbarPreferenceInformation> = Yup.object({
    thingsLoveMost: Yup.string()
    .test("validate-thingsLoveMost", "-", function(item) {
        if(!item) {
            return this.createError(
                Utility.buildYupErrorMessage("thingsLoveMost", "Please describe the things the individual loves the most")
            );
        }
        return true;
    }),
    thingsFearMost: Yup.string()
    .test("validate-thingsFearMost", "-", function(item) {
        if(!item) {
            return this.createError(
                Utility.buildYupErrorMessage("thingsFearMost", "Please describe the things the individual fears the most")
            );
        }
        return true;
    }),
    thingsDislikeMost: Yup.string()
    .test("validate-thingsDislikeMost", "-", function(item) {
        if(!item) {
            return this.createError(
                Utility.buildYupErrorMessage("thingsDislikeMost", "Please describe the things the individual dislikes the most")
            );
        }
        return true;
    }),
    thingsUpsetMost: Yup.string()
    .test("validate-thingsUpsetMost", "-", function(item) {
        if(!item) {
            return this.createError(
                Utility.buildYupErrorMessage("thingsUpsetMost", "Please describe the things that makes the individual angry or upset")
            );
        }
        return true;
    }),
});