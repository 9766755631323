import { Axios, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { IApiConfig } from "../api-resources/api.base.resource";
import { AppSettings } from "../../app-setting";
import { GatewayOapApiRequestInterceptor } from "../http-interceptor/http-request-interceptor";

class ApiConfigImp implements IApiConfig {
  private static instance: ApiConfigImp;

  public axios: Axios;
    public baseUrl: string;
  
    private constructor() {
      this.axios = new Axios({});
      const interceptor = new GatewayOapApiRequestInterceptor();
  
      this.axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig<any>) => interceptor.InterceptRequest(config),
        (error: any) => interceptor.InterceptRequest(error));
  
      this.axios.interceptors.response.use(
        (response: AxiosResponse<any>) => interceptor.InterceptResponse(response),
        (error: any) => interceptor.InterceptResponseError(error));
  
      this.baseUrl = AppSettings.apiUrl
    }

    public static getInstance() {
      if (!this.instance) {
        this.instance = new ApiConfigImp();
      }
      return this.instance;
    }
  }

  export const ApiConfig = ApiConfigImp.getInstance();