import { useEffect, useRef } from "react";

interface Props {
  when: boolean,
  children: React.ReactNode
}

function DisplayNoneComponent(props: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current.style.display = props.when ? "none" : "block";
  }, [props.when])

  return (
    <>
      <div ref={ref}>
        {props.children}
      </div>
    </>
  )
}

export const DisplayNone = DisplayNoneComponent;