import { ShowThen } from "shared-module/components/show-then/show-then.component";
import { Utility } from "shared-module/helpers/utility";
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";

function addressExists(address: GatewayOapApi.AddressModel) {
    if (!address || (!address.streetAddress1)) {
        return false;
    }
    return true;
}

function formatAddress(address: GatewayOapApi.AddressModel) {
    if (!addressExists(address)) {
        return "N/A";
    }
    return `${address.streetAddress1} ${address.streetAddress2} ${address.city}
        ${address.stateName} ${Utility.mergeZipCodeParts(address.zipCode, address.zipPlus4, "")}`;
}

function formatCurrentAddress(entity: GatewayOapApi.LegalRepresentativeModel | GatewayOapApi.ApplicationModel) {
    // if mailing address and no current address, return 'same as mailing'
    if(entity.mailingAddress?.streetAddress1 && !entity.currentAddress?.streetAddress1) { 
        return 'same as mailing';
    }
    else { 
        // there is no mailing address :
        // return formatted current address or N/A
        return formatAddress(entity.currentAddress);
    }
}



interface Props {
    entity: GatewayOapApi.LegalRepresentativeModel | GatewayOapApi.ApplicationModel
}

function AddressSummary({entity}: Props) {
    return (
        <>
            {/* Mailing and Current Addresses */}
            <ShowThen when={entity?.mailingAddress && !entity?.mailingAddress.isInternational}>
                {/* Mailing Address */ }
                <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                    {formatAddress(entity?.mailingAddress)}
                </div>
                
                <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Mailing County:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                    {Utility.defaultIfNull(entity?.mailingAddress?.countyName, "N/A")}
                </div>
            </ShowThen>
            
            <ShowThen when={!entity?.currentAddress.isInternational}>
                {/* Current Address */ }
                <div className="col-6 col-md-3 mt-3">
                    <label className="bds-summary-label">Current Address:</label>
                </div>
                <div className="col-6 col-md-3 mt-3">
                    {formatCurrentAddress(entity) }
                </div>

                <ShowThen when={addressExists(entity.currentAddress)}>
                    <div className="col-6 col-md-3 mt-3">
                        <label className="bds-summary-label">Current County:</label>
                    </div>
                    <div className="col-6 col-md-3 mt-3">
                        {Utility.defaultIfNull(entity?.currentAddress?.countyName, 
                            !entity?.mailingAddress?.streetAddress1 ? 'N/A': 'same as mailing'
                        )}
                    </div>
                </ShowThen>

            </ShowThen>

            {/* International Addresses */ }
            <ShowThen when={entity?.mailingAddress && entity?.mailingAddress?.isInternational}>
            <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">International Address:</label>
            </div>
            <div className="col-6 col-md-3 mt-3">
                {Utility.defaultIfNull(entity?.mailingAddress?.internationalAddress, "N/A")}
            </div>
            </ShowThen>

            <ShowThen when={entity.hasDifferentCurrentAddress && entity?.currentAddress && entity?.currentAddress?.isInternational}>
            <div className="col-6 col-md-3 mt-3">
                <label className="bds-summary-label">Current International Address:</label>
            </div>
            <div className="col-6 col-md-3 mt-3">
                {Utility.defaultIfNull(entity?.currentAddress?.internationalAddress, "N/A")}
            </div>
            </ShowThen></>
    )
}

export default AddressSummary;