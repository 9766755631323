import { useEffect, useState } from "react";

interface Props {
  id: string;
  name: string;
  label: string;
  cssClass?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}


const BdsCheckbox = ({id, name, label, cssClass, onChange, onBlur, checked } : Props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked])

  const handleChange = (event) => {
      setIsChecked((prev) => !prev);
      onChange(event);
  }

  const handleBlur = (event) => {
    if(onBlur) {
      onBlur(event);
    }
  }

  return (
    <div className={`bds-checkbox ${cssClass || ""}`}>       
      <input type="checkbox"
        id={id}
        name={name}
        checked={isChecked} 
        onChange={(event) => handleChange(event)} 
        onBlur={(event) => handleBlur(event)}
        className="form-control"
      />
      <label 
        className={`form-check-label`}
        html-for={id}>
          {label}
      </label>
    </div>
  );
};

export default BdsCheckbox;


