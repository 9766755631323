import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarOtherResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

    public addOrUpdateResidentInformation(residentInformationId: number, nbarApplicationRefId: string, name: string, 
        age: number, relationshipTypeId: number,  abortController?: AbortController): Promise<GatewayOapApi.ApiIntegerResponse>
    {
        return super.postRequest(`api/v1/nbar/resident/${nbarApplicationRefId}/add-or-update`, 
        null, 
        { residentInformationId, name, age, relationshipTypeId }, 
        abortController) as
            Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public deleteResidentInformation(residentInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiBooleanResponse>
    {
        return super.postRequest(`api/v1/nbar/resident/delete/${residentInformationId}`, null, {}, abortController) as 
            Promise<GatewayOapApi.ApiBooleanResponse>;
    }

    public addAdditionalBehaviorService(treatmentInformationId: number, behaviorManagementServiceTypeId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiIntegerResponse> {
        return super.postRequest(`api/v1/nbar/additionalbehaviorservice/add`, null, {treatmentInformationId, behaviorManagementServiceTypeId}, abortController) as 
            Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public deleteAdditionalBehaviorService(additionalBehaviorServiceId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiBooleanResponse>
    {
        return super.postRequest(`api/v1/nbar/additionalbehaviorservice/delete/${additionalBehaviorServiceId}`, null, {}, abortController) as 
            Promise<GatewayOapApi.ApiBooleanResponse>;
    }

    public addOrUpdateMedicationInformation(medicationInformationId: number, treatmentInformationId: number, medicationName: string, prescriptionReason: string, prescriptionChangeTypeId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiIntegerResponse> {
        return super.postRequest(`api/v1/nbar/medication/add-or-update`, null, { medicationInformationId, treatmentInformationId, medicationName, prescriptionReason, prescriptionChangeTypeId }, abortController) as 
            Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public deleteMedicationInformation(medicationInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiBooleanResponse>
    {
        return super.postRequest(`api/v1/nbar/medication/delete/${medicationInformationId}`, null, {}, abortController) as 
            Promise<GatewayOapApi.ApiBooleanResponse>;
    }

    public addMedicalInformation(treatmentInformationId: number, treatmentTypeId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiIntegerResponse> {
        return super.postRequest(`api/v1/nbar/medical/add`, null, { treatmentInformationId, treatmentTypeId }, abortController) as 
            Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public deleteMedicalInformation(medicalInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiBooleanResponse>
    {
        return super.postRequest(`api/v1/nbar/medical/delete/${medicalInformationId}`, null, {}, abortController) as 
            Promise<GatewayOapApi.ApiBooleanResponse>;
    }

    public addWaiverInformation(demographicInformationId: number, waiverTypeId: number, otherWaiverDescription: string, abortController?: AbortController): Promise<GatewayOapApi.ApiIntegerResponse> {
        return super.postRequest(`api/v1/nbar/waiver/add`, null, { demographicInformationId, waiverTypeId, otherWaiverDescription }, abortController) as 
            Promise<GatewayOapApi.ApiIntegerResponse>;
    }

    public deleteWaiverInformation(waiverInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiBooleanResponse>
    {
        return super.postRequest(`api/v1/nbar/waiver/delete/${waiverInformationId}`, null, {}, abortController) as 
            Promise<GatewayOapApi.ApiBooleanResponse>;
    }
}

