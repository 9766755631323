import { NavigateFunction } from "react-router-dom";

export class NavigationHelper {
  public static routes = {
    home: "/home",
    dashboard: "/dashboard",
    startApplication: "/start-application",
    editApplication: "/start-application",
    applicationInformation: '/application-information',
    applicantInformation: "/applicant-information",
    applicantDemographics: "/applicant-demographics",
    legalRepresentative: "/legal-representative",
    applicationSummary: "/application-summary",
    documentUpload: "/upload-documents",
    signature: "/signature",
    applicationSubmitted: "/application-submitted",
    underMaintenance: "/under-maintenance",
    authorize: "/authorize",
    unauthorize: "/unauthorize",
    nbarAuthorize: "/nbar-authorize",
    registeration: "https://access.in.gov/signup/",
    nbar: "/nbar",
    nbarInstructions: "/nbar-instructions",
    nbarCreateApplication: "/nbar-create-application",
    nbarStartApplication: "/nbar-start-application",
    nbarEditApplication: "/nbar-start-application",
    nbarDemographics: "/nbar-demographic-information",
    nbarTreatmentInformation: "/nbar-treatment-information",
    nbarApplicantPreferences: "/nbar-applicant-preferences",
    nbarBehavioralInformation: "/nbar-behavioral-information",
    nbarSignature: "/nbar-signature",
  }
  
  public static gotoDashboard(navigate: NavigateFunction): void {
    navigate(this.routes.dashboard);
  }

  public static gotoHome(navigate: NavigateFunction): void {
    navigate(this.routes.home);
  }

  public static gotoHomeWithReplace(navigate: NavigateFunction): void {
    navigate(this.routes.home, {
      replace: true,
      preventScrollReset: true
    });
  }

  public static gotoStartApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.startApplication + '/' + id);
  }

  public static gotoStartApplicationWithReplace(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.startApplication + '/' + id, {
      replace: true,
      preventScrollReset: true
    });
  }

  public static gotoEditApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.editApplication + '/' + id);
  }

  public static gotoApplicationInformation(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.applicationInformation + '/' + id);
  }

  public static gotoApplicantInformation(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.applicantInformation + '/' + id);
  }

  public static gotoApplicantDemographics(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.applicantDemographics + '/' + id);
  }

  public static gotoLegalRepresentative(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.legalRepresentative + '/' + id);
  }

  public static gotoApplicationSummary(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.applicationSummary + '/' + id);
  }

  public static goToDocumentUpload(navigate: NavigateFunction, id: string =''): void {
    navigate(this.routes.documentUpload + '/' + id);
  }
  
  public static gotoSignature(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.signature + '/' + id);
  }

  public static gotoApplicationSubmitted(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.applicationSubmitted + '/' + id);
  }

  public static gotoMaintenancePage(navigate: NavigateFunction): void {
    navigate(this.routes.underMaintenance);
  }

  public static gotoAuthorization(navigate: NavigateFunction): void {
    navigate(this.routes.authorize);
  }

  public static gotoUnauthorization(navigate: NavigateFunction): void {
    navigate(this.routes.unauthorize);
  }

  public static gotoNbarAuthorization(navigate: NavigateFunction): void {
    navigate(this.routes.nbarAuthorize);
  }

  public static gotoRegisteration(navigate: NavigateFunction): void {
    navigate(this.routes.authorize);
  }

  public static gotoNbarHome(navigate: NavigateFunction): void {
    navigate(this.routes.nbar);
  }

  public static gotoNbarInstructions(navigate: NavigateFunction): void {
    navigate(this.routes.nbarInstructions);
  }

  public static gotoNbarCreateApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarCreateApplication + '/' + id);
  }

  public static gotoNbarStartApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarStartApplication + '/' + id);
  }

  public static gotoNbarEditApplication(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarEditApplication + '/' + id);
  }

  public static gotoNbarDemographics(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarDemographics + '/' + id);
  }

  public static gotoNbarTreatmentInformation(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarTreatmentInformation + '/' + id);
  }

  public static gotoNbarApplicantPreferences(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarApplicantPreferences + '/' + id);
  }

  public static gotoNbarBehavioralInformation(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarBehavioralInformation + '/' + id);
  }

  public static gotoNbarSignature(navigate: NavigateFunction, id: string = ''): void {
    navigate(this.routes.nbarSignature + '/' + id);
  }

}