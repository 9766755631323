import { useEffect } from "react";


function OidcAuthorizeComponent() {

  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/auth/authorize");
    }, 2);
  }, []);

  return (
    <>
      <div className="bds-redirection-overlay">
        <div className="text-center">
          <div className="mb-4">Redirecting to External Website ...</div>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Redirecting...</span>
          </div>
        </div>
      </div>
    </>
  )
}

export const OidcAuthorize = OidcAuthorizeComponent;