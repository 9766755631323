import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { ApiConfig } from "shared-module/services/api-config";


function SignOutOidcLandingComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    NavigationHelper.gotoAuthorization(navigate);
  }, []);
  return (
    <>
      <div>Redirecting ...</div>
    </>
  )
}

export const SignOutOidcLanding = SignOutOidcLandingComponent;