import React, { useEffect } from "react";
import FilePreviewModalEventBus from "./file-preview-modal-event-bus";
import { Modal } from "bootstrap";
import { FilePreviewModalEvents } from "./file-preview-modal.component";
import { createPortal } from "react-dom";
import { useAsyncState } from "../../hooks/use-async-state";


export interface FilePreviewModalOptions {
  header: React.ReactNode;
  message: React.ReactNode;
  okButtonText: string;
  callback: () => Promise<void>
}

function FilePreviewModalContainerComponent(props) {
  const elementId = "bdsFilePreviewModal";
  const [options, setOptions] = useAsyncState<FilePreviewModalOptions>({
    header: 'add header',
    message: 'add message',
    okButtonText: "Close",
    callback: null
  });

  function getModalInstance() {
    var element = document.getElementById(elementId);
    var filePreviewModal = Modal.getOrCreateInstance(element, {
      keyboard: false
    });

    return filePreviewModal;
  }

  function hideModal() {
    var modal = getModalInstance()
    modal.hide();
  }

  useEffect(() => {
    FilePreviewModalEventBus.on(FilePreviewModalEvents.MODAL_SHOW, function (opts: FilePreviewModalOptions) {
      setOptions({
        header: opts.header,
        message: opts.message,
        okButtonText: opts.okButtonText,
        callback: null
      });

      var modal = getModalInstance();
      modal.show(document.body);
    });

    FilePreviewModalEventBus.on(FilePreviewModalEvents.MODAL_HIDE, function() {
      hideModal();
    });

    return () => {
        FilePreviewModalEventBus.remove(FilePreviewModalEvents.MODAL_SHOW);
        FilePreviewModalEventBus.remove(FilePreviewModalEvents.MODAL_HIDE);
    }

  }, [])

  function handleClose(event) {
    if (options?.callback) {
      return options.callback()
        .then((response) => {
          return hideModal();
        });
    }
    hideModal();
  }

  return (
    <>
    {
      createPortal(
        <div className="bds-alert-modal modal fade" id="bdsFilePreviewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{options?.header}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="bds-file-preview-container col d-flex justify-content-center p-lg-2">
                <div className={`col-10 ${options.message}`}></div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleClose} className="btn btn-secondary">
                {options?.okButtonText}</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
      )
    }
    </>
  )

}

export const FilePreviewModalContainer = FilePreviewModalContainerComponent;