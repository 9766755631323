import React, { useState } from "react"
import { ShowThen } from "../show-then/show-then.component";
import { Spinner } from "react-bootstrap";

interface Props {
    id: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>;
    cssClass?: string;
    iconClass?: string;
    children?: React.ReactNode | '';
    name? : string | "";
    type?: "button" | "submit" | "reset";
    isProcessing: boolean;
    disabled?: boolean | false;
}

const ProcessButton: React.FC<Props> = ({ id, name, onClick, cssClass, type, iconClass, children, disabled} : Props) => {
    const [isProcessing, setIsProcessing] = useState(false);

    async function handleProcessing(event) {
        setIsProcessing(true);
        onClick(event)
        .finally(() => {
            setIsProcessing(false);
        })
    }

    return (
        <button
            id={id}
            name={name || id}
            onClick={(event) => handleProcessing(event)}
            className={`btn ${cssClass}`}
            type={type || "button"}
            disabled = {disabled || false}
        >
        <ShowThen when={isProcessing}>
            <span className="me-2"><Spinner></Spinner></span>
        </ShowThen>
        <ShowThen when={!isProcessing}>
            <i className={`${iconClass} me-2`}></i>               
        </ShowThen>
        {children}           
        </button>
    )
};

export default ProcessButton;
