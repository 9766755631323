import { useNavigate } from "react-router-dom";
import { Card } from "../../../shared-module/components/card/card.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { AppSettings } from "../../../app-setting";
import { useEffect } from "react";

// useEffect(() => {
//   sessionStorage.removeItem('fromSummary');
// });

function ApplicationSubmitted() {
  const navigate = useNavigate();
  return (
    <div className="mt-4">
      <Card>
        <p>
            Thank you for submitting your application for {AppSettings.bdsAcronym} Services. A representative from your {AppSettings.bdsAcronym} office will contact you within 15 days to discuss the next steps. If you do not hear from the {AppSettings.bdsAcronym} office within 15 days, please do not submit a new application. Contact your local {AppSettings.bdsAcronym} office to inquire about the status of your application. You can find your local office <a href={AppSettings.Link_DDRSLanding} target="_blank" className="bds-anchor">here</a>.
        </p>
        <p>
            If the intent of your application was for the Family Supports Waiver, you may want to review the family supports checklist <a href="https://www.in.gov/fssa/ddrs/files/FSW_Checklist.pdf" target="_blank" className="bds-anchor">here</a> which provides information on what to expect through the process.
        </p>
        <p>
            You can obtain a copy of the Confirmation of Diagnoses (COD) form located <a href={AppSettings.Link_DDRSForms} target="_blank" className="bds-anchor">here</a>. This form will need to be completed and signed by a physician involved in care. This must be a medical doctor (MD) or a Doctor of Osteopathic Medicine (DO) currently involved in care and does not have to be their diagnosing physician. You may bring this completed form when you meet with the {AppSettings.bdsAcronym} representative but are not required to do so. If you need assistance getting this form completed the {AppSettings.bdsAcronym} representative will be happy to assist you.
        </p>
        <p>
            We look forward to meeting you and assisting you in obtaining your vision of a good life.
        </p>
      </Card>
      <div className="col-12 order-0 order-md-1 text-md-end mt-4">
        <button type="button"
          onClick={() => NavigationHelper.gotoHome(navigate)}
          className="btn bds-btn-primary">
            Close
        </button>
      </div>
    </div>
  );
}

export default ApplicationSubmitted;