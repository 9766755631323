
export module BDS {
    export class OnChangeEvent {

        constructor(private nameValue: string, private newValue: string) {
        }

        public get name() {
            return this.nameValue;
        }

        public get value() {
            return this.newValue;
        }
    }
}