import { AddressCorrectionModalSetup, AddressCorrectionModalOptions } from "../../app-module/extensions/address-correction-modal/address-correction-modal.component";
import { AddressResource } from "../api-resources/address.resource";
import { Utility } from "../helpers/utility";
import { GatewayOapApi } from "../models/gateway-oap-api.models";

export interface VerifyAddressRequest {
  streetAddress: string,
  city: string,
  stateId: number,
  zipCode: string,
  addressUpdateHandler: (streetAddress: string, city: string, stateId: string, zipCode: string) => Promise<any>
}

interface UserVerificationRequest {
  validatedAddress: GatewayOapApi.VerifiedAddressModel,
  addressUpdateHandler: (streetAddress: string, city: string, stateId: string, zipCode: string) => Promise<boolean>
}

export class AddressValidationService {

  public constructor(
    private addressService: AddressResource,
    private modalOptions: AddressCorrectionModalOptions
  ) {}

  public verifyAddresses(requests: VerifyAddressRequest[], postVerificationAction: () => void): Promise<any> {

    const promises = requests.map((request) => {
      return this.verifyAddress(request)
        .then((response) => {
          return {
            validatedAddress: response,
            addressUpdateHandler: request.addressUpdateHandler
          } as UserVerificationRequest
        });
    })

    return Promise.all(promises)
      .then((response) => {
        return this.executeUserVerifications(response, postVerificationAction);
      });
  }

  public executeUserVerifications(verifications: UserVerificationRequest[], postVerificationAction: () => void) {
    var queue = verifications;

    var next = (cont): void => {
      if (cont) {
        if (queue && queue.length > 0) {
          var verification = queue.shift();
          var record = verification.validatedAddress;
          if (record && ((record.isMatched && record.verifiedAddress) ||
            (!record.isMatched && !record.verifiedAddress))) {
            this.handleAddressVrification(verification, next);
            return;
          }
          next(true);
          return;
        }
        postVerificationAction();
      }
    }
    next(true);
  }

  private verifyAddress(request: VerifyAddressRequest)
    : Promise<GatewayOapApi.VerifiedAddressModel> {
    if (request.streetAddress) {
      return this.addressService.VerifyAddress(request.streetAddress, request.city, request.stateId, request.zipCode)
        .then((response) => {
          return response.record
        })
    }
    return Promise.resolve(null);
  }

  private handleAddressVrification(verification: UserVerificationRequest, next: (boolean) => void): void {
    const record = verification.validatedAddress;

    this.modalOptions.show({
      isVerified: record.isMatched,
      onCancel: () => {
        return Promise.resolve()
          .then((response) => {
            this.modalOptions.hide(() => next(false));
            return;
          })
      },
      onUseCurrentAddress: () => {
        return Promise.resolve()
          .then((response) => {
            this.modalOptions.hide(() => next(true));
            return;
          });
      },
      onAcceptUspsAddress: () => {
        return verification.addressUpdateHandler(
          Utility.defaultIfNull(record.verifiedAddress.streetAddress1, ""),
          Utility.defaultIfNull(record.verifiedAddress.city, ""),
          Utility.numberToString(record.verifiedAddress.stateId, ""),
          Utility.defaultIfNull(record.verifiedAddress.zipCode, "")
        )
          .then((response) => {
            this.modalOptions.hide(() => next(true));
            return;
          });
      },
      verifiedAddress: record
    } as AddressCorrectionModalSetup);
  }














}