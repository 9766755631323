import { action, makeObservable, observable } from "mobx";

export interface IAppData {
  showGuestApplicationDisclaimer: boolean
};

class AppStateResource implements IAppData {
  private showGuestApplicationDisclaimerLocal: boolean = false;

  public get showGuestApplicationDisclaimer(): boolean {
    return this.showGuestApplicationDisclaimerLocal;
  };

  constructor() {
  }

  public setShowGuestApplicationDisclaimer(value: boolean) {
    this.showGuestApplicationDisclaimerLocal = value;
  }

}

export const AppStateService = new AppStateResource();