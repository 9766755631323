import { clone, isEqual, isInteger, isObject, toPath } from "lodash";
import React, { useEffect } from "react";

export type FormFieldTouched<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
  ? Values[K][number] extends object
  ? FormFieldTouched<Values[K][number]>[]
  : boolean
  : Values[K] extends object
  ? FormFieldTouched<Values[K]>
  : boolean;
};

export function setRecursiveTouch<T>(
  object: any,
  value: any,
  visited: any = new WeakMap(),
  response: any = {}
): T {
  for (let k of Object.keys(object)) {
    const val = object[k];
    if (isObject(val)) {
      if (!visited.get(val)) {
        visited.set(val, true);
        response[k] = Array.isArray(val) ? [] : {};
        setRecursiveTouch(val, value, visited, response[k]);
      }
    } else {
      response[k] = value;
    }
  }

  return response;
}

export function GetNestedObject(
  obj: any,
  key: string | string[],
  def?: any,
  p: number = 0
) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }

  if (p !== path.length && !obj) {
    return def;
  }

  return obj === undefined ? def : obj;
}

export function setNestedTouchMultiple(obj: any, value: any, ...paths: string[]): any {
  paths.forEach((path) => {
    obj = setNestedTouch(obj, path, value);
  });

  return obj;
}

export function setNestedTouch(obj: any, path: string, value: any): any {
  let res: any = clone(obj);
  let resVal: any = res;
  let i = 0;
  let pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    let currentObj: any = GetNestedObject(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath: string = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

export interface FormTouchedState<Values> {
  touched: FormFieldTouched<Values>;
}

export interface FormTouchedConfig<Values> {
  initialTouched?: string[];
}

export interface KeyValueObject {
  [field: string]: any;
}

export function useFormTouch<Values extends KeyValueObject =
  KeyValueObject>(config: FormTouchedConfig<Values> = {}) {

  const array = config && config.initialTouched;
  const initialTouched = array && array.reduce((a, v) => ({ ...a, [v]: true}), {}) 
  const [, setIteration] = React.useState(0);
  const props = { initialTouched: initialTouched };
  const initialFieldTouched = React.useRef(props.initialTouched || {});
  const isMounted = React.useRef<boolean>(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const stateRef = React.useRef<FormTouchedState<Values>>({
    touched: props.initialTouched || {},
  });

  useEffect(() => {
    if (isMounted.current === true &&
      !isEqual(initialFieldTouched.current, props.initialTouched)) {
      initialFieldTouched.current = props.initialTouched || {};
      stateRef.current = setTouchedLocal(stateRef.current, props.initialTouched || {});
    }
  }, [props.initialTouched]);

  const state = stateRef.current;

  function setTouchedLocal<Values>(state: FormTouchedState<Values>, touched: FormFieldTouched<Values>)
    : FormTouchedState<Values> {
    return { ...state, touched };
  }

  function setFieldTouchedLocal<Values>(state: FormTouchedState<Values>, field: string, touched: boolean)
    : FormTouchedState<Values> {
    return {
      ...state, touched: setNestedTouch(state.touched, field, touched)
    }
  }

  function setFieldsTouchedLocal<Values>(state: FormTouchedState<Values>, touched: boolean, ...fields: string[])
    : FormTouchedState<Values> {
    return {
      ...state, touched: setNestedTouchMultiple(state.touched, touched, ...fields)
    }
  }

  function setTouchedAllLocal<Values>(state: FormTouchedState<Values>, values: Values)
    : FormTouchedState<Values> {
    return { touched: setRecursiveTouch<FormFieldTouched<Values>>(values, true) };
  }

  function changeTracker(func: () => FormTouchedState<Values>) {
    const prev = stateRef.current;
    stateRef.current = func();
    if (prev !== stateRef.current) setIteration(x => x + 1);
  }

  function setTouched(touched: FormFieldTouched<Values>): void {
    changeTracker(() => setTouchedLocal(stateRef.current, touched));
  }

  function setFieldTouched(field: string) {
    changeTracker(() => setFieldTouchedLocal(stateRef.current, field, true));
  }

  function setFieldsTouched(...fields: string[]) {
    changeTracker(() => setFieldsTouchedLocal(stateRef.current, true, ...fields));
  }

  function setTouchedAll(values: Values) {
    changeTracker(() => setTouchedAllLocal(stateRef.current, values));
  }

  return {
    touched: state.touched,
    setTouched: setTouched,
    setFieldTouched: setFieldTouched,
    setFieldsTouched: setFieldsTouched,
    setTouchedAll: setTouchedAll
  }
}