import { action, makeObservable, observable } from "mobx";
import { Utility } from "../../../shared-module/helpers/utility";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";

export interface ILegalRepresentative {
  _primary_primaryPhone: boolean,
  _secondary_primaryPhone: boolean,
  _primary_atleastOneContact: boolean,
  _secondary_atleastOneContact: boolean,

  primary_NoFirstName: boolean,
  primary_FirstName: string,
  primary_LastName: string,
  primary_MiddleName: string,
  primary_SuffixTypeId: string,
  primary_RepresentativeTypeId: string,
  primary_HasMailingInternationalAddress: boolean,
  primary_MailingStreetAddress: string,
  primary_MailingCity: string,
  primary_MailingStateId: string,
  primary_MailingZipCode: string,
  primary_MailingCountyId: string,
  primary_MailingInternationalAddress: string,
  primary_HasDifferentCurrentAddress: boolean,
  primary_HasCurrentInternationalAddress: boolean,
  primary_CurrentStreetAddress: string,
  primary_CurrentCity: string,
  primary_CurrentStateId: string,
  primary_CurrentZipCode: string,
  primary_CurrentCountyId: string,
  primary_CurrentInternationalAddress: string,
  primary_HomePhoneIsPrimary: boolean,
  primary_HomePhoneNumber: string,
  primary_MobilePhoneIsPrimary: boolean,
  primary_MobilePhoneNumber: string,
  primary_WorkPhoneIsPrimary: boolean,
  primary_WorkPhoneNumber: string,
  primary_EmailAddress: string,
  primary_PreferredLanguageId: string,
  primary_PreferredLanguageOther: string,

  hasSecondaryLegalRepresentative: string,

  secondary_NoFirstName: boolean,
  secondary_FirstName: string,
  secondary_LastName: string,
  secondary_MiddleName: string,
  secondary_SuffixTypeId: string,
  secondary_RepresentativeTypeId: string,
  secondary_HasMailingInternationalAddress: boolean,
  secondary_MailingStreetAddress: string,
  secondary_MailingCity: string,
  secondary_MailingStateId: string,
  secondary_MailingZipCode: string,
  secondary_MailingCountyId: string,
  secondary_MailingInternationalAddress: string,
  secondary_HasDifferentCurrentAddress: boolean,
  secondary_HasCurrentInternationalAddress: boolean,
  secondary_CurrentStreetAddress: string,
  secondary_CurrentCity: string,
  secondary_CurrentStateId: string,
  secondary_CurrentZipCode: string,
  secondary_CurrentCountyId: string,
  secondary_CurrentInternationalAddress: string,
  secondary_HomePhoneIsPrimary: boolean,
  secondary_HomePhoneNumber: string,
  secondary_MobilePhoneIsPrimary: boolean,
  secondary_MobilePhoneNumber: string,
  secondary_WorkPhoneIsPrimary: boolean,
  secondary_WorkPhoneNumber: string,
  secondary_EmailAddress: string,
  secondary_PreferredLanguageId: string,
  secondary_PreferredLanguageOther: string
}

class LegalRepresentativeStoreComponent implements ILegalRepresentative {
  _primary_primaryPhone: boolean;
  _secondary_primaryPhone: boolean;
  _primary_atleastOneContact: boolean;
  _secondary_atleastOneContact: boolean;

  primary_NoFirstName: boolean;
  primary_FirstName: string;
  primary_LastName: string;
  primary_MiddleName: string;
  primary_SuffixTypeId: string;
  primary_RepresentativeTypeId: string;
  primary_HasMailingInternationalAddress: boolean;
  primary_MailingStreetAddress: string;
  primary_MailingCity: string;
  primary_MailingStateId: string;
  primary_MailingZipCode: string;
  primary_MailingCountyId: string;
  primary_MailingInternationalAddress: string;
  primary_HasDifferentCurrentAddress: boolean;
  primary_HasCurrentInternationalAddress: boolean;
  primary_CurrentStreetAddress: string;
  primary_CurrentCity: string;
  primary_CurrentStateId: string;
  primary_CurrentZipCode: string;
  primary_CurrentCountyId: string;
  primary_CurrentInternationalAddress: string;
  primary_HomePhoneIsPrimary: boolean;
  primary_HomePhoneNumber: string;
  primary_MobilePhoneIsPrimary: boolean;
  primary_MobilePhoneNumber: string;
  primary_WorkPhoneIsPrimary: boolean;
  primary_WorkPhoneNumber: string;
  primary_EmailAddress: string;
  primary_PreferredLanguageId: string;
  primary_PreferredLanguageOther: string;

  hasSecondaryLegalRepresentative: string;

  secondary_NoFirstName: boolean;
  secondary_FirstName: string;
  secondary_LastName: string;
  secondary_MiddleName: string;
  secondary_SuffixTypeId: string;
  secondary_RepresentativeTypeId: string;
  secondary_HasMailingInternationalAddress: boolean;
  secondary_MailingStreetAddress: string;
  secondary_MailingCity: string;
  secondary_MailingStateId: string;
  secondary_MailingZipCode: string;
  secondary_MailingCountyId: string;
  secondary_MailingInternationalAddress: string;
  secondary_HasDifferentCurrentAddress: boolean;
  secondary_HasCurrentInternationalAddress: boolean;
  secondary_CurrentStreetAddress: string;
  secondary_CurrentCity: string;
  secondary_CurrentStateId: string;
  secondary_CurrentZipCode: string;
  secondary_CurrentCountyId: string;
  secondary_CurrentInternationalAddress: string;
  secondary_HomePhoneIsPrimary: boolean;
  secondary_HomePhoneNumber: string;
  secondary_MobilePhoneIsPrimary: boolean;
  secondary_MobilePhoneNumber: string;
  secondary_WorkPhoneIsPrimary: boolean;
  secondary_WorkPhoneNumber: string;
  secondary_EmailAddress: string;
  secondary_PreferredLanguageId: string;
  secondary_PreferredLanguageOther: string

  constructor() {
    this.primary_NoFirstName = false;
    this.primary_FirstName = "";
    this.primary_LastName = "";
    this.primary_MiddleName = "";
    this.primary_SuffixTypeId = "";
    this.primary_RepresentativeTypeId = "";
    this.primary_HasMailingInternationalAddress = false;
    this.primary_MailingStreetAddress = "";
    this.primary_MailingCity = "";
    this.primary_MailingStateId = "";
    this.primary_MailingZipCode = "";
    this.primary_MailingCountyId = "";
    this.primary_MailingInternationalAddress = "";
    this.primary_HasDifferentCurrentAddress = false;
    this.primary_HasCurrentInternationalAddress = false;
    this.primary_CurrentStreetAddress = "";
    this.primary_CurrentCity = "";
    this.primary_CurrentStateId = "";
    this.primary_CurrentZipCode = "";
    this.primary_CurrentCountyId = "";
    this.primary_CurrentInternationalAddress = "";
    this.primary_HomePhoneIsPrimary = false;
    this.primary_HomePhoneNumber = "";
    this.primary_MobilePhoneIsPrimary = false;
    this.primary_MobilePhoneNumber = "";
    this.primary_WorkPhoneIsPrimary = false;
    this.primary_WorkPhoneNumber = "";
    this.primary_EmailAddress = "";
    this.primary_PreferredLanguageId = "";
    this.primary_PreferredLanguageOther = "";

    this.hasSecondaryLegalRepresentative = "has-not-second-legal-representative";

    this.secondary_NoFirstName = false;
    this.secondary_FirstName = "";
    this.secondary_LastName = "";
    this.secondary_MiddleName = "";
    this.secondary_SuffixTypeId = "";
    this.secondary_RepresentativeTypeId = "";
    this.secondary_HasMailingInternationalAddress = false;
    this.secondary_MailingStreetAddress = "";
    this.secondary_MailingCity = "";
    this.secondary_MailingStateId = "";
    this.secondary_MailingZipCode = "";
    this.secondary_MailingCountyId = "";
    this.secondary_MailingInternationalAddress = "";
    this.secondary_HasDifferentCurrentAddress = false;
    this.secondary_HasCurrentInternationalAddress = false;
    this.secondary_CurrentStreetAddress = "";
    this.secondary_CurrentCity = "";
    this.secondary_CurrentStateId = "";
    this.secondary_CurrentZipCode = "";
    this.secondary_CurrentCountyId = "";
    this.secondary_CurrentInternationalAddress = "";
    this.secondary_HomePhoneIsPrimary = false;
    this.secondary_HomePhoneNumber = "";
    this.secondary_MobilePhoneIsPrimary = false;
    this.secondary_MobilePhoneNumber = "";
    this.secondary_WorkPhoneIsPrimary = false;
    this.secondary_WorkPhoneNumber = "";
    this.secondary_EmailAddress = "";
    this.secondary_PreferredLanguageId = "";
    this.secondary_PreferredLanguageOther = "";

    makeObservable(this, {
      primary_NoFirstName: observable,
      primary_FirstName: observable,
      primary_LastName: observable,
      primary_MiddleName: observable,
      primary_SuffixTypeId: observable,
      primary_RepresentativeTypeId: observable,
      primary_HasMailingInternationalAddress: observable,
      primary_MailingStreetAddress: observable,
      primary_MailingCity: observable,
      primary_MailingStateId: observable,
      primary_MailingZipCode: observable,
      primary_MailingCountyId: observable,
      primary_MailingInternationalAddress: observable,
      primary_HasDifferentCurrentAddress: observable,
      primary_HasCurrentInternationalAddress: observable,
      primary_CurrentStreetAddress: observable,
      primary_CurrentCity: observable,
      primary_CurrentStateId: observable,
      primary_CurrentZipCode: observable,
      primary_CurrentCountyId: observable,
      primary_CurrentInternationalAddress: observable,
      primary_HomePhoneIsPrimary: observable,
      primary_HomePhoneNumber: observable,
      primary_MobilePhoneIsPrimary: observable,
      primary_MobilePhoneNumber: observable,
      primary_WorkPhoneIsPrimary: observable,
      primary_WorkPhoneNumber: observable,
      primary_EmailAddress: observable,
      primary_PreferredLanguageId: observable,
      primary_PreferredLanguageOther: observable,

      hasSecondaryLegalRepresentative: observable,

      secondary_NoFirstName: observable,
      secondary_FirstName: observable,
      secondary_LastName: observable,
      secondary_MiddleName: observable,
      secondary_SuffixTypeId: observable,
      secondary_RepresentativeTypeId: observable,
      secondary_HasMailingInternationalAddress: observable,
      secondary_MailingStreetAddress: observable,
      secondary_MailingCity: observable,
      secondary_MailingStateId: observable,
      secondary_MailingZipCode: observable,
      secondary_MailingCountyId: observable,
      secondary_MailingInternationalAddress: observable,
      secondary_HasDifferentCurrentAddress: observable,
      secondary_HasCurrentInternationalAddress: observable,
      secondary_CurrentStreetAddress: observable,
      secondary_CurrentCity: observable,
      secondary_CurrentStateId: observable,
      secondary_CurrentZipCode: observable,
      secondary_CurrentCountyId: observable,
      secondary_CurrentInternationalAddress: observable,
      secondary_HomePhoneIsPrimary: observable,
      secondary_HomePhoneNumber: observable,
      secondary_MobilePhoneIsPrimary: observable,
      secondary_MobilePhoneNumber: observable,
      secondary_WorkPhoneIsPrimary: observable,
      secondary_WorkPhoneNumber: observable,
      secondary_EmailAddress: observable,
      secondary_PreferredLanguageId: observable,
      secondary_PreferredLanguageOther: observable,

      setPrimary_NoFirstName: action,
      setPrimary_FirstName: action,
      setPrimary_LastName: action,
      setPrimary_MiddleName: action,
      setPrimary_SuffixTypeId: action,
      setPrimary_RepresentativeTypeId: action,
      setPrimary_HasMailingInternationalAddress: action,
      setPrimary_MailingStreetAddress: action,
      setPrimary_MailingCity: action,
      setPrimary_MailingStateId: action,
      setPrimary_MailingZipCode: action,
      setPrimary_MailingCountyId: action,
      setPrimary_MailingInternationalAddress: action,
      setPrimary_HasDifferentCurrentAddress: action,
      setPrimary_HasCurrentInternationalAddress: action,
      setPrimary_CurrentStreetAddress: action,
      setPrimary_CurrentCity: action,
      setPrimary_CurrentStateId: action,
      setPrimary_CurrentZipCode: action,
      setPrimary_CurrentCountyId: action,
      setPrimary_CurrentInternationalAddress: action,
      setPrimary_HomePhoneIsPrimary: action,
      setPrimary_HomePhoneNumber: action,
      setPrimary_MobilePhoneIsPrimary: action,
      setPrimary_MobilePhoneNumber: action,
      setPrimary_WorkPhoneIsPrimary: action,
      setPrimary_WorkPhoneNumber: action,
      setPrimary_EmailAddress: action,
      setPrimary_PreferredLanguageId: action,
      setPrimary_PreferredLanguageOther: action,

      setHasSecondaryLegalRepresentative: action,

      setSecondary_NoFirstName: action,
      setSecondary_FirstName: action,
      setSecondary_LastName: action,
      setSecondary_MiddleName: action,
      setSecondary_SuffixTypeId: action,
      setSecondary_RepresentativeTypeId: action,
      setSecondary_HasMailingInternationalAddress: action,
      setSecondary_MailingStreetAddress: action,
      setSecondary_MailingCity: action,
      setSecondary_MailingStateId: action,
      setSecondary_MailingZipCode: action,
      setSecondary_MailingCountyId: action,
      setSecondary_MailingInternationalAddress: action,
      setSecondary_HasDifferentCurrentAddress: action,
      setSecondary_HasCurrentInternationalAddress: action,
      setSecondary_CurrentStreetAddress: action,
      setSecondary_CurrentCity: action,
      setSecondary_CurrentStateId: action,
      setSecondary_CurrentZipCode: action,
      setSecondary_CurrentCountyId: action,
      setSecondary_CurrentInternationalAddress: action,
      setSecondary_HomePhoneIsPrimary: action,
      setSecondary_HomePhoneNumber: action,
      setSecondary_MobilePhoneIsPrimary: action,
      setSecondary_MobilePhoneNumber: action,
      setSecondary_WorkPhoneIsPrimary: action,
      setSecondary_WorkPhoneNumber: action,
      setSecondary_EmailAddress: action,
      setSecondary_PreferredLanguageId: action,
      setSecondary_PreferredLanguageOther: action
    });
  }

  public setPrimary_NoFirstName(value: boolean) { this.primary_NoFirstName = value; }
  public setPrimary_FirstName(value: string) { this.primary_FirstName = value; }
  public setPrimary_LastName(value: string) { this.primary_LastName = value; }
  public setPrimary_MiddleName(value: string) { this.primary_MiddleName = value; }
  public setPrimary_SuffixTypeId(value: string) { this.primary_SuffixTypeId = value; }
  public setPrimary_RepresentativeTypeId(value: string) { this.primary_RepresentativeTypeId = value; }
  public setPrimary_HasMailingInternationalAddress(value: boolean) { this.primary_HasMailingInternationalAddress = value; }
  public setPrimary_MailingStreetAddress(value: string) { this.primary_MailingStreetAddress = value; }
  public setPrimary_MailingCity(value: string) { this.primary_MailingCity = value; }
  public setPrimary_MailingStateId(value: string) { this.primary_MailingStateId = value; }
  public setPrimary_MailingZipCode(value: string) { this.primary_MailingZipCode = value; }
  public setPrimary_MailingCountyId(value: string) { this.primary_MailingCountyId = value; }
  public setPrimary_MailingInternationalAddress(value: string) { this.primary_MailingInternationalAddress = value; }
  public setPrimary_HasDifferentCurrentAddress(value: boolean) { this.primary_HasDifferentCurrentAddress = value; }
  public setPrimary_HasCurrentInternationalAddress(value: boolean) { this.primary_HasCurrentInternationalAddress = value; }
  public setPrimary_CurrentStreetAddress(value: string) { this.primary_CurrentStreetAddress = value; }
  public setPrimary_CurrentCity(value: string) { this.primary_CurrentCity = value; }
  public setPrimary_CurrentStateId(value: string) { this.primary_CurrentStateId = value; }
  public setPrimary_CurrentZipCode(value: string) { this.primary_CurrentZipCode = value; }
  public setPrimary_CurrentCountyId(value: string) { this.primary_CurrentCountyId = value; }
  public setPrimary_CurrentInternationalAddress(value: string) { this.primary_CurrentInternationalAddress = value; }
  public setPrimary_HomePhoneIsPrimary(value: boolean) { this.primary_HomePhoneIsPrimary = value; }
  public setPrimary_HomePhoneNumber(value: string) { this.primary_HomePhoneNumber = value; }
  public setPrimary_MobilePhoneIsPrimary(value: boolean) { this.primary_MobilePhoneIsPrimary = value; }
  public setPrimary_MobilePhoneNumber(value: string) { this.primary_MobilePhoneNumber = value; }
  public setPrimary_WorkPhoneIsPrimary(value: boolean) { this.primary_WorkPhoneIsPrimary = value; }
  public setPrimary_WorkPhoneNumber(value: string) { this.primary_WorkPhoneNumber = value; }
  public setPrimary_EmailAddress(value: string) { this.primary_EmailAddress = value; }
  public setPrimary_PreferredLanguageId(value: string) { this.primary_PreferredLanguageId = value; }
  public setPrimary_PreferredLanguageOther(value: string) { this.primary_PreferredLanguageOther = value; }
  public setHasSecondaryLegalRepresentative(value: string) { this.hasSecondaryLegalRepresentative = value; }
  public setSecondary_NoFirstName(value: boolean) { this.secondary_NoFirstName = value; }
  public setSecondary_FirstName(value: string) { this.secondary_FirstName = value; }
  public setSecondary_LastName(value: string) { this.secondary_LastName = value; }
  public setSecondary_MiddleName(value: string) { this.secondary_MiddleName = value; }
  public setSecondary_SuffixTypeId(value: string) { this.secondary_SuffixTypeId = value; }
  public setSecondary_RepresentativeTypeId(value: string) { this.secondary_RepresentativeTypeId = value; }
  public setSecondary_HasMailingInternationalAddress(value: boolean) { this.secondary_HasMailingInternationalAddress = value; }
  public setSecondary_MailingStreetAddress(value: string) { this.secondary_MailingStreetAddress = value; }
  public setSecondary_MailingCity(value: string) { this.secondary_MailingCity = value; }
  public setSecondary_MailingStateId(value: string) { this.secondary_MailingStateId = value; }
  public setSecondary_MailingZipCode(value: string) { this.secondary_MailingZipCode = value; }
  public setSecondary_MailingCountyId(value: string) { this.secondary_MailingCountyId = value; }
  public setSecondary_MailingInternationalAddress(value: string) { this.secondary_MailingInternationalAddress = value; }
  public setSecondary_HasDifferentCurrentAddress(value: boolean) { this.secondary_HasDifferentCurrentAddress = value; }
  public setSecondary_HasCurrentInternationalAddress(value: boolean) { this.secondary_HasCurrentInternationalAddress = value; }
  public setSecondary_CurrentStreetAddress(value: string) { this.secondary_CurrentStreetAddress = value; }
  public setSecondary_CurrentCity(value: string) { this.secondary_CurrentCity = value; }
  public setSecondary_CurrentStateId(value: string) { this.secondary_CurrentStateId = value; }
  public setSecondary_CurrentZipCode(value: string) { this.secondary_CurrentZipCode = value; }
  public setSecondary_CurrentCountyId(value: string) { this.secondary_CurrentCountyId = value; }
  public setSecondary_CurrentInternationalAddress(value: string) { this.secondary_CurrentInternationalAddress = value; }
  public setSecondary_HomePhoneIsPrimary(value: boolean) { this.secondary_HomePhoneIsPrimary = value; }
  public setSecondary_HomePhoneNumber(value: string) { this.secondary_HomePhoneNumber = value; }
  public setSecondary_MobilePhoneIsPrimary(value: boolean) { this.secondary_MobilePhoneIsPrimary = value; }
  public setSecondary_MobilePhoneNumber(value: string) { this.secondary_MobilePhoneNumber = value; }
  public setSecondary_WorkPhoneIsPrimary(value: boolean) { this.secondary_WorkPhoneIsPrimary = value; }
  public setSecondary_WorkPhoneNumber(value: string) { this.secondary_WorkPhoneNumber = value; }
  public setSecondary_EmailAddress(value: string) { this.secondary_EmailAddress = value; }
  public setSecondary_PreferredLanguageId(value: string) { this.secondary_PreferredLanguageId = value; }
  public setSecondary_PreferredLanguageOther(value: string) { this.secondary_PreferredLanguageOther = value; } action


  public refresh(app: GatewayOapApi.ApplicationModel): void {
    var state = this.buildState(app);
    this.setPrimary_NoFirstName(state.primary_NoFirstName);
    this.setPrimary_FirstName(state.primary_FirstName);
    this.setPrimary_LastName(state.primary_LastName);
    this.setPrimary_MiddleName(state.primary_MiddleName);
    this.setPrimary_SuffixTypeId(state.primary_SuffixTypeId);
    this.setPrimary_RepresentativeTypeId(state.primary_RepresentativeTypeId);
    this.setPrimary_HasMailingInternationalAddress(state.primary_HasMailingInternationalAddress);
    this.setPrimary_MailingStreetAddress(state.primary_MailingStreetAddress);
    this.setPrimary_MailingCity(state.primary_MailingCity);
    this.setPrimary_MailingStateId(state.primary_MailingStateId);
    this.setPrimary_MailingZipCode(state.primary_MailingZipCode);
    this.setPrimary_MailingCountyId(state.primary_MailingCountyId);
    this.setPrimary_MailingInternationalAddress(state.primary_MailingInternationalAddress);
    this.setPrimary_HasDifferentCurrentAddress(state.primary_HasDifferentCurrentAddress);
    this.setPrimary_HasCurrentInternationalAddress(state.primary_HasCurrentInternationalAddress);
    this.setPrimary_CurrentStreetAddress(state.primary_CurrentStreetAddress);
    this.setPrimary_CurrentCity(state.primary_CurrentCity);
    this.setPrimary_CurrentStateId(state.primary_CurrentStateId);
    this.setPrimary_CurrentZipCode(state.primary_CurrentZipCode);
    this.setPrimary_CurrentCountyId(state.primary_CurrentCountyId);
    this.setPrimary_CurrentInternationalAddress(state.primary_CurrentInternationalAddress);
    this.setPrimary_HomePhoneIsPrimary(state.primary_HomePhoneIsPrimary);
    this.setPrimary_HomePhoneNumber(state.primary_HomePhoneNumber);
    this.setPrimary_MobilePhoneIsPrimary(state.primary_MobilePhoneIsPrimary);
    this.setPrimary_MobilePhoneNumber(state.primary_MobilePhoneNumber);
    this.setPrimary_WorkPhoneIsPrimary(state.primary_WorkPhoneIsPrimary);
    this.setPrimary_WorkPhoneNumber(state.primary_WorkPhoneNumber);
    this.setPrimary_EmailAddress(state.primary_EmailAddress);
    this.setPrimary_PreferredLanguageId(state.primary_PreferredLanguageId);
    this.setPrimary_PreferredLanguageOther(state.primary_PreferredLanguageOther);

    this.setHasSecondaryLegalRepresentative(state.hasSecondaryLegalRepresentative);

    this.setSecondary_NoFirstName(state.secondary_NoFirstName);
    this.setSecondary_FirstName(state.secondary_FirstName);
    this.setSecondary_LastName(state.secondary_LastName);
    this.setSecondary_MiddleName(state.secondary_MiddleName);
    this.setSecondary_SuffixTypeId(state.secondary_SuffixTypeId);
    this.setSecondary_RepresentativeTypeId(state.secondary_RepresentativeTypeId);
    this.setSecondary_HasMailingInternationalAddress(state.secondary_HasMailingInternationalAddress);
    this.setSecondary_MailingStreetAddress(state.secondary_MailingStreetAddress);
    this.setSecondary_MailingCity(state.secondary_MailingCity);
    this.setSecondary_MailingStateId(state.secondary_MailingStateId);
    this.setSecondary_MailingZipCode(state.secondary_MailingZipCode);
    this.setSecondary_MailingCountyId(state.secondary_MailingCountyId);
    this.setSecondary_MailingInternationalAddress(state.secondary_MailingInternationalAddress);
    this.setSecondary_HasDifferentCurrentAddress(state.secondary_HasDifferentCurrentAddress);
    this.setSecondary_HasCurrentInternationalAddress(state.secondary_HasCurrentInternationalAddress);
    this.setSecondary_CurrentStreetAddress(state.secondary_CurrentStreetAddress);
    this.setSecondary_CurrentCity(state.secondary_CurrentCity);
    this.setSecondary_CurrentStateId(state.secondary_CurrentStateId);
    this.setSecondary_CurrentZipCode(state.secondary_CurrentZipCode);
    this.setSecondary_CurrentCountyId(state.secondary_CurrentCountyId);
    this.setSecondary_CurrentInternationalAddress(state.secondary_CurrentInternationalAddress);
    this.setSecondary_HomePhoneIsPrimary(state.secondary_HomePhoneIsPrimary);
    this.setSecondary_HomePhoneNumber(state.secondary_HomePhoneNumber);
    this.setSecondary_MobilePhoneIsPrimary(state.secondary_MobilePhoneIsPrimary);
    this.setSecondary_MobilePhoneNumber(state.secondary_MobilePhoneNumber);
    this.setSecondary_WorkPhoneIsPrimary(state.secondary_WorkPhoneIsPrimary);
    this.setSecondary_WorkPhoneNumber(state.secondary_WorkPhoneNumber);
    this.setSecondary_EmailAddress(state.secondary_EmailAddress);
    this.setSecondary_PreferredLanguageId(state.secondary_PreferredLanguageId);
    this.setSecondary_PreferredLanguageOther(state.secondary_PreferredLanguageOther);
  }

  public buildState(app: GatewayOapApi.ApplicationModel): ILegalRepresentative {
    const primary = app.primaryLegalRepresentative;
    const secondary = app.secondaryLegalRepresentative;
    const hasSecondaryLegalRep = Utility.defaultIfNull(app.hasSecondaryLegalRepresentative, null);
    const primaryMailingAddress = primary && primary.mailingAddress;
    const primaryCurrentAddress = primary && primary.currentAddress;
    const secondaryMailingAddress = secondary && secondary.mailingAddress;
    const secondaryCurrentAddress = secondary && secondary.currentAddress;

    const primaryHomePhone = primary && primary.homePhone;
    const primaryMobilePhone = primary && primary.mobilePhone;
    const primaryWorkPhone = primary && primary.workPhone;

    const secondaryHomePhone = secondary && secondary.homePhone;
    const secondaryMobilePhone = secondary && secondary.mobilePhone;
    const secondaryWorkPhone = secondary && secondary.workPhone;

    return {
      _primary_primaryPhone: true,
      _secondary_primaryPhone: true,
      _primary_atleastOneContact: true,
      _secondary_atleastOneContact: true,
      primary_NoFirstName: Utility.defaultIfNull(primary && primary.noFirstName, false),
      primary_FirstName: Utility.defaultIfNull(primary && primary.firstName, ""),
      primary_MiddleName: Utility.defaultIfNull(primary && primary.middleName, ""),
      primary_SuffixTypeId: Utility.numberToString(primary && primary.suffixTypeId, ""),
      primary_LastName: Utility.defaultIfNull(primary && primary.lastName, ""),
      primary_RepresentativeTypeId: Utility.numberToString(primary && primary.legalRepresentativeTypeId, ""),
      primary_HasMailingInternationalAddress: Utility.defaultIfNull(primaryMailingAddress && primaryMailingAddress.isInternational, false),
      primary_MailingStreetAddress: Utility.defaultIfNull(primaryMailingAddress && primaryMailingAddress.streetAddress1, ""),
      primary_MailingCity: Utility.defaultIfNull(primaryMailingAddress && primaryMailingAddress.city, ""),
      primary_MailingStateId: Utility.numberToString(primaryMailingAddress && primaryMailingAddress.stateId, ""),
      primary_MailingZipCode: Utility.mergeZipCodeParts(primaryMailingAddress?.zipCode, primaryMailingAddress?.zipPlus4, ""),
      primary_MailingCountyId: Utility.numberToString(primaryMailingAddress && primaryMailingAddress.countyId, ""),
      primary_MailingInternationalAddress: Utility.defaultIfNull(primaryMailingAddress && primaryMailingAddress.internationalAddress, ""),
      primary_HasDifferentCurrentAddress: Utility.defaultIfNull(primary && primary.hasDifferentCurrentAddress, false),
      primary_HasCurrentInternationalAddress: Utility.defaultIfNull(primaryCurrentAddress && primaryCurrentAddress.isInternational, false),
      primary_CurrentStreetAddress: Utility.defaultIfNull(primaryCurrentAddress && primaryCurrentAddress.streetAddress1, ""),
      primary_CurrentCity: Utility.defaultIfNull(primaryCurrentAddress && primaryCurrentAddress.city, ""),
      primary_CurrentStateId: Utility.numberToString(primaryCurrentAddress && primaryCurrentAddress.stateId, ""),
      primary_CurrentZipCode: Utility.mergeZipCodeParts(primaryCurrentAddress?.zipCode, primaryCurrentAddress?.zipPlus4, ""),
      primary_CurrentCountyId: Utility.numberToString(primaryCurrentAddress && primaryCurrentAddress.countyId, ""),
      primary_CurrentInternationalAddress: Utility.defaultIfNull(primaryCurrentAddress && primaryCurrentAddress.internationalAddress, ""),
      primary_HomePhoneIsPrimary: Utility.defaultIfNull(primaryHomePhone && primaryHomePhone.isPrimary, false),
      primary_HomePhoneNumber: format(Utility.defaultIfNull(primaryHomePhone && primaryHomePhone.phoneNumber, "")),
      primary_MobilePhoneIsPrimary: Utility.defaultIfNull(primaryMobilePhone && primaryMobilePhone.isPrimary, false),
      primary_MobilePhoneNumber: format(Utility.defaultIfNull(primaryMobilePhone && primaryMobilePhone.phoneNumber, "")),
      primary_WorkPhoneIsPrimary: Utility.defaultIfNull(primaryWorkPhone && primaryWorkPhone.isPrimary, false),
      primary_WorkPhoneNumber: format(Utility.defaultIfNull(primaryWorkPhone && primaryWorkPhone.phoneNumber, "")),
      primary_EmailAddress: Utility.defaultIfNull(primary && primary.emailAddress, ""),
      primary_PreferredLanguageId: Utility.numberToString(primary && primary.preferredLanguageId, ""),
      primary_PreferredLanguageOther: Utility.defaultIfNull(primary && primary.preferredLanguageOther, ""),

      hasSecondaryLegalRepresentative: hasSecondaryLegalRep ?
        "has-second-legal-representative" : "has-not-second-legal-representative",

      secondary_NoFirstName: Utility.defaultIfNull(secondary && secondary.noFirstName, false),
      secondary_FirstName: Utility.defaultIfNull(secondary && secondary.firstName, ""),
      secondary_LastName: Utility.defaultIfNull(secondary && secondary.lastName, ""),
      secondary_MiddleName: Utility.defaultIfNull(secondary && secondary.middleName, ""),
      secondary_SuffixTypeId: Utility.numberToString(secondary && secondary.suffixTypeId, ""),
      secondary_RepresentativeTypeId: Utility.numberToString(secondary && secondary.legalRepresentativeTypeId, ""),
      secondary_HasMailingInternationalAddress: Utility.defaultIfNull(secondaryMailingAddress && secondaryMailingAddress.isInternational, false),
      secondary_MailingStreetAddress: Utility.defaultIfNull(secondaryMailingAddress && secondaryMailingAddress.streetAddress1, ""),
      secondary_MailingCity: Utility.defaultIfNull(secondaryMailingAddress && secondaryMailingAddress.city, ""),
      secondary_MailingStateId: Utility.numberToString(secondaryMailingAddress && secondaryMailingAddress.stateId, ""),
      secondary_MailingZipCode: Utility.mergeZipCodeParts(secondaryMailingAddress?.zipCode, secondaryMailingAddress?.zipPlus4, ""),
      secondary_MailingCountyId: Utility.numberToString(secondaryMailingAddress && secondaryMailingAddress.countyId, ""),
      secondary_MailingInternationalAddress: Utility.defaultIfNull(secondaryMailingAddress && secondaryMailingAddress.internationalAddress, ""),
      secondary_HasDifferentCurrentAddress: Utility.defaultIfNull(secondary && secondary.hasDifferentCurrentAddress, false),
      secondary_HasCurrentInternationalAddress: Utility.defaultIfNull(secondaryCurrentAddress && secondaryCurrentAddress.isInternational, false),
      secondary_CurrentStreetAddress: Utility.defaultIfNull(secondaryCurrentAddress && secondaryCurrentAddress.streetAddress1, ""),
      secondary_CurrentCity: Utility.defaultIfNull(secondaryCurrentAddress && secondaryCurrentAddress.city, ""),
      secondary_CurrentStateId: Utility.numberToString(secondaryCurrentAddress && secondaryCurrentAddress.stateId, ""),
      secondary_CurrentZipCode: Utility.mergeZipCodeParts(secondaryCurrentAddress?.zipCode, secondaryCurrentAddress?.zipPlus4, ""),
      secondary_CurrentCountyId: Utility.numberToString(secondaryCurrentAddress && secondaryCurrentAddress.countyId, ""),
      secondary_CurrentInternationalAddress: Utility.defaultIfNull(secondaryCurrentAddress && secondaryCurrentAddress.internationalAddress, ""),
      secondary_HomePhoneIsPrimary: Utility.defaultIfNull(secondaryHomePhone && secondaryHomePhone.isPrimary, false),
      secondary_HomePhoneNumber: format(Utility.defaultIfNull(secondaryHomePhone && secondaryHomePhone.phoneNumber, "")),
      secondary_MobilePhoneIsPrimary: Utility.defaultIfNull(secondaryMobilePhone && secondaryMobilePhone.isPrimary, false),
      secondary_MobilePhoneNumber: format(Utility.defaultIfNull(secondaryMobilePhone && secondaryMobilePhone.phoneNumber, "")),
      secondary_WorkPhoneIsPrimary: Utility.defaultIfNull(secondaryWorkPhone && secondaryWorkPhone.isPrimary, false),
      secondary_WorkPhoneNumber: format(Utility.defaultIfNull(secondaryWorkPhone && secondaryWorkPhone.phoneNumber, "")),
      secondary_EmailAddress: Utility.defaultIfNull(secondary && secondary.emailAddress, ""),
      secondary_PreferredLanguageId: Utility.numberToString(secondary && secondary.preferredLanguageId, ""),
      secondary_PreferredLanguageOther: Utility.defaultIfNull(secondary && secondary.preferredLanguageOther, "")
    }
    }

    public formatPhoneNumber() {
        this.setPrimary_HomePhoneNumber(format(this.primary_HomePhoneNumber));
        this.setPrimary_MobilePhoneNumber(format(this.primary_MobilePhoneNumber));
        this.setPrimary_WorkPhoneNumber(format(this.primary_WorkPhoneNumber));

        this.setSecondary_HomePhoneNumber(format(this.secondary_HomePhoneNumber));
        this.setSecondary_MobilePhoneNumber(format(this.secondary_MobilePhoneNumber));
        this.setSecondary_WorkPhoneNumber(format(this.secondary_WorkPhoneNumber));
    }

    public unformat(value: string): string {
        return unformat(value);
    }

}

function unformat(value: string): string {
    return value?.replace(/\D+/g, '');
}

function format(value: string): string {
    if (value && value.length > 0) {
        var phone = unformat(value);
        if (phone.length > 3) {
            var firstPart = `(${phone.substring(0, 3)}) `;
            var secondPart = phone.length > 6 ? `${phone.substring(3, 6)}-` : `${phone.substring(3)}`;
            var thirdPart = phone.length > 6 ? phone.substring(6, 10) : ``;
            return `${firstPart}${secondPart}${thirdPart}`;
        }

        return phone;
    }
    return;
}
export const LegalRepresentativeStore = new LegalRepresentativeStoreComponent(); 