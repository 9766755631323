import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarBehavioralInformationResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

    public getNbarBehavioralInformation(behaviorInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiSingleResponseOfBehavioralInformationModel>
    {
        return super.getRequest(`api/v1/nbar/behavioral/${behaviorInformationId}`, null, abortController) as
            Promise<GatewayOapApi.ApiSingleResponseOfBehavioralInformationModel>;
    }

    public saveNbarBehavioralInformation(
        behavioralInformationId: number,
        hasInappropriateLanguge: number, languageDescription: string, languageFreqTypeId: number, languageTechniques: string,
        refuseToAcceptDirection: number, refusalDescription: string, refusalFreqTypeId: number, refusalTechniques: string,
        hasIntimidation: number, intimidationDescription: string, intimidationFreqTypeId: number, intimidationTechniques: string,
        hasDestructionOfPersonalProp: number, destructionOfPersonalPropDesc: string, destructionOfPersonalPropFreqTypeId: number, destructionOfPersonalPropTech: string,
        hasDestructionOfPublicProp: number, destructionOfPublicPropDesc: string, destructionOfPublicPropFreqTypeId: number, destructionOfPublicPropTech: string,
        hasSelfAgression: number, SelfAgressionDesc: string, SelfAgressionFreqTypeId: number, SelfAgressionTech: string,
        hasAgressionOthers: number, AgressionOthersDesc: string, AgressionOthersFreqTypeId: number, AgressionOthersTech: string,
        hasElopementHome: number, elopementHomeDesc: string, elopementHomeFreqTypeId: number, elopementHomeTech: string,
        hasElopementCommunity: number, elopementCommunityDesc: string, elopementCommunityFreqTypeId: number, elopementCommunityTech: string,
        hasRageAndOutbursts: number, rageAndOutburstsDesc: string, rageAndOutburstsFreqTypeId: number, rageAndOutburstsTech: string,
        hasInappropriateSexualConduct: number, inappropriateSexualConductDesc: string, inappropriateSexualConductFreqTypeId: number, inappropriateSexualConductTech: string,
        abortController?: AbortController)
        : Promise<GatewayOapApi.ApiIntegerResponse>
    {
      return super.postRequest(`api/v1/nbar/behavioral/update`, 
      null, 
      {
        behavioralInformationId,
        hasInappropriateLanguge, languageDescription, languageFreqTypeId, languageTechniques,
        refuseToAcceptDirection, refusalDescription, refusalFreqTypeId, refusalTechniques,
        hasIntimidation, intimidationDescription, intimidationFreqTypeId, intimidationTechniques,
        hasDestructionOfPersonalProp, destructionOfPersonalPropDesc, destructionOfPersonalPropFreqTypeId, destructionOfPersonalPropTech,
        hasDestructionOfPublicProp, destructionOfPublicPropDesc, destructionOfPublicPropFreqTypeId, destructionOfPublicPropTech,
        hasSelfAgression, SelfAgressionDesc, SelfAgressionFreqTypeId, SelfAgressionTech,
        hasAgressionOthers, AgressionOthersDesc, AgressionOthersFreqTypeId, AgressionOthersTech,
        hasElopementHome, elopementHomeDesc, elopementHomeFreqTypeId, elopementHomeTech,
        hasElopementCommunity, elopementCommunityDesc, elopementCommunityFreqTypeId, elopementCommunityTech,
        hasRageAndOutbursts, rageAndOutburstsDesc, rageAndOutburstsFreqTypeId, rageAndOutburstsTech,
        hasInappropriateSexualConduct, inappropriateSexualConductDesc, inappropriateSexualConductFreqTypeId, inappropriateSexualConductTech
      },
      abortController) as 
        Promise<GatewayOapApi.ApiIntegerResponse>;
    }  
}

// behavioralInformationId: number,
// hasInappropriateLanguge: number, languageDescription: string, languageFreqTypeId: number, languageTechniques: string,
// refuseToAcceptDirection: number, refusalDescription: string, refusalFreqTypeId: number, refusalTechniques: string,
// hasIntimidation: number, intimidationDescription: string, intimidationFreqTypeId: number, intimidationTechniques: string,
// hasDestructionOfPersonalProp: number, destructionOfPersonalPropDesc: string, destructionOfPersonalPropFreqTypeId: number, destructionOfPersonalPropTech: string,
// hasDestructionOfPublicProp: number, destructionOfPublicPropDesc: string, destructionOfPublicPropFreqTypeId: number, destructionOfPublicPropTech: string,
// hasSelfAgression: number, SelfAgressionDesc: string, SelfAgressionFreqTypeId: number, SelfAgressionTech: string,
// hasAgressionOthers: number, AgressionOthersDesc: string, AgressionOthersFreqTypeId: number, AgressionOthersTech: string,
// hasElopementHome: number, elopementHomeDesc: string, elopementHomeFreqTypeId: number, elopementHomeTech: string,
// hasElopementCommunity: number, elopementCommunityDesc: string, elopementCommunityFreqTypeId: number, elopementCommunityTech: string,
// hasRageAndOutbursts: number, rageAndOutburstsDesc: string, rageAndOutburstsFreqTypeId: number, rageAndOutburstsTech: string,
// hasInappropriateSexualConduct: number, inappropriateSexualConductDesc: string, inappropriateSexualConductFreqTypeId: number, inappropriateSexualConductTech: string