import confirmModalEventBus from "./confirm-modal-event-bus";
import { ConfirmModalOptions } from "./confirm-modal-container.component";

export enum ConfirmModalEvents {
  MODAL_SHOW = "CONFIRM.MODAL.SHOW",
  MODAL_HIDE = "CONFIRM.MODAL.HIDE"
}

class ConfirmModal {
  private static instance: ConfirmModal;

  private constructor() {
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ConfirmModal();
    }
    return this.instance;
  }

  public showModal(options: ConfirmModalOptions) {
    confirmModalEventBus.dispatch(ConfirmModalEvents.MODAL_SHOW, options);
  }

  public hideModal() {
    confirmModalEventBus.dispatch(ConfirmModalEvents.MODAL_HIDE, null);
  }
}

export default ConfirmModal.getInstance();
