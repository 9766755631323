interface Props {
  id: string
}

function AcceptUserInvitationModalComponent(props: Props) {

  return (
    <>
      <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Accept Invitation</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>Enter the Unique 4-digit PIN that has been shared with you to access the application.</div>
              <div className="row mt-4">
                <div className="col-4 mandatory">PIN:</div>
                <div className="col-8">
                  <input type="password" 
                    className="bds-modal-form-text bds-modal-pin form-control" maxLength={4} />
                </div>
                <div className="col-4 mt-2 mandatory">Confirm PIN:</div>
                <div className="col-8 mt-2">
                  <input type="password"
                    className="bds-modal-form-text bds-modal-pin form-control" maxLength={4} />
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const AcceptUserInvitationModal = AcceptUserInvitationModalComponent