import { autorun } from "mobx";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormFieldState } from "../../components/form-field-status/form-field-indicator.component";
import { useFormTouch } from "../use-form-touch/use-form-touch";

export interface BdsYupValidationError extends Record<string, unknown> {
  key: string,
  message: string
}

interface SubmittingState {
  isSubmitting: boolean,
  eventSource: string
}

export function useFormValidation<T>(validationSchema: Yup.ObjectSchema<T>, data: T, initialyTouched?: string[]) {
  const [errors, setErrors] = useState<BdsYupValidationError[]>([]);
  const [submitting, setSubmitting] = useState<SubmittingState>({ isSubmitting: false, eventSource: null });

  const { setTouchedAll, setFieldsTouched, touched } = useFormTouch<T>({
    initialTouched: initialyTouched
  });

  function setIsSubmitting(isSubmitting: boolean, eventSource: string): void {
    setSubmitting({ isSubmitting: isSubmitting, eventSource: isSubmitting ? eventSource : null });
  }

  function getFieldState<T, E>(name: string, currentValue: T, previousValue: T): FormFieldState<T, BdsYupValidationError> {
    return {
      name: name,
      isSubmitting: submitting.isSubmitting,
      isFieldSubmitting: submitting.isSubmitting && submitting.eventSource === name,
      isFieldTouched: touched[name],
      currentValue: currentValue,
      previousValue: previousValue,
      fieldError: errors.find((item) => item.key === name) || null,
    };
  }

  function touchAll() {
    setTouchedAll(data);
  }

  useEffect(() => {
    autorun(() => {
      validationSchema.validate(data, { abortEarly: false })
        .then((response) => {
          setErrors([] as BdsYupValidationError[]);
        })
        .catch((err) => {
          setErrors(err.errors as BdsYupValidationError[]);
        });
    });
  }, [])

  return {
    errors: errors,
    setIsSubmitting: setIsSubmitting,
    getFieldState: getFieldState,
    touched: touched,
    setFieldsTouched: setFieldsTouched,
    touchedAll: touchAll
  };
}