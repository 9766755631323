
interface Props { }

export const NbarReferences = () => {
    return (
        <>
            <p>
                <strong>IC 12-15-1.3-15</strong><br />
                <strong>Waiver amendment; emergency placement priority</strong>
            </p>

            <span>Sec. 15. (a) As used in this section, "division" refers to the division of disability and rehabilitative services established by IC 12-9-1-1.</span>
            <ul className="no-bullets">
                <li>(b) As used in this section, "waiver" refers to any waiver administered by the office and the division under section 1915(c) of the federal Social Security Act.</li>
                <li>(c) Before October 1, 2011, the office shall apply to the United States Department of Health and Human Services for approval to amend a waiver to set an emergency placement priority for individuals in the following situations:
                    <ul className="no-bullets">
                        <li>(1) Death of a primary caregiver where alternative placement in a supervised group living setting:
                            <ul className="no-bullets">
                                <li>(A) is not available; or</li>
                                <li>(B) is determined by the division to be an inappropriate option.</li>
                            </ul>
                        </li>
                        <li>(2) A situation in which:
                            <ul className="no-bullets">
                                <li>(A) the primary caregiver is at least eighty (80) years of age; and</li>
                                <li>(B) alternate placement in a supervised group living setting is not available or is determined by the division to be an inappropriate option.</li>
                            </ul>
                        </li>
                        <li>(3) There is evidence of abuse or neglect in the current institutional or home placement, and alternate placement in a supervised group living setting is not available or is determined by the division to be an inappropriate option.</li>
                        <li>(4) There are other health and safety risks, as determined by the division director, and alternate placement in a supervised group living setting is not available or is determined by the division to be an inappropriate option.</li>
                    </ul>
                </li>
                <li>(d) The division shall report on a quarterly basis the following information to the division of disability and rehabilitative services advisory council established by IC 12-9-4-2 concerning each Medicaid waiver for which the office has been approved under this section to administer an emergency placement priority for individuals described in this section:
                    <ul className="no-bullets">
                        <li>(1) The number of applications for emergency placement priority waivers.</li>
                        <li>(2) The number of individuals served on the waiver.</li>
                        <li>(3) The number of individuals on a wait list for the waiver.</li>
                    </ul>
                </li>
                <li>(e) The office may adopt rules under IC 4-22-2 necessary to implement this section.</li>
                <li>(f) This section expires July 1, 2016.</li>
            </ul>
            <p>
                As added by P.L.220-2011, SEC.264. Amended by P.L.229-2011,
                Please be honest, accurate, and thorough in your responses to these questions so that we may identify the most appropriate service mix available.  If additional space is needed only any of the questions, please indicate that additional information is available on a separate page and attach that document to this questionnaire when you return to the BDS office.
            </p>
        </>
    )
}