import App from "./components/_app/app.component";
import HomePage from "./components/home-page/home-page.component";
import ApplicationInformation from "./components/application-information/application-information.component";
import ApplicantInformation from "./components/applicant-information/applicant-information.component";
import ApplicantDemographics from "./components/applicant-demographics/applicant-demographics.component";
import ApplicantionSummary from "./components/application-summary/application-summary.component";
import ApplicantSignature from "./components/applicant-signature/applicant-signature.component";
import PageNotFound from "./components/page-not-found/page-not-found.component";
import ApplicationSessionExpired from "./components/application-session-expired/application-session-expired.component";
import LegalRepresentative from "./components/legal-representative/legal-representative.component";
import ApplicationSubmitted from "./components/application-submitted/applicationsubmitted.component";
import ApplicantDashboard from "./components/applicant-dashboard/applicant-dashboard.component";
import { RouteObject, redirect } from "react-router-dom";
import StartApplication from "./components/start-application/start-application.component";
import { OidcAuthorize } from "./components/oidc/oidc-authorize/oidc-authorize";
import { OidcUnauthorize } from "./components/oidc/oidc-unauthorize/oidc-unauthorize";
import { SignInOidcLanding } from "./components/oidc/signin-landing-oidc/signin-landing-oidc";
import { SignOutOidcLanding } from "./components/oidc/signout-landing-oidc/signout-landing-oidc";
import { Layout } from "./components/_layout/layout.component";
import { ErrorBoundary } from "../shared-module/components/error-boundary/error-boundary";
import ApplicantDocumentUpload from "./components/applicant-document-upload/applicant-document-upload";
import { SessionService } from "shared-module/services/session.service";
import { NavigationHelper } from "shared-module/helpers/navigation-helper";
import BehavioralInformationComponent from "./components/_NBAR/behavioral-information/behavioral-information.component";
import NbarInstructions from "./components/_NBAR/instructions/nbar-instructions.component";
import DemographicInformationComponent from "./components/_NBAR/demographic-information/demographic-information.component";
import TreatmentInformationComponent from "./components/_NBAR/treatment-information/treatment-information.component";
import NbarSignatureComponent from "./components/_NBAR/signature/nbar-signature.component";
import StartNbarApplication from "./components/_NBAR/start-application/nbar-start-application.component"
import NBarLanding from "./components/_NBAR/landing-page/nbar-landing.component";
import ApplicantPreferencesComponent from "./components/_NBAR/applicant-preferences/applicant-preferences.component";
import NbarCreateApplication from "./components/_NBAR/create-application/create-application.component";
import { OidcNbarAuthorize } from "./components/oidc/oidc-nbar-authorize/oidc-nbar-authorize";
import { NbarLayout } from "./components/_layout/nbar-layout.component";

function routes() {

  async function authenticationGuard() {
    var authenticated = await SessionService.isAuthenticated();
    if (!authenticated) {
      return redirect(NavigationHelper.routes.home);
    }
    return null;
  }

  async function permissionGuard(permissions: string[]) {
    var HasPermissions = await SessionService.hasPermission(permissions);
    if (!HasPermissions) {
      return redirect(NavigationHelper.routes.home);
    }
    return null;
  }

  return [
    {
      path: "/"
    },
    {
      path: "",
      element: <App></App>,
      errorElement: <ErrorBoundary></ErrorBoundary>,
      children: [
        {
          path: "",
          element: <Layout></Layout>,
          children: [
            { index: true, element: <HomePage /> },
              
            { path: "home", element: <HomePage /> },
            { 
              path: "dashboard", 
              element: <ApplicantDashboard />, 
              loader: async () => await authenticationGuard() 
            },
            { path: "start-application", element: <StartApplication /> },
            { path: "start-application/:id", element: <StartApplication /> },
            { path: "application-information/:id", element: <ApplicationInformation /> },
            { path: "applicant-information/:id", element: <ApplicantInformation /> },
            { path: "applicant-demographics/:id", element: <ApplicantDemographics /> },
            { path: "legal-representative/:id", element: <LegalRepresentative /> },
            { path: "application-summary/:id", element: <ApplicantionSummary /> },            
            { path: "upload-documents/:id", element: <ApplicantDocumentUpload /> },
            { path: "signature/:id", element: <ApplicantSignature /> },
            { path: "application-submitted/:id", element: <ApplicationSubmitted /> },
            { path: "session-expired", element: <ApplicationSessionExpired /> },
          ]
        },
        {
          path: "",
          element: <NbarLayout></NbarLayout>,
          children: [
            { path: "nbar", element: <NBarLanding />},
            { path: "nbar-instructions", element: <NbarInstructions /> },
            { path: "nbar-create-application/", element: <NbarCreateApplication /> },
            { path: "nbar-create-application/:id", element: <NbarCreateApplication /> },
            { path: "nbar-start-application/:id", element: <StartNbarApplication /> },
            { path: "nbar-demographic-information/:id", element: <DemographicInformationComponent /> },
            { path: "nbar-treatment-information/:id", element: <TreatmentInformationComponent /> },
            { path: "nbar-applicant-preferences/:id", element: <ApplicantPreferencesComponent />},
            { path: "nbar-behavioral-information/:id", element: <BehavioralInformationComponent /> },
            { path: "nbar-signature/:id", element: <NbarSignatureComponent /> },
          ]
        },
        { path: "authorize", element: <OidcAuthorize /> },
        { path: "nbar-authorize", element: <OidcNbarAuthorize /> },
        { path: "unauthorize", element: <OidcUnauthorize /> },
        { path: "signin-landing-oidc", element: <SignInOidcLanding /> },
        { path: "signout-landing-oidc", element: <SignOutOidcLanding /> },
        
        {
          path: "*",
          element: <Layout></Layout>,
          children: [
            { path: "*", element: <PageNotFound /> }
          ]
        }
      ]
    },
  ] as RouteObject[];
};

export default routes();
