interface Props {
    minValue: number,
    maxValue: number,
    value: number
}

function ProgressBarComponent({ minValue, maxValue, value }: Props) {
    return (
        <div className="bds-progress-bar progress" role="progressbar"
            aria-valuenow={value} aria-valuemin={minValue} aria-valuemax={maxValue}>
            <div className="progress-bar" style={{ "width": value + '%' }}></div>
        </div>
    );
}

export const ProgressBar = ProgressBarComponent;