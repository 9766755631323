import { useEffect } from "react";
import { ProgressBar } from "../../../shared-module/components/progress-bar/progress-bar.component";

interface Props {
  step: number,
  totalSteps: number
}

function ApplicationProgressBarComponent(props: Props) {
  useEffect(() => {
    document.title = `BDS Gateway - Step ${props.step}/${props.totalSteps}`;
  })

  return (
    <>
      <div className="bds-step-progress">
        <div className="step-details">
          Application Progress: Step {props.step} of {props.totalSteps}
        </div>
        <div className="mt-4">
          <ProgressBar minValue={0} maxValue={100} value={(100 / props.totalSteps) * props.step} />
        </div>
      </div>
    </>
  )
}

export const ApplicationProgressBar = ApplicationProgressBarComponent;