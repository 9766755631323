
interface Props {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    labelText: string,
    id: string,
    name: string,
    value: string
}
export const NbarTextArea = ({onChange, onBlur, id, name, labelText, value}: Props) => {
    return (
        <>
            <div className="row mt-3">
                <div className="col-10"><label className="bds-form-label form-label" htmlFor={id}>{labelText}</label>  </div>
                <div className="col-2"><span>{value?.length || 0}/1000</span>   </div>                               
                <div className="col-12">
                    <textarea id={id} name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className="bds-textarea form-control" 
                        rows={4} 
                        maxLength={1000} 
                    />
                </div>
            </div>
        </>
    )
}