import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../../shared-module/components/card/card.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { PageTitleSetter } from "../../extensions/page-title-setter/page-title-setter.component";
import { ApplicationProgressBar } from "../../extensions/application-progress-bar/application-progress-bar.component";
import { ApplicationResource } from "../../../shared-module/api-resources/application.resource";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { Utility } from "../../../shared-module/helpers/utility";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { ApplicantSignatureStore } from "./applicant-signature.store";
import { observer } from "mobx-react-lite";
import { useFormValidation } from "../../../shared-module/hooks/use-form-validation/use-form-validation.hook";
import { PrintAndSubmitModal, PrintAndSubmitModalOptions, PrintAndSubmitModalSetup } from "../../extensions/print-and-submit-modal/print-and-submit-modal.component";
import { DebugContainer } from "../../../shared-module/components/debug-container/debug-container.component";
import { ApplicantSignatureValidationSchema } from "./applicant-signature.validation";
import { ApiConfig } from "shared-module/services/api-config";
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { ApplicationExpirationError, GatewayNetworkError } from "shared-module/models/common.models";
import BdsCheckbox from "shared-module/components/checkbox/bds-checkbox.component";

function ApplicantSignature() {
  const submissionModalId = "printAndSubmitModal";
  const { id: applicationRefId } = useParams();
  const navigate = useNavigate();
  const applicationService = new ApplicationResource(ApiConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [printAndSubmitModalOptions, setPrintAndSubmitModalOptions] = useState<PrintAndSubmitModalOptions>();
  const store = ApplicantSignatureStore;

  useEffect(() => {
    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
      return;
    }

    setIsLoading(true);
    Utility.showLoadingOverlay();

    Promise.all([applicationService.getApplication(applicationRefId)])
      .then((response) => {
        const app = response[0].record;
        store.refresh(app);
        return true;
      })
      .catch((ex) => {
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      }) // Go to home when get application fails.
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });
  }, []);

  const { errors, touched, setFieldsTouched: setFieldTouched } =
    useFormValidation(ApplicantSignatureValidationSchema, store);

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  function submitApplication(printWithSubmit: boolean): Promise<any> {
    setIsSaving(true);
    let applicationStamp = null;
    let legalRepStamp = null;

    if(store.applicantCertified) {
      applicationStamp = Utility.getApplicantStamp(store.applicantSignatureSeed, applicationRefId);
    }

    if(store.legalRepresentativeCertified) {
      legalRepStamp = Utility.getLegalRepresentativeStamp(store.legalRepresentativeSignatureSeed, applicationRefId);
    }


    return applicationService.submitApplication(
      applicationRefId,
      store.applicantCertified,
      store.legalRepresentativeCertified,
      applicationStamp !== null ? applicationStamp.signature : '',
      legalRepStamp !== null ? legalRepStamp.signature : '',
      applicationStamp !== null ?applicationStamp.signatureOn : '',
      legalRepStamp !== null ? legalRepStamp.signatureOn : '',
      printWithSubmit
    )
      .then((response: GatewayOapApi.ApiBooleanResponse) => {
        if (printWithSubmit) {
          return openApplicationforPrint(applicationRefId);
        } else {
          return Promise.resolve();
        }
      })
      .then((response) => {
        NavigationHelper.gotoApplicationSubmitted(navigate, applicationRefId);
        return response;
      })
      .catch(ex => {
        if(ex instanceof ApplicationExpirationError) {
          Utility.alert("Application Timeout", ex.errorMessage);
        }
        if (!(ex instanceof GatewayNetworkError)) {
          Utility.alert("Error Saving", ex.errorMessage);
        }
        NavigationHelper.gotoHome(navigate);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  function openApplicationforPrint(applicationRefId: string): Promise<any> {
    var date = new Date();
    return applicationService.printSubmittedApplication(applicationRefId)
      .then((response: string) => {
        var file = new Blob([response], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        var fileLink = document.createElement("a");
        fileLink.target = "_blank";
        fileLink.href = fileURL;
        fileLink.download = `bds_services_application_${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}.pdf`;
        fileLink.click();
      });
  }

  function beginSubmission() {
    printAndSubmitModalOptions.show({
      onCancel: () => {
        return Promise.resolve(true)
          .then((response) => {
            return printAndSubmitModalOptions.hide();
          })
      },
      onPrintAndSubmit: () => {
        return submitApplication(true)
          .then((response) => {
            return printAndSubmitModalOptions.hide();
          });
      },
      onSubmitOnly: () => {
        return submitApplication(false)
          .then((response) => {
            return printAndSubmitModalOptions.hide();
          })
      },
    } as PrintAndSubmitModalSetup);
  }

  return (
    <>
      <DebugContainer data={store} touched={touched} errors={errors}></DebugContainer>
      <PrintAndSubmitModal id={submissionModalId} accessor={(options: PrintAndSubmitModalOptions) => {
        setPrintAndSubmitModalOptions(options);
      }} />
      <PageTitleSetter step={8} totalSteps={8}></PageTitleSetter>
      <div className="mt-4">
        <ApplicationProgressBar
          step={8}
          totalSteps={8}
        ></ApplicationProgressBar>
      </div>

      <form>
        <ShowThen when={store.hasLegalRepresentative}>
          <div className="mt-4">
            <Card>
              <div className="signature-heading mandatory">
                Signature of Legal Representative
              </div>
              <div className="signature-description mt-2">
                By checking the signature box, you are attesting to the fact
                that you are a legal representative of the applicant and wish to
                pursue an application for BDS services for the applicant. At any
                time, you can choose to decline services for the applicant or
                stop the application process.
              </div>
              <div className="mt-3">
                The applicant may check the signature box but is not required to
                do so.
              </div>
              <div>
                <BdsCheckbox 
                  id="legal-representative-certified"
                  name="legalRepresentativeCertified"
                  checked={store.legalRepresentativeCertified}
                  cssClass="mt-3"
                  onChange={(event) => {
                    store.setLegalRepresentativeCertified(
                      event.target.checked
                    );
                    setFieldTouched("legalRepresentativeCertified");
                  }}
                  onBlur={(event) => {
                    setFieldTouched("legalRepresentativeCertified");
                  }}
                  label="I certify under penalty of perjury, all information I have
                  given on this application is complete and correct to the
                  best of my knowledge and belief."
                />
              </div>
              <div className="mt-3">
                Check the box to sign this application.
              </div>
            </Card>
          </div>
        </ShowThen>

        <div className="mt-4">
          <Card>
            <div
              className={`signature-heading ${!store.hasLegalRepresentative && "mandatory"
                }`}
            >
              Signature of Applicant
            </div>
            <div className="signature-description mt-2">
              By clicking the signature box, you are attesting to the fact that
              you are the applicant and wish to pursue an application for BDS
              services. At any time, you can choose to decline services or stop
              the application process.
            </div>
            <div>
              <BdsCheckbox
                id="applicant-certified"
                name="applicantCertified"
                checked={store.applicantCertified}
                onChange={(event) => {
                  store.setApplicantCertified(event.target.checked);
                  setFieldTouched("applicantCertified");
                }}
                onBlur={(event) => {
                  setFieldTouched("applicantCertified");
                }}
                label="I certify under penalty of perjury, all information I have
                given on this application is complete and correct to the best
                of my knowledge and belief." 
              />
              <div className="mt-3">
                Check the box to sign this application.
              </div>
            </div>
          </Card>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
            <button type="button" onClick={() => NavigationHelper.goToDocumentUpload(navigate, applicationRefId)}
              className="btn bds-btn-primary">Back</button>
            <IsAuthenticated>
              <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
               onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
            </IsAuthenticated>
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
            <button type="button" disabled={isLoading || isSaving || !ApplicantSignatureValidationSchema.isValidSync(store)}
              onClick={beginSubmission}
              className="btn bds-btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default observer(ApplicantSignature);
