import { Modal } from "bootstrap";
import { useEffect, useState } from "react";
import { ModalOptions } from "../../../shared-module/models/common.models";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";

interface Props {
  id: string,
  accessor: (ModalOptions) => void
}

export interface PrintAndSubmitModalOptions {
  show: (setup: PrintAndSubmitModalSetup) => void,
  hide: () => void,
}

export interface PrintAndSubmitModalSetup {
  onCancel: () => Promise<void>,
  onPrintAndSubmit: () => Promise<void>,
  onSubmitOnly: () => Promise<void>,
  message: React.ReactNode
}

function PrintAndSubmitModalComponent(props: Props) {
  const [modalSetup, setModalSetup] = useState<PrintAndSubmitModalSetup>({} as PrintAndSubmitModalSetup);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    props.accessor({
      show: function (setup: PrintAndSubmitModalSetup) {
        setModalSetup(setup);
        var element = document.getElementById(props.id);
        if (element) {
          var modal = Modal.getOrCreateInstance(element, {
            keyboard: false
          });
          modal.show();
        }
      },
      hide: function () {
        var element = document.getElementById(props.id);
        if (element) {
          var modal = Modal.getOrCreateInstance(element);
          modal.hide();
        }
      },
    });
  }, [])

  function handlePrintAndSubmit(e) {
    setIsSaving(true);
    if (modalSetup.onPrintAndSubmit) {
      modalSetup.onPrintAndSubmit()
      .finally(() => {
        setIsSaving(false);
      });
    }
  }

  function handleSubmitOnly(e) {
    setIsSaving(true);
    if (modalSetup.onSubmitOnly) {
      modalSetup.onSubmitOnly()
      .finally(() => {
        setIsSaving(false);
      });
    }
  }

  function handleCancel() {
    setIsSaving(true);
    if (modalSetup.onCancel) {
      modalSetup.onCancel()
      .finally(() => {
        setIsSaving(false);
      });
    }
  }

  return (
    <>
      <div className="modal fade" id={props.id} data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Print Application</h5>
              <button type="button" className="btn-close" onClick={handleCancel} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
                <b>If you are completing this application and are not logged into Access Indiana, this will be the only chance to print your application.</b>
              </p>
              <p>
                It is recommended that you print or download a copy of your application for your records.
              </p>
              <p>
                Would you like to print or download a copy of your application now? If so,
                select '<b>PRINT and SUBMIT</b>'. If you do not wish to print or download
                a copy of the application, select '<b>SUBMIT ONLY</b>' to continue.
              </p>
            </div>
            <div className="modal-footer">
              <ShowThen when={isSaving}>
                <div className="spinner-border text-secondary me-3" role="status">
                  <span className="visually-hidden">Saving...</span>
                </div>
              </ShowThen>
              <button type="button" className="btn btn-secondary" onClick={handlePrintAndSubmit}>PRINT and SUBMIT</button>
              <button type="button" className="btn btn-primary" onClick={handleSubmitOnly}>SUBMIT ONLY</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const PrintAndSubmitModal = PrintAndSubmitModalComponent