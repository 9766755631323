import * as Yup from "yup";
import { IApplicantDemographics } from "./applicant-demographics.store";
import { Utility } from "../../../shared-module/helpers/utility";

export const ApplicantDemographicsValidationSchema: Yup.ObjectSchema<IApplicantDemographics> = Yup.object({
  applicantGenderId: Yup.string(),
  applicantMaritalStatusId: Yup.string(),
  isCurrentlyStudent: Yup.string()
    .test("validate-isCurrentlyStudent", "-", function (item) {
      if (item) {
        if (item === "is-currently-student" && !this.parent.applicantEnrolledEducationTypeId) {
          return this.createError(
            Utility.buildYupErrorMessage("isCurrentlyStudent",
              "An educational program must be selected."));
        }
      }
      return true;
    }),
  applicantEnrolledEducationTypeId: Yup.string(),
  applicantNotEnrolledEducationTypeId: Yup.string(),
  applicantIsHispanicOrLatino: Yup.string(),
  applicantRaceOptions: Yup.array(),
  applicantPreferredLanguageId: Yup.string(),
  applicantPreferredLanguageOther: Yup.string()
    .test("validate-applicantPreferredLanguageOther", "-", function (item) {
      if (!item && this.parent.applicantPreferredLanguageId == "16") {
        return this.createError(
          Utility.buildYupErrorMessage("applicantPreferredLanguageOther",
            "A language must be entered for the other preferred languange not in the list."))
      }
      return true;
    }),
  hasApplicantVrsAssessed: Yup.string(),
  hasLegalRepresentative: Yup.string()
});
