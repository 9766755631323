interface EventListener {
  event: string,
  callback: any
}

class AlertModalEventBus {
  private static instance: AlertModalEventBus;
  private listeners: EventListener[] = [];

  private constructor() {
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AlertModalEventBus();
    }
    return this.instance;
  }

  public on(event, callback) {
    var listener = {
      event: event,
      callback: callback
    } as EventListener;

    this.listeners.push(listener);
    document.addEventListener(event, (e) => callback(e.detail));
    console.debug(event, ': AlertModal listener added');
  }

  public dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  public remove(event) {
    var listener = this.listeners.find((item) => {
      return item.event == event;
    });

    if (listener) {
      document.removeEventListener(listener.event, listener.callback);
      console.debug(event, ': AlertModal listener removed');
    }

    console.debug(event, ': AlertModal listener not found - not removed');
  }

};

export default AlertModalEventBus.getInstance();