import { useEffect } from "react";
import { EventBroadcasterService } from "../../../shared-module/events/event-broadcaster.service";
import { AppSettings } from "../../../app-setting";
import { ApiConfig } from "shared-module/services/api-config";
import { SystemResource } from "shared-module/api-resources/system.resource";

function GuestActivityPollComponent() {
    const systemService = new SystemResource(ApiConfig);
    function pollActivity(): Promise<any> {
        var guestSessionId = sessionStorage.getItem("guestSessionId");
        return systemService.checkGuestActivityPulse(Number(guestSessionId))
        .then((response) => {
            var record = response.record;
            if(record) {
                EventBroadcasterService.RaiseGuestSessionExpirationEvent({
                    isAuthenticated: record?.isAuthenticated || false,
                    remainingTimeInSeconds: record?.expirationSeconds || -999,
                    hasActiveMaintenance: record?.hasActiveMaintenance
                });
            }         
        })
    }

    useEffect(() => {
        const timerId = setInterval(() => pollActivity(), AppSettings.activityPulseInterval);
        return () => clearInterval(timerId);
    }, []);

    return (
        <></>
    )
}

export const GuestActivityPoll = GuestActivityPollComponent;