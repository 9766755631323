import { action, makeObservable, observable } from "mobx"
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { IGatewayTransientStore } from "../../../shared-module/models/IGatewayTransientStore";

export interface IApplicantInformation {
  _primaryPhone: boolean,
  _atleastOneContact: boolean,
  livingArrangementTypeId: string;
  applicantMailingAddressLine: string;
  applicantMailingCity: string;
  applicantMailingStateId: string;
  applicantMailingZipCode: string;
  applicantMailingCountyId: string;
  applicantHasDifferentCurrentAddress: boolean;
  applicantCurrentAddressLine: string;
  applicantCurrentCity: string;
  applicantCurrentStateId: string;
  applicantCurrentZipCode: string;
  applicantCurrentCountyId: string;
  applicantHomePhoneIsPrimary: boolean;
  applicantHomePhone: string;
  applicantMobilePhoneIsPrimary: boolean;
  applicantMobilePhone: string;
  applicantWorkPhoneIsPrimary: boolean;
  applicantWorkPhone: string;
  applicantEmailAddress: string;
  diagnosisAge: string;
  disabilityDescription: string;
}

class ApplicantInformationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.ApplicationModel, IApplicantInformation>,
  IApplicantInformation {
    
  public _primaryPhone: boolean;
  public _atleastOneContact: boolean;
  public livingArrangementTypeId: string;
  public applicantMailingAddressLine: string;
  public applicantMailingCity: string;
  public applicantMailingStateId: string;
  public applicantMailingZipCode: string;
  public applicantMailingCountyId: string;
  public applicantHasDifferentCurrentAddress: boolean;
  public applicantCurrentAddressLine: string;
  public applicantCurrentCity: string;
  public applicantCurrentStateId: string;
  public applicantCurrentZipCode: string;
  public applicantCurrentCountyId: string;
  public applicantHomePhoneIsPrimary: boolean;
  public applicantHomePhone: string;
  public applicantMobilePhoneIsPrimary: boolean;
  public applicantMobilePhone: string;
  public applicantWorkPhoneIsPrimary: boolean;
  public applicantWorkPhone: string;
  public applicantEmailAddress: string;
  public diagnosisAge: string;
  public disabilityDescription: string;

  constructor() {
    primaryPhone: true;
    atleastOneContact: true;
    this.livingArrangementTypeId = "";
    this.applicantMailingAddressLine = "";
    this.applicantMailingCity = "";
    this.applicantMailingStateId = "";
    this.applicantMailingZipCode = "";
    this.applicantMailingCountyId = "";
    this.applicantHasDifferentCurrentAddress = false;
    this.applicantCurrentAddressLine = "";
    this.applicantCurrentCity = "";
    this.applicantCurrentStateId = "";
    this.applicantCurrentZipCode = "";
    this.applicantCurrentCountyId = "";
    this.applicantHomePhoneIsPrimary = false;
    this.applicantHomePhone = "";
    this.applicantMobilePhoneIsPrimary = false;
    this.applicantMobilePhone = "";
    this.applicantWorkPhoneIsPrimary = false;
    this.applicantWorkPhone = "";
    this.applicantEmailAddress = "";
    this.diagnosisAge = "";
    this.disabilityDescription = "";

    makeObservable(this, {
      livingArrangementTypeId: observable,
      applicantMailingAddressLine: observable,
      applicantMailingCity: observable,
      applicantMailingStateId: observable,
      applicantMailingZipCode: observable,
      applicantMailingCountyId: observable,
      applicantHasDifferentCurrentAddress: observable,
      applicantCurrentAddressLine: observable,
      applicantCurrentCity: observable,
      applicantCurrentStateId: observable,
      applicantCurrentZipCode: observable,
      applicantCurrentCountyId: observable,
      applicantHomePhoneIsPrimary: observable,
      applicantHomePhone: observable,
      applicantMobilePhoneIsPrimary: observable,
      applicantMobilePhone: observable,
      applicantWorkPhoneIsPrimary: observable,
      applicantWorkPhone: observable,
      applicantEmailAddress: observable,
      diagnosisAge: observable,
      disabilityDescription: observable,

      setLivingArrangementTypeId: action,
      setApplicantMailingAddressLine: action,
      setApplicantMailingCity: action,
      setApplicantMailingStateId: action,
      setApplicantMailingZipCode: action,
      setApplicantMailingCountyId: action,
      setApplicantHasDifferentCurrentAddress: action,
      setApplicantCurrentAddressLine: action,
      setApplicantCurrentCity: action,
      setApplicantCurrentStateId: action,
      setApplicantCurrentZipCode: action,
      setApplicantCurrentCountyId: action,
      setApplicantHomePhoneIsPrimary: action,
      setApplicantHomePhone: action,
      setApplicantMobilePhoneIsPrimary: action,
      setApplicantMobilePhone: action,
      setApplicantWorkPhoneIsPrimary: action,
      setApplicantWorkPhone: action,
      setApplicantEmailAddress: action,
      setDiagnosisAge: action,
      setDisabilityDescription: action,
      refresh: action
    });
  }

  public setLivingArrangementTypeId(value: string) {
    this.livingArrangementTypeId = value;
  }

  public setApplicantMailingAddressLine(value: string) {
    this.applicantMailingAddressLine = value;
  }

  public setApplicantMailingCity(value: string) {
    this.applicantMailingCity = value;
  }

  public setApplicantMailingStateId(value: string) {
    this.applicantMailingStateId = value;
  }

  public setApplicantMailingZipCode(value: string) {
    this.applicantMailingZipCode = value;
  }

  public setApplicantMailingCountyId(value: string) {
    this.applicantMailingCountyId = value;
  }

  public setApplicantHasDifferentCurrentAddress(value: boolean) {
    this.applicantHasDifferentCurrentAddress = value;
  }

  public setApplicantCurrentAddressLine(value: string) {
    this.applicantCurrentAddressLine = value;
  }

  public setApplicantCurrentCity(value: string) {
    this.applicantCurrentCity = value;
  }

  public setApplicantCurrentStateId(value: string) {
    this.applicantCurrentStateId = value;
  }

  public setApplicantCurrentZipCode(value: string) {
    this.applicantCurrentZipCode = value;
  }

  public setApplicantCurrentCountyId(value: string) {
    this.applicantCurrentCountyId = value;
  }

  public setApplicantHomePhoneIsPrimary(value: boolean) {
    this.applicantHomePhoneIsPrimary = value;
  }

  public setApplicantHomePhone(value: string) {
    this.applicantHomePhone = value;
  }

  public setApplicantMobilePhoneIsPrimary(value: boolean) {
    this.applicantMobilePhoneIsPrimary = value;
  }

  public setApplicantMobilePhone(value: string) {
    this.applicantMobilePhone = value;
  }

  public setApplicantWorkPhoneIsPrimary(value: boolean) {
    this.applicantWorkPhoneIsPrimary = value;
  }

  public setApplicantWorkPhone(value: string) {
    this.applicantWorkPhone = value;
  }

  public setApplicantEmailAddress(value: string) {
    this.applicantEmailAddress = value;
  }

  public setDiagnosisAge(value: string) {
    this.diagnosisAge = value;
  }

  public setDisabilityDescription(value: string) {
    this.disabilityDescription = value;
  }

  public refresh(app: GatewayOapApi.ApplicationModel): void {
    var state = this.buildState(app);
    this.setLivingArrangementTypeId(state.livingArrangementTypeId);
    this.setApplicantMailingAddressLine(state.applicantMailingAddressLine);
    this.setApplicantMailingCity(state.applicantMailingCity);
    this.setApplicantMailingStateId(state.applicantMailingStateId);
    this.setApplicantMailingZipCode(state.applicantMailingZipCode);
    this.setApplicantMailingCountyId(state.applicantMailingCountyId);
    this.setApplicantHasDifferentCurrentAddress(state.applicantHasDifferentCurrentAddress);
    this.setApplicantCurrentAddressLine(state.applicantCurrentAddressLine);
    this.setApplicantCurrentCity(state.applicantCurrentCity);
    this.setApplicantCurrentStateId(state.applicantCurrentStateId);
    this.setApplicantCurrentZipCode(state.applicantCurrentZipCode);
    this.setApplicantCurrentCountyId(state.applicantCurrentCountyId);
    this.setApplicantHomePhoneIsPrimary(state.applicantHomePhoneIsPrimary);
    this.setApplicantHomePhone(state.applicantHomePhone);
    this.setApplicantMobilePhoneIsPrimary(state.applicantMobilePhoneIsPrimary);
    this.setApplicantMobilePhone(state.applicantMobilePhone);
    this.setApplicantWorkPhoneIsPrimary(state.applicantWorkPhoneIsPrimary);
    this.setApplicantWorkPhone(state.applicantWorkPhone);
    this.setApplicantEmailAddress(state.applicantEmailAddress);
    this.setDiagnosisAge(state.diagnosisAge);
    this.setDisabilityDescription(state.disabilityDescription);
  }

  public buildState(app: GatewayOapApi.ApplicationModel): IApplicantInformation {
    return {
      _primaryPhone: true,
      _atleastOneContact: true,
      livingArrangementTypeId: Utility.numberToString(app.livingArrangementTypeId, ""),
      applicantMailingAddressLine: Utility.defaultIfNull(app.mailingAddress && app.mailingAddress.streetAddress1, ""),
      applicantMailingCity: Utility.defaultIfNull(app.mailingAddress && app.mailingAddress.city, ""),
      applicantMailingStateId: Utility.numberToString(app.mailingAddress && app.mailingAddress.stateId, ""),
      applicantMailingZipCode: Utility.mergeZipCodeParts(app.mailingAddress?.zipCode, app.mailingAddress?.zipPlus4, ""),
      applicantMailingCountyId: Utility.numberToString(app.mailingAddress && app.mailingAddress.countyId, ""),
      applicantHasDifferentCurrentAddress: Utility.defaultIfNull(app.hasDifferentCurrentAddress, false),
      applicantCurrentAddressLine: Utility.defaultIfNull(app.currentAddress && app.currentAddress.streetAddress1, ""),
      applicantCurrentCity: Utility.defaultIfNull(app.currentAddress && app.currentAddress.city, ""),
      applicantCurrentStateId: Utility.numberToString(app.currentAddress && app.currentAddress.stateId, ""),
      applicantCurrentZipCode: Utility.mergeZipCodeParts(app.currentAddress?.zipCode, app.currentAddress?.zipPlus4, ""),
      applicantCurrentCountyId: Utility.numberToString(app.currentAddress && app.currentAddress.countyId, ""),
      applicantHomePhoneIsPrimary: Utility.defaultIfNull(app.homePhone && app.homePhone.isPrimary, false),
      applicantHomePhone: format(Utility.defaultIfNull(app.homePhone && app.homePhone.phoneNumber, "")),
      applicantMobilePhoneIsPrimary: Utility.defaultIfNull(app.mobilePhone && app.mobilePhone.isPrimary, false),
      applicantMobilePhone: format(Utility.defaultIfNull(app.mobilePhone && app.mobilePhone.phoneNumber, "")),
      applicantWorkPhoneIsPrimary: Utility.defaultIfNull(app.workPhone && app.workPhone.isPrimary, false),
      applicantWorkPhone: format(Utility.defaultIfNull(app.workPhone && app.workPhone.phoneNumber, "")),
      applicantEmailAddress: Utility.defaultIfNull(app.emailAddress, ""),
      diagnosisAge: Utility.numberToString(app.diagnosisAge, ""),
      disabilityDescription: Utility.defaultIfNull(app.disabilityDescription, ""),
    };
  }
    public formatPhoneNumber() {
        this.setApplicantHomePhone(format(this.applicantHomePhone));
        this.setApplicantMobilePhone(format(this.applicantMobilePhone));
        this.setApplicantWorkPhone(format(this.applicantWorkPhone));
    }

    public unformat(value: string): string {
        return unformat(value);
    }
   
    public clearCurrentAddress() {
      this.setApplicantCurrentAddressLine("");
      this.setApplicantCurrentCity("");
      this.setApplicantCurrentStateId("");
      this.setApplicantCurrentZipCode("");
      this.setApplicantCurrentCountyId("");
    }
}

function unformat(value: string): string {
    return value?.replace(/\D+/g, '');
}

function format(value: string): string {
    console.log('format phone number: ', value);
    if (value.length > 0) {
        var phone = unformat(value);
        if (phone.length > 3) {
            var firstPart = `(${phone.substring(0, 3)}) `;
            var secondPart = phone.length > 6 ? `${phone.substring(3, 6)}-` : `${phone.substring(3)}`;
            var thirdPart = phone.length > 6 ? phone.substring(6, 10) : ``;
            return `${firstPart}${secondPart}${thirdPart}`;
        }
        return phone;
    }
    return;
}


export const ApplicantInformationStore = new ApplicantInformationStoreComponent(); 