import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarDemographicsResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

    public getNbarDemographics(demographicsId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiSingleResponseOfDemographicInformationModel>
    {
      console.log('nbar-demographics.resource.ts:getNbarDemographics :: ', demographicsId);
        return super.getRequest(`api/v1/nbar/demographic/${demographicsId}`, null, abortController) as
            Promise<GatewayOapApi.ApiSingleResponseOfDemographicInformationModel>;
    }

    public saveNbarDemographics(
        nbarApplicationId: number, demographicInformationId: number, hasMedicaid: number, medicaidRid: string, medicaidCategoryTypeId: number, hasSsi: number,hasGuardian: number, 
        guardianName: string, hasPreviousPlacement: number, placementAgency: string, placementAgencyDate: string, hasSGLPlacementExplored: number, 
        sGLPlacementOutcome: string, sGLNotExploringReason: string, isRecevingDDRSServices: number, informalSupportDescription: string, 
        informalSupportUtilizedDescription: string, hasCommunitySupportExplored: number, communitySupportDescription: string, hasLossOfPrimaryCaregiver: number,
        dateOfLoss: Date, primaryCaregiverCapacityDescription: string, currentCaregiverArrangementDescription: string, isPrimaryCaregiverAbove80: number,
        hasSecondaryCaregiver: number, isSecondaryCaregiverBelow80: number, hasAbuseOrNeglect: number, abuseOrNeglectDescription: string,
        hasExtraordinarySafetyRisk: number, safetyRiskDescription: string, abortController?: AbortController)
        : Promise<GatewayOapApi.ApiIntegerResponse>
    {
      return super.postRequest(`api/v1/nbar/demographic/update`, 
      null, 
      {
        nbarApplicationId, demographicInformationId, hasMedicaid, medicaidRid, medicaidCategoryTypeId, hasSsi, hasGuardian, 
        guardianName, hasPreviousPlacement, placementAgency, placementAgencyDate, hasSGLPlacementExplored, 
        sGLPlacementOutcome, sGLNotExploringReason, isRecevingDDRSServices, informalSupportDescription, 
        informalSupportUtilizedDescription, hasCommunitySupportExplored, communitySupportDescription, hasLossOfPrimaryCaregiver,
        dateOfLoss, primaryCaregiverCapacityDescription, currentCaregiverArrangementDescription, isPrimaryCaregiverAbove80,
        hasSecondaryCaregiver, isSecondaryCaregiverBelow80, hasAbuseOrNeglect, abuseOrNeglectDescription,
        hasExtraordinarySafetyRisk, safetyRiskDescription
      },
      abortController) as 
        Promise<GatewayOapApi.ApiIntegerResponse>;
    }
}