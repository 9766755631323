import React from "react";

export class InviteUserModal extends React.Component {
  constructor(props: {}) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="modal fade" id="inviteCurrentUser" data-bs-backdrop="static" data-bs-keyboard="false"
          tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Invite Another User</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div>Please enter the email address for the user you would like to invite to this application.</div>
                <div className="row mt-4">
                  <div className="col-4 mandatory">Recepient email:</div>
                  <div className="col-8">
                    <input type="text" className="bds-modal-form-text bds-modal-email form-control" />
                  </div>
                </div>
                <div className="mt-4">Enter a Unique 4-digit PIN that the invited user will use to access your application.</div>
                <div className="row mt-4">
                  <div className="col-4 mandatory">PIN:</div>
                  <div className="col-8">
                    <input type="password"
                      className="bds-modal-form-text bds-modal-pin form-control" maxLength={4} />
                  </div>
                  <div className="col-4 mt-2 mandatory">Confirm PIN:</div>
                  <div className="col-8 mt-2">
                    <input type="password"
                      className="bds-modal-form-text bds-modal-pin form-control" maxLength={4} />
                  </div>
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Send</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}