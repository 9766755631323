import * as Yup from "yup";
import { INbarBehavioralInformation } from "./behavioral-information.store";
import { Utility } from "../../../../shared-module/helpers/utility";
import * as enums from '../../../../shared-module/helpers/enums';

export const NbarBehavioralInformationValidationSchema: Yup.ObjectSchema<INbarBehavioralInformation> = Yup.object({
    useOfInappropriateLanguage: Yup.number()
        .test("validate-useOfInappropriateLanguage", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("useOfInappropriateLanguage",
                    "Please select an answer for Use of Inappropriate Language question"));
                }
            return true;
        }),
    useOfInappropriateLanguageFrequency: Yup.number().nullable()
        .test("validate-useOfInappropriateLanguageFrequency", "-", function (item) {
            if (this.parent.useOfInappropriateLanguage == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("useOfInappropriateLanguageFrequency",
                    "Please select an option for Use of Inappropriate Language Frequency"));
                }
            return true;
        }),
    useOfInappropriateLanguageDescription: Yup.string().nullable()
        .test("validate-useOfInappropriateLanguageDescription", "-", function (item) {
            if (this.parent.useOfInappropriateLanguage == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("useOfInappropriateLanguageDescription",
                    "Please provide an answer for Use of Inappropriate Language description"));
                }
            return true;
        }),
    useOfInappropriateLanguageTechniques: Yup.string().nullable()
    .test("validate-useOfInappropriateLanguageTechniques", "-", function (item) {
        if (this.parent.useOfInappropriateLanguage == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("useOfInappropriateLanguageTechniques",
                "Please provide an answer for Use of Inappropriate Language techniques"));
            }
        return true;
    }),
    refusalToAcceptDirection: Yup.number()
        .test("validate-refusalToAcceptDirection", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("refusalToAcceptDirection",
                    "Please select an answer for Refusal to Accept Direction question"));
                }
            return true;
        }),
    refusalToAcceptDirectionFrequency: Yup.number().nullable()
        .test("validate-refusalToAcceptDirectionFrequency", "-", function (item) {
            if (this.parent.refusalToAcceptDirection == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("refusalToAcceptDirectionFrequency",
                    "Please select an option for Refusal To Accept Direction Frequency"));
                }
            return true;
        }),
    refusalToAcceptDirectionDescription: Yup.string().nullable()
        .test("validate-refusalToAcceptDirectionDescription", "-", function (item) {
            if (this.parent.refusalToAcceptDirection == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("refusalToAcceptDirectionDescription",
                    "Please provide an answer for Refusal to Accept Direction description"));
                }
            return true;
        }),
    refusalToAcceptDirectionTechniques: Yup.string().nullable()
    .test("validate-refusalToAcceptDirectionTechniques", "-", function (item) {
        if (this.parent.refusalToAcceptDirection == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("refusalToAcceptDirectionTechniques",
                "Please provide an answer for Refusal to Accept Direction techniques"));
            }
        return true;
    }),
    verbalOrPhysicalIntimidation: Yup.number()
        .test("validate-verbalOrPhysicalIntimidation", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("verbalOrPhysicalIntimidation",
                    "Please select an answer for Verbal or Physical Intimidation question"));
                }
            return true;
        }),
    verbalOrPhysicalIntimidationFrequency: Yup.number().nullable()
        .test("validate-verbalOrPhysicalIntimidationFrequency", "-", function (item) {
            if (this.parent.verbalOrPhysicalIntimidation == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("verbalOrPhysicalIntimidationFrequency",
                    "Please select an option for Verbal or Physical Intimidation Frequency"));
                }
            return true;
        }),
    verbalOrPhysicalIntimidationDescription: Yup.string().nullable()
        .test("validate-verbalOrPhysicalIntimidationDescription", "-", function (item) {
            if (this.parent.verbalOrPhysicalIntimidation == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("verbalOrPhysicalIntimidationDescription",
                    "Please provide an answer for Verbal or Physical Intimidation description"));
                }
            return true;
        }),
    verbalOrPhysicalIntimidationTechniques: Yup.string().nullable()
    .test("validate-verbalOrPhysicalIntimidationTechniques", "-", function (item) {
        if (this.parent.verbalOrPhysicalIntimidation == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("verbalOrPhysicalIntimidationTechniques",
                "Please provide an answer for Verbal or Physical Intimidation techniques"));
            }
        return true;
    }),
    destructionOfPersonalProperty: Yup.number()
        .test("validate-useOfInappropriateLanguage", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPersonalProperty",
                    "Please select an answer for Destruction of Personal Property question"));
                }
            return true;
        }),
    destructionOfPersonalPropertyFrequency: Yup.number().nullable()
        .test("validate-destructionOfPersonalPropertyFrequency", "-", function (item) {
            if (this.parent.destructionOfPersonalProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPersonalPropertyFrequency",
                    "Please select an option for Destruction of Personal Property Frequency"));
                }
            return true;
        }),
    destructionOfPersonalPropertyDescription: Yup.string().nullable()
        .test("validate-destructionOfPersonalPropertyDescription", "-", function (item) {
            if (this.parent.destructionOfPersonalProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPersonalPropertyDescription",
                    "Please provide an answer for Destruction of Personal Property description"));
                }
            return true;
        }),
    destructionOfPersonalPropertyTechniques: Yup.string().nullable()
    .test("validate-destructionOfPersonalPropertyTechniques", "-", function (item) {
        if (this.parent.destructionOfPersonalProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("destructionOfPersonalPropertyTechniques",
                "Please provide an answer for Destruction of Personal Property techniques"));
            }
        return true;
    }),
    destructionOfPublicProperty: Yup.number()
        .test("validate-destructionOfPublicProperty", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPublicProperty",
                    "Please select an answer for Destruction of Public Property question"));
                }
            return true;
        }),
    destructionOfPublicPropertyFrequency: Yup.number().nullable()
        .test("validate-destructionOfPublicPropertyFrequency", "-", function (item) {
            if (this.parent.destructionOfPublicProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPublicPropertyFrequency",
                    "Please select an option for Destruction Of Public Property Frequency"));
                }
            return true;
        }),
    destructionOfPublicPropertyDescription: Yup.string().nullable()
        .test("validate-destructionOfPublicPropertyDescription", "-", function (item) {
            if (this.parent.destructionOfPublicProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("destructionOfPublicPropertyDescription",
                    "Please provide an answer for Destruction of Public Property description"));
                }
            return true;
        }),
    destructionOfPublicPropertyTechniques: Yup.string().nullable()
    .test("validate-destructionOfPublicPropertyTechniques", "-", function (item) {
        if (this.parent.destructionOfPublicProperty == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("destructionOfPublicPropertyTechniques",
                "Please provide an answer for Destruction of Public Property techniques"));
            }
        return true;
    }),
    physicalAgressionTowardsSelf: Yup.number()
        .test("validate-physicalAgressionTowardsSelf", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("physicalAgressionTowardsSelf",
                    "Please select an answer for Physical Aggression Towards Self question"));
                }
            return true;
        }),
    physicalAgressionTowardsSelfFrequency: Yup.number().nullable()
        .test("validate-physicalAgressionTowardsSelfFrequency", "-", function (item) {
            if (this.parent.physicalAgressionTowardsSelf == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("physicalAgressionTowardsSelfFrequency",
                    "Please select an option for Physical Aggression Towards Self Frequency"));
                }
            return true;
        }),
    physicalAgressionTowardsSelfDescription: Yup.string().nullable()
    .test("validate-physicalAgressionTowardsSelfDescription", "-", function (item) {
        if (this.parent.physicalAgressionTowardsSelf == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("physicalAgressionTowardsSelfDescription",
                "Please provide an answer for Physical Aggression Towards Self description"));
            }
        return true;
    }),
    physicalAgressionTowardsSelfTechniques: Yup.string().nullable()
    .test("validate-physicalAgressionTowardsSelfTechniques", "-", function (item) {
        if (this.parent.physicalAgressionTowardsSelf == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("physicalAgressionTowardsSelfTechniques",
                "Please provide an answer for Physical Aggression Towards Self techniques"));
            }
        return true;
    }),
    physicalAgressionTowardsOthers: Yup.number()
        .test("validate-physicalAgressionTowardsOthers", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("physicalAgressionTowardsOthers",
                    "Please select an answer for Physical Aggression Towards Others question"));
                }
            return true;
        }),
    physicalAgressionTowardsOthersFrequency: Yup.number().nullable()
        .test("validate-physicalAgressionTowardsOthersFrequency", "-", function (item) {
            if (this.parent.physicalAgressionTowardsOthers == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("physicalAgressionTowardsOthersFrequency",
                    "Please select an option for Physical Aggression Towards Others Frequency"));
                }
            return true;
        }),
    physicalAgressionTowardsOthersDescription: Yup.string().nullable()
        .test("validate-physicalAgressionTowardsOthersDescription", "-", function (item) {
            if (this.parent.physicalAgressionTowardsOthers == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("physicalAgressionTowardsOthersDescription",
                    "Please provide an answer for Physical Aggression Towards Others description"));
                }
            return true;
        }),
    physicalAgressionTowardsOthersTechniques: Yup.string().nullable()
    .test("validate-physicalAgressionTowardsOthersTechniques", "-", function (item) {
        if (this.parent.physicalAgressionTowardsOthers == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("physicalAgressionTowardsOthersTechniques",
                "Please provide an answer for Physical Aggression Towards Others techniques"));
            }
        return true;
    }),
    elopementFromHome: Yup.number()
        .test("validate-elopementFromHome", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromHome",
                    "Please select an answer for Elopment From Home question"));
                }
            return true;
        }),
    elopementFromHomeFrequency: Yup.number().nullable()
        .test("validate-elopementFromHomeFrequency", "-", function (item) {
            if (this.parent.elopementFromHome == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromHomeFrequency",
                    "Please select an option for Elopement From Home Frequency"));
                }
            return true;
        }),
    elopementFromHomeDescription: Yup.string().nullable()
        .test("validate-elopementFromHomeDescription", "-", function (item) {
            if (this.parent.elopementFromHome == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromHomeDescription",
                    "Please provide an answer for Elopment From Home description"));
                }
            return true;
        }),
    elopementFromHomeTechniques: Yup.string().nullable()
    .test("validate-elopementFromHomeTechniques", "-", function (item) {
        if (this.parent.elopementFromHome == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("elopementFromHomeTechniques",
                "Please provide an answer for Elopment From Home techniques"));
            }
        return true;
    }),
    elopementFromCommunity: Yup.number()
        .test("validate-elopementFromCommunity", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromCommunity",
                    "Please select an answer for Elopement From Community question"));
                }
            return true;
        }),
    elopementFromCommunityFrequency: Yup.number().nullable()
        .test("validate-elopementFromCommunityFrequency", "-", function (item) {
            if (this.parent.elopementFromCommunity == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromCommunityFrequency",
                    "Please select an option for Elopement From Community Frequency"));
                }
            return true;
        }),
    elopementFromCommunityDescription: Yup.string().nullable()
        .test("validate-elopementFromCommunityDescription", "-", function (item) {
            if (this.parent.elopementFromCommunity == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("elopementFromCommunityDescription",
                    "Please provide an answer for Elopement From Community description"));
                }
            return true;
        }),
    elopementFromCommunityTechniques: Yup.string().nullable()
    .test("validate-elopementFromCommunityTechniques", "-", function (item) {
        if (this.parent.elopementFromCommunity == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("elopementFromCommunityTechniques",
                "Please provide an answer for Elopement From Community techniques"));
            }
        return true;
    }),
    rageAndOutbursts: Yup.number()
        .test("validate-rageAndOutbursts", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("rageAndOutbursts",
                    "Please select an answer for Rage and Outbursts question"));
                }
            return true;
        }),
    rageAndOutburstsFrequency: Yup.number().nullable()
        .test("validate-rageAndOutburstsFrequency", "-", function (item) {
            if (this.parent.rageAndOutbursts == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("rageAndOutburstsFrequency",
                    "Please select an option for Rage and Outbursts Frequency"));
                }
            return true;
        }),
    rageAndOutburstsDescription: Yup.string().nullable()
    .test("validate-rageAndOutburstsDescription", "-", function (item) {
        if (this.parent.rageAndOutbursts == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("rageAndOutburstsDescription",
                "Please provide an answer for Rage and Outbursts description"));
            }
        return true;
    }),
    rageAndOutburstsTechniques: Yup.string().nullable()
    .test("validate-rageAndOutburstsTechniques", "-", function (item) {
        if (this.parent.rageAndOutbursts == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("rageAndOutburstsTechniques",
                "Please provide an answer for Rage and Outbursts techniques"));
            }
        return true;
    }),
    inappropriateSexualBehavior: Yup.number()
        .test("validate-inappropriateSexualBehavior", "-", function (item) {
            if (item == enums.YesNoNARadioButtonGroupOptions.NotAnswered) {
                return this.createError(
                    Utility.buildYupErrorMessage("inappropriateSexualBehavior",
                    "Please select an answer for Inappropriate Sexual Behavior question"));
                }
            return true;
        }),
    inappropriateSexualBehaviorFrequency: Yup.number().nullable()
        .test("validate-inappropriateSexualBehaviorFrequency", "-", function (item) {
            if (this.parent.inappropriateSexualBehavior == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("inappropriateSexualBehaviorFrequency",
                    "Please select an option for Inappropriate Sexual Behavior Frequency"));
                }
            return true;
        }),
    inappropriateSexualBehaviorDescription: Yup.string().nullable()
        .test("validate-inappropriateSexualBehaviorDescription", "-", function (item) {
            if (this.parent.inappropriateSexualBehavior == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
                return this.createError(
                    Utility.buildYupErrorMessage("inappropriateSexualBehaviorDescription",
                    "Please provide an answer for Inappropriate Sexual Behavior description"));
                }
            return true;
        }),
    inappropriateSexualBehaviorTechniques: Yup.string().nullable()
    .test("validate-inappropriateSexualBehaviorTechniques", "-", function (item) {
        if (this.parent.inappropriateSexualBehavior == enums.YesNoNARadioButtonGroupOptions.Yes && !item) {
            return this.createError(
                Utility.buildYupErrorMessage("inappropriateSexualBehaviorTechniques",
                "Please provide an answer for Inappropriate Sexual Behavior techniques"));
            }
        return true;
    }),

    availableFrequencyOptions: Yup.array().nullable()
});