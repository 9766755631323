import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../shared-module/helpers/utility";
import { IGatewayTransientStore } from "../../../shared-module/models/IGatewayTransientStore";

export interface IApplicationInformation {
  selfApplying: string,
  entryRelationTypeId: string,
  entryRelationIsBdds: boolean,
  noFirstName: boolean,
  firstName: string,
  lastName: string,
  middleName: string,
  suffixTypeId: string,
  dateOfBirth: string,
  hasMedicaid: string,
  medicaid: string
};

class ApplicationInformationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.ApplicationModel, IApplicationInformation>, 
  IApplicationInformation {

  public selfApplying: string;
  public entryRelationTypeId: string;
  public entryRelationIsBdds: boolean;
  public noFirstName: boolean;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public suffixTypeId: string;
  public dateOfBirth: string;
  public hasMedicaid: string;
  public medicaid: string;

  constructor() {
    this.selfApplying = "applying-myself";
    this.entryRelationTypeId = "";
    this.entryRelationIsBdds = false;
    this.noFirstName = false;
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.suffixTypeId = "";
    this.dateOfBirth = "";
    this.hasMedicaid = "has-not-medicaid";
    this.medicaid = "";

    makeObservable(this, {
      selfApplying: observable,
      entryRelationTypeId: observable,
      entryRelationIsBdds: observable,
      noFirstName: observable,
      firstName: observable,
      lastName: observable,
      middleName: observable,
      suffixTypeId: observable,
      dateOfBirth: observable,
      hasMedicaid: observable,
      medicaid: observable,

      setSelfApplying: action,
      setEntryRelationTypeId: action,
      setEntryRelationIsBdds: action,
      setNoFirstName: action,
      setFirstName: action,
      setLastName: action,
      setMiddleName: action,
      setSuffixTypeId: action,
      setDateOfBirth: action,
      setHasMedicaid: action,
      setMedicaid: action,
      refresh: action
    });
  }

  public setSelfApplying(value: string) {
    this.selfApplying = value;
  }

  public setEntryRelationTypeId(value: string) {
    this.entryRelationTypeId = value;
  }

  public setEntryRelationIsBdds(value: boolean) {
    this.entryRelationIsBdds = value;
  }

  public setNoFirstName(value: boolean) {
    this.noFirstName = value;
  }

  public setFirstName(value: string) {
    this.firstName = value;
  }

  public setLastName(value: string) {
    this.lastName = value;
  }

  public setMiddleName(value: string) {
    this.middleName = value;
  }

  public setSuffixTypeId(value: string) {
    this.suffixTypeId = value;
  }

  public setDateOfBirth(value: string) {
    this.dateOfBirth = value;
  }

  public setHasMedicaid(value: string) {
    this.hasMedicaid = value;
  }

  public setMedicaid(value: string) {
    this.medicaid = value;
  }

  public refresh(application: GatewayOapApi.ApplicationModel): void {
    var state = this.buildState(application);
    this.setSelfApplying(state.selfApplying);
    this.setEntryRelationTypeId(state.entryRelationTypeId);
    this.setEntryRelationIsBdds(state.entryRelationIsBdds);
    this.setNoFirstName(state.noFirstName);
    this.setFirstName(state.firstName);
    this.setLastName(state.lastName);
    this.setMiddleName(state.middleName);
    this.setSuffixTypeId(state.suffixTypeId);
    this.setDateOfBirth(state.dateOfBirth);
    this.setHasMedicaid(state.hasMedicaid);
    this.setMedicaid(state.medicaid)
  }

  public buildState(application: GatewayOapApi.ApplicationModel): IApplicationInformation {
    return {
      selfApplying: Utility.defaultIfNull(application.selfApply, true) ? "applying-myself" : "apply-for-others",
      entryRelationTypeId: Utility.numberToString(application.entryRelationTypeId, ""),
      entryRelationIsBdds: Utility.defaultIfNull(application.entryRelationIsBdds, false),
      noFirstName: Utility.defaultIfNull(application.noFirstName, false),
      firstName: Utility.defaultIfNull(application.firstName, ""),
      lastName: Utility.defaultIfNull(application.lastName, ""),
      middleName: Utility.defaultIfNull(application.middleName, ""),
      suffixTypeId: Utility.numberToString(application.suffixTypeId, ""),
      dateOfBirth: Utility.toHtmlDateString(Utility.toDate(application.dateOfBirth), ""),
      hasMedicaid: Utility.defaultIfNull(application.hasMedicaid, false) ? "has-medicaid" : "has-not-medicaid",
      medicaid: Utility.defaultIfNull(application.medicaidRid, "")
    }
  }
}

export const ApplicationInformationStore = new ApplicationInformationStoreComponent();