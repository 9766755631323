import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ShowThen } from "../../../shared-module/components/show-then/show-then.component";
import { AppSettings } from "../../../app-setting";
import { EventBroadcasterService, SessionStateChangedEventArgs } from "../../../shared-module/events/event-broadcaster.service";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { SessionService } from "shared-module/services/session.service";

function AutoExpireNotificationComponent() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(true);

  function getRemainingTimeText(): string {
    return `less than ${remainingTime && remainingTime <= 60 ? "one (1) minute" : "two (2) minutes"}`;
  }

  function getInactiveMinutes(): string {
    return remainingTime && remainingTime <= 60 ? "14" : "13";
  }

  function resumeSession() {
    setIsActive(false);
    const isGuest = sessionStorage.getItem('isGuestUser');

    if(isGuest) {
      return SessionService.slideGuestSession(Number(sessionStorage.getItem("guestSessionId")));
    }
    else {
      SessionService.getProfile();
    }      
  }

  function logoutSession() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  function expireGuestSession() {
    setIsActive(false);
    var id = sessionStorage.getItem("guestSessionId");
    SessionService.expireGuestSession(Number(id))
  }
  
  function handleClearTimeoutBanner() {
    setIsActive(false);
  }

  function handleAuthenticatedSessionStateChanged(event: SessionStateChangedEventArgs) {
    // console.log('EVENT: ', event);
    if(event.isAuthenticated) {
      setIsGuestUser(false);
      setIsActive(event.remainingTimeInSeconds <= AppSettings.autoLogoutNotificationWindowInSeconds);
      setRemainingTime(event.remainingTimeInSeconds);
      
      if (event.remainingTimeInSeconds <= 0) {
        sessionStorage.setItem('applicationTimeout', 'true');
        NavigationHelper.gotoUnauthorization(navigate); 
        return;    
      }
    }
  }

  function handleGuestSessionStateChanged(event: SessionStateChangedEventArgs) {
    // console.log('EVENT: ', event);
    if(event.remainingTimeInSeconds == -999) { return; }
    setIsGuestUser(false);
    setIsActive(event.remainingTimeInSeconds <= AppSettings.autoLogoutNotificationWindowInSeconds);
    setRemainingTime(event.remainingTimeInSeconds);

    if(event.remainingTimeInSeconds <= 0) {
      expireGuestSession();
      sessionStorage.setItem('applicationTimeout', 'true');
      if( sessionStorage.getItem('fromNbar') == "true" )
      {
        return NavigationHelper.gotoNbarHome(navigate);
      } 
      else 
      {
        return NavigationHelper.gotoHome(navigate);     
      }
    }   
  }

  useEffect(() => {
    EventBroadcasterService.RegisterForGuestSessionExpirationEvent(handleGuestSessionStateChanged);
    EventBroadcasterService.RegisterForSessionStateChangedEvent(handleAuthenticatedSessionStateChanged);
    EventBroadcasterService.RegisterForClearTimeoutBannerEvent(handleClearTimeoutBanner);
    return () => {
      EventBroadcasterService.UnregisterForGuestSessionExpirationEvent(handleGuestSessionStateChanged);
      EventBroadcasterService.UnregisterForSessionStateChangedEvent(handleAuthenticatedSessionStateChanged);
      EventBroadcasterService.UnregisterForClearTimeoutBannerEvent(handleClearTimeoutBanner);
    }
  }, []);

  return (
    <>
      <ShowThen when={isActive}>
        <div className="bds-auto-expire-session-banner">
          <div className="container bds-container p-2">
            <div className="row">
              <div className="col-9 d-flex">
                <div className="align-self-center">
                  <span className="heading">Auto Expiration Notification</span>
                  {" - "}
                  <span className="message">This application has been inactive for { getInactiveMinutes() } minutes. You will be logged out in { getRemainingTimeText() } if there is no further activity to ensure account and personal information remain secure.</span>
                </div>
              </div>
              <div className="col-3">
                <button type="button" className="btn btn-sm bds-btn-primary w-100" onClick={resumeSession}>Resume Session</button>
                <ShowThen when={!isGuestUser}><button type="button" className="btn btn-sm bds-btn-primary w-100 mt-3" onClick={logoutSession}>Log out</button></ShowThen>
              </div>
            </div>
          </div>
        </div>
      </ShowThen>
    </>
  )
}

export const AutoExpireComponent = observer(AutoExpireNotificationComponent)
