
import ReactSelect from 'react-select';

export interface BdsSelectOption {
    value: string;
    label: string;
}

interface Props {
    className?: string;
    isClearable: boolean;
    isDisabled: boolean;
    isSearchable: boolean;
    name: string;
    options: BdsSelectOption[];
    defaultValue?: BdsSelectOption[];
    value?: BdsSelectOption[];
    onChange?: (e) => void;
    onBlur?: (e) => void;
    loadingMessage?: () => React.ReactNode;
}

function BdsMultiSelectComponent({ className, isClearable, isDisabled, isSearchable, 
    name, options, defaultValue, value, onChange, onBlur, loadingMessage }: Props) {
    return <ReactSelect
        defaultValue={defaultValue}
        value={value}
        isMulti={true}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable={isSearchable} 
        name={name}
        options={options}
        className={className}
        classNamePrefix="bds"
        onChange={onChange}
        onBlur={onBlur}
        loadingMessage={loadingMessage}
    />
};

export const BdsMultiSelect = BdsMultiSelectComponent;