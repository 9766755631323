import * as Yup from "yup";
import { INbarStartApplication } from "./nbar-start-application.store";
import { Utility } from "../../../../shared-module/helpers/utility";

// validation schema
export const NbarStartApplicationValidationSchema: Yup.ObjectSchema<INbarStartApplication> = Yup.object({
  firstName: Yup.string()
  .test("validate-firstName", "-",
    function (item) {
      const message = "The First Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
      if (!item) {
        return this.createError(
          Utility.buildYupErrorMessage("firstName", "First name is required."));
      } else if (item) {
        if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
          return this.createError(
            Utility.buildYupErrorMessage("firstName",
              "At least 2 letters are necessary for First Name."));
        } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{2,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("firstName", message));
        }
      }
      return true;
    }),
  lastName: Yup.string()
    .test("validate-lastName", "-",
      function (item) {
        const message = "The Last Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName", "Last name is required."));
        } else if (item.replace(/[^a-zA-Z]/gi, '').length < 2) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName",
              "At least 2 letters are necessary for Last Name."));
        } else if (!new RegExp(/^([a-zA-Z]{0,2}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("lastName", message));
        }
        return true;
      }),
  middleName: Yup.string()
    .test("validate-middleName", "-",
      function (item) {
        if (item && !new RegExp(/^([a-zA-Z]{0,1}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("middleName", "The Middle Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
        }
        return true;
      }),
  suffixTypeId: Yup.string(),
  dateOfBirth: Yup.string()
    .test("validate-dateOfBirth", "-",
      function (item) {
        var date = Utility.fromHtmlDateString(item, null);
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is required."));
        } else if (date == null) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is invalid."));
        } else if (date > Utility.getToday()) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "A date of birth cannot be in the future."));
        } else if (date <= Utility.getMinimumAge()) {
          return this.createError(
            Utility.buildYupErrorMessage("dateOfBirth", "Age cannot exceed 130 years. Please modify date of birth."));
        }
        return true;
      }),
    streetAddress1: Yup.string()
      .test("validate-streetAddress1", "-", function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("streetAddress1",
              "A street address is required."));
        }
        return true;
      }),
    city: Yup.string()
      .test("validate-city", "-", function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("city",
              "A city is required."));
        }
        return true;
      }),
    state: Yup.string()
      .test("validate-state", "-", function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("state",
              "A State is required."));
        }
        return true;
      }),
    zipCode: Yup.string()
      .test("validate-zipCode", "-", function (item) {
        if (!item) {
          return this.createError(
            Utility.buildYupErrorMessage("zipCode",
              "A 5-digit or 9-digit numeric zip code is required."));
        } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("zipCode",
              "Invalid Mailing Address Zip Code (XXXXX-XXXX)."));
        }
        return true;
      }),
    homePhoneNumber: Yup.string()
      .test("validate-homePhoneNumber", "-", function (item) {
        if(!item && (!this.parent.alternatePhoneNumber)) {
          return this.createError(Utility.buildYupErrorMessage("homePhoneNumber",
            "You must provide at least one phone number."));
        } else if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(Utility.buildYupErrorMessage("homePhoneNumber",
            "A telephone number must not being with 0 or 1."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("homePhoneNumber",
              "A telephone number must have 10-digits."));
        }
        return true;
      }),
    alternatePhoneNumber: Yup.string()
      .test("validate-alternatePhoneNumber", "-", function (item) {
        if (item && new RegExp(/^[(][0-1]/).test(item)) {
          return this.createError(
            Utility.buildYupErrorMessage("alternatePhoneNumber",
              "A telephone number must not being with 0 or 1."));
        } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item.replace(/\D+/g, ''))) {
          return this.createError(
            Utility.buildYupErrorMessage("alternatePhoneNumber",
              "A telephone number must have 10-digits."));
        }
        return true;
      }),
    reasonDescription: Yup.string()
      .test("validate-reasonDescription", "-", function(item) {
        if(!item) {
          return this.createError(Utility.buildYupErrorMessage("reasonDescription", 
              "A reason for seeking the CIH Waiver is required."
            )
          )
        }
        return true;
      }),
    guardians: Yup.array().nullable(),
    residents: Yup.array().nullable(),
    triggerResidentValidation: Yup.boolean(),
    residentValidationMessages: Yup.array().nullable()
      .test('validate-residentValidationMessages', "-", function() {
        if(this.parent.triggerResidentValidation) {
            if (!this.parent.addResidentName || !this.parent.addResidentAge || !this.parent.addResidentRelationship) {
                return this.createError( 
                    Utility.buildYupErrorMessage("residentValidationMessages", `All Resident Information fields are required`)
                );
            }
        }
        return true;
    }),
    addResidentName: Yup.string().nullable(),
    addResidentAge: Yup.number().nullable(),
    addResidentRelationship: Yup.string().nullable(),
    additionalInformation: Yup.string().nullable(),
  });