import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { IGatewayTransientStore } from "../../../../shared-module/models/IGatewayTransientStore";
import { BdsSelectOption } from "shared-module/components/multi-select/multi-select.component";
import * as enums from "shared-module/helpers/enums"

export interface INbarBehavioralInformation {
    useOfInappropriateLanguage: number,
    useOfInappropriateLanguageFrequency: number,
    useOfInappropriateLanguageDescription: string,
    useOfInappropriateLanguageTechniques: string,
    refusalToAcceptDirection: number,
    refusalToAcceptDirectionFrequency: number,
    refusalToAcceptDirectionDescription: string,
    refusalToAcceptDirectionTechniques: string,
    verbalOrPhysicalIntimidation: number,
    verbalOrPhysicalIntimidationFrequency: number,
    verbalOrPhysicalIntimidationDescription: string,
    verbalOrPhysicalIntimidationTechniques: string,
    destructionOfPersonalProperty: number,
    destructionOfPersonalPropertyFrequency: number,
    destructionOfPersonalPropertyDescription: string,
    destructionOfPersonalPropertyTechniques: string,
    destructionOfPublicProperty: number,
    destructionOfPublicPropertyFrequency: number,
    destructionOfPublicPropertyDescription: string,
    destructionOfPublicPropertyTechniques: string,
    physicalAgressionTowardsSelf: number,
    physicalAgressionTowardsSelfFrequency: number,
    physicalAgressionTowardsSelfDescription: string,
    physicalAgressionTowardsSelfTechniques: string,
    physicalAgressionTowardsOthers: number,
    physicalAgressionTowardsOthersFrequency: number,
    physicalAgressionTowardsOthersDescription: string,
    physicalAgressionTowardsOthersTechniques: string,
    elopementFromHome: number,
    elopementFromHomeFrequency: number,
    elopementFromHomeDescription: string,
    elopementFromHomeTechniques: string,
    elopementFromCommunity: number,
    elopementFromCommunityFrequency: number,
    elopementFromCommunityDescription: string,
    elopementFromCommunityTechniques: string,
    rageAndOutbursts: number,
    rageAndOutburstsFrequency: number,
    rageAndOutburstsDescription: string,
    rageAndOutburstsTechniques: string,
    inappropriateSexualBehavior: number,
    inappropriateSexualBehaviorFrequency: number,
    inappropriateSexualBehaviorDescription: string,
    inappropriateSexualBehaviorTechniques: string,
    availableFrequencyOptions: BdsSelectOption[]
}

class NbarBehavioralInformationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.BehavioralInformationModel, INbarBehavioralInformation>, 
  INbarBehavioralInformation {
    public useOfInappropriateLanguage: number;
    public useOfInappropriateLanguageFrequency: number;
    public useOfInappropriateLanguageDescription: string;
    public useOfInappropriateLanguageTechniques: string;
    public refusalToAcceptDirection: number;
    public refusalToAcceptDirectionFrequency: number;
    public refusalToAcceptDirectionDescription: string;
    public refusalToAcceptDirectionTechniques: string;
    public verbalOrPhysicalIntimidation: number;
    public verbalOrPhysicalIntimidationFrequency: number;
    public verbalOrPhysicalIntimidationDescription: string;
    public verbalOrPhysicalIntimidationTechniques: string;
    public destructionOfPersonalProperty: number;
    public destructionOfPersonalPropertyFrequency: number;
    public destructionOfPersonalPropertyDescription: string;
    public destructionOfPersonalPropertyTechniques: string;
    public destructionOfPublicProperty: number;
    public destructionOfPublicPropertyFrequency: number;
    public destructionOfPublicPropertyDescription: string;
    public destructionOfPublicPropertyTechniques: string;
    public physicalAgressionTowardsSelf: number;
    public physicalAgressionTowardsSelfFrequency: number;
    public physicalAgressionTowardsSelfDescription: string;
    public physicalAgressionTowardsSelfTechniques: string;
    public physicalAgressionTowardsOthers: number;
    public physicalAgressionTowardsOthersFrequency: number;
    public physicalAgressionTowardsOthersDescription: string;
    public physicalAgressionTowardsOthersTechniques: string;
    public elopementFromHome: number;
    public elopementFromHomeFrequency: number;
    public elopementFromHomeDescription: string;
    public elopementFromHomeTechniques: string;
    public elopementFromCommunity: number;
    public elopementFromCommunityFrequency: number;
    public elopementFromCommunityDescription: string;
    public elopementFromCommunityTechniques: string;
    public rageAndOutbursts: number;
    public rageAndOutburstsFrequency: number;
    public rageAndOutburstsDescription: string;
    public rageAndOutburstsTechniques: string;
    public inappropriateSexualBehavior: number;
    public inappropriateSexualBehaviorFrequency: number;
    public inappropriateSexualBehaviorDescription: string;
    public inappropriateSexualBehaviorTechniques: string;
    public availableFrequencyOptions: BdsSelectOption[];

    constructor() {
        this.useOfInappropriateLanguage = -1;
        this.useOfInappropriateLanguageFrequency = null;
        this.useOfInappropriateLanguageDescription = '';
        this.useOfInappropriateLanguageTechniques = '';
        this.refusalToAcceptDirection = -1;
        this.refusalToAcceptDirectionFrequency = null;
        this.refusalToAcceptDirectionDescription = '';
        this.refusalToAcceptDirectionTechniques = '';
        this.verbalOrPhysicalIntimidation = -1;
        this.verbalOrPhysicalIntimidationFrequency = null;
        this.verbalOrPhysicalIntimidationDescription = '';
        this.verbalOrPhysicalIntimidationTechniques = '';
        this.destructionOfPersonalProperty = -1;
        this.destructionOfPersonalPropertyFrequency = null;
        this.destructionOfPersonalPropertyDescription = '';
        this.destructionOfPersonalPropertyTechniques = '';
        this.destructionOfPublicProperty = -1;
        this.destructionOfPublicPropertyFrequency = null;
        this.destructionOfPublicPropertyDescription = '';
        this.destructionOfPublicPropertyTechniques = '';
        this.physicalAgressionTowardsSelf = -1;
        this.physicalAgressionTowardsSelfFrequency = null;
        this.physicalAgressionTowardsSelfDescription = '';
        this.physicalAgressionTowardsSelfTechniques = '';
        this.physicalAgressionTowardsOthers = -1;
        this.physicalAgressionTowardsOthersFrequency = null;
        this.physicalAgressionTowardsOthersDescription = '';
        this.physicalAgressionTowardsOthersTechniques = '';
        this.elopementFromHome = -1;
        this.elopementFromHomeFrequency = null;
        this.elopementFromHomeDescription = '';
        this.elopementFromHomeTechniques = '';
        this.elopementFromCommunity = -1;
        this.elopementFromCommunityFrequency = null;
        this.elopementFromCommunityDescription = '';
        this.elopementFromCommunityTechniques = '';
        this.rageAndOutbursts = -1;
        this.rageAndOutburstsFrequency = null;
        this.rageAndOutburstsDescription = '';
        this.rageAndOutburstsTechniques = '';
        this.inappropriateSexualBehavior = -1;
        this.inappropriateSexualBehaviorFrequency = null;
        this.inappropriateSexualBehaviorDescription = '';
        this.inappropriateSexualBehaviorTechniques = '';
        this.availableFrequencyOptions = [];
        
        makeObservable(this, {
            useOfInappropriateLanguage: observable,
            useOfInappropriateLanguageFrequency: observable,
            useOfInappropriateLanguageDescription: observable,
            useOfInappropriateLanguageTechniques: observable,
            refusalToAcceptDirection: observable,
            refusalToAcceptDirectionFrequency: observable,
            refusalToAcceptDirectionDescription: observable,
            refusalToAcceptDirectionTechniques: observable,
            verbalOrPhysicalIntimidation: observable,
            verbalOrPhysicalIntimidationFrequency: observable,
            verbalOrPhysicalIntimidationDescription: observable,
            verbalOrPhysicalIntimidationTechniques: observable,
            destructionOfPersonalProperty: observable,
            destructionOfPersonalPropertyFrequency: observable,
            destructionOfPersonalPropertyDescription: observable,
            destructionOfPersonalPropertyTechniques: observable,
            destructionOfPublicProperty: observable,
            destructionOfPublicPropertyFrequency: observable,
            destructionOfPublicPropertyDescription: observable,
            destructionOfPublicPropertyTechniques: observable,
            physicalAgressionTowardsSelf: observable,
            physicalAgressionTowardsSelfFrequency: observable,
            physicalAgressionTowardsSelfDescription: observable,
            physicalAgressionTowardsSelfTechniques: observable,
            physicalAgressionTowardsOthers: observable,
            physicalAgressionTowardsOthersFrequency: observable,
            physicalAgressionTowardsOthersDescription: observable,
            physicalAgressionTowardsOthersTechniques: observable,
            elopementFromHome: observable,
            elopementFromHomeFrequency: observable,
            elopementFromHomeDescription: observable,
            elopementFromHomeTechniques: observable,
            elopementFromCommunity: observable,
            elopementFromCommunityFrequency: observable,
            elopementFromCommunityDescription: observable,
            elopementFromCommunityTechniques: observable,
            rageAndOutbursts: observable,
            rageAndOutburstsFrequency: observable,
            rageAndOutburstsDescription: observable,
            rageAndOutburstsTechniques: observable,
            inappropriateSexualBehavior: observable,
            inappropriateSexualBehaviorFrequency: observable,
            inappropriateSexualBehaviorDescription: observable,
            inappropriateSexualBehaviorTechniques: observable,
            availableFrequencyOptions: observable,

            setUseOfInappropriateLanguage: action,
            setUseOfInappropriateLanguageFrequency: action,
            setUseOfInappropriateLanguageDescription: action,
            setUseOfInappropriateLanguageTechniques: action,
            setRefusalToAcceptDirection: action,
            setRefusalToAcceptDirectionFrequency: action,
            setRefusalToAcceptDirectionDescription: action,
            setRefusalToAcceptDirectionTechniques: action,
            setVerbalOrPhysicalIntimidation: action,
            setVerbalOrPhysicalIntimidationFrequency: action,
            setVerbalOrPhysicalIntimidationDescription: action,
            setVerbalOrPhysicalIntimidationTechniques: action,
            setDestructionOfPersonalProperty: action,
            setDestructionOfPersonalPropertyFrequency: action,
            setDestructionOfPersonalPropertyDescription: action,
            setDestructionOfPersonalPropertyTechniques: action,
            setDestructionOfPublicProperty: action,
            setDestructionOfPublicPropertyFrequency: action,
            setDestructionOfPublicPropertyDescription: action,
            setDestructionOfPublicPropertyTechniques: action,
            setPhysicalAgressionTowardsSelf: action,
            setPhysicalAgressionTowardsSelfFrequency: action,
            setPhysicalAgressionTowardsSelfDescription: action,
            setPhysicalAgressionTowardsSelfTechniques: action,
            setPhysicalAgressionTowardsOthers: action,
            setPhysicalAgressionTowardsOthersFrequency: action,
            setPhysicalAgressionTowardsOthersDescription: action,
            setPhysicalAgressionTowardsOthersTechniques: action,
            setElopementFromHome: action,
            setElopementFromHomeFrequency: action,
            setElopementFromHomeDescription: action,
            setElopementFromHomeTechniques: action,
            setElopementFromCommunity: action,
            setElopementFromCommunityFrequency: action,
            setElopementFromCommunityDescription: action,
            setElopementFromCommunityTechniques: action,
            setRageAndOutbursts: action,
            setRageAndOutburstsFrequency: action,
            setRageAndOutburstsDescription: action,
            setRageAndOutburstsTechniques: action,
            setInappropriateSexualBehavior: action,
            setInappropriateSexualBehaviorFrequency: action,
            setInappropriateSexualBehaviorDescription: action,
            setInappropriateSexualBehaviorTechniques: action,
            setAvailableFrequencyOptions: action,
        });
    }

    public setUseOfInappropriateLanguage(value: number) {
        this.useOfInappropriateLanguage = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setUseOfInappropriateLanguageFrequency(null);
            this.setUseOfInappropriateLanguageDescription('');
            this.setUseOfInappropriateLanguageTechniques('');
        }
    }

    public setUseOfInappropriateLanguageFrequency(value: number) {
        this.useOfInappropriateLanguageFrequency = value;
    }

    public setUseOfInappropriateLanguageDescription(value: string) {
        this.useOfInappropriateLanguageDescription = value;
    }

    public setUseOfInappropriateLanguageTechniques(value: string) {
        this.useOfInappropriateLanguageTechniques = value;
    }

    public setRefusalToAcceptDirection(value: number) {
        this.refusalToAcceptDirection = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setRefusalToAcceptDirectionFrequency(null);
            this.setRefusalToAcceptDirectionDescription('');
            this.setRefusalToAcceptDirectionTechniques('');
        }
    }

    public setRefusalToAcceptDirectionFrequency(value: number) {
        this.refusalToAcceptDirectionFrequency = value;
    }

    public setRefusalToAcceptDirectionDescription(value: string) {
        this.refusalToAcceptDirectionDescription = value;
    }

    public setRefusalToAcceptDirectionTechniques(value: string) {
        this.refusalToAcceptDirectionTechniques = value;
    }

    public setVerbalOrPhysicalIntimidation(value: number) {
        this.verbalOrPhysicalIntimidation = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setVerbalOrPhysicalIntimidationFrequency(null);
            this.setVerbalOrPhysicalIntimidationDescription('');
            this.setVerbalOrPhysicalIntimidationTechniques('');
        }
    }

    public setVerbalOrPhysicalIntimidationFrequency(value: number) {
        this.verbalOrPhysicalIntimidationFrequency = value;
    }

    public setVerbalOrPhysicalIntimidationDescription(value: string) {
        this.verbalOrPhysicalIntimidationDescription = value;
    }

    public setVerbalOrPhysicalIntimidationTechniques(value: string) {
        this.verbalOrPhysicalIntimidationTechniques = value;
    }

    public setDestructionOfPersonalProperty(value: number) {
        this.destructionOfPersonalProperty = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setDestructionOfPersonalPropertyFrequency(null);
            this.setDestructionOfPersonalPropertyDescription('');
            this.setDestructionOfPersonalPropertyTechniques('');
        }
    }

    public setDestructionOfPersonalPropertyFrequency(value: number) {
        this.destructionOfPersonalPropertyFrequency = value;
    }

    public setDestructionOfPersonalPropertyDescription(value: string) {
        this.destructionOfPersonalPropertyDescription = value;
    }

    public setDestructionOfPersonalPropertyTechniques(value: string) {
        this.destructionOfPersonalPropertyTechniques = value;
    }

    public setDestructionOfPublicProperty(value: number) {
        this.destructionOfPublicProperty = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setDestructionOfPublicPropertyFrequency(null);
            this.setDestructionOfPublicPropertyDescription('');
            this.setDestructionOfPublicPropertyTechniques('');
        }
    }

    public setDestructionOfPublicPropertyFrequency(value: number) {
        this.destructionOfPublicPropertyFrequency = value;
    }

    public setDestructionOfPublicPropertyDescription(value: string) {
        this.destructionOfPublicPropertyDescription = value;
    }

    public setDestructionOfPublicPropertyTechniques(value: string) {
        this.destructionOfPublicPropertyTechniques = value;
    }

    public setPhysicalAgressionTowardsSelf(value: number) {
        this.physicalAgressionTowardsSelf = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPhysicalAgressionTowardsSelfFrequency(null);
            this.setPhysicalAgressionTowardsSelfDescription('');
            this.setPhysicalAgressionTowardsSelfTechniques('');
        }
    }

    public setPhysicalAgressionTowardsSelfFrequency(value: number) {
        this.physicalAgressionTowardsSelfFrequency = value;
    }

    public setPhysicalAgressionTowardsSelfDescription(value: string) {
        this.physicalAgressionTowardsSelfDescription = value;
    }

    public setPhysicalAgressionTowardsSelfTechniques(value: string) {
        this.physicalAgressionTowardsSelfTechniques = value;
    }

    public setPhysicalAgressionTowardsOthers(value: number) {
        this.physicalAgressionTowardsOthers = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPhysicalAgressionTowardsOthersFrequency(null);
            this.setPhysicalAgressionTowardsOthersDescription('');
            this.setPhysicalAgressionTowardsOthersTechniques('');
        }
    }

    public setPhysicalAgressionTowardsOthersFrequency(value: number) {
        this.physicalAgressionTowardsOthersFrequency = value;
    }

    public setPhysicalAgressionTowardsOthersDescription(value: string) {
        this.physicalAgressionTowardsOthersDescription = value;
    }

    public setPhysicalAgressionTowardsOthersTechniques(value: string) {
        this.physicalAgressionTowardsOthersTechniques = value;
    }

    public setElopementFromHome(value: number) {
        this.elopementFromHome = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setElopementFromHomeFrequency(null);
            this.setElopementFromHomeDescription('');
            this.setElopementFromHomeTechniques('');
        }
    }

    public setElopementFromHomeFrequency(value: number) {
        this.elopementFromHomeFrequency = value;
    }

    public setElopementFromHomeDescription(value: string) {
        this.elopementFromHomeDescription = value;
    }

    public setElopementFromHomeTechniques(value: string) {
        this.elopementFromHomeTechniques = value;
    }

    public setElopementFromCommunity(value: number) {
        this.elopementFromCommunity = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setElopementFromCommunityFrequency(null);
            this.setElopementFromCommunityDescription('');
            this.setElopementFromCommunityTechniques('');
        }
    }

    public setElopementFromCommunityFrequency(value: number) {
        this.elopementFromCommunityFrequency = value;
    }

    public setElopementFromCommunityDescription(value: string) {
        this.elopementFromCommunityDescription = value;
    }

    public setElopementFromCommunityTechniques(value: string) {
        this.elopementFromCommunityTechniques = value;
    }

    public setRageAndOutbursts(value: number) {
        this.rageAndOutbursts = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setRageAndOutburstsFrequency(null);
            this.setRageAndOutburstsDescription('');
            this.setRageAndOutburstsTechniques('');
        }
    }

    public setRageAndOutburstsFrequency(value: number) {
        this.rageAndOutburstsFrequency = value;
    }

    public setRageAndOutburstsDescription(value: string) {
        this.rageAndOutburstsDescription = value;
    }

    public setRageAndOutburstsTechniques(value: string) {
        this.rageAndOutburstsTechniques = value;
    }

    public setInappropriateSexualBehavior(value: number) {
        this.inappropriateSexualBehavior = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setInappropriateSexualBehaviorFrequency(null);
            this.setInappropriateSexualBehaviorDescription('');
            this.setInappropriateSexualBehaviorTechniques('');
        }
    }

    public setInappropriateSexualBehaviorFrequency(value: number) {
        this.inappropriateSexualBehaviorFrequency = value;
    }

    public setInappropriateSexualBehaviorDescription(value: string) {
        this.inappropriateSexualBehaviorDescription = value;
    }

    public setInappropriateSexualBehaviorTechniques(value: string) {
        this.inappropriateSexualBehaviorTechniques = value;
    }

    public setAvailableFrequencyOptions(values: GatewayOapApi.FrequencyTypesModel[]) {
        const options: BdsSelectOption[] = []
        values.map((option) => {
            return options.push({label: option.name, value:option.frequencyTypeId.toString()})
        })
        this.availableFrequencyOptions = options;
    }


    public refresh(info: GatewayOapApi.BehavioralInformationModel): void {
        var state = this.buildState(info);
        this.setUseOfInappropriateLanguage(state.useOfInappropriateLanguage);
        this.setUseOfInappropriateLanguageFrequency(state.useOfInappropriateLanguageFrequency);
        this.setUseOfInappropriateLanguageDescription(state.useOfInappropriateLanguageDescription);
        this.setUseOfInappropriateLanguageTechniques(state.useOfInappropriateLanguageTechniques);
        this.setRefusalToAcceptDirection(state.refusalToAcceptDirection);
        this.setRefusalToAcceptDirectionFrequency(state.refusalToAcceptDirectionFrequency);
        this.setRefusalToAcceptDirectionDescription(state.refusalToAcceptDirectionDescription);
        this.setRefusalToAcceptDirectionTechniques(state.refusalToAcceptDirectionTechniques);
        this.setVerbalOrPhysicalIntimidation(state.verbalOrPhysicalIntimidation);
        this.setVerbalOrPhysicalIntimidationFrequency(state.verbalOrPhysicalIntimidationFrequency);
        this.setVerbalOrPhysicalIntimidationDescription(state.verbalOrPhysicalIntimidationDescription);
        this.setVerbalOrPhysicalIntimidationTechniques(state.verbalOrPhysicalIntimidationTechniques);
        this.setDestructionOfPersonalProperty(state.destructionOfPersonalProperty);
        this.setDestructionOfPersonalPropertyFrequency(state.destructionOfPersonalPropertyFrequency);
        this.setDestructionOfPersonalPropertyDescription(state.destructionOfPersonalPropertyDescription);
        this.setDestructionOfPersonalPropertyTechniques(state.destructionOfPersonalPropertyTechniques);
        this.setDestructionOfPublicProperty(state.destructionOfPublicProperty);
        this.setDestructionOfPublicPropertyFrequency(state.destructionOfPublicPropertyFrequency);
        this.setDestructionOfPublicPropertyDescription(state.destructionOfPublicPropertyDescription);
        this.setDestructionOfPublicPropertyTechniques(state.destructionOfPublicPropertyTechniques);
        this.setPhysicalAgressionTowardsSelf(state.physicalAgressionTowardsSelf);
        this.setPhysicalAgressionTowardsSelfFrequency(state.physicalAgressionTowardsSelfFrequency);
        this.setPhysicalAgressionTowardsSelfDescription(state.physicalAgressionTowardsSelfDescription);
        this.setPhysicalAgressionTowardsSelfTechniques(state.physicalAgressionTowardsSelfTechniques);
        this.setPhysicalAgressionTowardsOthers(state.physicalAgressionTowardsOthers);
        this.setPhysicalAgressionTowardsOthersFrequency(state.physicalAgressionTowardsOthersFrequency);
        this.setPhysicalAgressionTowardsOthersDescription(state.physicalAgressionTowardsOthersDescription);
        this.setPhysicalAgressionTowardsOthersTechniques(state.physicalAgressionTowardsOthersTechniques);
        this.setElopementFromHome(state.elopementFromHome);
        this.setElopementFromHomeFrequency(state.elopementFromHomeFrequency);
        this.setElopementFromHomeDescription(state.elopementFromHomeDescription);
        this.setElopementFromHomeTechniques(state.elopementFromHomeTechniques);
        this.setElopementFromCommunity(state.elopementFromCommunity);
        this.setElopementFromCommunityFrequency(state.elopementFromCommunityFrequency);
        this.setElopementFromCommunityDescription(state.elopementFromCommunityDescription);
        this.setElopementFromCommunityTechniques(state.elopementFromCommunityTechniques);
        this.setRageAndOutbursts(state.rageAndOutbursts);
        this.setRageAndOutburstsFrequency(state.rageAndOutburstsFrequency);
        this.setRageAndOutburstsDescription(state.rageAndOutburstsDescription);
        this.setRageAndOutburstsTechniques(state.rageAndOutburstsTechniques);
        this.setInappropriateSexualBehavior(state.inappropriateSexualBehavior);
        this.setInappropriateSexualBehaviorFrequency(state.inappropriateSexualBehaviorFrequency);
        this.setInappropriateSexualBehaviorDescription(state.inappropriateSexualBehaviorDescription);
        this.setInappropriateSexualBehaviorTechniques(state.inappropriateSexualBehaviorTechniques);
    }

    public buildState(info: GatewayOapApi.BehavioralInformationModel): INbarBehavioralInformation {
        return {
            useOfInappropriateLanguage: info.hasInappropriateLanguge,
            useOfInappropriateLanguageFrequency: info.languageFreqTypeId,
            useOfInappropriateLanguageDescription: info.languageDescription,
            useOfInappropriateLanguageTechniques: info.languageTechniques,
            refusalToAcceptDirection: info.refuseToAcceptDirection,
            refusalToAcceptDirectionFrequency: info.refusalFreqTypeId,
            refusalToAcceptDirectionDescription: info.refusalDescription,
            refusalToAcceptDirectionTechniques: info.refusalTechniques,
            verbalOrPhysicalIntimidation: info.hasIntimidation,
            verbalOrPhysicalIntimidationFrequency: info.intimidationFreqTypeId,
            verbalOrPhysicalIntimidationDescription: info.intimidationDescription,
            verbalOrPhysicalIntimidationTechniques: info.intimidationTechniques,
            destructionOfPersonalProperty: info.hasDestructionOfPersonalProp,
            destructionOfPersonalPropertyFrequency: info.destructionOfPersonalPropFreqTypeId,
            destructionOfPersonalPropertyDescription: info.destructionOfPersonalPropDesc,
            destructionOfPersonalPropertyTechniques: info.destructionOfPersonalPropTech,
            destructionOfPublicProperty: info.hasDestructionOfPublicProp,
            destructionOfPublicPropertyFrequency: info.destructionOfPublicPropFreqTypeId,
            destructionOfPublicPropertyDescription: info.destructionOfPublicPropDesc,
            destructionOfPublicPropertyTechniques: info.destructionOfPublicPropTech,
            physicalAgressionTowardsSelf: info.hasSelfAgression,
            physicalAgressionTowardsSelfFrequency: info.selfAgressionFreqTypeId,
            physicalAgressionTowardsSelfDescription: info.selfAgressionDesc,
            physicalAgressionTowardsSelfTechniques: info.selfAgressionTech,
            physicalAgressionTowardsOthers: info.hasAgressionOthers,
            physicalAgressionTowardsOthersFrequency: info.agressionOthersFreqTypeId,
            physicalAgressionTowardsOthersDescription: info.agressionOthersDesc,
            physicalAgressionTowardsOthersTechniques: info.agressionOthersTech,
            elopementFromHome: info.hasElopementHome,
            elopementFromHomeFrequency: info.elopementHomeFreqTypeId,
            elopementFromHomeDescription: info.elopementHomeDesc,
            elopementFromHomeTechniques: info.elopementHomeTech,
            elopementFromCommunity: info.hasElopementCommunity,
            elopementFromCommunityFrequency: info.elopementCommunityFreqTypeId,
            elopementFromCommunityDescription: info.elopementCommunityDesc,
            elopementFromCommunityTechniques: info.elopementCommunityTech,
            rageAndOutbursts: info.hasRageAndOutbursts,
            rageAndOutburstsFrequency: info.rageAndOutburstsFreqTypeId,
            rageAndOutburstsDescription: info.rageAndOutburstsDesc,
            rageAndOutburstsTechniques: info.rageAndOutburstsTech,
            inappropriateSexualBehavior: info.hasInappropriateSexualConduct,
            inappropriateSexualBehaviorFrequency: info.inappropriateSexualConductFreqTypeId,
            inappropriateSexualBehaviorDescription: info.inappropriateSexualConductDesc,
            inappropriateSexualBehaviorTechniques: info.inappropriateSexualConductTech,
            availableFrequencyOptions: this.availableFrequencyOptions || [],
        }
    }
  }

  export const NbarBehavioralInformation = new NbarBehavioralInformationStoreComponent();