import { useEffect } from "react";

interface Props {
  step: number,
  totalSteps: number
}

function PageTitleSetterComponent(props: Props) {
  useEffect(() => {
    document.title = `BDS Gateway - Step ${props.step}/${props.totalSteps}`;
  })

  return <></>
}

export const PageTitleSetter = PageTitleSetterComponent;