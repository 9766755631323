import { GatewayOapApi } from "../../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "../api.base.resource";

export class NbarPreferencesResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

    public getNbarPreferences(preferenceInformationId: number, abortController?: AbortController): Promise<GatewayOapApi.ApiSingleResponseOfPreferenceInformationModel>
    {
        return super.getRequest(`api/v1/nbar/preference/${preferenceInformationId}`, null, abortController) as
            Promise<GatewayOapApi.ApiSingleResponseOfPreferenceInformationModel>;
    }

    public saveNbarPreferences(
        preferenceInformationId: number, loveMostDescription: string, fearMostDescription: string, dislikeMostDescription: string, angerMostDescription: string, abortController?: AbortController)
        : Promise<GatewayOapApi.ApiIntegerResponse>
    {
        console.log('NbarPreferencesResource.saveNbarPreferences: ', preferenceInformationId)
      return super.postRequest(`api/v1/nbar/preference/update`, 
      null, 
      {
        preferenceInformationId, loveMostDescription, fearMostDescription, dislikeMostDescription, angerMostDescription,
      },
      abortController) as 
        Promise<GatewayOapApi.ApiIntegerResponse>;
    }
}