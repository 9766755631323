export enum YesNoNARadioButtonGroupOptions {
    "NotAnswered" = -1,
    "No" = 0,
    "Yes" = 1,
    "NotApplicable" = 2
}

export enum StudentStatusRadioButtonGroupOptions {
    "NotAnswered" = -1,
    "FullTime" = 0,
    "PartTime" = 1,
    "NotApplicable" = 2
}