import { GatewayOapApi } from "../models/gateway-oap-api.models";
import { ApiBaseResource, IApiConfig } from "./api.base.resource";

export class LookupResource extends ApiBaseResource {
  constructor(apiConfig: IApiConfig) {
    super(apiConfig);
  }

  public getCounties(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfCountyModel> {
    return super.getRequest(`api/v1/lookups/counties`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfCountyModel>;
  }

  public getStates(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfStateModel> {
    return super.getRequest(`api/v1/lookups/states`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfStateModel>;
  }

  public getEducationalTypesNoSchool(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfEducationalTypeModel> {
    return super.getRequest(`api/v1/lookups/educational-types/no-school`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfEducationalTypeModel>;
  }

  public getEducationalTypesSchool(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfEducationalTypeModel> {
    return super.getRequest(`api/v1/lookups/educational-types/school-completed`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfEducationalTypeModel>;
  }

  public getEntryRelationshipTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfEntryRelationshipTypeModel> {
    return super.getRequest(`api/v1/lookups/entry-relationship-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfEntryRelationshipTypeModel>;
  }

  public getGenders(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfGenderModel> {
    return super.getRequest(`api/v1/lookups/genders`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfGenderModel>;
  }

  public getLivingArrangements(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfLivingArrangementModel> {
    return super.getRequest(`api/v1/lookups/living-arrangements`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfLivingArrangementModel>;
  }

  public getMaritalStatuses(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfMaritalStatusModel> {
    return super.getRequest(`api/v1/lookups/marital-statuses`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfMaritalStatusModel>;
  }

  public getPreferredLanguages(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfPreferredLanguageModel> {
    return super.getRequest(`api/v1/lookups/preferred-languages`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfPreferredLanguageModel>;
  }

  public getSuffixTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfSuffixTypeModel> {
    return super.getRequest(`api/v1/lookups/suffix-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfSuffixTypeModel>;
  }

  public getDocumentTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfDocumentTypeModel> {
    return super.getRequest(`api/v1/lookups/application-document-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfDocumentTypeModel>;
  }

  public getBehaviorFrequencyTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfBehaviorFrequencyTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-behavior-frequency-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfBehaviorFrequencyTypesModel>;
  }

  public getBehaviorManagementServiceTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfBehaviorManagementServiceTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-behavior-management-service-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfBehaviorManagementServiceTypesModel>;
  }

  public getFrequencyTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfFrequencyTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-frequency-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfFrequencyTypesModel>;
  }

  public getPrescriptionChangeTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfPrescriptionChangeTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-prescription-change-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfPrescriptionChangeTypesModel>;
  }

  public getNbarRelationshipTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfRelationshipTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-relationship-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfRelationshipTypesModel>;
  }

  public getTreatmentTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfTreatmentTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-treatment-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfTreatmentTypesModel>;
  }

  public getWaiverTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfWaiverTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-waiver-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfWaiverTypesModel>;
  }

  public getMedicaidCategoryTypes(abortController?: AbortController): Promise<GatewayOapApi.ApiMultipleResponseOfMedicaidCategoryTypesModel> {
    return super.getRequest(`api/v1/lookups/nbar-medicaid-category-types`, null, abortController) as
      Promise<GatewayOapApi.ApiMultipleResponseOfMedicaidCategoryTypesModel>;
  }

}