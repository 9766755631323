import { useEffect, useRef, useState } from "react";
import { AppSettings } from "../../../../app-setting";
import { Card } from "../../../../shared-module/components/card/card.component";
import * as bootstrap from "bootstrap";
import { NavigationHelper } from "../../../../shared-module/helpers/navigation-helper";
import { useNavigate } from "react-router-dom";
import { ShowThen } from "../../../../shared-module/components/show-then/show-then.component";
import $ from "jquery";
import "../../../../assets/scripts/print-this";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { AppStateService } from "../../../services/app-state-service";
import { ApiConfig } from "shared-module/services/api-config";
import { SessionService } from "shared-module/services/session.service";
import { NbarDefinitions } from "shared-module/components/nbar-definitions/nbar-definitions.component";
import { NbarReferences } from "shared-module/components/nbar-references/nbar-references.component";

function NbarInstructions() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<GatewayOapApi.UserModel>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const tooltipRef = useRef();


  function handleModalConfirm() : () => Promise<void> {
    sessionStorage.setItem("isGuestUser", "true");
    return;
  }
  
  useEffect(() => {
    bindPrintButton();

    // const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipRef.current, {
    //   boundary: document.body,
    //   container: '[data-bs-toggle="tooltip"]',
    //   trigger: 'hover'
    // });

    setIsLoading(true);
    Utility.showLoadingOverlay();
    SessionService.isAuthenticated()
    .then((response) => {
      if (!response && AppStateService.showGuestApplicationDisclaimer) {
        AppStateService.setShowGuestApplicationDisclaimer(false);
        Utility.alert("Warning",
          <>
            You are currently not logged in.<br />
            Any progress made on this application will be lost after 15 minutes of inactivity.
          </>, handleModalConfirm());
      }
    });


    Promise.all([
      SessionService.isAuthenticated(),
      SessionService.getProfile()
    ])
      .then((response) => {
        setIsAuthenticated(response[0]);
        setUser(response[1]);
        return Promise.resolve(true);
      })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });

    return () => {
      // tooltip.dispose();
    }

  }, []);

  function handleLogout() {
    NavigationHelper.gotoUnauthorization(navigate);
  }

  function handleGoToNextStep() {
      NavigationHelper.gotoNbarCreateApplication(navigate)
  }

  function bindPrintButton() {
    $("#print-instructions").on('click', function () {
      ($("#instructions") as any).printThis({
        debug: false,
        importCSS: false,
        header: "<h3>" + AppSettings.bdsAcronym + " Gateway Instructions</h3>"
      });
      return false;

    });
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-8 order-2 order-md-0">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button" onClick={() => handleLogout()}
              className="btn bds-btn-primary">Logout</button>
          </ShowThen>
        </div>
        <div className="col-12 col-md-2 text-start text-md-center order-1 order-md-1 mt-2 mt-md-0">
        </div>
        <div className="col-12 col-md-2 text-start text-md-end order-0 order-md-2">
          <ShowThen when={!isLoading && isAuthenticated}>
            <button type="button"
              onClick={() => NavigationHelper.gotoDashboard(navigate)}
              className="btn bds-btn-primary">My Dashboard</button>
          </ShowThen>
        </div>
      </div>

      <div id="instructions" className="mt-4">
        <div className="row mt-4">
          <Card>
            <p>The <strong>Bureau of Disabilities Services</strong> is the placement authority for persons with a developmental disability in the State of Indiana, 
              including all placements in community-based settings and Intermediate Care Facilities for Individuals with Intellectual Disabilities (ICF/IDD).
            </p>

            <p>Individuals who are designated to meet Emergency Intervention Criteria are individuals who have been determined to require BDS placement after a review of their case indicates that all possibilities for natural and informal support systems have exhausted. 
              These individuals may be considered for supervised group living or for waiver services under the emergency placement categories. 
              As placement authority, BDS will decide which placement will be given to the individual.  As a non-entitlement program, waiver placement will be utilized after entitlement options are exhausted or determined by BDS to be inappropriate for the individual.
            </p>

            <p>With every request, individual cases will be reviewed to determine if ALL possibilities for care supports have been exhausted prior to assigning a priority waiver slot.
              Individuals receiving funding through other waivers, ICF/IIDs, and other state funded programs shall be excluded from receiving a priority waiver if their current funding can meet their basic needs as determined by BDS.
            </p>
          </Card>
        </div>

          <div className="row mt-4">
            <Card>
              <div className="bds-section-heading">Criteria for Waiver Placement</div>
              <div className="row mt-2">
                <p><strong>Loss of Primary Caregiver: </strong> 
                  An individual who has lived in the family home and been left with no individual to care for them due to death of the primary caregiver may qualify for this category. 
                  Individuals who have lost primary caregiver but still have a secondary caregiver who can fill the primary caregiver role do not meet this criterion.
                </p>
              </div>

              <div className="row mt-2">
                <p><strong>Caregivers over 80 years of age: </strong> 
                  An individual who has lived in the family home and have been cared for by a primary caregiver over 80 years of age may be considered for a priority waiver
                  If an individual is also receiving care from a secondary caregiver less than 80 years of age who is capable of becoming the primary caregiver, the individual does not meet this criterion. 
                  For those who do meet, an affidavit must be signed attesting to the caregiver's age.
                </p>
              </div>

              <div className="row mt-2">
                <p><strong>Evidence of abuse or neglect in the current institutional or home placement: </strong> 
                An individual who has incurred abuse or neglect as substantiated by BDS or ISDH and alternate placement in a supervised group living setting is not available or is determined by BDS to be an inappropriate option.
                </p>
              </div>

              <div className="row mt-2">
                <p><strong>Extraordinary Health & Safety Risk: </strong> 
                  Individuals who are at extraordinary health and safety risk, as determined by the Division Director, and alternate placement in a supervised group living setting is not available or is determined by BDS to be an inappropriate option.
                </p>
              </div>
          </Card>
        </div>

      <div className="row mt-4">
        <Card>
          <div className="bds-section-heading">Definitions</div>
          <NbarDefinitions></NbarDefinitions>
        </Card>
      </div>

      <div className="row mt-4">
        <Card>
          <div className="bds-section-heading">References</div>
          <NbarReferences></NbarReferences>
        </Card>
      </div>

      <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
              <button type="button" className="btn bds-btn-primary" disabled={isLoading}
              onClick={() => NavigationHelper.gotoNbarHome(navigate)}>Back to Home</button>
              {/* <IsAuthenticated>
              <button type="button" className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                  onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
              </IsAuthenticated> */}
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end">
              <button type="button" disabled={isLoading} onClick={() => handleGoToNextStep()}
              className="btn bds-btn-primary">Agree and Proceed With Application</button>
          </div>
      </div>

      </div>
    </>
  );
};

export default NbarInstructions
