import { action, makeObservable, observable } from "mobx";
import { GatewayOapApi } from "../../../../shared-module/models/gateway-oap-api.models";
import { Utility } from "../../../../shared-module/helpers/utility";
import { IGatewayTransientStore } from "../../../../shared-module/models/IGatewayTransientStore";
import { BdsSelectOption } from "shared-module/components/multi-select/multi-select.component";
import * as enums from "shared-module/helpers/enums";

export interface INbarDemographicInformation {
    hasMedicaid: number,
    medicaidNumber: string,
    medicaidCategoryTypeId: number,
    hasSSI: number,
    hasGuardian: number,
    nameOfGuardian: string,
    hasPlacement: number,
    placementAgency: string,
    placementAgencyDate: string,
    placementConsidered: number,
    placementNotesYes: string,
    placementNotesNo: string,
    hasCurrentDDRS: number,
    ddrsSelectedOptions: BdsSelectOption[],
    ddrsOtherText: string,
    naturalSupportAvailable: string,
    naturalSupportComments: string,
    hasCommunitySupportExplored: number,
    communitySupportComments: string,
    hasLostPrimaryCaregiver: number,
    dateOfPrimaryCaregiverLoss: string,
    primaryCaregiverCapacityDescription: string,
    currentCaregiverArrangementDescription: string,
    primaryCaregiverOverEighty: number,
    hasSecondaryCaregiver: number,
    secondaryCaregiverLessThanEighty: number,
    abuseNeglectSubstantiated: number,
    abuseNeglectDescription: string,
    hasExtraordinarySafetyRisk: number,
    extraordinarySafetyRiskDescription: string,
    isMinor: boolean,
    guardianshipFile: File,
    guardianshipFileName: string,
};

class NbarDemographicInformationStoreComponent
  implements IGatewayTransientStore<GatewayOapApi.DemographicInformationModel, INbarDemographicInformation>, 
  INbarDemographicInformation {
    public hasMedicaid: number;
    public medicaidNumber: string;
    public medicaidCategoryTypeId: number;
    public hasSSI: number;
    public hasGuardian: number;
    public nameOfGuardian: string;
    public hasPlacement: number;
    public placementAgency: string;
    public placementAgencyDate: string;
    public placementConsidered: number;
    public placementNotesYes: string;
    public placementNotesNo: string;
    public hasCurrentDDRS: number;
    public ddrsSelectedOptions: BdsSelectOption[];
    public ddrsOtherText: string;
    public waiverOptions: BdsSelectOption[];
    public naturalSupportAvailable: string;
    public naturalSupportComments: string;
    public hasCommunitySupportExplored: number;
    public communitySupportComments: string;
    public hasLostPrimaryCaregiver: number;
    public dateOfPrimaryCaregiverLoss: string;
    public primaryCaregiverCapacityDescription: string;
    public currentCaregiverArrangementDescription: string;
    public primaryCaregiverOverEighty: number;
    public hasSecondaryCaregiver: number;
    public secondaryCaregiverLessThanEighty: number;
    public abuseNeglectSubstantiated: number;
    public abuseNeglectDescription: string;
    public hasExtraordinarySafetyRisk: number;
    public extraordinarySafetyRiskDescription: string;
    public isMinor: boolean;
    public guardianshipFile: File;
    public guardianshipFileName: string;


    constructor() {
        this.hasMedicaid = undefined;
        this.medicaidNumber = '';
        this.medicaidCategoryTypeId = undefined;
        this.hasSSI = -1;
        this.hasGuardian = -1;
        this.nameOfGuardian = '';
        this.hasPlacement = -1;
        this.placementAgency = '';
        this.placementAgencyDate = '';
        this.placementConsidered = -1;
        this.placementNotesYes = '';
        this.placementNotesNo = '';
        this.hasCurrentDDRS = -1;
        this.ddrsSelectedOptions = [];
        this.ddrsOtherText = '';
        this.naturalSupportAvailable = '';
        this.naturalSupportComments = '';
        this.hasCommunitySupportExplored = -1;
        this.communitySupportComments = '';
        this.hasLostPrimaryCaregiver = -1;
        this.dateOfPrimaryCaregiverLoss = '';
        this.primaryCaregiverCapacityDescription = '';
        this.currentCaregiverArrangementDescription = '';
        this.primaryCaregiverOverEighty = -1;
        this.hasSecondaryCaregiver = -1;
        this.secondaryCaregiverLessThanEighty = -1;
        this.abuseNeglectSubstantiated = -1;
        this.abuseNeglectDescription = '';
        this.hasExtraordinarySafetyRisk = -1;
        this.extraordinarySafetyRiskDescription = '';
        this.isMinor = false;
        this.guardianshipFile = null;
        this.guardianshipFileName = '';

        makeObservable(this, {
            hasMedicaid: observable,
            medicaidNumber: observable,
            medicaidCategoryTypeId: observable,
            hasSSI: observable,
            hasGuardian: observable,
            nameOfGuardian: observable,
            hasPlacement: observable,
            placementAgency: observable,
            placementAgencyDate: observable,
            placementConsidered: observable,
            placementNotesYes: observable,
            placementNotesNo: observable,
            hasCurrentDDRS: observable,
            ddrsSelectedOptions: observable,
            ddrsOtherText: observable,
            waiverOptions: observable,
            naturalSupportAvailable: observable,
            naturalSupportComments: observable,
            hasCommunitySupportExplored: observable,
            communitySupportComments: observable,
            hasLostPrimaryCaregiver: observable,
            dateOfPrimaryCaregiverLoss: observable,
            primaryCaregiverCapacityDescription: observable,
            currentCaregiverArrangementDescription: observable,
            primaryCaregiverOverEighty: observable,
            hasSecondaryCaregiver: observable,
            secondaryCaregiverLessThanEighty: observable,
            abuseNeglectSubstantiated: observable,
            abuseNeglectDescription: observable,
            hasExtraordinarySafetyRisk: observable,
            extraordinarySafetyRiskDescription: observable,
            isMinor: observable,
            guardianshipFile: observable,
            guardianshipFileName: observable,

            setHasMedicaid: action,
            setMedicaidNumber: action,
            setMedicaidCategoryTypeId: action,
            setHasSSI: action,
            setHasGuardian: action,
            setNameOfGuardian: action,
            setHasPlacement: action,
            setPlacementAgency: action,
            setPlacementAgencyDate: action,
            setPlacementConsidered: action,
            setPlacementNotesYes: action,
            setPlacementNotesNo: action,
            setHasCurrentDDRS: action,
            setDdrsSelectedOptions: action,
            setDdrsOtherText: action,
            setWaiverOptions: action,
            setNaturalSupportAvailable: action,
            setNaturalSupportComments: action,
            setHasCommunitySupportExplored: action,
            setCommunitySupportComments: action,
            setHasLostPrimaryCaregiver: action,
            setDateOfPrimaryCaregiverLoss: action,
            setPrimaryCaregiverCapacityDescription: action,
            setCurrentCaregiverArrangementDescription: action,
            setPrimaryCaregiverOverEighty: action,
            setHasSecondaryCaregiver: action,
            setSecondaryCaregiverLessThanEighty: action,
            setAbuseNeglectSubstantiated: action,
            setAbuseNeglectDescription: action,
            setHasExtraordinarySafetyRisk: action,
            setExtraordinarySafetyRiskDescription: action,
            setIsMinor: action,
            setGuardianshipFile: action,
            setGuardianshipFileName: action
        });
    }

    public setHasMedicaid(value: number) {
        this.hasMedicaid = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setMedicaidNumber('');-
            this.setMedicaidCategoryTypeId(null);
        }
    }

    public setMedicaidNumber(value: string) {
        this.medicaidNumber = value;
    }

    public setMedicaidCategoryTypeId(value: number) {
        this.medicaidCategoryTypeId = value;
    }

    public setHasSSI(value: number) {
        this.hasSSI = value;
    }

    public setHasGuardian(value: number) {
        this.hasGuardian = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setNameOfGuardian('');
        }
    }

    public setNameOfGuardian(value: string) {
        this.nameOfGuardian = value;
    }

    public setHasPlacement(value: number) {
        this.hasPlacement = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPlacementAgency('');
            this.setPlacementAgencyDate('');
        }
    }

    public setPlacementAgency(value: string) {
        this.placementAgency = value;
    }

    public setPlacementAgencyDate(value: string) {
        this.placementAgencyDate = value;
    }

    public setPlacementConsidered(value: number) {
        this.placementConsidered = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setPlacementNotesNo('');
        } else {
            this.setPlacementNotesYes('');
        }
    }

    public setPlacementNotesYes(value: string) {
        this.placementNotesYes = value;
    }

    public setPlacementNotesNo(value: string) {
        this.placementNotesNo = value;
    }

    public setHasCurrentDDRS(value: number) {
        this.hasCurrentDDRS = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setDdrsSelectedOptions([]);
            this.setDdrsOtherText('');
        } 
    }

    public setDdrsSelectedOptions(values: BdsSelectOption[]) {
        this.ddrsSelectedOptions = values;
        var otherOptionSelected = values.find((opt) => {
            return opt.label == "Other"
        });
        if(otherOptionSelected == undefined) {
            this.setDdrsOtherText('');
        }
    }

    public setDdrsOtherText(value: string) {
        this.ddrsOtherText = value;
    }

    public setWaiverOptions(values: GatewayOapApi.WaiverTypesModel[]) {
        let waivers = [];
        values.map((option) => {
            waivers.push({label: option.waiverName, value: option.waiverTypeId});
        })
        this.waiverOptions = waivers;
    }

    public setNaturalSupportAvailable(value: string) {
        this.naturalSupportAvailable = value;
    }

    public setNaturalSupportComments(value: string) {
        this.naturalSupportComments = value;
    }

    public setHasCommunitySupportExplored(value: number) {
        this.hasCommunitySupportExplored = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setCommunitySupportComments('');
        } 
    }

    public setCommunitySupportComments(value: string) {
        this.communitySupportComments = value;
    }

    public setHasLostPrimaryCaregiver(value: number) {
        this.hasLostPrimaryCaregiver = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setDateOfPrimaryCaregiverLoss('');
            this.setPrimaryCaregiverCapacityDescription('');
            this.setCurrentCaregiverArrangementDescription('');
            this.setPrimaryCaregiverOverEighty(-1);
        } 
    }

    public setDateOfPrimaryCaregiverLoss(value: string) {
        this.dateOfPrimaryCaregiverLoss = value;
    }

    public setPrimaryCaregiverCapacityDescription(value: string) {
        this.primaryCaregiverCapacityDescription = value;
    }

    public setCurrentCaregiverArrangementDescription(value: string) {
        this.currentCaregiverArrangementDescription = value;
    }

    public setPrimaryCaregiverOverEighty(value: number) {
        this.primaryCaregiverOverEighty = value;
    }

    public setHasSecondaryCaregiver(value: number) {
        this.hasSecondaryCaregiver = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setSecondaryCaregiverLessThanEighty(-1);
        } 
    }

    public setSecondaryCaregiverLessThanEighty(value: number) {
        this.secondaryCaregiverLessThanEighty = value;
    }

    public setAbuseNeglectSubstantiated(value: number) {
        this.abuseNeglectSubstantiated = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setAbuseNeglectDescription('');
        } 
    }

    public setAbuseNeglectDescription(value: string) {
        this.abuseNeglectDescription = value;
    }

    public setHasExtraordinarySafetyRisk(value: number) {
        this.hasExtraordinarySafetyRisk = value;
        if(value != enums.YesNoNARadioButtonGroupOptions.Yes) {
            this.setExtraordinarySafetyRiskDescription('');
        } 
    }

    public setExtraordinarySafetyRiskDescription(value: string) {
        this.extraordinarySafetyRiskDescription = value;
    }

    public setIsMinor(value: string) {
        const ageDifMs = Date.now() - new Date(value).getTime();
        const ageDate = new Date(ageDifMs);
        this.isMinor = Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
    }

    public setGuardianshipFile(value: File) {
        this.guardianshipFile = value;
    }

    public setGuardianshipFileName(value: string) {
        this.guardianshipFileName = value;
    }

    public mapDdrsSelectedOptions(selected: GatewayOapApi.WaiverTypesModel[]) : BdsSelectOption[] {
        const selectedIds = selected.map(x => x.waiverTypeId);
        return this.waiverOptions.filter(x => selectedIds.includes(Number(x.value)));
    }

    public refresh(application: GatewayOapApi.DemographicInformationModel): void {
        var state = this.buildState(application);
        this.setHasMedicaid(state.hasMedicaid);
        this.setMedicaidNumber(state.medicaidNumber);
        this.setMedicaidCategoryTypeId(state.medicaidCategoryTypeId);
        this.setHasSSI(state.hasSSI);
        this.setHasGuardian(state.hasGuardian);
        this.setNameOfGuardian(state.nameOfGuardian);
        this.setHasPlacement(state.hasPlacement);
        this.setPlacementAgency(state.placementAgency);
        this.setPlacementAgencyDate(state.placementAgencyDate);
        this.setPlacementConsidered(this.isMinor ? enums.YesNoNARadioButtonGroupOptions.NotApplicable : state.placementConsidered);
        this.setPlacementNotesYes(this.isMinor ? '' : state.placementNotesYes);
        this.setPlacementNotesNo(this.isMinor ? '' : state.placementNotesNo);
        this.setHasCurrentDDRS(state.hasCurrentDDRS);
        this.setDdrsSelectedOptions(state.ddrsSelectedOptions);
        this.setDdrsOtherText(state.ddrsOtherText);
        this.setNaturalSupportAvailable(state.naturalSupportAvailable);
        this.setNaturalSupportComments(state.naturalSupportComments);
        this.setHasCommunitySupportExplored(state.hasCommunitySupportExplored);
        this.setCommunitySupportComments(state.communitySupportComments);
        this.setHasLostPrimaryCaregiver(state.hasLostPrimaryCaregiver);
        this.setDateOfPrimaryCaregiverLoss(state.dateOfPrimaryCaregiverLoss);
        this.setPrimaryCaregiverCapacityDescription(state.primaryCaregiverCapacityDescription);
        this.setCurrentCaregiverArrangementDescription(state.currentCaregiverArrangementDescription);
        this.setPrimaryCaregiverOverEighty(state.primaryCaregiverOverEighty);
        this.setHasSecondaryCaregiver(state.hasSecondaryCaregiver);
        this.setSecondaryCaregiverLessThanEighty(state.secondaryCaregiverLessThanEighty);
        this.setAbuseNeglectSubstantiated(state.abuseNeglectSubstantiated);
        this.setAbuseNeglectDescription(state.abuseNeglectDescription);
        this.setHasExtraordinarySafetyRisk(state.hasExtraordinarySafetyRisk);
        this.setExtraordinarySafetyRiskDescription(state.extraordinarySafetyRiskDescription);
        this.setGuardianshipFile(state.guardianshipFile);
        this.setGuardianshipFileName(state.guardianshipFileName);
    }

    public buildState(info: GatewayOapApi.DemographicInformationModel): INbarDemographicInformation {
        return {
            hasMedicaid: info.hasMedicaid,
            medicaidNumber: info.medicaidRid,
            medicaidCategoryTypeId: info.medicaidCategoryTypeId,
            hasSSI: info.hasSsi,
            hasGuardian: info.hasGuardian,
            nameOfGuardian: info.guardianName,
            hasPlacement: info.hasPreviousPlacement,
            placementAgency: info.placementAgency,
            placementAgencyDate: info.placementAgencyDate,
            placementConsidered: info.hasSGLPlacementExplored,
            placementNotesYes: info.sglPlacementOutcome,
            placementNotesNo: info.sglNotExploringReason,
            hasCurrentDDRS: info.isRecevingDDRSServices,
            ddrsSelectedOptions: this.mapDdrsSelectedOptions(info.waiverInformation),
            ddrsOtherText: info.waiverInformation.find(x => x.waiverTypeId == Number(this.waiverOptions.find(y => y.label.toLowerCase() == 'other').value))?.otherWaiverDescription || '',
            naturalSupportAvailable: info.informalSupportDescription || '',
            naturalSupportComments: info.informalSupportUtilizedDescription || '',
            hasCommunitySupportExplored: info.hasCommunitySupportExplored,
            communitySupportComments: info.communitySupportDescription,
            hasLostPrimaryCaregiver: info.hasLossOfPrimaryCaregiver,
            dateOfPrimaryCaregiverLoss: Utility.toHtmlDateString(new Date(info.dateOfLoss), ""),
            primaryCaregiverCapacityDescription: info.primaryCaregiverCapacityDescription,
            currentCaregiverArrangementDescription: info.currentCaregiverArrangementDescription,
            primaryCaregiverOverEighty: info.isPrimaryCaregiverAbove80,
            hasSecondaryCaregiver: info.hasSecondaryCaregiver,
            secondaryCaregiverLessThanEighty: info.isSecondaryCaregiverBelow80,
            abuseNeglectSubstantiated: info.hasAbuseOrNeglect,
            abuseNeglectDescription: info.abuseOrNeglectDescription,
            hasExtraordinarySafetyRisk: info.hasExtraordinarySafetyRisk,
            extraordinarySafetyRiskDescription: info.safetyRiskDescription,
            isMinor: false,
            guardianshipFile: this.guardianshipFile || null,
            guardianshipFileName: this.guardianshipFileName || '',
        }
    }
  }

  export const NbarDemographicInformation = new NbarDemographicInformationStoreComponent();