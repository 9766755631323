import * as Yup from "yup";
import { INbarApplicantSignature } from "./nbar-signature.store";
import { Utility } from "../../../../shared-module/helpers/utility";

// validation schema
export const NbarApplicantSignatureValidationSchema: Yup.ObjectSchema<INbarApplicantSignature> = Yup.object({
  applicantSignatureSeed: Yup.string(),
  applicantCertified: Yup.boolean()
    .test("validate-applicantCertified", "-", function (item) {
      if (!item && !this.parent.legalRepresentativeCertified) {
        return this.createError(
          Utility.buildYupErrorMessage("applicantCertified",
            "applicantCertified field must be true"));
      }
      return true;
    }),
    additionalInformation: Yup.string(),
    firstName: Yup.string()
    .test("validate-firstName", "-",
      function (item) {
        const message = "The First Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
        if (!this.parent.noFirstName && !item) {
          return this.createError(
            Utility.buildYupErrorMessage("firstName", "First name is required."));
        } else if (item) {
          if (item.length < 2) {
            return this.createError(
              Utility.buildYupErrorMessage("firstName",
                "At least 2 letters are necessary for First Name."));
          } else if (!new RegExp(/^[a-zA-Z][a-zA-Z-']{1,34}$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("firstName", message));
          }
        }
        return true;
      }),
    lastName: Yup.string()
      .test("validate-lastName", "-",
        function (item) {
          const message = "The Last Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct.";
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("lastName", "Last name is required."));
          } else if (item.length < 2) {
            return this.createError(
              Utility.buildYupErrorMessage("lastName",
                "At least 2 letters are necessary for Last Name."));
          } else if (!new RegExp(/^[a-zA-Z][a-zA-Z-']{1,34}$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("lastName", message));
          }
          return true;
        }),
    middleName: Yup.string()
      .test("validate-middleName", "-",
        function (item) {
          if (item && !new RegExp(/^([a-zA-Z]{0,1}$|[a-zA-Z][a-zA-Z-']{1,34})$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("middleName", "The Middle Name field can have a hyphen or apostrophe, but other special characters and numbers are not allowed. Please correct."));
          }
          return true;
        }),
    suffixTypeId: Yup.string(),
    dateOfBirth: Yup.string()
      .test("validate-dateOfBirth", "-",
        function (item) {
          var date = Utility.fromHtmlDateString(item, null);
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is required."));
          } else if (date == null) {
            return this.createError(
              Utility.buildYupErrorMessage("dateOfBirth", "Date of birth is invalid."));
          } else if (date > Utility.getToday()) {
            return this.createError(
              Utility.buildYupErrorMessage("dateOfBirth", "A date of birth cannot be in the future."));
          } else if (date < Utility.fromHtmlDateString("1800-01-01", null)) {
            return this.createError(
              Utility.buildYupErrorMessage("dateOfBirth", "A date of birth cannot be too much in the past."));
          }
          return true;
        }),
      streetAddress1: Yup.string()
        .test("validate-streetAddress1", "-", function (item) {
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("streetAddress1",
                "A street address is required."));
          }
          return true;
        }),
      city: Yup.string()
        .test("validate-city", "-", function (item) {
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("city",
                "A city is required."));
          }
          return true;
        }),
      state: Yup.string()
        .test("validate-state", "-", function (item) {
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("state",
                "A State is required."));
          }
          return true;
        }),
      zipCode: Yup.string()
        .test("validate-zipCode", "-", function (item) {
          if (!item) {
            return this.createError(
              Utility.buildYupErrorMessage("zipCode",
                "A 5-digit or 9-digit numeric zip code is required."));
          } else if (item && !new RegExp(/^[0-9]{5}([-][0-9]{4}){0,1}$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("zipCode",
                "Invalid Mailing Address Zip Code (XXXXX-XXXX)."));
          }
          return true;
        }),
      homePhoneNumber: Yup.string()
        .test("validate-homePhoneNumber", "-", function (item) {
          if(!item && (!this.parent.alternatePhoneNumber)) {
            return this.createError(Utility.buildYupErrorMessage("homePhoneNumber",
              "You must provide at least one phone number."));
          } else if (item && new RegExp(/^[(][0-1]/).test(item)) {
            return this.createError(Utility.buildYupErrorMessage("homePhoneNumber",
              "A telephone number must not being with 0 or 1."));
          } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("homePhoneNumber",
                "A telephone number must have 10-digits."));
          }
          return true;
        }),
      alternatePhoneNumber: Yup.string()
        .test("validate-alternatePhoneNumber", "-", function (item) {
          if (item && new RegExp(/^[(][0-1]/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("alternatePhoneNumber",
                "A telephone number must not being with 0 or 1."));
          } else if (item && !new RegExp(/^[2-9][0-9]{9}$/).test(item)) {
            return this.createError(
              Utility.buildYupErrorMessage("alternatePhoneNumber",
                "A telephone number must have 10-digits."));
          }
          return true;
        }),
      reasonDescription: Yup.string()
        .test("validate-reasonDescription", "-", function(item) {
          if(!item) {
            return this.createError(Utility.buildYupErrorMessage("reasonDescription", 
                "A reason for seeking the CIH Waiver is required."
              )
            )
          }
          return true;
        }),
      guardians: Yup.array().nullable(),
      residents: Yup.array().nullable(),
      triggerResidentValidation: Yup.boolean(),
      residentValidationMessages: Yup.array().nullable()
        .test('validate-residentValidationMessages', "-", function() {
          if(this.parent.triggerResidentValidation) {
              if (!this.parent.addResidentName || !this.parent.addResidentAge || !this.parent.addResidentRelationship) {
                  return this.createError( 
                      Utility.buildYupErrorMessage("residentValidationMessages", `All Resident Information fields are required`)
                  );
              }
          }
          return true;
      }),
      addResidentName: Yup.string().nullable(),
      addResidentAge: Yup.number().nullable(),
      addResidentRelationship: Yup.string().nullable(),
      legalRepresentativeSignature: Yup.string().nullable(),
      legalRepresentativeCertified: Yup.bool(),
      legalRepresentativeSignatureSeed: Yup.string().nullable(),
      legalRepresentativeFullName: Yup.string().nullable()
      .test('validate-legalRepresentativeFullName', '-', function(item) {
        if (this.parent.legalRepresentativeCertified && !item) {
          return this.createError(
            Utility.buildYupErrorMessage("legalRepresentativeCertified",
              "legalRepresentativeCertified field must be true"));
        }
        return true;
      })
});