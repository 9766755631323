import * as Yup from "yup";
import { IDocumentUpload } from "./applicant-document-upload.store";
import { Utility } from "../../../shared-module/helpers/utility";
import { AppSettings } from '../../../app-setting';

export const DocumentUploadValidationSchema: Yup.ObjectSchema<IDocumentUpload> = Yup.object({
  applicationRefId: Yup.string(),
  documentTypes: Yup.array().nullable(),
  confirmationOfDiagnosisFileName: Yup.string().nullable(),
  confirmationOfDiagnosisTypeId: Yup.number().nullable(),
  confirmationOfDiagnosisFile: Yup.mixed<File>().nullable()
    .test("confirmationOfDiagnosisFileSize", "-", function (value) {
      if (!validateFileSize(value)) {
        // console.log('inside confirmation of diagnosis file size create error');
        return this.createError(
          Utility.buildYupErrorMessage("confirmationOfDiagnosisFile",
            "Confirmation Of Diagnosis file is too large"));
      }
      return true;
    })
    .test("confirmationOfDiagnosisFileType", "-", function (value) {
      if (!validateFileType(value)) {
        // console.log('inside confirmation of diagnosis file type create error');
        return this.createError(
          Utility.buildYupErrorMessage("confirmationOfDiagnosisFile",
            "Confirmation Of Diagnosis file type is not supported"));
      }
      return true;
    }),
  authorizationForDisclosureFileName: Yup.string().nullable(),
  authorizationForDisclosureTypeId: Yup.number().nullable(),
  authorizationForDisclosureFile: Yup.mixed<File>().nullable()
    .test("authorizationForDisclosureFileSize", "-", function (value) {
      if (!validateFileSize(value)) {
        // console.log('inside authorization for disclosure file size create error');
        return this.createError(
          Utility.buildYupErrorMessage("authorizationForDisclosureFile",
            "Authorization For Disclosure file is too large"));
      }
      return true;
    })
    .test("authorizationForDisclosureFileType", "-", function (value) {
      if (!validateFileType(value)) {
        // console.log('inside authorization for disclosure file type create error');
        return this.createError(
          Utility.buildYupErrorMessage("authorizationForDisclosureFile",
            "Authorization For Disclosure file type is not supported"));
      }
      return true;
    }),
});

function validateFileSize(value: File) {
  if (value) {
    return value.size <= AppSettings.maxUploadFileSize
  }
  return true;
}

function validateFileType(value: File) {
  if (value) {
    const validFileTypes = AppSettings.validUploadFileFormats;
    const extension = value.name.split('.').pop().toLowerCase();
    return validFileTypes.filter(type => type == `.${extension}`).length > 0;
  }
  return true;
}