
enum AppEventBroadcastEvents {
  /* 
    Raised when server request fails due to session expiry
    Client application needs to update itself for this event    
  */
  APP_EVENT_SESSION_EXPIRED = "APP.EVENTS.SESSION.EXPIRED",
  /*
    Raised when application state changes such as the status monitor
    API is called and a response is received.
  */
  APP_EVENT_SESSION_STATE_CHANGED = "APP.EVENTS.SESSION.STATE.CHANGED",

  /*
    Raise this event to clear a previously activated timeout banner
    any network activity should raise this event to immediately dismiss the banner,
    rather than waiting on the polling mechanism to update
  */
  APP_EVENT_CLEAR_TIMEOUT_BANNER = "APP.EVENTS.CLEAR.TIMEOUT.BANNER",


  /*
    Raised when an application is logged out based on user request or
    session state change. Any logout based on client side activity should
    cal this.
  */
  APP_EVENT_LOGGED_OUT = "APP.EVENTS.LOGGED.OUT",
  /*
    Raised when an ongoing user application is expired. 
  */
  APP_EVENT_APPLICATION_EXPIRED = "APP.EVENTS.APPLICATION.EXPIRED",
  /*
    Raised when a guest user application is expired. 
  */
  APP_EVENT_GUEST_SESSION_EXPIRATION = "APP_EVENT_GUEST_SESSION_EXPIRATION",

  /*
    Raised when internal error has been cleared after being raised
  */
  APP_EVENT_CLEAR_API_ERROR_500 = "APP.EVENTS.API.CLEAR.ERROR.500",
  /*
   Raise when serverside HTTP errors are received. 
  */
  APP_EVENT_API_ERROR_500 = "APP.EVENTS.API.ERROR.500",
  APP_EVENT_API_ERROR_503 = "APP.EVENTS.API.ERROR.503",
  APP_EVENT_API_ERROR_400 = "APP.EVENTS.API.ERROR.400",
  APP_EVENT_API_ERROR_401 = "APP.EVENTS.API.ERROR.401",
  APP_EVENT_API_ERROR_403 = "APP.EVENTS.API.ERROR.403"
}

export interface SessionStateChangedEventArgs {
  isAuthenticated: boolean,
  remainingTimeInSeconds: number,
  hasActiveMaintenance: boolean
}

class EventBroadcastServiceComponent {
  private listeners: { event: string, callback: any }[] = [];

  constructor() {
  }

  private on(event, callback) {
    var listener = {
      event: event,
      callback: callback
    };

    this.listeners.push(listener);
    document.addEventListener(event, (e) => callback(e.detail));
    console.debug(event, ': Event listener added');
  }

  private dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  private remove(event, handler) {
    var listener = this.listeners.find((item) => {
      return item.event == event && item.callback == handler;
    });

    if (listener) {
      document.removeEventListener(listener.event, listener.callback);
      console.debug(event, ': Event listener removed');
      return;
    }

    console.debug(event, ': Event listener not found - not removed');
  }

  /* Unregister for Event */
  public UnregisterForBadRequestErrorEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_API_ERROR_400, handler)
  }

  public UnregisterForUnautorizedRequestErrorEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_API_ERROR_401, handler)
  }

  public UnregisterForForbiddenRequestErrorEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_API_ERROR_403, handler)
  }

  public UnregisterForServerResponseErrorEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_API_ERROR_500, handler)
  }

  public UnregisterForClearServerResponseErrorEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_CLEAR_API_ERROR_500, handler)
  }

  public UnregisterApplicationSessionExpiredEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_APPLICATION_EXPIRED, handler)
  }

  public UnregisterForSessionExpiredEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_SESSION_EXPIRED, handler)
  }

  public UnregisterForSessionStateChangedEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_SESSION_STATE_CHANGED, handler)
  }

  public UnregisterForLoggedOutExpiredEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_LOGGED_OUT, handler)
  }

  public UnregisterForGuestSessionExpirationEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_GUEST_SESSION_EXPIRATION, handler)
  }

  public UnregisterForClearTimeoutBannerEvent(handler: any) {
    this.remove(AppEventBroadcastEvents.APP_EVENT_CLEAR_TIMEOUT_BANNER, handler);
  }

  /* Register for Event */
  public RegisterForBadRequestErrorEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_API_ERROR_400, handler)
  }

  public RegisterForUnautorizedRequestErrorEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_API_ERROR_401, handler)
  }

  public RegisterForForbiddenRequestErrorEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_API_ERROR_403, handler)
  }

  public RegisterApplicationSessionExpiredEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_APPLICATION_EXPIRED, handler)
  }

  public RegisterForServerResponseErrorEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_API_ERROR_500, handler)
  }

  public RegisterForClearServerResponseErrorEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_CLEAR_API_ERROR_500, handler)
  }

  public RegisterForSessionExpiredEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_SESSION_EXPIRED, handler)
  }

  public RegisterForSessionStateChangedEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_SESSION_STATE_CHANGED, handler)
  }

  public RegisterForLoggedOutExpiredEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_LOGGED_OUT, handler)
  }

  public RegisterForGuestSessionExpirationEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_GUEST_SESSION_EXPIRATION, handler)
  }

  public RegisterForClearTimeoutBannerEvent(handler: any) {
    this.on(AppEventBroadcastEvents.APP_EVENT_CLEAR_TIMEOUT_BANNER, handler);
  }

  /* Raise Events */
  public RaiseBadRequestErrorEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_API_ERROR_400, {})
  }

  public RaiseUnautorizedRequestErrorEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_API_ERROR_401, {})
  }

  public RaiseForbiddenRequestErrorEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_API_ERROR_403, {})
  }

  public RaiseServerResponseErrorEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_API_ERROR_500, {})
  }

  public RaiseClearServerResponseErrorEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_CLEAR_API_ERROR_500, {})
  }

  public RaiseApplicationSessionExpiredEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_APPLICATION_EXPIRED, {})
  }

  public RaiseSessionExpiredEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_SESSION_EXPIRED, {})
  }

  public RaiseSessionStateChangedEvent(event: SessionStateChangedEventArgs) {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_SESSION_STATE_CHANGED, event);
  }

  public RaiseGuestSessionExpirationEvent(event: SessionStateChangedEventArgs) {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_GUEST_SESSION_EXPIRATION, event);
  }

  public RaiseLoggedOutEvent() {
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_LOGGED_OUT, {})
  }

  public RaiseClearTimeoutBannerEvent() {
    console.log('raiseClearTimeoutBannerEvent');
    this.dispatch(AppEventBroadcastEvents.APP_EVENT_CLEAR_TIMEOUT_BANNER, {});
  }
}

export const EventBroadcasterService = new EventBroadcastServiceComponent();
